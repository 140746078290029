import React from 'react'
import { useTranslation } from 'react-i18next'
// import { FaHandHoldingUsd } from 'react-icons/fa'

const Shop = ({ shopData, list }) => {
    const { t } = useTranslation()
    return (
        <>
            <div className="container mx-auto mt-[4rem]">
                <h2 className='text-center font-bold lg:text-[2rem] text-[20px] my-9'>{shopData?.heading}</h2>
                {
                    shopData?.lists.map((item, i) => (
                        <div className="grid lg:grid-cols-2  " key={i}>
                            <div className={`${i % 2 ? 'lg:order-0 order-1' : 'lg:order-1 order-1'} lg:my-[7rem] 2xl:my-[8rem]  py-5 mx-6 `}>
                                <h2 className='text-[28px] font-bold '>{item?.name}</h2>
                                <p className='py-2 max-w-[550px]'>{item?.title}</p>
                                {list && i === 3 &&
                                    <div>
                                        <ul>
                                            <li><span className='font-bold pr-2'>{t('saved')}:</span>{t('web_list_rental_18')}</li>
                                            <li><span className='font-bold pr-2'>{t('booked')}:</span>{t('web_list_rental_19')}</li>
                                            <li><span className='font-bold pr-2'>{t('pending')}:</span>{t('web_list_rental_20')}</li>
                                            <li><span className='font-bold pr-2'>{t('archived')}:</span>{t('web_list_rental_21')}</li>
                                        </ul>
                                    </div>
                                }
                                {/* <button className='px-8 py-2 border-2 rounded-md my-2 text-[18px] border-[#F79F15] text-[#F79F15] hover:bg-[#F79F15] hover:text-white'>{t('web_rental_20')}
                                </button> */}

                            </div>

                            <div className={`relative group  ${i % 2 ? 'lg:order-1 order-0' : 'order-0'}`}>
                                <img src={item.hoverImg} alt="shop_image" className='h-full w-full object-cover' />
                                <div className='absolute bg-black group-hover:opacity-[0.3] opacity-0 top-0  w-full h-full'></div>
                                <div className={`absolute top-[40%] ${item?.name?.length > 20 ? 'left-[10%]' :'left-[25%]'} text-white group-hover:opacity-100 opacity-0  flex flex-col justify-center items-center`}>
                                    <img src={item.original} alt="hover_image" />
                                    <h2 className='py-2 font-bold text-[2rem]'>{item.name}</h2>
                                </div>
                            </div>

                        </div>
                    ))
                }

            </div>

        </>
    )
}

export default Shop