import React from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../utils/loaderButton'

const Confirermation = ({ handleConfirm, close, confirmLoading }) => {
    const { t } = useTranslation()
    return (
        <div className='p-10'>
            <div className='' >
                <h1 className='text-[30px] font-bold text-center'> {t('are_you_sure')} </h1>
                <p className='text-[20px] font-samibold mt-2'> {t('confirmation')} </p>
                <div className='flex pt-10 gap-5'>
                    {/* <button onClick={() => handleConfirm()} className='bg-[#F79F15] w-[50%] rounded-md  text-white'>{t('yes')} </button> */}
                    <button onClick={() => close()} className='bg-red-500  w-[60%] rounded-md py-2 my-2 text-white'>{t('no')}</button>
                    <button onClick={() => handleConfirm()} className='bg-[#F79F15] w-[60%] rounded-md py-2 my-2 text-white'>
                        {
                            confirmLoading ?
                                <Loader />
                                :
                                t('yes')}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Confirermation