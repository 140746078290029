import React, { useState } from 'react'
import keys from '../../../assests/svg/house-key.svg'
import hands from '../../../assests/svg/briefcase-2.svg'
import { useEffect } from 'react'
// import keys2 from '../../../assests/images/key2.png'
// import hands2 from '../../../assests/images/hand2.png'
const Choose = ({ handleNext, State , t  }) => {
  const [active, setActive] = useState(null)
  const [Error, setError] = useState("")
  // group-hover:bg-gradient-to-r from-[#F79F15]  to-[#FFC000]
  // group-hover:bg-gradient-to-r from-[#F79F15]  to-[#FFC000]


  const Submit = () => {
    if (active !== null) {
      let lister_type = active === 0 ? "owner" : "realtor"
      let paylod = {
        lister_type: lister_type
      }
      handleNext(paylod)
    }
    else {
      setError("Please Choose any Identifies")
    }
  }

  const handleSelect = (id) => {
    setActive(id)
    setError('')
  }


  useEffect(() => {
    if (State?.lister_type) {
      setActive(State?.lister_type === "owner" ? 0 : 1)
    }
    else {
      setActive(null)
    }
  }, [])

  return (
    <>
      <h2 className='text-center text-[30px] font-bold'>{t('choose_property')}</h2>
      <div className="grid lg:grid-cols-2 gap-5  pt-[3rem] ">
        <div className='group'>
          <div onClick={() => handleSelect(0)} className={`${active === 0 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  max-w-[500px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <img src={keys} className="w-[30%] " alt="keys" />
            <h2 className='text-[1.5rem] font-bold'>{t('propty_owner')} </h2>

          </div>
        </div>
        <div className='group'>
          <div onClick={() => handleSelect(1)} className={`${active === 1 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  max-w-[500px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <img src={hands} className="w-[30%]" alt="keys" />
            <h2 className='text-[1.5rem] font-bold '>{t('Realtor')}</h2>

          </div>
        </div>
      </div>
      <span className='text-left text-red-500 text-lg pt-5'>{Error}</span>

      <div className='flex justify-end py-3'>
        <button onClick={() => Submit()} disabled={active === null ? true : false} className='font-semibold disabled:cursor-not-allowed bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{`${`${t('Next')}`}`}</button>
      </div>

    </>
  )
}

export default Choose