import React, { useEffect, useState } from 'react'
import keys from '../../../assests/images/keys.png'
// import { MdArrowForwardIos } from 'react-icons/md'
import { GetLeasedPeriod } from '../../../lib/Api/RantelServiceApi'
import { Loader } from '../../../utils/loaderButton'
import { useSelector } from 'react-redux'

const LeasedPeriod = ({ handleNext, handleBack, State , t  }) => {

  const [leased, setLeased] = useState([])
  const [loading, setLoading] = useState(false)
  const [leasedId, setLeasdId] = useState(null)
  const [error, setError] = useState("")

  const lng = useSelector((state) =>  state?.userAuth?.language)

  useEffect(() => {
    let fetchLeased = async () => {
      setLoading(true)
      let { res } = await GetLeasedPeriod();
      const ConvertObj = Object.assign({}, ...res)
      if (res) {
        setLoading(false)
        setLeased(ConvertObj?.lease_periods)
      }
      else {
        setLoading(false)
      }
    }
    setTimeout(() => {
      fetchLeased()
      
    }, 1000);
  }, [lng])


  const OnSubmit = () => {
    if (leasedId !== null) {
      let data = {
        "leased_period_id": leasedId
      }
      handleNext(data)
    }
    else {
      setError("Leased period is required ")
    }


  }

  useEffect(() => {
    if (State?.leased_period_id) {
      setLeasdId(State?.leased_period_id)
    }
    else {
      setLeasdId(null)
    }
  }, [])

  return (
    <>
      <div className='xl:mx-20 lg:mx-20 md:mx-20 '>
        <h2 className='font-bold text-[20px]'>{t('leased_p')}</h2>
        {
          loading ? <Loader type="loadData" />
            :
            <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 gap-5 mt-4">
              {
                leased?.map((item, i) => (
                  <div className={`${leasedId === item.id ? 'border-2 border-[#F79F15]' : 'bg-white '} rounded-md border shadow-md p-1`} key={i} onClick={() => setLeasdId(item?.id)}>
                    <div className='flex justify-center flex-col items-center cursor-pointer p-2'>
                      <img src={item?.icon_image || keys} className='w-[50%] object-cover' alt="keys" />
                      <div className='flex flex-col text-center'>
                        <h2 className='font-semibold text-[18px] pt-4'>{item?.title}</h2>
                        <p>{item?.description}</p>
                      </div>
                      {/* <MdArrowForwardIos className='text-[40px]' /> */}
                    </div>
                  </div>
                ))
              }
            </div>
        }
        <span className='text-red-500 text-md mt-3'>{error}</span>
      </div>
      {
        loading === false &&
        <div className='flex justify-between py-3'>
          <button type='button'  onClick={() => handleBack()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
          <button onClick={() => OnSubmit()} disabled={leasedId === null ? true : false} className='font-semibold bg-[#F79F15] disabled:cursor-not-allowed px-6 py-1.5 text-white rounded-md'>{t('Next')}</button>

        </div>
      }
    </>
  )
}

export default LeasedPeriod