import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect } from 'react';
import { UpdateRental } from '../../../../lib/Api/RantelServiceApi';
import { Loader } from '../../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';

const schema = yup.object({
    rent: yup.string().required(),
    security_deposit: yup.string().required(),
});


const EditRentAmount = ({ close, data , setEditType}) => {
    const [loading, setLoading] = useState(false)
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });


    const onSubmit = async (data) => {
        setLoading(true)
        // data.images = null

        let { res, error } = await UpdateRental(data)
        if (res) {
            setLoading(false)
            close(false)
            setEditType('')
        }
        else if (error) {
            setLoading(false)

        }
    }

    useEffect(() => {
        reset(data)
    }, [data])


    const {t} = useTranslation()

    return (
        <div className='p-5 max-w-[600px]'>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className='grid xl:grid-cols-2 xl:gap-5 lg:grid-cols-2 lg:gap-5 md:grid-cols-2 md:gap-5 '>
                    <div className="flex flex-col">
                        <label>Rent Amount</label>
                        <input type="number" {...register('rent')} placeholder='$50' className={`focus:outline-none py-2 px-2 border rounded-md w-full  ${errors.rent && 'border-red-500'}`} />
                        {errors.rent && (
                            <p className="text-red-500 text-sm text-left pt-2">{errors.rent.message}</p>
                        )}
                    </div>
                    <div className="flex flex-col">
                        <label>Security Deposit</label>
                        <input type="number" {...register('security_deposit')} placeholder='$50' className={`focus:outline-none py-2 px-2 border rounded-md w-full  ${errors.secuity && 'border-red-500'}`} />
                        {errors.security_deposit && (
                            <p className="text-red-500 text-sm text-left pt-2">{errors.security_deposit.message}</p>
                        )}
                    </div>

                </div>
                <div className='flex items-center py-5 justify-end '>
                    <button type='button' onClick={() => close(false)} className='w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>{t('cancel')}</button>
                    {
                        loading ? <div className='ml-5'><Loader type="saved" /></div>
                            :
                            <button className='w-[138px] py-2 ml-5 rounded-md bg-[#FF8C00] text-white'>{t('save')}</button>
                    }
                </div>
            </form>
        </div>
    )
}

export default EditRentAmount