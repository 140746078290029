import React from 'react'
import com1 from '../../assests/images/comment1.png'
import com2 from '../../assests/images/comment2.png'
const Comments = () => {
    const comments = [com1, com2]
    return (
        <>
            <div>
                {
                    comments.map((list, i) => (
                        <div className='flex my-5 ' key={i}>
                            <div>
                                <img src={list} className="lg:w-[220px] w-[350px] my-4" alt="comment_image" />
                            </div>
                            <div className='mx-3'>
                                <div className='flex items-center '>
                                    <h2 className='font-semibold'>BY Hong hang on jan 13, 2022 </h2>
                                    <span className='mx-4 bg-blue-500 px-6  text-white '>New</span>
                                </div>
                                <p className='font-normal mt-1'>Nam tempus turpis at metus scelerisque placerat nulla deumantos sollicitudin felis. Pellentesque diam dolor, elementum  lobortis at, mollis ut risus. Sedcuman des faucibus sullamcorper mattis fusce molestie elit a lorem tempus scelerisque blandit est cursus. Quisque volutpat orci ut metus malesuada in interdumos lectus anmos scelerisque lorem. Praesent deu odio des nisios dullamcorper sultricies. Cum sociis natoque penatibus et magnis dis parturient montes...</p>
                            </div>
                        </div>
                    ))
                }
            </div>
            
        </>
    )
}

export default Comments