import React, { useState } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { AiFillEye, AiFillEyeInvisible, AiOutlineKey } from 'react-icons/ai';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';


const SettingPopup = ({ permition, Toggle }) => {
    const [show] = useState(permition)
    const [showPass, setShowPass] = useState(false)

    const handleClose = () => {
        Toggle(false)
    };

    const {t} = useTranslation()
    const schema = yup.object({
        password: yup.string().required(t('pass_req')),
    });
    

    const { register, watch, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });


    const onSubmit = async (data) => {
    }

    return (
        <div>
            <Modal open={show} onClose={() => handleClose()} center>
                <div className='p-10'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=''>
                            <div className={`flex flex-col pt-2 my-3  w-full ${watch('password') && ' border border-[#F79F15]'}`}>
                                <label>{t('current_password')}</label>
                                <div className='flex items-center border p-1 rounded-md'>
                                    <AiOutlineKey />
                                    <input type={showPass ? "text" : "password"}  {...register('password')} className='p-2  w-full focus:outline-none ' name="password" placeholder={t('password')} />
                                    {
                                        showPass ?
                                            <AiFillEyeInvisible onClick={() => setShowPass(false)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                                            :
                                            <AiFillEye onClick={() => setShowPass(true)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />

                                    }

                                </div>
                            </div>
                            {errors.password && (
                                <p className="text-red-500 text-sm ">{errors.password.message}</p>
                            )}

                        </div>

                        <div className=''>
                            <div className={`flex flex-col pt-2 my-3  w-full ${watch('password') && ' border border-[#F79F15]'}`}>
                                <label> {t('password')}</label>
                                <div className='flex items-center border p-1 rounded-md'>
                                    <AiOutlineKey />
                                    <input type={showPass ? "text" : "password"}  {...register('password')} className='p-2  w-full focus:outline-none ' name="password" placeholder={t('password')}/>
                                    {
                                        showPass ?
                                            <AiFillEyeInvisible onClick={() => setShowPass(false)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                                            :
                                            <AiFillEye onClick={() => setShowPass(true)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />

                                    }

                                </div>
                            </div>
                            {errors.password && (
                                <p className="text-red-500 text-sm ">{errors.password.message}</p>
                            )}

                        </div>
                        <div className=''>
                            <div className={`flex flex-col pt-2 my-3  w-full ${watch('password') && ' border border-[#F79F15]'}`}>
                                <label>{t('c_password_text')}</label>
                                <div className='flex items-center border p-1 rounded-md'>
                                    <AiOutlineKey />
                                    <input type={showPass ? "text" : "password"}  {...register('password')} className='p-2  w-full focus:outline-none ' name="password" placeholder={t('password')} />
                                    {
                                        showPass ?
                                            <AiFillEyeInvisible onClick={() => setShowPass(false)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                                            :
                                            <AiFillEye onClick={() => setShowPass(true)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />

                                    }

                                </div>
                            </div>
                            {errors.password && (
                                <p className="text-red-500 text-sm ">{errors.password.message}</p>
                            )}

                        </div>

                        <div className='flex justify-center items-center pt-[2rem]'>
                            <button className='font-semibold bg-[#F79F15] w-full py-2 text-white rounded-md'>{t('done')}</button>
                        </div>
                    </form>
                </div>
            </Modal>


        </div >
    )
}

export default SettingPopup
