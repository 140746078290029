import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Loader } from '../../../utils/loaderButton';
import bed from '../../../assests/images/bed.png'
import bath from '../../../assests/images/bath.png'
import { useEffect } from 'react';
import keys from '../../../assests/images/keys.png'
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { CreateRommateOffCampus, GetCampusInterest, GetLeasedPeriod, GetPropertyAmenities, GetPublicPropertyType } from '../../../lib/Api/RantelServiceApi';
// import { FaRegImages } from 'react-icons/fa';
// import { MdArrowBackIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { AddRommateIndex, IspopUp } from '../../../Redux/UserAuthSlice/RommateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API } from '../../../config/GoolePlace';
import { toast } from 'react-toastify';
import { IoArrowBackSharp } from 'react-icons/io5';


const OffnCampus = () => {
  const [propType, setPropType] = useState([])
  const [amenities, setAmenities] = useState([])
  const [Act, setAct] = useState([])
  const [interest, setInterest] = useState([])
  const [latitude, setLatutude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [lists, setLists] = useState([])
  const [leased, setLeased] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [activePropty, setActivePropty] = useState(null)
  const [leasedId, setLeasdId] = useState(null)
  const [Errors, SetErrors] = useState({})
  const [locate, setLocate] = useState({})
  const [Error, setError] = useState({
    propertyId: "",
    leasdePeriod: "",
    aminity: "",
    interests: ""
  })
  const [defaultValue, setDefaultValue] = useState("")
  const [images, setImages] = useState([])

  const { t } = useTranslation()


  const schema = yup.object({
    number_of_beds: yup.string().required(t('invalid_bed')),
    number_of_baths: yup.string().required(t('invalid_bath')),
    rent: yup.string().required(t('rent_am_r')),
    security_deposit: yup.string().required(t('invalid_sec')),
    city: yup.string().required(t('city_msg')),
    state: yup.string().required(t('state_msg')),
    street: yup.string().required(t('street_msg')),
    zipcode: yup.string().required(t('zipcode_msg')),
    // electricity: yup.number().typeError(t('electricity')).required(t('energy_invalid')),
    // wifi: yup.number().typeError(t('wifi')).required(t('wifi_invalid')),
    // gas: yup.number().typeError(t('gass')).required(t('gass_invalid')),
  });

  const { register, control, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { rommateId, rommateList, college, campus, isNewRommate } = useSelector((state) => state?.rommate)



  const formatCurrency = (value) => {
    if (!rommateList) {
      const numericValue = value?.replace(/[^0-9]/g, '');
      const formattedValue = new Intl.NumberFormat('en-US').format(numericValue);
      return `$${formattedValue}`;
    }
    else {
      const formattedValue = new Intl.NumberFormat('en-US').format(value);
      return `$${formattedValue}`;
    }
  };

  const handleMove = () => {
    if (rommateList?.property_details === null) {
      navigate('/roommatesList')
    }
    else {
      dispatch(AddRommateIndex(2))
    }
  }

  const HandleInterestChecks = (id) => {
    if (Act?.includes(id)) {
      let l = Act.filter((f) => f !== id)
      setAct(l)
    }
    else {
      setAct((prev) => ([...prev, id]))
    }
  }


  const HandleChecks = (id) => {
    if (lists?.includes(id)) {
      let l = lists.filter((f) => f !== id)
      setLists(l)
    }
    else {
      setLists((prev) => ([...prev, id]))
    }
  }



  let GetPropertyType = async () => {
    setLoading(true)
    let { res } = await GetPublicPropertyType()
    let ConvertObj = Object.assign({}, ...res)
    if (res) {
      setLoading(false)
      setPropType(ConvertObj.property_type)
    }
    else {
      setLoading(false)
    }
  }
  let GetAminty = async () => {
    setLoading(true)
    let { res } = await GetPropertyAmenities()
    const ConvertObj = Object.assign({}, ...res)
    if (res) {
      setLoading(false)
      setAmenities(ConvertObj.amenities)
    }
    else {
      setLoading(false)
    }
  }

  let fetchLeased = async () => {
    setLoading(true)
    let { res } = await GetLeasedPeriod();
    // get interest 
    if (rommateList?.property_details === null) {
      let { resInterest } = await GetCampusInterest()
      const ConvertIntr = Object.assign({}, ...resInterest)
      setInterest(ConvertIntr.interests)

    }
    const ConvertObj = Object.assign({}, ...res)

    if (res) {
      setLoading(false)
      setLeased(ConvertObj?.lease_periods)

    }
    else {
      setLoading(false)
    }
  }

  const autocompleteOptions = {
    types: ['address'],
    componentRestrictions: { country: 'us' }
  };

  const websiteLink = window.location.origin;


  const OnSubmit = async (data) => {

    if (leasedId === null) {
      setError((prev) => ({
        ...prev,
        leasdePeriod: t('propertyType')
      }))
    }
    else if (activePropty === null) {
      setError((prev) => ({
        ...prev,
        propertyId: t('leased_Period')
      }))
    }
    else if (lists?.length === 0) {
      setError((prev) => ({
        ...prev,
        aminity: t('aminties_Req')
      }))
    }
    else if (Act?.length === 0 && rommateList?.property_details === null) {
      setError((prev) => ({
        ...prev,
        interests: t('rommate_intr')
      }))
    }

    else {
      setLoadingSave(true)
      let formData = new FormData()
      formData.append('roommate_id', rommateId)
      formData.append('latitude', locate.geometry?.location?.lat() || latitude)
      formData.append('longitude', locate.geometry?.location?.lng() || longitude)
      formData.append('street', data?.street)
      formData.append('city', data?.city)
      formData.append('state', data?.state)
      formData.append('zipcode', data?.zipcode)
      formData.append('rent', data?.rent)
      formData.append('security_deposit', data?.security_deposit)
      formData.append('property_type_id', activePropty)
      formData.append('number_of_beds', data?.number_of_beds)
      formData.append('number_of_baths', data?.number_of_baths)
      formData.append('lease_period_id', leasedId)
      lists.forEach((file, index) => {
        formData.append(`roommate_amenities[${index}]`, file);
      });
      if (rommateList?.property_details === null) {
        Act.forEach((file, index) => {
          formData.append(`interests[${index}]`, file);
        });
      }
      let { res, error } = await CreateRommateOffCampus(formData)
      if (error) {
        setLoadingSave(false)
        toast.error(
          <a href={`${websiteLink}/aboutus/contactus`} target="_blank" rel="noopener noreferrer"   style={{ cursor: 'pointer' }}>
            {t('support_link')} <span className='underline text-blue-500'>{t('sup_link')}</span>.
          </a>
        );
      }
      else if (res) {
        setLoadingSave(false)
        if (isNewRommate === false) {
          navigate('/roommatesList')
        }
        else if (college === 1 && campus === 0) {
          dispatch(AddRommateIndex(9))
        }
        else {
          dispatch(AddRommateIndex(8))
        }
      }
    }
  }


  let fetch = async () => {
    const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBD-xaHdgobMyufM5tW3whuusr8l5J57X0`);
    const result = res?.data?.results[0]?.formatted_address;
    setDefaultValue(result)
  }


  useEffect(() => {
    GetPropertyType()
    GetAminty()
    fetchLeased()
    if (isNewRommate === false) {
      let list = rommateList?.property_details
      let romateAmi = list?.roommate_amenities.map((am) => am.id)
      reset(rommateList?.property_details)
      setLatutude(list?.latitude)
      setLongitude(list?.longitude)
      fetch(list?.latitude, list?.longitude)
      setActivePropty(list?.property_type?.id)
      setLists(romateAmi || [])
      setLeasdId(list?.lease_period_id)
    }
  }, [])

  return (
    <>
      <div className='xl:hidden lg:hidden md:hidden block'>
        <Link to="/roommatesList">
          <IoArrowBackSharp size={20} />
        </Link>
      </div>
      <h1 className='text-center text-[25px] font-semibold'>{t('property_Detail')}</h1>
      <form className='mt-5 border shadow-md p-5 xl:pb-auto lg:pb-auto md:pb-auto pb-10 rounded-sm' onSubmit={handleSubmit(OnSubmit)}>
        <h2 className='font-semibold text-[20px] pb-4 '>{t('address')}</h2>
        <div>
          <GooglePlacesAutocomplete
            apiKey={API}
            options={autocompleteOptions}

            defaultValue={defaultValue}
            onPlaceSelected={async (place) => {
              setLocate((prev) => (place))
              let city = "";
              let country = "";
              let zipcode = "";
              let street = "";
              let propertyId = ""
              // Iterate through the address components
              place?.address_components.forEach(component => {
                const types = component.types;
                // Check for the types relevant to city, country, and zipcode
                if (types.includes("locality")) {
                  city = component.long_name;
                } else if (types.includes("administrative_area_level_1")) {
                  country = component.short_name;
                } else if (types.includes("postal_code")) {
                  zipcode = component.long_name;
                }
                else if (types.includes("route")) {
                  street = component.long_name;
                }
                else if (types.includes("street_number")) {
                  propertyId = component.long_name;
                }
              })

              let PropertyId = `${propertyId} ${street}`
              let payload = {
                city,
                state: country,
                zipcode,
                street: PropertyId

              }
              reset(payload)

            }}
            className='border p-2 w-full focus:outline-none rounded-md my-2'
            placeholder={t('location_search')}
          />

          {Errors && (
            <p className="text-red-500 text-sm ">{Errors?.location}</p>
          )}

        </div>
        <div className='grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 xl:gap-5 lg:gap-5 md:gap-5'>
          <div className='flex flex-col'>
            <label className='font-medium' htmlFor="">{t('street')}</label>
            <input type='text' placeholder='Street'{...register('street')} className='p-2 rounded-md border' />
            {errors.street && (
              <p className="text-red-500 text-sm ">{errors.street.message}</p>
            )}
          </div>

          <div className='flex flex-col'>
            <label className='font-medium' htmlFor="">{t('city')}</label>
            <input type='text' placeholder='City' {...register('city')} className='p-2 rounded-md border' />
            {errors.city && (
              <p className="text-red-500 text-sm ">{errors.city.message}</p>
            )}
          </div>
          <div className='flex flex-col'>
            <label className='font-medium' htmlFor="">{t('state')}</label>
            <input type='text' placeholder='State' {...register('state')} className='p-2 rounded-md border' />
            {errors.state && (
              <p className="text-red-500 text-sm ">{errors.state.message}</p>
            )}
          </div>
          <div className='flex flex-col'>
            <label className='font-medium' htmlFor="">{t('zipcode')}</label>
            <input type='text' placeholder='Zip' {...register('zipcode')} className='p-2 rounded-md border' />
            {errors.zipcode && (
              <p className="text-red-500 text-sm ">{errors.zipcode.message}</p>
            )}
          </div>
        </div>


        <h2 className='font-semibold text-[20px] mt-5'>{t('rent_amount')}</h2>
        <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 lg:gap-20 md:gap-20 my-5'>
          <div className='flex flex-col mb-4 '>
            <label className='mb-2 text-[16px] font-semibold'>{t('rent_lbl')} </label>
            <Controller
              name="rent"
              control={control}
              defaultValue=''
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className=' p-2 rounded-md bg-white border focus:outline-none'
                  value={formatCurrency(field.value)}
                  onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                />
              )}
            />
            {errors.rent && (
              <p className="text-red-500 text-sm ">{errors.rent.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('sec_dep_lbl')} </label>
            <Controller
              name="security_deposit"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className=' p-2 rounded-md bg-white border focus:outline-none'
                  value={formatCurrency(field.value)}
                  onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                />
              )}
            />
            {errors.security_deposit && (
              <p className="text-red-500 text-sm ">{errors.security_deposit.message}</p>
            )}
          </div>

        </div>
        <div>
          <h2 className='text-[20px] font-semibold my-4'>{t('property_labl')}</h2>
          {
            loading ?
              <div className='flex items-start justify-start'>
                <Loader type="loadData" />
              </div>
              :
              <div className='flex flex-col'>
                <div className='flex flex-wrap items-center w-full max-h-[200px] overflow-y-auto '>
                  {
                    propType?.map((p, i) => (
                      <button key={i} type="button" onClick={() => setActivePropty(p.id)} className={`border ${activePropty === p?.id ? 'bg-[#F79F15] text-white' : 'bg-white text-black'} rounded-md px-4 py-1.5 mx-2 my-2`}>{p?.name}</button>
                    ))
                  }
                </div>
                {Error && (
                  <p className="text-red-500 text-sm pt-2">{Error.propertyId}</p>
                )}
              </div>

          }
          {/* {Errors && (
            <p className="text-red-500 text-sm ">{Errors?.property}</p>
          )} */}
          <h2 className='text-[20px] font-semibold my-4'>{t('bedRom')} {t('and')} {t('bathRom')}</h2>
          <div className='px-2 '>
            <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center'>
              <div>
                <div className='flex items-center'>
                  <img src={bed} alt="beds" />
                  <h2 className='px-2'>{t('bedRom')}</h2>
                  <input type="number" min={1} defaultValue={1} {...register('number_of_beds')} className='border px-2 w-[50px] focus:outline-none rounded-md' />
                </div>
                {errors.number_of_beds && (
                  <p className="text-red-500 text-sm ">{errors.number_of_beds.message}</p>
                )}
              </div>
              <div className='xl:mt-0 lg:mt-0 md:mt-0 mt-3'>
                <div className='flex items-center xl:mx-3 lg:mx-3 md:mx-3'>
                  <img src={bath} alt="beds" />
                  <h2 className='px-2'>{t('bathRom')}</h2>
                  <input type="number" min={1} defaultValue={1} {...register('number_of_baths')} className='border px-2 w-[50px] focus:outline-none rounded-md' />
                </div>
                {errors.number_of_baths && (
                  <p className="text-red-500 text-sm mx-5 ">{errors.number_of_baths.message}</p>
                )}
              </div>
            </div>
          </div>

        </div>
        <div>
          <h2 className='text-[20px] font-semibold my-4'>{t('aminity')}</h2>
          {
            loading ?
              <div className='flex justify-start items-start'>
                <Loader type="loadData" />
              </div>
              :
              <div className='flex flex-col'>
                <div className='flex flex-wrap items-center xl:max-h-[200px] xl:overflow-y-auto lg:max-h-[200px] lg:overflow-y-auto md:max-h-[200px] md:overflow-y-auto'>
                  {
                    amenities?.map((c, i) => {
                      let d = lists.find((f) => f === c.id)
                      return (
                        <div className='flex items-center mx-1' key={i}>
                          <input id={c?.id} type="checkbox" onChange={() => HandleChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                          <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.name}</label>
                        </div>
                      )
                    })
                  }
                </div>
                {Error && (
                  <p className="text-red-500 text-sm pt-2 ">{Error.aminity}</p>
                )}
              </div>
          }
          {rommateList?.property_details === null &&
            <>
              <h2 className='text-[20px] font-semibold my-4'>{t('interested')}</h2>
              {
                loading ?
                  <div className='flex justify-start items-start'>
                    <Loader type="loadData" />
                  </div>
                  :
                  <div className='flex flex-wrap items-center xl:max-h-[200px] xl:overflow-y-auto lg:max-h-[200px] lg:overflow-y-auto md:max-h-[200px] md:overflow-y-auto'>
                    {
                      interest?.map((c, i) => {
                        let d = Act.find((f) => f === c.id)
                        return (
                          <div className='flex items-center mx-1' key={i}>
                            <input id={c?.id} name="interst" type="checkbox" onChange={() => HandleInterestChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                            <label htmlFor={c?.id} name="interst" className='mx-1 text-[16px]'>{c.interest}</label>
                          </div>
                        )
                      })
                    }
                  </div>
              }
            </>
          }

          {Error.interests && (
            <p className="text-red-500 text-sm ">{Error.interests}</p>
          )}

          <div className='mt-5'>
            <h2 className='font-semibold text-[20px]'>{t('leased_p')}</h2>
            {
              loading ?
                <div className='flex justify-start items-start'>
                  <Loader type="loadData" />
                </div>
                :
                <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 xl:gap-5 lg:gap-5 md:gap-5 gap-3 mt-4">
                  {
                    leased?.map((item, i) => (
                      <div className={`${leasedId === item.id ? 'border-2 border-[#F79F15]' : 'bg-white '} rounded-md border shadow-md p-2 w-full`} key={i} onClick={() => setLeasdId(item?.id)}>
                        <div className='flex justify-center flex-col items-center cursor-pointer'>
                          <img src={item?.icon_image || keys} className='w-[40%] object-cover' alt="keys" />
                          <div className='flex flex-col text-center'>
                            <h2 className='font-semibold text-[18px] pt-4'>{item?.title}</h2>
                            <p>{item?.description}</p>
                          </div>
                          {/* <MdArrowForwardIos className='text-[40px]' /> */}
                        </div>
                      </div>
                    ))
                  }
                  {Error && (
                    <p className="text-red-500 text-sm ">{Error.leasdePeriod}</p>
                  )}
                </div>
            }
            {/* <span className='text-red-500 text-md mt-3'>{error}</span> */}
          </div>

          {/* <h2 className='font-semibold text-[20px] mt-5'>{t('img_lab')}</h2>
          <div className='border py-2 pl-3 flex items-center '>
            <label htmlFor='upload' className={` bg-gray-100 cursor-pointer border rounded-md px-6 h-[30px] flex justify-center items-center`}>
             
              <input type="file" className=' bg-transparent' multiple onChange={handleImageChange} />
            </label>

          </div> */}

          {/* <div className='flex flex-col'>
            <h2 className='text-[20px] font-semibold my-4'>{t('desc_info')}</h2>
            <textarea rows={8} {...register('description')} className='border p-2 rounded-md focus:outline-none' placeholder='Text Here...' />
            {errors.description && (
              <p className="text-red-500 text-sm ">{errors.description.message}</p>
            )}
          </div> */}
          <div className='flex justify-between items-center'>
            {rommateList?.property_details === null &&
              <div>
                <button onClick={() => handleMove()} type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                  {`${t(`Back`)}`}</button>
              </div>
            }
            {
              rommateList?.property_details !== null &&
              <div></div>
            }

            <div className='flex justify-end py-3 gap-2'>
              {rommateList?.property_details !== null &&
                <Link to="/roommatesList">
                  <button type="button" className='font-semibold border-[#F79F15] border text-[#F79F15]  px-6 py-1.5  rounded-md'>{t('cancel')}</button>
                </Link>
              }
              <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                {loadingSave ? <Loader type="saveLoder" /> : `${t(`save`)}`}</button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default OffnCampus