import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { GetLeaseds, UpdateLeased } from '../../../lib/Api/LeasedBuilder';
import { Loader } from '../../../utils/loaderButton';
import { ConvertBase64 } from '../../../utils/ConvBase64';
import PdfPreview from '../../RenatalPanelComp/RentalApp/PdfPreview';

const schema = yup.object({
    property_id: yup.string().required("Property field is required"),
});

const ViewLeasd = ({ t , action, handleClose }) => {
    const [properies, setProperties] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingProperty, setLoadingProperty] = useState(false)
    const [pdf, setPdf] = useState({})
    const [file, setFile] = useState(null);
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const handleChange = async (e) => {
        let file = e.target.files[0]
        let result = await ConvertBase64(file)
        setPdf(result)
        const fileExtension = file.name.split('.').pop();
        setFile(fileExtension);
    }


    const OnSubmit = async (data) => {
        let propertyId = Number(data.property_id)
        let formData = new FormData()
        formData.append('lease_id', action?.list?.id)
        formData.append('property_id', propertyId)
        formData.append('pdf', pdf)
        formData.append('extension', file)
        setLoading(true)
        await UpdateLeased(formData)
        setLoading(false)
        handleClose()

    }


    useEffect(() => {
        let getApi = async () => {
            setLoadingProperty(true)
            let { res } = await GetLeaseds()
            if (res) {
                setLoadingProperty(false)
                setProperties(res)
                if (action?.type === "edit") {
                    let payload = {
                        property_id: action?.list?.property_id
                    }
                    reset(payload)
                }

            }
            else {
                setLoadingProperty(false)
            }
        }
        getApi()
    }, [t])



    return (
        <>
            <h2 className='text-[20px] px-5 font-semibold'>{action?.type === "view" ? t('view_lease_builder') : t('edit_lease_builder')}</h2>
            {
                loadingProperty ? <Loader type="loadData" /> :
                action?.type === "edit" ?
                    <form onSubmit={handleSubmit(OnSubmit)}>
                        <div className='p-5'>
                            <div className='grid grid-cols-2 gap-20 my-5'>
                                <div className='flex flex-col mb-4 '>
                                    <label className='mb-2 text-[16px] font-semibold'>{t('property')} </label>
                                    <select  {...register('property_id')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none'>
                                        <option value="">{t('sel_property')}</option>
                                        {
                                            properies?.map((item, i) => (
                                                <option key={i} value={item?.property_id}  >{item?.property?.complete_address}</option>
                                            ))
                                        }

                                    </select>
                                    {errors.property_id && (
                                        <p className="text-red-500 text-sm ">{errors.property_id.message}</p>
                                    )}
                                </div>
                                <div className='flex flex-col mb-4  '>
                                    <label className='mb-2 text-[16px] font-semibold'>{t('pdf_lbl')} </label>
                                    <input type='file' onChange={(e) => handleChange(e)} name="pdf" className='border rounded-md p-1' />
                                </div>

                            </div>

                            <div className='flex justify-end py-3'>
                                <button className={`font-semibold  mx-2 text-[#F79F15] border-[#F79F15] border bg-white ${loading ? '' : 'px-6 py-1.5'}  rounded-md`}>
                                    {
                                        loading ? <Loader type="loadData" /> : t('update')
                                    }
                                </button>


                            </div>
                        </div>
                    </form>
                    :
                    <PdfPreview t={t} pdf={action?.list?.pdf} />
            }
        </>
    )
}

export default ViewLeasd