import { off, onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { GetUsers } from '../../components/GenericComp/GetcChatUser';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { database } from '../../config/Firebase';
import { Truncate } from '../../utils/TrucateString';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../utils/loaderButton';

const UsersList = () => {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state?.userAuth?.userInfo)
    const reciverid = useParams().id


    const { t } = useTranslation()

    useEffect(() => {
        const messagesRef = ref(database, 'Messages');
        setLoading(true)
        const chatListener = onValue(messagesRef, (snapshot) => {
            const data = snapshot.val();
            if (data && data[user?.userid]) {
                const userArray = Object.keys(data[user?.userid]).map((key) => ({
                    userId: key,
                    ...data[user?.userid][key],
                }));
                setUsers(userArray);

                let findUser = async () => {
                    let updatedUsersArray = [];
                    try {
                        for (let index = 0; index < userArray?.length; index++) {
                            const element = userArray[index];
                            let user = await GetUsers(element?.receiverID);
                            if (user) {
                                updatedUsersArray.push({ ...element, ...user });
                            } else {
                                //console.log(`User not found for receiverID: ${element?.receiverID}`);
                            }
                        }
                        setLoading(false)
                        setUsers(updatedUsersArray)
                    } catch (error) {
                        //console.error('Error finding user:', error.message);
                    }
                };
                findUser();

            } else {
                setUsers([]); // No user messages found
            }
        }, (error) => {
            //console.error('Error fetching user messages:', error);
            setUsers([]); // Handle the error by setting users to an empty array
        });

        // Cleanup the listener when the component unmounts
        return () => {
            off(messagesRef, 'value', chatListener);
        };
    }, [reciverid]);


    return (
        <>
            <div className="w-full  overflow-x-hidden h-auto  ">
                {
                    !loading && users?.length > 0 ? users?.map((userlist) => {
                        const timeAgo = moment.utc(userlist?.timestamp)
                            .fromNow();
                        const translatedTimeAgo = t(timeAgo);
                        let check = Number(user.userid) === userlist?.receiverID ? userlist?.senderID : userlist?.receiverID
                        return (
                            <button
                                className={` ${Number(reciverid) === check && 'bg-gray-100'} hover:bg-gray-100 w-full   border-b-2 p-2 mb-2`}
                            >
                                <Link to={`/message/${check}`} >
                                    <div className='flex justify-between w-full'>
                                        <div className='flex '>
                                            <div
                                                className="flex items-center justify-center border h-8 w-8 bg-indigo-200 rounded-full"
                                            >
                                                <img src={userlist?.image || userlist?.image} alt="user" className='rounded-full w-8 h-8' />
                                            </div>
                                            <div className='flex flex-col items-start'>
                                                <h2 className="text-[12px] px-2 leading-[18px] font-semibold text-[#444444] truncate  lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full">
                                                    {userlist.first_name !== "" ? Truncate(userlist?.first_name, 15) : "N/A"}
                                                </h2>
                                                <div className='px-2'>
                                                    <p className="text-[12px] text-[#898989] leading-[18px] truncate lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full ">
                                                        {Truncate(userlist?.lastMessage, 25)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <h2 className="text-[#98A2B3] text-[10px] flex items-center">
                                                {t(translatedTimeAgo)}

                                            </h2>
                                        </div>
                                    </div>
                                </Link>

                            </button>
                        )
                    })
                        : ""
                }
                {loading ? <div className='flex justify-center items-center'><Loader type="socialLoder" /></div> : ""}
            </div>
            {/* {
                users?.map((userlist) => {
                    let check = Number(user.userid) === userlist?.receiverID ? userlist?.senderID : userlist?.receiverID
                    return (
                        <button
                            className={` ${Number(reciverid) === check && 'bg-gray-100'} hover:bg-gray-100 w-full  rounded-xl p-2 mb-2`}
                        >
                            <Link to={`/message/${check}`} className='w-100 flex   justify-between'>
                                <div className='flex'>
                                    <div
                                        className="flex items-center justify-center border h-8 w-8 bg-indigo-200 rounded-full"
                                    >
                                        <img src={userlist?.image || userlist?.image} alt="user" className='rounded-full w-8 h-8' />
                                    </div>
                                    <div className='flex flex-col items-start'>
                                        <h2 className="text-[12px] px-2 leading-[18px] font-semibold text-[#444444] truncate  lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full">
                                            {userlist.first_name !== "" ? Truncate(userlist?.first_name, 15) : "N/A"}
                                        </h2>
                                        <div className='px-2'>
                                            <p className="text-[12px] text-[#898989] leading-[18px] truncate lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full ">
                                                {Truncate(userlist?.lastMessage, 15)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <h2 className="text-[#98A2B3] text-[10px] flex items-center">
                                        {moment.utc(userlist?.timestamp)
                                            .fromNow()}

                                    </h2>
                                </div>

                            </Link>
                        </button>
                    )
                })
            } */}
        </>
    )
}

export default UsersList