import React, { useEffect } from 'react'
import sl1 from '../../assests/images/slider1.png'
import logo from '../../assests/images/logo.png'
// import f from '../../assests/images/f.png'
import g from '../../assests/images/g.png'
import i from '../../assests/images/i.png'
import { AiFillEye, AiFillEyeInvisible, AiOutlineClose, AiOutlineKey } from 'react-icons/ai'
// import DynamicDropdonw from '../../utils/DaynamicDropdown';
import { MdAlternateEmail } from 'react-icons/md';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
// import { SignUpUser } from '../../lib/Api/UserApi';
import { useState } from 'react';
import { Loader } from '../../utils/loaderButton';
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { CheckUserRegister, CheckUserSocialy, SignInUserSocialy } from '../../lib/Api/UserApi';
import { toast } from 'react-toastify';
import LanguageDropdonw from '../../utils/Dropdowns/LanguageDropdonw';
import { isOver18 } from '../../utils/CheckDateOfBirth';
import { isMacOs } from 'react-device-detect';
import { generateCap } from '../../utils/PhoneCaptcGene';
import { signInWithPopup,
     GoogleAuthProvider,
    //  FacebookAuthProvider,
      OAuthProvider } from "firebase/auth";
import { auth } from '../../config/Firebase'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { signin } from '../../Redux/UserAuthSlice/UserAuthSlice'
// import StripLoader from '../GenericComp/StripLoader'
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import es from 'date-fns/locale/es';
// import { BsCalendar } from 'react-icons/bs'
// import moment from 'moment/moment'
// import 'moment/locale/es';
const SignUpForm = ({ onCloseModal, setSignIn, permistion, setActive, setFormData, setResult, formData, setMediaName, mediaName }) => {
    const { t } = useTranslation();

    const schema = yup.object({
        fname: yup.string().required(t('invalid_fname')),
        lname: yup.string().required(t('invalid_lname')),
        dateofbirth: yup.string().required(t('invalid_db')),
        email: yup.string().email(t('invalid_email')).required(t('emial_req')),
        password: yup.string()
            .required(t('invalid_password'))
            .min(3, t('password_charctor')),
        confirmPwd: yup.string()
            .required(t('invalid_c_password'))
            .oneOf([yup.ref('password')], t('match_password')),
        // Role: yup.string().required(),
    });

    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const [show_c_pass, setShow_c_pass] = useState(false)
    const [checkPolicy, setCheckPolicy] = useState(false)
    const [phone, setPhone] = useState('')
    const [striploading, setStripLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const [fiedError, setFieldError] = useState({ phoneError: "", agreement: "" })
    // const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    // const [selectedDate, setSelectedDate] = useState(new Date());


    const lng = useSelector((state) => state?.userAuth?.language)

    const { register, reset, watch, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const navigate = useNavigate();
    const dispatch = useDispatch()



    // firebase auth
    const firebaseAuth = auth;
    const provider = new GoogleAuthProvider();
    // const Fbprovider = new FacebookAuthProvider();
    const Approvider = new OAuthProvider('apple.com');

    // Social signin functions

    const signInGog = async () => {
        const { user } = await signInWithPopup(firebaseAuth, provider);
        const { providerData } = user;
        let obj = Object.assign({}, ...providerData)
        // let role = { role: permistion }
        let checkProvider = obj?.providerId === "google.com" ? `google_user_id` : null
        let newObj = { ...obj, checkProvider }
        let { res } = await CheckUserSocialy(newObj)
        let UserData = Object.assign({}, ...res)
        if (UserData?.is_new_user === false) {
            // setSignInLoading({ google: true })
            let payload = {
                role: permistion,
                ...newObj
            }
            let { resUser } = await SignInUserSocialy(payload)
            let signInData = Object.assign({}, ...resUser)
            if (signInData?.user?.role === "servicer") {
                dispatch(signin(signInData))
                navigate('/servicerPanel')
                toast.success(t('login_suc'))

            }
            else {
                dispatch(signin(signInData))
                navigate('/dashboard')
                toast.success(t('login_suc'))
            }
        }
        else {
            let payload = {
                "first_name": obj?.displayName,
                "phone_number": obj?.phoneNumber,
                "email": obj?.email,
                "providerId": "google.com",
                "uid": obj.uid,
                "role": permistion
            }

            let { resUser } = await SignInUserSocialy(payload)
            let UserData = Object.assign({}, ...resUser)
            setStripLoading(true)
            if (resUser !== undefined) {
                if (UserData?.user?.role === "servicer") {
                    dispatch(signin(UserData))
                    setUserInfo(UserData)
                    // navigate('/servicerPanel')
                    toast.success(t('login_suc'))

                }
                else {
                    dispatch(signin(UserData))
                    setUserInfo(UserData)
                    // navigate('/dashboard')
                    toast.success(t('login_suc'))
                }
            }
            else {
                setLoading(false)
            }

        }


    };
    // const signInFb = async () => {

    //     const { user } = await signInWithPopup(firebaseAuth, Fbprovider);
    //     const { providerData } = user;
    //     let obj = Object.assign({}, ...providerData)
    //     // let role = { role: permistion }
    //     let checkProvider = obj?.providerId === "facebook.com" ? 'fb_user_id' : obj?.providerId === "apple.com" ? 'apple_user_id' : null
    //     let newObj = { ...obj, checkProvider }
    //     let { res } = await CheckUserSocialy(newObj)
    //     let UserData = Object.assign({}, ...res)
    //     if (UserData?.is_new_user === false) {
    //         // setSignInLoading({ google: true })
    //         let payload = {
    //             role: UserData?.user?.role,
    //             ...newObj
    //         }
    //         let { resUser } = await SignInUserSocialy(payload)
    //         let signInData = Object.assign({}, ...resUser)
    //         if (signInData?.user?.role === "servicer") {
    //             dispatch(signin(signInData))
    //             navigate('/servicerPanel')
    //             toast.success(t('login_suc'))

    //         }
    //         else {
    //             dispatch(signin(signInData))
    //             navigate('/dashboard')
    //             toast.success(t('login_suc'))
    //         }
    //     }
    //     else {
    //         // setMedia(true)
    //         let payload = {
    //             "first_name": obj?.displayName,
    //             "phone_number": obj?.phoneNumber,
    //             "email": obj?.email,
    //             "providerId": "facebook.com",
    //             "uid": obj.uid,
    //             "role": permistion
    //         }
    //         let { resUser } = await SignInUserSocialy(payload)
    //         let UserData = Object.assign({}, ...resUser)
    //         if (resUser !== undefined) {
    //             setStripLoading(true)
    //         }
    //         if (resUser?.user?.role === "servicer") {
    //             dispatch(signin(UserData))
    //             setUserInfo(UserData)
    //             navigate('/servicerPanel')
    //             toast.success(t('login_suc'))

    //         }
    //         else {
    //             dispatch(signin(UserData))
    //             setUserInfo(UserData)
    //             navigate('/dashboard')
    //             toast.success(t('login_suc'))
    //         }
    //     }


    // };

    const signInAp = async () => {
        // alert("Apple SignUp flow ")
        const { user } = await signInWithPopup(firebaseAuth, Approvider);
        const { providerData } = user;
        let obj = Object.assign({}, ...providerData)
        let checkProvider = obj?.providerId === "apple.com" ? 'apple_user_id' : null
        let newObj = { ...obj, checkProvider }
        let { res } = await CheckUserSocialy(newObj)
        let UserData = Object.assign({}, ...res)
        if (UserData?.is_new_user === false) {
            let payload = {
                role: UserData?.user?.role,
                ...newObj
            }
            let { resUser } = await SignInUserSocialy(payload)
            let signInData = Object.assign({}, ...resUser)
            if (signInData?.user?.role === "servicer") {
                dispatch(signin(signInData))
                navigate('/servicerPanel')
                toast.success(t('login_suc'))

            }
            else {
                dispatch(signin(signInData))
                navigate('/dashboard')
                toast.success(t('login_suc'))
            }
        }
        else {
            let payload = {
                "first_name": obj?.displayName,
                "phone_number": obj?.phoneNumber,
                "email": obj?.email,
                "providerId": "apple.com",
                "uid": obj.uid,
                "role": permistion
            }
            let { resUser } = await SignInUserSocialy(payload)
            let UserData = Object.assign({}, ...resUser)
            if (resUser !== undefined) {
                setStripLoading(true)
            }
            if (resUser?.user?.role === "servicer") {
                dispatch(signin(UserData))
                setUserInfo(UserData)
                navigate('/servicerPanel')
                toast.success(t('login_suc'))

            }
            else {
                dispatch(signin(UserData))
                setUserInfo(UserData)
                navigate('/dashboard')
                toast.success(t('login_suc'))
            }
        }

    };

    const handlePhone = (phone) => {
        if (phone.length > 0) {
            setFieldError({ phoneError: "" })
        }
        else {
            setFieldError({ phoneError: t('invalid_phone') })
        }
        setPhone(phone)
    }
    const onSubmit = async (data) => {
        if (phone === "") {
            setFieldError({ phoneError: t('invalid_phone') })
        }
        if (checkPolicy === false) {
            setFieldError({ agreement: t('invalid_terms') })

        }
        else {
            const isOver18Check = isOver18(data?.dateofbirth);
            data.phone = phone
            data.lang = lng
            setFormData(data)
            setLoading(true)
            if (isOver18Check === true && phone !== "") {
                const { res, error } = await CheckUserRegister(data)
                if (res) {
                    setLoading(false)
                    const response = await generateCap(`+${phone}`, setActive);
                    if (response) {
                        setResult(response);
                    }
                }
                else if (error) {
                    setLoading(false)

                }
            }
            else {
                setLoading(false)
                toast.error(t('18_year'))
            }
        }
    }

    useEffect(() => {
        reset(formData)
    }, [])

    useEffect(() => {
        // if (mediaName === 0) {
        //     signInFb()
        // }
        if (mediaName === 1) {
            signInGog()
        }
        else if (mediaName === 2) {
            signInAp()
        }
    }, [mediaName, permistion])

    return (
        <>
            {/* {striploading ? <StripLoader UserInfo={userInfo} setStripLoading={setStripLoading} /> : ""} */}

            {
                striploading ?
                    ""
                    :
                    <div className='lg:flex md:w-[700px] signUp_screen w-full'>
                        <div className='lg:w-[70%] w-full relative hidden lg:block'>
                            <img src={sl1} className=" h-full w-full object-cover" alt="slider" />
                            <div className='absolute bottom-[10%] left-[20%]'>
                                <h2 className='text-[20px] text-white font-semibold'>{t('welcome')}</h2>
                            </div>

                        </div>
                        <div className='w-full px-5 '>
                            <div className='flex justify-between items-center '>
                                <img src={logo} className="h-[95px] object-contain" alt="logo" />
                                <div>
                                    <LanguageDropdonw />
                                </div>
                            </div>
                            <div className=' flex justify-end items-end cursor-pointer' onClick={() => onCloseModal()}>
                                <div className='border w-[35px] rounded-md shadow-md p-2'>
                                    <AiOutlineClose />
                                </div>

                            </div>
                            <div>
                                <h2 className='lg:text-[2.5rem] text-[2rem] font-bold'> {t('register_heading')}</h2>
                                <p className='text-[14px]'>
                                    {t('already_registered')} <span className='text-[16px] underline text-[#F79F15] font-bold cursor-pointer' onClick={() => { return setSignIn(true), onCloseModal() }}>{t('already_registered_singin')}</span>
                                </p>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='mt-5'>
                                    <div className='grid lg:grid-cols-2 md:grid-cols-2 gap-5'>
                                        <div>
                                            <div className={`flex flex-col pt-2 border-b w-full ${watch('fname') && ' border-b border-[#F79F15]'}`}>
                                                <label className='font-semibold'>{t('f_name')}</label>
                                                <div className='flex items-center w-full'>
                                                    <input type="text"  {...register('fname')} className='p-2  w-full focus:outline-none' placeholder={`${t('f_name')}`} />
                                                </div>
                                            </div>
                                            {errors.fname && (
                                                <p className="text-red-500 text-sm ">{errors.fname.message}</p>
                                            )}
                                        </div>
                                        <div>
                                            <div className={`flex flex-col pt-2 border-b w-full ${watch('lname') && ' border-b border-[#F79F15]'}`}>
                                                <label className='font-semibold'>{t('l_name')}</label>
                                                <div className='flex items-center w-full'>
                                                    <input type="text" {...register('lname')} className='p-2  w-full focus:outline-none' placeholder={`${t('l_name')}`} />
                                                </div>
                                            </div>
                                            {errors.lname && (
                                                <p className="text-red-500 text-sm ">{errors.lname.message}</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className={`flex flex-col pt-3 border-b w-full ${watch('email') && ' border-b border-[#F79F15]'}`}>
                                            <label className='font-semibold'>{t('email')}</label>
                                            <div className='flex items-center'>
                                                <MdAlternateEmail />
                                                <input type="email" {...register('email')} className='p-2  w-full focus:outline-none' placeholder={`${t('email')}`} />
                                            </div>
                                        </div>
                                        {errors.email && (
                                            <p className="text-red-500 text-sm ">{errors.email.message}</p>
                                        )}
                                    </div>

                                    {/* <div className='grid lg:grid-cols-2 md:grid-cols-2 gap-5 mt-4'> */}
                                    <div className="mt-4">
                                        <div className={`flex flex-col pt-2 border-b w-full ${watch('dateofbirth') && ' border-b border-[#F79F15]'}`}>
                                            <label className='font-semibold'>{t('dob')}</label>
                                            <div className='flex items-center justify-between w-full'>
                                                <input type="date" {...register('dateofbirth')} pattern="\d{1,2}/\d{1,2}/\d{4}" className='p-2  w-full focus:outline-none' locale="es" />
                                            </div>
                                        </div>
                                      
                                    </div>

                                    {/* </div> */}
                                    <div className=' mt-4'>
                                        <div>
                                            <div className={`flex flex-col pt-2 border-b w-full ${watch('phone') && ' border-b border-[#F79F15]'}`}>
                                                <label className='font-semibold'>{t('phone')}</label>

                                                <div className={`${fiedError?.phoneError ? 'border-b border-red-500 ' : ''} w-full`}>
                                                    <PhoneInput
                                                        country={'us'}
                                                        value={phone}
                                                        onChange={d => handlePhone(d)}
                                                    />
                                                </div>
                                            </div>
                                            {fiedError?.phoneError && (
                                                <p className="text-red-500 text-sm ">{fiedError?.phoneError}</p>
                                            )}
                                        </div>

                                    </div>

                                    <div>
                                        <div className={`flex flex-col pt-2 border-b w-full ${watch('password') && ' border-b border-[#F79F15]'}`}>
                                            <label className='font-semibold'>{t('password')}</label>
                                            <div className='flex items-center'>
                                                <AiOutlineKey />
                                                <input type={show ? 'text' : 'password'} {...register('password')} className='p-2  w-full focus:outline-none' name="password" placeholder={t('password_text')} />
                                                {show ? <AiFillEye onClick={() => setShow(false)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                                                    : <AiFillEyeInvisible onClick={() => setShow(true)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />}
                                            </div>
                                        </div>
                                        {errors.password && (
                                            <p className="text-red-500 text-sm ">{errors.password.message}</p>
                                        )}
                                    </div>
                                    <div>
                                        <div className={`flex flex-col pt-2 border-b w-full ${watch('confirmPwd') && ' border-b border-[#F79F15]'}`}>
                                            <label className='font-semibold'>{t('c_password')}</label>
                                            <div className='flex items-center'>
                                                <AiOutlineKey />
                                                <input type={show_c_pass ? 'text' : 'password'} {...register('confirmPwd')} className='p-2  w-full focus:outline-none' placeholder={t('c_password_text')} />
                                                {show_c_pass ? <AiFillEye onClick={() => setShow_c_pass(false)} className={`${watch('confirmPwd') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                                                    : <AiFillEyeInvisible onClick={() => setShow_c_pass(true)} className={`${watch('confirmPwd') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />}
                                            </div>
                                        </div>
                                        {errors.confirmPwd && (
                                            <p className="text-red-500 text-sm ">{errors.confirmPwd.message}</p>
                                        )}
                                    </div>

                                    <div className=" flex text-start mt-2">

                                        <h2 className='pl-2'> <span> <input type="checkbox" onChange={() => setCheckPolicy(!checkPolicy)} id="check_box" /><label htmlFor='check_box' className=' cursor-pointer'> {t('privacy_policy_agree')}</label> </span><a href={lng === "en" ? "/privacy" : "/sp/privacy"} target='_blank' className='underline decoration-[#F79F15] text-[#F79F15]'>{t('privacy_ploicy')}</a> {t('and')} <a href={lng === "en" ? "/terms" : "/sp/terms"} target='_blank' className='underline text-[#F79F15] decoration-[#F79F15]'>{t('terms_agree')}</a></h2>
                                    </div>
                                    {fiedError?.agreement && (
                                        <p className="text-red-500 text-sm ">{fiedError?.agreement}</p>
                                    )}
                                    {/* <div className="py-3 flex items-center">
                                <input type="checkbox" />
                                <h2 className='px-2'>{t('guest_agreement')}</h2>
                            </div> */}


                                    <div id="recaptcha-container"
                                        data-sitekey="6LcsaxsdAAAAAEBn0sPDCEncnU9564MisyRuDzD_"
                                        data-callback="sendForm"
                                        data-size="normal">
                                    </div>




                                    <div className='py-6'>
                                        {
                                            loading ?
                                                <Loader />
                                                :
                                                <button type='submit' className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white'>{t('continue')}</button>

                                        }
                                    </div>

                                    <div className='flex items-center justify-center pt-6'>
                                        <span className='border lg:w-[35%] w-[25%] h-0'></span>
                                        <h2 className='text-[13px] px-2 font-semibold text-center'>{t('or_sign_up')} </h2>
                                        <span className='border lg:w-[35%] w-[25%] h-0'></span>
                                    </div>


                                    <div className='flex items-center mt-5 justify-center '>
                                        {/* <div onClick={() => setMediaName(0)}><img src={f} alt="f_image" /></div> */}
                                        <div onClick={() => setMediaName(1)}> <img src={g} alt="f_image" /></div>
                                        {isMacOs ? (
                                            <div onClick={() => setMediaName(2)} > <img src={i} alt="f_image" /></div>
                                        ) : ""}

                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>
            }
        </>
    )
}

export default SignUpForm