import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import LikeDislikePopup from '../../../../utils/Popup/LikeDisLike';
import PostAppointment from '../../../../utils/Popup/PostAppointment'
import propImg from '../../../../assests/svg/home.svg'
import Arrow from '../../../../assests/svg/arrow.svg'
import bed from '../../../../assests/images/bed.png'
import gp1 from '../../../../assests/images/gp1.png'
import gp2 from '../../../../assests/images/gp2.png'
import gp3 from '../../../../assests/images/gp3.png'
import gp4 from '../../../../assests/images/gp4.png'
import { FaFastBackward } from "react-icons/fa";
import bath from '../../../../assests/images/bath.png'
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { FilterRommateOffCampus, GetCampusActivities, GetCampusInterest, GetLeasedPeriod, GetPropertyAmenities, GetPropertyType } from '../../../../lib/Api/RentalAppApi';
import { Loader } from '../../../../utils/loaderButton';
import DynamicPopup from '../../../../utils/Popup/DynamicPopup';
import Deck from '../Tinder';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropertyOffCampus from '../PropertyDetaiOffCamp';
import CreditConfirm from '../../../GenericComp/CreditConfirmation';
import { API } from '../../../../config/GoolePlace';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { GetCountries } from '../../../../lib/Api/RantelServiceApi';
import Select from 'react-select'
import { setUniversity } from '../../../../Redux/UserAuthSlice/RommateSlice';
import { toast } from 'react-toastify';
import ConstData from '../../../../contant/Constant.json'
import { IoArrowBackSharp } from 'react-icons/io5';
const OffCampusMobile = () => {
    const [intrests, setInterest] = useState([])
    const [Intr, setIntr] = useState([])
    const [activity, setActivity] = useState([])
    const [Act, setAct] = useState([])
    const [loadingCon, setLoadingCon] = useState(false)
    const [options, setOptions] = useState([]);
    const [active, setActive] = useState(null)
    const [propType, setPropType] = useState([])
    const [lists, setLists] = useState([])
    const [amenities, setAmenities] = useState([])
    const [leased, setLeased] = useState([])
    const [show, setShow] = useState(false)
    const [detail, setDetail] = useState("")
    const [showPost, setShowPost] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activePropty, setActivePropty] = useState([])
    const [searchLoading, setSearchLoading] = useState(false)
    const [listing, setListing] = useState([])
    const [originalLists, setOriginalLists] = useState([])
    const [type, setType] = useState("")
    const [dropdown, setDropdown] = useState(false)
    const [property, setProperty] = useState({})
    const [locate, setLocate] = useState({})
    const [swapSlider, setSwapSlider] = useState("")
    const [afterSearch, setAfterSearch] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [leasedId, setLeasdId] = useState(null)
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [revert, setRevert] = useState("")
    const [isCredit, setIsCredit] = useState(false)
    const [priceRange, setPriceRange] = useState(10000);
    const [purchaseType, setPurchaseType] = useState("")
    const [uni, setUni] = useState({})
    const [dorm, setDorm] = useState("")
    const [major, setMajor] = useState("")
    const [gradlee, setGradlee] = useState("")
    const [showList, setShowList] = useState(false)
    const [defaultAddress, setDefaultAddress] = useState("")
    const [Errors, setError] = useState({
        propertyId: "",
        leasdePeriod: "",
        aminity: "",
        intrest: "",
        stay: "",
        active: "",
        uni: "",
        dormN: "",
        maj: "",
        grade: "",
    })
    const [savedPositions, setSavedPositions] = useState([]);
    const [points, setPoints] = useState([]);

    const containerRef = useRef(null);
    const topRef = useRef(null);

    const { rommate, renterQuestion, universityInfo } = useSelector((state) => state?.rommate)

    let checkOff = rommate?.college_student === 1 && rommate?.on_campus === 0

    const { t } = useTranslation()

    const gradle = [
        { heading: t('gradle_op1'), key: "Freshmen" },
        { heading: t('gradle_op2'), key: "Sophomore" },
        { heading: t('gradle_op3'), key: "Junior" },
        { heading: t('gradle_op4'), key: "Senior" },
        { heading: t('gradle_op5'), key: "Post-Graduate" },
        { heading: t('gradle_op6'), key: "Graduate" },
        { heading: t('gradle_op7'), key: "Other" }
    ]

    const schema = yup.object({
        number_of_beds: yup.string().required(t('invalid_bed')),
        number_of_baths: yup.string().required(t('invalid_bath')),
        // university_name: yup
        //   .object({
        //     value: yup.string().required(t('rommate_uni')),
        //     label: yup.string().required(t('rommate_uni')),
        //   })
        //   .nullable()
        //   .required(t('rommate_uni')),
        // domm_name: yup.string(),
        // major: yup.string().required(t('rommate_uni_major')),
        // grade_level: yup.string().required(t('rommate_uni_grad')),
        city: yup.string(),
        state: yup.string(),
        street: yup.string(),
        zipcode: yup.string(),
    });

    const { register, handleSubmit, reset, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const handleToggle = (info) => {
        if (info === type && dropdown === true) {
            setType(info)
            setDropdown(false)
        }
        else {
            setType(info)
            setDropdown(true)
        }
    }

    const dispatch = useDispatch()

    // On Campus Info
    const handleActivityChecks = (id) => {
        if (Act?.includes(id)) {
            let l = Act.filter((f) => f !== id)
            setAct(l)
        }
        else {
            setAct((prev) => ([...prev, id]))
        }
    }

    let GetActivityInterest = async () => {
        setLoading(true)
        let { resActivity } = await GetCampusActivities()
        const ConvertActi = Object.assign({}, ...resActivity)
        if (resActivity) {
            setLoading(false)
            setActivity(ConvertActi.activities)
        }
        else {
            setLoading(false)
        }
    }

    let getCountries = async () => {
        try {
            setLoadingCon(true)
            let res = await GetCountries()
            if (res) {
                setLoadingCon(false)
                const formattedOptions = res.map(item => ({ label: item.name, value: item.id }));
                setOptions(formattedOptions);
            }
        }
        catch (err) { }
    }

    //  Icon on above property 
    let Icons = [
        { img: gp1, type: "dislike" },
        { img: gp2, link: "/flash" },
        { img: "", type: "backForword" },
        { img: gp3, link: "/reazy" },
        { img: gp4, type: "like" },
    ]

    const handleNextProperty = (type) => {
        if (type === "like") {
            setSwapSlider("like")
            swipeRight()
        }
        else {
            setSwapSlider("dislike")
            swipeLeft()
        }
    }


    // Off Campus Info
    const HandleChecks = (id) => {
        if (lists?.includes(id)) {
            let l = lists.filter((f) => f !== id)
            setLists(l)
        }
        else {
            setLists((prev) => ([...prev, id]))
        }
    }

    const HandleInterestChecks = (id) => {
        if (Intr?.includes(id)) {
            let l = Intr.filter((f) => f !== id)
            setIntr(l)
        }
        else {
            setIntr((prev) => ([...prev, id]))
        }

    }

    let GetPropertyTypes = async () => {
        setLoading(true)
        let { res } = await GetPropertyType()
        let ConvertObj = Object.assign({}, ...res)
        if (res) {
            setLoading(false)
            setPropType(ConvertObj.property_type)
        }
        else {
            setLoading(false)
        }
    }

    let GetAminty = async () => {
        setLoading(true)
        let { res } = await GetPropertyAmenities()
        const ConvertObj = Object.assign({}, ...res)
        if (res) {
            setLoading(false)
            setAmenities(ConvertObj.amenities)
        }
        else {
            setLoading(false)
        }
    }

    // handle Price and handle Redius value
    const handleRangeChange = (event) => {
        setPriceRange(event.target.value);
    };

    // add doller sign in values
    const formatCurrencs = (value) => {
        return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    };
    const formattedValue = formatCurrencs(priceRange);

    let fetchLeased = async () => {
        setLoading(true)
        let { res } = await GetLeasedPeriod();
        // get interest 
        let { resInterest } = await GetCampusInterest()
        const ConvertObj = Object.assign({}, ...res)
        const ConvertIntr = Object.assign({}, ...resInterest)

        if (res) {
            setLoading(false)
            setLeased(ConvertObj?.lease_periods)
            setInterest(ConvertIntr.interests)

        }
        else {
            setLoading(false)
        }
    }

    var autocompleteOptions = {
        types: ['(regions)'],
        componentRestrictions: { country: "us" }
    };

    const OnSubmit = async (data) => {

        if (leasedId === null) {
            toast.error(t('field_required'))
        }
        else if (Object.keys(uni).length === 0 && checkOff) {
            setType("uni")
            toast.error(t('field_required'))
            setDropdown(true)
        }
        // else if (dorm === "" && checkOff) {
        //     setType("uni")
        //     toast.error(t('field_required'))
        //     setDropdown(true)
        // }
        else if (major === "" && checkOff) {
            setType("uni")
            toast.error(t('field_required'))
            setDropdown(true)
        }
        else if (gradlee === "" && checkOff) {
            setType("uni")
            toast.error(t('field_required'))
            setDropdown(true)
        }

        else if (activePropty?.length === 0) {
            toast.error(t('field_required'))
        }
        // else if (lists?.length === 0 && checkOff) {
        //   setType("uni")
        //   setDropdown(true)
        //   toast.error(t('field_required'))
        // }
        // else if (Intr?.length === 0) {
        //   toast.error(t('field_required'))
        // }

        else {
            setSearchLoading(true)
            let ques = renterQuestion?.OffCampus?.priorites?.map((qu) => {
                return {
                    "question_id": qu?.question?.id,
                    "option_id": qu?.option?.id
                }
            })

            let updateArr = []
            for (let index = 0; index < activePropty.length; index++) {
                const element = activePropty[index];
                updateArr.push(element.id)
            }

            let payload = {
                "on_campus": rommate?.on_campus,
                "college_student": rommate?.college_student,
                'latitude': locate.geometry?.location?.lat(),
                'longitude': locate.geometry?.location?.lng(),
                'street': data?.street,
                'city': data?.city,
                'state': data?.state,
                'zipcode': data?.zipcode,
                "rent_max": Number(priceRange),
                "rent_min": 0,
                'property_type_id': updateArr,
                'number_of_beds': Number(data?.number_of_beds),
                'number_of_baths': Number(data?.number_of_baths),
                'lease_period_id': leasedId,
                "priorities": ques,
                "interests": Intr,
                'roommate_amenities': lists
            }
            let uniInfo = {
                'university_id': uni?.value || universityInfo?.university_name?.value,
                'university_name': uni.label || universityInfo?.university_name?.label,
                'domm_name': dorm || universityInfo?.domm_name,
                'major': major || universityInfo?.major,
                'grade_level': gradlee || universityInfo?.grade_level,
                'length_of_stay': active === 0 ? "One Semester" : "Two Semester",
            }

            if (rommate?.on_campus === 0 && rommate?.college_student === 1) {
                payload.university_id = uni?.value || universityInfo?.university_name?.value
                payload.university_name = uni?.label || universityInfo?.university_name?.label
                payload.domm_name = dorm || universityInfo?.domm_name
                payload.major = major || universityInfo?.major
                payload.grade_level = gradlee || universityInfo?.grade_level
                payload.length_of_stay = active === 0 ? "One Semester" : "Two Semester"
                payload.extra_curricular_activities = Act
                dispatch(setUniversity(uniInfo))
            }
            if (topRef.current) {
                topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
            }


            let { res } = await FilterRommateOffCampus(payload)
            if (res) {
                setError({})
                let obj = Object.assign({}, ...res)
                setSearchLoading(false)
                const reversedRoommates = [...obj.roommates].reverse();
                setListing(reversedRoommates);
                if (obj.roommates?.length === 0) {
                    setAfterSearch(true)
                }
                else {
                    setAfterSearch(false)
                }

            }
        }
    }


    // Function to handle left swipe
    const swipeLeft = async (id) => {

        const numberOfElementsToKeep = 1;
        const filteredData = listing.filter((item, index) => index >= listing.length - numberOfElementsToKeep);
        let obj = Object.assign({}, ...filteredData)
        const updatedData = listing.filter((item, i) => item?.id !== obj?.id)
        let check = originalLists.find((f) => f.id === obj.id)
        if (!check) {
            setOriginalLists((prev) => [...prev, ...filteredData])
        }
        setListing(updatedData)


    };


    // Function to handle right swipe
    const swipeRight = async () => {
        const numberOfElementsToKeep = 1;
        const filteredData = listing.filter((item, index) => index >= listing.length - numberOfElementsToKeep);
        let obj = Object.assign({}, ...filteredData)
        const updatedData = listing.filter((item, i) => item?.id !== obj?.id)
        setListing(updatedData)
        let check = originalLists.find((f) => f.id === obj.id)
        if (!check) {
            setOriginalLists((prev) => [...prev, ...filteredData])
        }
        setShow(true)
        setProperty(obj)
    };


    const handlePropertyType = (data) => {
        let checkId = activePropty.find((f) => f.id === data?.id)
        if (checkId) {
            let UpdatedArry = activePropty.filter((f) => f.id !== data?.id)
            setActivePropty(UpdatedArry)
        }
        else {
            setActivePropty([...activePropty, data])
        }
    }

    const RevertProperty = () => {
        const numberOfElementsToKeep = 1;
        const filteredData = originalLists.filter((item, index) => index >= originalLists.length - numberOfElementsToKeep);
        let obj = Object.assign({}, ...filteredData)
        const lastElement = points.pop();
        setSavedPositions((prev) => [...prev, lastElement])
        setListing((prev) => [...prev, obj]);
        let check = originalLists.filter((f) => f.id !== obj?.id)
        setOriginalLists(check)
        setRevert("revert")
    }


    useEffect(() => {
        // on campus
        getCountries()
        GetActivityInterest()
        // off campus
        GetPropertyTypes()
        GetAminty()
        fetchLeased()
    }, [])

    const handleBack = () => {
        setListing([])
        setShowList(false)
    }


    // Reset University Info
    useEffect(() => {
        if (Object.keys(universityInfo)?.length > 0) {
            setUni({ label: universityInfo?.university_name, value: universityInfo?.university_id })
            setDorm(universityInfo?.domm_name)// Ensure all expected fields are included
            setMajor(universityInfo?.major)
            setGradlee(universityInfo?.grade_level)
            setActive(universityInfo?.length_of_stay === "One Semester" ? 0 : 1)

        }
    }, [])


    useEffect(() => {
        const handleResize = () => {
            setInnerWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (rommate?.on_campus === 0 && rommate?.college_student === 1) {
            setType("uni")
            setDropdown(true)
        }
        else {
            setType("property")
            setDropdown(true)
        }
    }, [rommate])


    // useEffect(() => {
    //     setListing(ConstData)
    // }, [])



    return (
        <div className=' overflow-hidden' ref={containerRef} >
            {/* <div ></div> */}
            {detail && <DynamicPopup modalOpen={detail} data={<PropertyOffCampus swipeLeft={(val) => swipeLeft(val)} detail={detail} close={() => setDetail("")} />} close={() => setDetail("")} />}
            {isCredit && <DynamicPopup data={<CreditConfirm purchaseType={purchaseType} close={() => setIsCredit(false)} />} modalOpen={isCredit} close={() => setIsCredit(false)} />}
            {
                <>
                    {show && <LikeDislikePopup propertyType="rommate" setShowPost={setShowPost} setPurchaseType={setPurchaseType} permition={show} Toggle={setShow} type={type} property={property} setIsCredit={setIsCredit} />}
                    {showPost && <PostAppointment propertyType="rommate" swipeLeft={(val) => swipeLeft(val)} permition={showPost} Toggle={setShowPost} type={type} property={property} />}

                    <form className='   rounded-sm' onSubmit={handleSubmit(OnSubmit)}>
                        <div className="container mx-auto " ref={topRef} >
                            <div className="grid xl:hidden lg:hidden md:hidden mb-10  xl:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 xl:gap-3 lg:gap-3 md:gap-3">
                                {listing?.length === 0 && showList === false ?
                                    <div className='border mt-0'>
                                        {rommate?.on_campus === 0 && rommate?.college_student === 1 &&
                                            <div div className=' '>
                                                <div onClick={() => handleToggle("uni")} className='flex justify-between p-3 cursor-pointer items-center border-b '>
                                                    <h2 className='font-medium'>{t('rommate_uni_detail')}</h2>
                                                    {dropdown && type === "uni" ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
                                                </div>
                                                {
                                                    dropdown && type === "uni" &&
                                                    <div className='p-5'>
                                                        <div>
                                                            <div className='grid grid-cols-1 gap-2'>
                                                                <div className='flex flex-col'>
                                                                    <label className='font-medium' htmlFor="">{t('rommate_uni')}</label>
                                                                    <Select
                                                                        defaultValue={uni}
                                                                        onChange={(uni) => setUni(uni)}
                                                                        className="rounded-md mt-1  focus:outline-none"
                                                                        placeholder={t('search_lab')}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        isLoading={loadingCon}
                                                                        options={options}
                                                                    />
                                                                    {/* )} */}
                                                                    {/* /> */}
                                                                    {Errors?.uni && (
                                                                        <p className="text-red-500 text-sm ">{Errors?.uni}</p>
                                                                    )}
                                                                </div>
                                                                <div className='flex flex-col'>
                                                                    <label className='font-medium' htmlFor="">{t('rommate_uni_dorm')}</label>
                                                                    <input
                                                                        onChange={(e) => setDorm(e.target.value)}
                                                                        // {...register('domm_name')}
                                                                        value={dorm}
                                                                        type='text' placeholder={t('rommate_uni_dorm')} className='p-2 rounded-md border focus:outline-none' />
                                                                    {Errors?.dormN && (
                                                                        <p className="text-red-500 text-sm ">{Errors?.dormN}</p>
                                                                    )}
                                                                </div>

                                                            </div>
                                                            <div className='grid grid-cols-1 gap-2 mt-2 '>
                                                                <div className='flex flex-col  '>
                                                                    <label className='font-medium' htmlFor="">{t('rommate_uni_major')}</label>
                                                                    <input
                                                                        //  {...register('major')}
                                                                        value={major}
                                                                        onChange={(e) => setMajor(e.target.value)}
                                                                        type='text' placeholder={t('rommate_uni_major')} className='p-2 rounded-md border focus:outline-none' />
                                                                    {Errors?.maj && (
                                                                        <p className="text-red-500 text-sm ">{Errors?.maj}</p>
                                                                    )}
                                                                </div>
                                                                <div className='flex flex-col '>
                                                                    <label className='font-medium' htmlFor="">{t('rommate_uni_grad')}</label>
                                                                    <select
                                                                        className='p-2 rounded-md border focus:outline-none'
                                                                        // {...register('grade_level')}
                                                                        value={gradlee}
                                                                        onChange={(e) => setGradlee(e.target.value)}
                                                                    >
                                                                        <option>{t('please_select')}</option>
                                                                        {
                                                                            gradle?.map((grad, index) => (
                                                                                <option key={index} value={grad?.key}>{grad?.heading}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {errors.grade_level && (
                                                                        <p className="text-red-500 text-sm ">{errors.grade_level.message}</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h2 className='text-[20px] font-semibold my-4'>{t('rommate_uni_stay')}</h2>
                                                                <div className='flex items-center gap-2 '>
                                                                    <div onClick={() => setActive(0)} className={`${active === 0 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium text-[14px] shadow-md border  rounded-md p-2 cursor-pointer`}>
                                                                        {t('rommate_uni_one_sm')}
                                                                    </div>
                                                                    <div onClick={() => setActive(1)} className={`${active === 1 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium text-[14px] shadow-md mx-5 border rounded-md p-2 cursor-pointer`}>
                                                                        {t('rommate_uni_two_sm')}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>

                                                            <h2 className='text-[20px] font-semibold my-4'>{t('rommate_ex_acti')}</h2>
                                                            {
                                                                loading ?
                                                                    <div className='flex justify-start items-start'>
                                                                        <Loader type="loadData" />
                                                                    </div>
                                                                    :
                                                                    <div className='flex flex-col'>
                                                                        <div className='flex flex-wrap items-center'>
                                                                            {activity?.map((interest, index) => {
                                                                                const isChecked = Act.includes(interest.id);
                                                                                return (
                                                                                    <div className='flex items-center mx-1' key={index}>
                                                                                        <input
                                                                                            id={`interest-${interest.id}`}
                                                                                            name="interest"
                                                                                            type="checkbox"
                                                                                            onChange={() => handleActivityChecks(interest.id)}
                                                                                            defaultChecked={isChecked}
                                                                                            className='w-[20px] h-[20px]'
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={`interest-${interest.id}`}
                                                                                            className='mx-1 text-[16px]'
                                                                                        >
                                                                                            {interest.activity}
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                        {/* <div className='flex flex-wrap items-center '>
                                                                            {
                                                                                activity?.map((c, i) => {
                                                                                    let d = Act.find((f) => f === c.id)
                                                                                    return (
                                                                                        <div className='flex items-center mx-1' key={i}>
                                                                                            <input id={c?.id} type="checkbox" onChange={() => handleActivityChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                                                                                            <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.activity}</label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div> */}
                                                                        {Errors && (
                                                                            <p className="text-red-500 text-sm pt-2 ">{Errors.active}</p>
                                                                        )}
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        <div className=' mt-3'>
                                            <div onClick={() => handleToggle("property")} className='flex justify-between p-3 cursor-pointer items-center border-b '>
                                                <h2 className='font-medium'>{t('property_Detail')}</h2>
                                                {dropdown && type === "property" ? <MdKeyboardArrowUp size={20} /> : <MdKeyboardArrowDown size={20} />}
                                            </div>
                                            {dropdown && type === "property" &&
                                                <div className='p-5'>
                                                    <div className='mt-1'>
                                                        <h2 className='font-semibold text-[20px]'>{t('leased_p')}</h2>
                                                        {
                                                            loading ?
                                                                <div className='flex justify-start items-start'>
                                                                    <Loader type="loadData" />
                                                                </div>
                                                                :
                                                                <div className="grid grid-cols-1 gap-5 mt-4">
                                                                    {
                                                                        leased?.map((item, i) => (
                                                                            <div className={`${leasedId === item.id ? 'border-2 border-[#F79F15]' : 'bg-white '} rounded-md border shadow-md p-2 w-full`} key={i} onClick={() => setLeasdId(item?.id)}>
                                                                                <div className='flex justify-center flex-col items-center cursor-pointer'>
                                                                                    <div className='flex flex-col text-center'>
                                                                                        <h2 className='font-semibold text-[18px] '>{item?.title}</h2>

                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {Errors && (
                                                                        <p className="text-red-500 text-sm ">{Errors.leasdePeriod}</p>
                                                                    )}
                                                                </div>
                                                        }

                                                    </div>
                                                    <div className='grid grid-cols-1 gap-2'>
                                                        <h2 className='font-semibold text-[20px]  '>{t('location')}</h2>
                                                        <div>
                                                            <GooglePlacesAutocomplete
                                                                apiKey={API}
                                                                options={autocompleteOptions}
                                                                defaultValue={defaultAddress}
                                                                onPlaceSelected={async (place) => {
                                                                    setLocate((prev) => (place))
                                                                    let city = "";
                                                                    let country = "";
                                                                    let zipcode = "";
                                                                    let street = "";
                                                                    let propertyId = ""
                                                                    // Iterate through the address components
                                                                    place?.address_components.forEach(component => {
                                                                        const types = component.types;
                                                                        // Check for the types relevant to city, country, and zipcode
                                                                        if (types.includes("locality")) {
                                                                            city = component.long_name;
                                                                        } else if (types.includes("administrative_area_level_1")) {
                                                                            country = component.short_name;
                                                                        } else if (types.includes("postal_code")) {
                                                                            zipcode = component.long_name;
                                                                        }
                                                                        else if (types.includes("route")) {
                                                                            street = component.long_name;
                                                                        }
                                                                        else if (types.includes("street_number")) {
                                                                            propertyId = component.long_name;
                                                                        }
                                                                    })
                                                                    let PropertyId = `${propertyId} ${street}`
                                                                    let payload = {
                                                                        city,
                                                                        state: country,
                                                                        zipcode,
                                                                        street: PropertyId

                                                                    }
                                                                    reset(payload)
                                                                    setDefaultAddress(place?.formatted_address)

                                                                }}
                                                                className='border p-2 w-full focus:outline-none rounded-md '
                                                                placeholder={t('select_location_romm')}
                                                            />

                                                            {Errors && (
                                                                <p className="text-red-500 text-sm ">{Errors?.location}</p>
                                                            )}

                                                        </div>
                                                        <div>

                                                            <h2 className='text-[20px] font-semibold my-4'>{t('rent_lbl')}</h2>
                                                            <div className='flex  items-center '>
                                                                <span>$0</span>
                                                                <input
                                                                    type="range"
                                                                    min="0"
                                                                    className='progress' style={{ accentColor: "#FF8C00", width: '80%', color: 'red' }}
                                                                    max="200000"
                                                                    step="1000"
                                                                    value={priceRange}
                                                                    onChange={handleRangeChange}
                                                                />
                                                                <p> {formattedValue}</p>
                                                            </div>

                                                        </div>
                                                        <div>
                                                            <h2 className='text-[20px] font-semibold my-4'>{t('property_labl')}</h2>
                                                            {
                                                                loading ?
                                                                    <div className='flex items-start justify-start'>
                                                                        <Loader type="loadData" />
                                                                    </div>
                                                                    :
                                                                    <div className='flex flex-col'>
                                                                        <div className='flex flex-wrap items-center w-full max-h-[200px] overflow-y-auto '>
                                                                            {

                                                                                propType?.map((p, i) => (
                                                                                    <button type="button" key={i} onClick={() => handlePropertyType(p)} className={`border my-2  rounded-md px-4 mx-2 py-1.5 ${activePropty?.find((f) => f.id === p.id) ? 'bg-[#F79F15] text-white' : 'bg-white text-[#F79F15]'}`}>
                                                                                        {p.name}</button>

                                                                                ))
                                                                            }
                                                                        </div>
                                                                        {Errors && (
                                                                            <p className="text-red-500 text-sm pt-2">{Errors.propertyId}</p>
                                                                        )}
                                                                    </div>

                                                            }

                                                            <h2 className='text-[20px] font-semibold my-4'>{t('bedRom')} {t('and')} {t('bathRom')}</h2>
                                                            <div className=''>
                                                                <div className='flex items-center gap-2 flex-wrap'>
                                                                    <div>
                                                                        <div className='flex items-center'>
                                                                            <img src={bed} alt="beds" />
                                                                            <h2 className='px-2'>{t('bedRom')}</h2>
                                                                            <input type="number" min={1} defaultValue={1} {...register('number_of_beds')} className='border px-2 w-[50px] focus:outline-none rounded-md' />
                                                                        </div>
                                                                        {errors.number_of_beds && (
                                                                            <p className="text-red-500 text-sm ">{errors.number_of_beds.message}</p>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <div className='flex items-center '>
                                                                            <img src={bath} alt="beds" />
                                                                            <h2 className='px-2'>{t('bathRom')}</h2>
                                                                            <input type="number" min={1} defaultValue={1} {...register('number_of_baths')} className='border px-2 w-[50px] focus:outline-none rounded-md' />
                                                                        </div>
                                                                        {errors.number_of_baths && (
                                                                            <p className="text-red-500 text-sm mx-5 ">{errors.number_of_baths.message}</p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div>
                                                            <h2 className='text-[20px] font-semibold my-4'>{t('interested')}</h2>
                                                            {
                                                                loading ?
                                                                    <div className='flex justify-start items-start'>
                                                                        <Loader type="loadData" />
                                                                    </div>
                                                                    :
                                                                    <div className='flex flex-col'>
                                                                        <div className='flex flex-wrap items-center'>
                                                                            {intrests?.map((interest, index) => {
                                                                                const isChecked = Intr.includes(interest.id);
                                                                                return (
                                                                                    <div className='flex items-center mx-1' key={index}>
                                                                                        <input
                                                                                            id={`interest-${interest.id}`}
                                                                                            name="interest"
                                                                                            type="checkbox"
                                                                                            onChange={() => HandleInterestChecks(interest.id)}
                                                                                            defaultChecked={isChecked}
                                                                                            className='w-[20px] h-[20px]'
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={`interest-${interest.id}`}
                                                                                            className='mx-1 text-[16px]'
                                                                                        >
                                                                                            {interest.interest}
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                        {/* <div className='flex flex-wrap items-center '>
                                                                            {
                                                                                intrests?.map((c, i) => {
                                                                                    let d = Intr.find((f) => f === c.id)
                                                                                    return (
                                                                                        <div className='flex items-center mx-1' key={i}>
                                                                                            <input id={c?.id} name="interst" type="checkbox" onChange={() => HandleInterestChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                                                                                            <label htmlFor={c?.id} name="interst" className='mx-1 text-[16px]'>{c.interest}</label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div> */}
                                                                        {Errors && (
                                                                            <p className="text-red-500 text-sm pt-2 ">{Errors.intrest}</p>
                                                                        )}
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div>
                                                            <h2 className='text-[20px] font-semibold my-4'>{t('aminity')}</h2>
                                                            {
                                                                loading ?
                                                                    <div className='flex justify-start items-start'>
                                                                        <Loader type="loadData" />
                                                                    </div>
                                                                    :
                                                                    <div className='flex flex-col'>
                                                                         <div className='flex flex-wrap items-center'>
                                                                            {amenities?.map((interest, index) => {
                                                                                const isChecked = Intr.includes(interest.id);
                                                                                return (
                                                                                    <div className='flex items-center mx-1' key={index}>
                                                                                        <input
                                                                                            id={`interest-${interest.id}`}
                                                                                            name="interest"
                                                                                            type="checkbox"
                                                                                            onChange={() => HandleInterestChecks(interest.id)}
                                                                                            defaultChecked={isChecked}
                                                                                            className='w-[20px] h-[20px]'
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={`interest-${interest.id}`}
                                                                                            className='mx-1 text-[16px]'
                                                                                        >
                                                                                            {interest.name}
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                        {/* <div className='flex flex-wrap items-center '>
                                                                            {
                                                                                amenities?.map((c, i) => {
                                                                                    let d = lists.find((f) => f === c.id)
                                                                                    return (
                                                                                        <div className='flex items-center mx-1' key={i}>
                                                                                            <input id={c?.id} type="checkbox" onChange={() => HandleChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                                                                                            <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.name}</label>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div> */}
                                                                        {Errors && (
                                                                            <p className="text-red-500 text-sm pt-2 ">{Errors.aminity}</p>
                                                                        )}
                                                                    </div>
                                                            }
                                                            <div className='flex justify-end py-3'>
                                                                {
                                                                    <button type='submit' className='font-semibold bg-[#F79F15] w-full px-6 py-1.5 text-white rounded-md'>
                                                                        {searchLoading ? <Loader /> : `${t(`search_lab`)}`}</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className=" ">
                                        {searchLoading ?
                                            <div className='flex justify-center items-center'>
                                                <Loader type="loadData" />
                                            </div>
                                            :
                                            <div className="col-span-3 h-full">
                                                <div className='xl:hidden lg:hidden md:hidden block'>
                                                    <div className='cursor-pointer' onClick={() => handleBack()}>
                                                        <IoArrowBackSharp size={20} />
                                                    </div>
                                                </div>
                                                <div className={`max-w-[760px] max-h-full h-[530px] relative mx-auto `} ref={topRef}>


                                                    {
                                                        listing && listing?.length > 0 ?
                                                            <div className={`${listing?.length > 15 && 'mt-[3rem] m-auto'}`}>
                                                                <Deck rommateType="oncampus" currentIndex={currentIndex} swapSlider={swapSlider} toggle={setShow} listing={listing} setSavedPositions={setSavedPositions} savedPositions={savedPositions} setPoints={setPoints} setListing={(val) => setListing(val)} setDetail={setDetail} originalLists={originalLists} setOriginalLists={setOriginalLists} setProperty={setProperty} />
                                                            </div>
                                                            :
                                                            loading ? <div className='flex justify-center items-center pt-10'> {`${t('wait_lab')} .....`}</div> :
                                                                <div className='flex flex-col mt-5'>
                                                                    <div className="flex flex-row justify-center items-center">
                                                                        <div className='xl:flex lg:flex md:flex hidden justify-center items-center rotate-90'>
                                                                            <img src={Arrow} alt="home" />
                                                                        </div>
                                                                        <div className="flex flex-col justify-center items-center">
                                                                            <img src={propImg} className='w-[40%]' alt="property_img" />
                                                                            <h2 className='font-semibold text-[20px] pt-3 max-w-[250px] text-center'>{t('rommate_filter')}</h2>
                                                                        </div>

                                                                    </div>
                                                                    <div className='w-[200px] mx-auto'>
                                                                        <button type='submit' className='border xl:mx-10 lg:mx-10 md:mx-10  w-full mt-5 py-2 px-6 rounded-md text-white   bg-[#F79F15]'>{afterSearch ? t('re-search') : t('search_lab')}</button>
                                                                    </div>
                                                                </div>

                                                    }




                                                </div>
                                                <div className="">
                                                    {listing && listing?.length > 0 &&
                                                        <div className={`flex justify-center w-full absolute -bottom-5 left-0 right-0  self-center items-center`}>
                                                            {
                                                                Icons.map((icon, i) => (
                                                                    <>
                                                                        {icon.link ? <Link to={icon?.link}>
                                                                            <img className='cursor-pointer' key={i} src={icon.img} alt={icon.img} />
                                                                        </Link> :
                                                                            icon?.img === "" && originalLists?.length > 0 ?
                                                                                <div className='border h-[55px] mb-[8px] w-[65px] rounded-md cursor-pointer flex justify-center items-center' onClick={() => RevertProperty()}>
                                                                                    <FaFastBackward size={22} />
                                                                                </div>

                                                                                :
                                                                                <img className='cursor-pointer' onClick={() => handleNextProperty(icon?.type)} key={i} src={icon.img} alt={icon.img} />
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </div>
                                                    }

                                                </div>

                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </form>

                </>
            }
        </div >
    )
}

export default OffCampusMobile