import React, { useState, useEffect, useRef } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assests/images/logo.png'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
function Sidebar({
    sidebarOpen,
    setSidebarOpen
}) {
    const [toggle, setToggle] = useState('')

    const location = useLocation().pathname

    // const trigger = useRef(null);
    const sidebar = useRef(null);



    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });



    useEffect(() => {
        if (sidebarOpen === true) {
            if (typeof window != "undefined" && window.document) {
                document.body.style.overflow = "hidden";
            }
        } else {
            document.body.style.overflow = "auto";
        }
    }, [sidebarOpen]);




    return (
        <div>
            {/* Sidebar backdrop (mobile only) */}
            <div className={`fixed inset-0 bg-slate-900 bg-opacity-30   transition-opacity duration-200 ${sidebarOpen ? 'opacity-100 z-50' : 'opacity-0 pointer-events-none'}`} aria-hidden="true" ></div>

            {/* Sidebar */}



            <div id="sidebar" ref={sidebar} className={`flex flex-col   absolute z-50  left-0 top-0 lg:left-0  
      ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} lg:translate-x-0 h-screen  overflow-y-auto no-scrollbar w-64  lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-white  border-l py-4 transition-all duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`} >
                {/* Sidebar header */}
                <div className="  pr-6 sm:px-2">
                    {/* Close button */}
                    <div className='flex justify-start items-start cursor-pointer mx-3'>
                        <BsArrowLeft className='border text-[30px] rounded-full border-black p-1' onClick={() => setSidebarOpen(!sidebarOpen)} />
                    </div>
                    {/* <button ref={trigger} className=" text-black border  rounded-full border-black hover:text-white" onClick={() => setSidebarOpen(!sidebarOpen)} aria-controls="sidebar" aria-expanded={sidebarOpen}  >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button> */}
                    {/* Logo */}

                    <div className="flex items-center justify-center  ">

                        <div className="flex ">
                            <img src={logo} className=" w-full object-contain " alt="logo" />
                        </div>
                    </div>
                </div>

                {/* Links */}
                <div>
                    <div className="h-full pb-2">
                        <ul>
                            <li className={`   px-4 cursor-pointer text-[20px] font-bold ${toggle === "/rent" && 'bg-[#F7E6CB] border-b-2 border-[#F79F15] '} py-5  `} >
                                <div className='flex justify-between items-center'>
                                    <Link to="/list" > List </Link>
                                    {
                                        toggle === "list" ?
                                            <MdKeyboardArrowDown onClick={() => setToggle("")} />
                                            :
                                            <MdKeyboardArrowUp onClick={() => setToggle("list")} />
                                    }
                                </div>
                                {
                                    toggle === "list" &&
                                    <ul className=' border  shadow-md border-white'>

                                        <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/list/rentals" && 'border-b-2 border-[#F79F15]'}   `}> <Link to="/list/rentals"> Rentals </Link></li>
                                        <li className={`px-4 py-5 bg-[#F7E6CB]  ${location === "/list/rommate" && 'border-b-2 border-[#F79F15]'} `}> <Link to="/list/rommate"> Rommate </Link></li>
                                        {/* <li className={`px-4 py-5 bg-[#F7E6CB]  ${location === "/list" || "/rentals" ? 'block  ' : 'hidden'}`}> <Link to="/"> Features </Link></li> */}
                                        {/* <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center`}> <Link to="/list/rentals"> Rentals </Link></li>
                                        <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center`}> <Link to="/list/rommate"> Rommate </Link></li> */}
                                        {/* <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center  ${location === "/list" && toggle ? 'block  ' : ''}`}> <Link to="/"> Features </Link></li> */}
                                    </ul>
                                }
                            </li>

                            <li className={`   px-4 cursor-pointer text-[20px] font-bold ${toggle === "/rent" && 'bg-[#F7E6CB] border-b-2 border-[#F79F15] '} py-5  `} >
                                <div className='flex justify-between items-center'>
                                    <Link to="/rent"> Rent </Link>
                                    {
                                        toggle === "rent" ?
                                            <MdKeyboardArrowDown onClick={() => setToggle("")} />
                                            :
                                            <MdKeyboardArrowUp onClick={() => setToggle("rent")} />
                                    }
                                </div>
                                {
                                    toggle === "rent" &&
                                    <ul className=' border  shadow-md border-white'>

                                        <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/rent/rentals" && 'border-b-2 border-[#F79F15]'}`}> <Link to="/rent/rentals"> Rentals </Link></li>
                                        <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/rent/rommate" && 'border-b-2 border-[#F79F15]'}`}> <Link to="/rent/rommate"> Rommate </Link></li>
                                        {/* <li className={`px-4 py-5 bg-[#F7E6CB]  ${location === "/rent" ? 'block  ' : 'hidden'}`}> <Link to="/"> Features </Link></li> */}
                                        {/* <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center`}> <Link to="/rent/rentals"> Rentals </Link></li>
                                        <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center`}> <Link to="/rent/rommate"> Rommate </Link></li> */}
                                        {/* <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center  ${location === "/list" && toggle ? 'block  ' : ''}`}> <Link to="/"> Features </Link></li> */}
                                    </ul>
                                }
                            </li>

                            <li className={`   px-4 cursor-pointer text-[20px] font-bold  py-5  `} >
                                <div className='flex justify-between items-center'>
                                    <Link to="/aboutus"> About Us </Link>
                                    {
                                        toggle === "about" ?
                                            <MdKeyboardArrowDown onClick={() => setToggle("")} />
                                            :
                                            <MdKeyboardArrowUp onClick={() => setToggle("about")} />
                                    }
                                </div>
                                {
                                    toggle === "about" &&
                                    <ul className=' border  shadow-md border-white'>
                                        {/* <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center`}> <Link to="/aboutus/faq"> FAQ </Link></li>
                                        <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center`}> <Link to="/aboutus/contactus"> Contact Us </Link></li> */}
                                        <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/aboutus/faq" && 'border-b-2 border-[#F79F15]'}`}> <Link to="/aboutus/faq"> FAQ’s </Link></li>
                                        <li className={`px-4 py-5 bg-[#F7E6CB] ${location === "/aboutus/contactus" && 'border-b-2 border-[#F79F15]'}`}> <Link to="/aboutus/contactus"> Contact Us  </Link></li>

                                        {/* <li className={`px-4 cursor-pointer text-[18px]  hover:border-b border-white py-5 bg-[#F7E6CB] font-bold text-center  ${location === "/list" && toggle ? 'block  ' : ''}`}> <Link to="/"> Features </Link></li> */}
                                    </ul>
                                }

                            </li>

                            {/* <li className={` px-4 cursor-pointer text-[20px]  hover:border-b  py-5  font-bold `}> <Link to="/blog"> Highlights  </Link></li> */}
                        </ul>

                    </div>
                </div>

                {/* Expand / collapse button */}
                {/* <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div> */}
            </div>
        </div>
    );
}

export default Sidebar;