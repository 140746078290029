import React, { useState } from 'react'
// import { IoClose } from 'react-icons/io5'
import { MdMessage } from 'react-icons/md'
// import { AddRentalAppUser } from '../../Redux/UserAuthSlice/RentalSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import propImg from '../../assests/svg/home.svg'
// import Arrow from '../../assests/svg/arrow.svg'
import { Truncate } from '../../utils/TrucateString'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../utils/loaderButton'
import DeletePopup from '../../utils/Popup/DeletePopup'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
// import RentalsDetail from '../Property/PropertyDetail'
import { SavedListAcept, RequestRentalApp, BookActions } from '../../lib/Api/RantelServiceApi'
import gp4 from '../../assests/svg/Group 4188.svg'
import ViewRentalApp from '../../components/ServicerPanelComp/MyList/ViewRentalApp'
import moment from 'moment'
import ReazyRentalsDetail from './ReazyPropertyDetail'
import { AddRentalAppUser } from '../../Redux/UserAuthSlice/RentalSlice'
import VerifyRentalApp from '../../components/RenatalPanelComp/RentalApp/VerifyRentalApp'

const REasypass = ({ fetchProperty, properties, loading, selectedProperty, activeTab }) => {
  const [savedListLoader, setSavedListLoader] = useState(false)
  const [del, setDel] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [property, setProperty] = useState({})
  const [type, setType] = useState("")
  const [leasePdf, setLeasePdf] = useState("")
  const [isVerify, setIsVerify] = useState(false)


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleOpen = (item) => {
    setProperty(item)
    setDel(true)
  }

  const handleMessage = (data) => {
    dispatch(AddRentalAppUser(data))
    navigate(`/message/${data?.id}`)
  }


  const handleDecline = async () => {
    setType("cancel")
    setSavedListLoader(true)
    let payload = {
      "list_id": property?.id,
      "action": "decline"
    }
    let { res } = await BookActions(payload)
    if (res) {
      await fetchProperty()
      setDel(false)
      setSavedListLoader(false)
    }
    else {
      setSavedListLoader(false)
    }
  }

  const handleSendRent = async (item) => {
    setType("sendRent")
    setProperty(item)
    let payload = {
    }
    if (item?.my_list) {
      payload.list_id = item?.my_list?.id
    } else {
      payload.from_user_id = item?.id
      payload.property_id = item?.id

    }
    setSavedListLoader(true)
    let { res } = await RequestRentalApp(payload)
    if (res) {
      await fetchProperty()
      setSavedListLoader(false)
    }else{
      setSavedListLoader(false)

    }
  }

  const handlePopup = (item) => {
    // setType("pdf")
    // setIsOpen(true)
    setIsVerify(true)
    setLeasePdf(item)
  }


  const handleVerify = () => {
    setIsVerify(false)
    setIsOpen(true)
    setType("pdf")
  }

  const handleDetail = (detail) => {
    setProperty(detail)
    setType("detail")
    setIsOpen(true)
  }

  const handleAcept = async (list) => {
    setProperty(list)
    setType("accept")
    setSavedListLoader(true)
    let payload = {
      "action": "accept",
      "list_id": list?.my_list?.id
    }
    let { res } = await SavedListAcept(payload)
    if (res) {
      await fetchProperty()
      setSavedListLoader(false)
    }
    else {
      setSavedListLoader(false)
    }
  }

  const { t } = useTranslation()

  if (isVerify) {
    return (<DynamicPopup modalOpen={isVerify} data={<VerifyRentalApp handleClose={handleVerify} />}
      close={() => setIsVerify(false)}
    />)
  } else {

    return (
      <>
        {isOpen && <DynamicPopup modalOpen={isOpen} data={
          type === "detail" ?
            <ReazyRentalsDetail detail={property} close={() => setIsOpen(false)} />
            : type === "pdf" ? <ViewRentalApp list={leasePdf} /> : ""
        } close={() => setIsOpen(false)} />}

        {del && <DeletePopup permition={del} loading={savedListLoader} Toggle={(val) => setDel(val)} callback={handleDecline} title={t('archive_this')} />}

        <div className='grid lg:grid-cols-3 md:grid-cols-2 pt-4 gap-4'>
          {!loading && properties?.map((list, index) => (
            <div className='rounded-md bg-white shadow-md pb-4 mb-8 w-full relative' key={index}>
              <div className='relative'>
                <img src={list?.user_image} className="w-full h-[200px] object-cover rounded-tl-xl rounded-tr-xl" alt="faverit_img" />
                <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5] rounded-tl-xl rounded-tr-xl'>
                  {activeTab === 0 && (
                    <div className='absolute top-[7%]  right-[5%]'>
                      <div onClick={() => handleMessage(list)} className=' mt-1 cursor-pointer p-[3px] text-white bg-blue-500 w-8 h-8 flex justify-center items-center rounded-full ' >
                        <div className='text-[18px] ' >
                        </div> <MdMessage />
                      </div>
                    </div>
                  )}
                  <div className='absolute p-[2px] top-[7%] text-white bg-gray-200 rounded-full left-[5%]'>
                    <div className='text-[20px] cursor-pointer' onClick={() => handleDetail(list)}>
                      <img src={gp4} alt="detail" className='w-6 h-6' />
                    </div>

                  </div>
                </div>
              </div>
              <div className='flex justify-between items-center p-3 '>
                <div className='flex items-start flex-col w-[80%] '>
                  <h2 className='font-bold  text-[14px]  max-w-[120px]'>{Truncate(list?.first_name, 10)}</h2>
                  <p>
                    {Truncate(list?.renter_reazy_settings?.description, 40)}
                  </p>
                  <p>
                    {list?.renter_reazy_settings?.state && list?.renter_reazy_settings?.city
                      ? `${list?.renter_reazy_settings?.city} ${list?.renter_reazy_settings?.state} `
                      : list?.renter_reazy_settings?.state
                        ? list?.renter_reazy_settings?.state
                        : list?.renter_reazy_settings?.city
                          ? list?.renter_reazy_settings?.city
                          : null}
                  </p>
                </div>

                {
                  list?.my_list?.booking_details &&
                  <div className='w-[50%]'>
                    <h2 className='text-[11px] font-semibold text-right'>{moment(list?.my_list?.booking_details?.date_of_appointment).format('ll')}</h2>
                    <h2 className='text-[11px] font-semibold text-right'>{list?.my_list?.booking_details?.time_of_appointment} </h2>
                  </div>
                }
              </div>
              {
                activeTab === 0 ? (
                  list?.my_list ? (
                    <div className='mx-2 flex flex-col gap-2'>
                      {/* Accept button: Only visible if booking details are not null and status is '0' */}
                      {list?.my_list?.booking_details && list?.my_list?.booking_details?.status === '0' && (
                        <button
                          onClick={() => handleAcept(list)}
                          className='px-6 py-1 w-full rounded-md bg-[#00AF0C] text-white'
                        >
                          {property?.id === list?.id && savedListLoader && type === "accept" ? (
                            <div className='flex items-center justify-center'>
                              <Loader type="single" />
                            </div>
                          ) : (
                            t('r_accept')
                          )}
                        </button>
                      )}

                      {/* Rental Application Sent Check */}
                      {list?.my_list?.rental_app_sent ? (
                        <button
                          onClick={() => handlePopup(list?.my_list?.rental_app_sent)}
                          className='px-[6px] py-1 rounded-md bg-blue-500 w-full text-white'
                        >
                          {t('view_rent_app')}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleSendRent(list)}
                          className='px-[5px] py-1 w-full rounded-md bg-blue-500 text-white'
                        >
                          {property?.id === list?.id && type === "sendRent" && savedListLoader ? (
                            <div className='flex items-center justify-center'>
                              <Loader type="single" />
                            </div>
                          ) : (
                            t('snd_req_rnt_ap')
                          )}
                        </button>
                      )}

                      {/* Cancel Button: Only visible if booking details are not null and status is '0' */}
                      {list?.my_list?.booking_details && (
                        <button
                          onClick={() => handleOpen(list?.my_list)}
                          className='px-6 py-1 w-full rounded-md bg-[#F63030] text-white'
                        >
                          {property?.id === list?.id && savedListLoader && type === "cancel" ? (
                            <div className='flex items-center justify-center'>
                              <Loader type="single" />
                            </div>
                          ) : (
                            list?.my_list?.booking_details?.status === '1' ? t('cancel_app') : t('decline')
                          )}
                        </button>
                      )}
                    </div>
                  ) : (
                    // Render this section if my_list is null
                    <div className="px-2">
                      <button
                        onClick={() => handleSendRent(list)}
                        className='px-[5px] py-1 w-full rounded-md bg-blue-500 text-white'
                      >
                        {property?.id === list?.id && type === "sendRent" && savedListLoader ? (
                          <div className='flex items-center justify-center'>
                            <Loader type="single" />
                          </div>
                        ) : (
                          t('snd_req_rnt_ap')
                        )}
                      </button>
                    </div>
                  )
                ) : (
                  <div className="px-2">
                    <button onClick={() => handleMessage(list)}
                      className={`px-[5px] py-1  w-full  rounded-md bg-blue-500 text-white `}>
                      {t('message')}
                    </button>
                  </div>
                )
              }

            </div>
          ))}

        </div>
        {
          !loading && properties?.length === 0 &&
          <div className=' flex justify-center items-center w-full  pt-3'>
            <Loader type="loader" />
          </div>
        }
      </>
    )
  }
}

export default REasypass