import React from 'react'
import listImg from '../../assests/clientImgs/Leased Servicer App.png'
// import rommateImg from '../../assests/images/list.png'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const Banner = ({ list }) => {
    const token = useSelector((state) => state.userAuth.loginInfo.token);
    const { t } = useTranslation()
    return (
        <>
            <div className="bg-[#F5F6FC] w-full h-full pb-7 lg:px-0 px-5">
                <div className="container mx-auto">
                    <div className="grid lg:grid-cols-2 pt-[3rem] gap-8">
                        <div className='flex justify-center items-center'>
                            <img src={listImg} className="rounded-xl object-cover h-[400px] w-auto" alt="list_image" />
                        </div>
                        <div className='flex flex-col justify-center '>
                            <h2 className='lg:text-[2.8rem] text-[2rem] font-bold'>{t('list_with_leased')}</h2>
                            <p className='text-[1rem] font-medium'>{t('discover_new')}</p>
                            {
                                !token &&
                                <div className='flex  items-center my-4'>
                                    <h2 className='font-bold text-[18px]'>{t('sign_up_post')}</h2>
                                    <button className='lg:px-6 py-2 lg:w-auto w-full text-white bg-[#F79F15] rounded-md lg:mx-[40px]'>{t('sign_up_register')}</button>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Banner