import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../utils/loaderButton'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import moment from 'moment';
import "moment/locale/es";

import { GetTimeSlotes } from '../../lib/Api/RantelServiceApi';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GetRenterTimeSlotes } from '../../lib/Api/RentalAppApi';

const BlockTime = ({ selected, setBooked, setAutoBook, setPackage, setIsTimeSelect }) => {
    const [loading, setLoading] = useState(false);
    const [selectedAll, setSelectedAll] = useState([]);
    const [dateArray, setDateArray] = useState([]);
    const [timeSlotsByDate, setTimeSlotsByDate] = useState({});
    const [currentDateIndex, setCurrentDateIndex] = useState(0);
    const [selectedTimesByDate, setSelectedTimesByDate] = useState({});
    const [autoSelect, setAutoSelect] = useState(false);


    const { language } = useSelector((state) => state.userAuth)

    moment.locale(language === "en" ? 'en' : 'es');
    const location = useLocation().pathname


    const roundToNearestHalfHour = (date) => {
        const minutes = date.getMinutes();
        const roundedMinutes = Math.round(minutes / 5) * 5;
        const roundedDate = new Date(date);
        roundedDate.setMinutes(roundedMinutes);
        return roundedDate;
    };

    const currentTime = roundToNearestHalfHour(new Date());

    const getTimeSlots = async () => {
        let payload = { "price": selected?.id };
        setLoading(true)
        let res = await (location === "/reazy" ? GetRenterTimeSlotes(payload) : GetTimeSlotes(payload));
        if (res) {
            setLoading(false)
            let obj = Object.assign({}, ...res)
            const timeSlotsResponse = obj?.timeslots || [];
            const formattedDates = timeSlotsResponse.map(item => ({
                day: moment(item.day).format('YYYY-MM-DD'),
                timeslots: item.timeslots
            }));

            const dates = formattedDates.map(item => item.day);
            setDateArray(dates);

            const timeSlotsMap = {};
            formattedDates.forEach(item => {
                timeSlotsMap[item.day] = item.timeslots;
            });
            setTimeSlotsByDate(timeSlotsMap);
        }
    };

    const handleNextDate = () => {
        if (currentDateIndex < dateArray.length - 1) {
            setCurrentDateIndex(currentDateIndex + 1);
        }
    };

    const handlePreviousDate = () => {
        if (currentDateIndex > 0) {
            setCurrentDateIndex(currentDateIndex - 1);
        }
    };

    // const handleSelect = (id, time) => {
    //     let payload = {
    //         time: time,
    //         id: id
    //     };
    //     const currentDisplayDate = moment(dateArray[currentDateIndex]).format('YYYY-MM-DD');
    //     setSelectedTimesByDate(prevState => {
    //         const currentTimes = prevState[currentDisplayDate] || []; // Get current selected times for the date
    //         const exists = currentTimes.some(item => item.id === id); // Check if the time is already selected
    //         const updatedTimes = exists
    //             ? currentTimes.filter(item => item.id !== id) // Deselect if already selected
    //             : [...currentTimes, payload]; // Select if not already selected

    //         const updatedSelectedTimesByDate = {
    //             ...prevState,
    //             [currentDisplayDate]: updatedTimes // Update the state for the current date
    //         };

    //         const result = Object.keys(updatedSelectedTimesByDate).map(date => ({
    //             date: moment(date).format('MM/DD/YYYY'), // Format the date as MM/DD/YYYY
    //             times: updatedSelectedTimesByDate[date].map(item => moment(item.time, 'h:mm A').format('hh:mm A')) // Keep times in 12-hour format
    //         }));

    //         setSelectedAll(result);
    //         return updatedSelectedTimesByDate;
    //     });
    // };


    const handleSelect = (id, time) => {
        let payload = {
            time: time,
            id: id
        };
        const currentDisplayDate = moment(dateArray[currentDateIndex]).format('YYYY-MM-DD');

        setSelectedTimesByDate(prevState => {
            const currentTimes = prevState[currentDisplayDate] || []; // Get current selected times for the date
            const exists = currentTimes.some(item => item.id === id); // Check if the time is already selected
            const updatedTimes = exists
                ? currentTimes.filter(item => item.id !== id) // Deselect if already selected
                : [...currentTimes, payload]; // Select if not already selected

            let updatedSelectedTimesByDate = {
                ...prevState,
                [currentDisplayDate]: updatedTimes // Update the state for the current date
            };

            // Remove the date if no times are selected
            if (updatedTimes.length === 0) {
                delete updatedSelectedTimesByDate[currentDisplayDate];
            }

            // Create the result array without empty dates
            const result = Object.keys(updatedSelectedTimesByDate)
                .filter(date => updatedSelectedTimesByDate[date].length > 0) // Exclude dates with no times
                .map(date => ({
                    date: moment(date).format('MM/DD/YYYY'), // Format the date as MM/DD/YYYY
                    times: updatedSelectedTimesByDate[date].map(item => moment(item.time, 'h:mm A').format('hh:mm A')) // Keep times in 12-hour format
                }));

            setSelectedAll(result); // Update the selectedAll state
            return updatedSelectedTimesByDate;
        });
    };

    const saveSelectedTimes = () => {
        const result = Object.keys(selectedTimesByDate).map(date => ({
            date: moment(date).format('MM/DD/YYYY'),
            times: selectedTimesByDate[date].map(item => moment(item.time, 'h:mm A').format('HH:mm')) // Keep times in 12-hour format
        }));
        setBooked(result);
        setIsTimeSelect(false);
        setPackage(true);
    };

    useEffect(() => {
        getTimeSlots();
    }, []);

    const { t } = useTranslation();


    const currentDisplayDate = dateArray[currentDateIndex];
    const currentTimeslots = timeSlotsByDate[currentDisplayDate] || [];

    return (
        <>
            {

                <div className='h-full p-5'>
                    <div className='flex items-center  font-semibold cursor-pointer ' onClick={() => setIsTimeSelect(false)}>
                        {t('Back')}
                    </div>
                    {loading ?
                        <Loader type="loadData" />
                        :
                        <div className="flex justify-center  items-center flex-col">
                            <div className="bg-gradient-to-r from-[#B913F0] to-[#E63ECD] rounded-md p-3 text-white">
                                <h2>{t('reasy_date')}</h2>
                            </div>
                            <div className='border justify-between rounded-md mt-5 w-[40%] flex items-center p-2 border-[#E63ECD]'>
                                <IoIosArrowBack
                                    onClick={handlePreviousDate}
                                    size={24}
                                    className={`cursor-pointer ${currentDateIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={currentDateIndex === 0}
                                />
                                <p className='flex justify-center items-center'>
                                    {moment(currentDisplayDate).format('DD MMMM YYYY')}
                                </p>
                                <IoIosArrowForward
                                    onClick={handleNextDate}
                                    size={24}
                                    className={`cursor-pointer ${currentDateIndex === dateArray.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    disabled={currentDateIndex === dateArray.length - 1}
                                />
                            </div>

                            <div className='flex flex-wrap max-w-[400px] items-center'>
                                {currentTimeslots?.map((time, index) => {
                                    const currentDisplayDate = moment(dateArray[currentDateIndex]).format('YYYY-MM-DD');
                                    const currentTimes = selectedTimesByDate[currentDisplayDate] || [];
                                    return (
                                        <div
                                            onClick={() => handleSelect(index, time)}
                                            className={`border rounded-md w-[90px] flex justify-center items-center cursor-pointer mx-1 text-center py-2 my-2 ${currentTimes.some(item => item.time === time)
                                                ? 'bg-white text-black'
                                                : 'bg-white text-[#E63ECD] border border-[#E63ECD]'
                                                }`}
                                            key={index}
                                        >
                                            {currentTimes.some(item => item.time === time)
                                                ? <MdClose size={20} />
                                                : time}
                                        </div>
                                    );
                                })}

                            </div>

                            <div className='flex justify-between gap-5 items-center pt-3'>
                                <div className='flex items-center gap-2'>
                                    <div className='border flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                        <MdClose size={20} />
                                    </div>
                                    <p className='text-[16px] font-medium'>{t('booked')}</p>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <div className='border border-[#E63ECD] flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                    </div>
                                    <p className='text-[16px] font-medium'>{t('avail')}</p>
                                </div>
                                <div className='flex items-center gap-2'>
                                    <div className='border flex justify-center items-center rounded-md w-[30px] bg-[#E63ECD] h-[30px]'>
                                    </div>
                                    <p className='text-[16px] font-medium'>{t('selected')}</p>
                                </div>
                            </div>
                            <div className='flex items-center flex-wrap gap-1 max-w-[400px] mt-5'>
                                {selectedAll.map((item, index) => (
                                    <div key={index} className="mb-4">
                                        <p className="font-semibold text-[16px]">{item.date}</p>
                                        <div className="flex flex-wrap gap-2">
                                            {item.times.map((time, timeIndex) => (
                                                <div
                                                    key={timeIndex}
                                                    className="rounded-md w-[90px] cursor-pointer text-center py-2 my-2 bg-white text-[#E63ECD] border border-[#E63ECD]"
                                                >
                                                    {time}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className='flex justify-center items-center mt-5 w-[40%]'>
                                {
                                   
                                    <button onClick={() => saveSelectedTimes()} className=" w-full disabled:bg-opacity-[0.5] disabled:cursor-not-allowed max-w-full my-4 bg-gradient-to-r from-[#B913F0] to-[#E63ECD] rounded-md p-3 text-white">
                                        {t('block_time')}
                                    </button>
                                }
                            </div>
                        </div>
                        // <div className={`${selectedAll.length > 0 && 'xl:pl-[200px]'} flex justify-center items-center`}>
                        //     <div className={` grid ${selectedAll.length > 0 ? 'grid-cols-2' : 'grid-cols-1'}  mx-auto gap-10 `}>
                        //         <div className={`${selectedAll.length > 0 ? 'w-[400px] ' :'max-w-[400px]'} mx-auto `}>
                        //             <div className="flex justify-center items-center flex-col">
                        //                 <div className="bg-gradient-to-r w-full from-[#B913F0] to-[#E63ECD] rounded-md p-3 text-white">
                        //                     <h2>{t('reasy_date')}</h2>
                        //                 </div>
                        //                 <div className='border justify-between rounded-md mt-5 w-full flex items-center p-2 border-[#E63ECD]'>
                        //                     <IoIosArrowBack
                        //                         onClick={handlePreviousDate}
                        //                         size={24}
                        //                         className={`cursor-pointer ${currentDateIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        //                         disabled={currentDateIndex === 0}
                        //                     />
                        //                     <p className='flex justify-center items-center'>
                        //                         {moment(currentDisplayDate).format('DD MMMM YYYY')}
                        //                     </p>
                        //                     <IoIosArrowForward
                        //                         onClick={handleNextDate}
                        //                         size={24}
                        //                         className={`cursor-pointer ${currentDateIndex === dateArray.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                        //                         disabled={currentDateIndex === dateArray.length - 1}
                        //                     />
                        //                 </div>

                        //                 <div className='flex flex-wrap justify-center items-center'>
                        //                     {currentTimeslots?.map((time, index) => {
                        //                         const currentDisplayDate = moment(dateArray[currentDateIndex]).format('YYYY-MM-DD');
                        //                         const currentTimes = selectedTimesByDate[currentDisplayDate] || [];
                        //                         return (
                        //                             <div
                        //                                 onClick={() => handleSelect(index, time)}
                        //                                 className={`border rounded-md w-[90px] flex justify-center items-center cursor-pointer mx-1 text-center py-2 my-2 ${currentTimes.some(item => item.time === time)
                        //                                     ? 'bg-white text-black'
                        //                                     : 'bg-white text-[#E63ECD] border border-[#E63ECD]'
                        //                                     }`}
                        //                                 key={index}
                        //                             >
                        //                                 {currentTimes.some(item => item.time === time)
                        //                                     ? <MdClose size={20} />
                        //                                     : time}
                        //                             </div>
                        //                         );
                        //                     })}

                        //                 </div>

                        //                 <div className='flex justify-between gap-5 items-center pt-3'>
                        //                     <div className='flex items-center gap-2'>
                        //                         <div className='border flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                        //                             <MdClose size={20} />
                        //                         </div>
                        //                         <p className='text-[16px] font-medium'>{t('booked')}</p>
                        //                     </div>
                        //                     <div className='flex items-center gap-2'>
                        //                         <div className='border border-[#E63ECD] flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                        //                         </div>
                        //                         <p className='text-[16px] font-medium'>{t('avail')}</p>
                        //                     </div>
                        //                     <div className='flex items-center gap-2'>
                        //                         <div className='border flex justify-center items-center rounded-md w-[30px] bg-[#E63ECD] h-[30px]'>
                        //                         </div>
                        //                         <p className='text-[16px] font-medium'>{t('selected')}</p>
                        //                     </div>
                        //                 </div>

                        //                 <div className='flex justify-center items-center mt-5 w-full'>
                        //                     <button onClick={() => saveSelectedTimes()} className=" w-full disabled:bg-opacity-[0.5] disabled:cursor-not-allowed my-4 bg-gradient-to-r from-[#B913F0] to-[#E63ECD] rounded-md p-3 text-white">
                        //                         {t('block_time')}
                        //                     </button>
                        //                 </div>
                        //             </div>
                        //         </div>
                        //         <div className='pt-20 '>
                        //             {selectedAll.length > 0 && selectedAll?.map((item, index) => (
                        //                 <div key={index} className="mb-2">
                        //                     <p className="font-semibold text-[16px]">{item.date}</p>
                        //                     <div className="flex flex-wrap gap-2 pt-2">
                        //                         {item.times.map((time, timeIndex) => (
                        //                             <div
                        //                                 key={timeIndex}
                        //                                 className="rounded-md w-[90px] cursor-pointer text-center py-2  bg-white text-[#E63ECD] border border-[#E63ECD]"
                        //                             >
                        //                                 {time}
                        //                             </div>
                        //                         ))}
                        //                     </div>
                        //                 </div>
                        //             ))}
                        //         </div>
                        //     </div>
                        // </div>

                    }
                </div >
            }
        </>
    );
};


export default BlockTime;
