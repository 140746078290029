import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreateRentalApp } from '../../lib/Api/RentalAppApi'
import { CheckRentalApp } from '../../Redux/UserAuthSlice/RentalSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { setLocateNotification, updateUser } from '../../Redux/UserAuthSlice/UserAuthSlice'
import { EditUserProfile } from '../../lib/Api/UserApi'
import moment from 'moment'
// import { EditUserProfile } from '../../lib/Api/'

const SuccessPayment = () => {
    const navigation = useNavigate()
    const { rentAppSend } = useSelector((state) => state?.rental)
    // const user = useSelector((state) => state?.userAuth?.userInfo)
    const dispatch = useDispatch()

    // let updateUserInfo = async () => {
    //     try {
    //         const formData = new FormData();
    //         formData.append('id_verified_at', moment().format('YYYY-MM-DD'))
    //         let {res} = await EditUserProfile(formData)
    //         if (res && user?.userRole === "servicer" ) {
    //             let UserData = Object.assign({}, ...res)
    //             dispatch(updateUser(UserData))
    //             navigation('/servicerPanel')
    //         }
    //         else {
    //             let UserData = Object.assign({}, ...res)
    //             dispatch(updateUser(UserData))
    //             navigation('/dashboard')
    //         }
    //     }
    //     catch (err) { }
    // }



    useEffect(() => {
        let fetchData = async () => {
            let { res } = await CreateRentalApp()
            if (res) {
                if (rentAppSend) {
                    if (rentAppSend?.type === "confirm") {
                        dispatch(setLocateNotification("Confirmed"))
                        navigation('/dashboard')
                    }
                    else if (rentAppSend?.type === "book") {
                        dispatch(setLocateNotification("Booked"))
                        navigation('/dashboard')
                    }
                    else if (rentAppSend?.type === "rentalApp") {
                        navigation('/rentalApp')
                    }
                    else {
                        navigation('/rental/rental_property')
                    }

                }
                else {
                    dispatch(CheckRentalApp("yes"))
                    navigation('/rentalApp')
                }
            }
        }
        // if (user?.userRole === "servicer" || user?.userRole === "renter") {
        //     updateUserInfo()
        // } else {
            fetchData()
        // }
    }, [])

    const { t } = useTranslation()

    return (
        <div>
            <h1 className='text-xl pt-10 text-center font-bold '>{t('order_done')}</h1>
        </div>
    )
}

export default SuccessPayment