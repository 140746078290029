import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom';
import LikeDislikePopup from '../../../utils/Popup/LikeDisLike';
import PostAppointment from '../../../utils/Popup/PostAppointment'
import propImg from '../../../assests/svg/home.svg'
import gp1 from '../../../assests/images/gp1.png'
import gp2 from '../../../assests/images/gp2.png'
import gp3 from '../../../assests/images/gp3.png'
import gp4 from '../../../assests/images/gp4.png'
import Arrow from '../../../assests/svg/arrow.svg'
import Resey from '../../../assests/images/f6.png'
import flash from '../../../assests/images/f5.png'
import bath from '../../../assests/images/bath.png'
import { FaFastBackward } from "react-icons/fa";
import { yupResolver, } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FilterRommateFlashOnCampus, FilterRommateOnCampus, FilterRommateReazyOnCampus } from '../../../lib/Api/RentalAppApi';
import { Loader } from '../../../utils/loaderButton';
import DynamicPopup from '../../../utils/Popup/DynamicPopup';
import Deck from './Tinder';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PropertyOnCampus from './PropertyDetailOnCamp';
import CreditConfirm from '../../GenericComp/CreditConfirmation';
import { setResetStates, setUniversity } from '../../../Redux/UserAuthSlice/RommateSlice';
import OnCampusMobile from './MobileVersion/OnCampusMobile';
import OnCampusSidebar from '../../GenericComp/Sidebars/OnCampusSidebar';
import { MdClose } from 'react-icons/md';
import Flashpass from '../../../pages/RentalPanelPages/FlashPass';
import { Countdown } from '../../../hooks/TimeCountDown';

const OnCampus = ({ setActives, handleClose, handleOpen, selectedItem, timeLeft, autoSelect, handleUpdate, info }) => {

  const [Intr, setIntr] = useState([])
  const [detail, setDetail] = useState("")
  const [Act, setAct] = useState([])
  const [active, setActive] = useState(null)
  const [show, setShow] = useState(false)
  const [showPost, setShowPost] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [listing, setListing] = useState([])
  const [originalLists, setOriginalLists] = useState([])
  const [type, setType] = useState("")
  const [property, setProperty] = useState({})
  const [swapSlider, setSwapSlider] = useState("")
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isCredit, setIsCredit] = useState(false)
  const [purchaseType, setPurchaseType] = useState("")
  const [afterSearch, setAfterSearch] = useState(false)
  const [showList, setShowList] = useState(false)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [savedPositions, setSavedPositions] = useState([]);
  const [points, setPoints] = useState([]);
  const [error, setError] = useState({
    stay: "",
    active: "",
    intrest: "",
    imags: ""
  })
  const containerRef = useRef(null);
  const topRef = useRef(null);

  const location = useLocation().pathname


  //  Icon on above property 
  let Icons = [
    { img: gp1, type: "dislike" },
    { img: gp2, link: "/flash" },
    { img: "", type: "backForword" },
    { img: gp3, link: "/reazy" },
    { img: gp4, type: "like" },
  ]

  const { rommate, renterQuestion, universityInfo } = useSelector((state) => state?.rommate)

  const { t } = useTranslation()


  const schema = yup.object({
    university_name: yup
      .object({
        value: yup.string().required(t('rommate_uni')),
        label: yup.string().required(t('rommate_uni')),
      })
      .nullable()
      .required(t('rommate_uni')),
    // domm_name: yup.string().required(t('rommate_uni_dorm')),
    domm_name: yup.string(),
    // room: yup.string().required(t('rommate_uni_room')),
    major: yup.string().required(t('rommate_uni_major')),
    grade_level: yup.string().required(t('rommate_uni_grad')),
  });


  const { register, handleSubmit, reset, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const dispatch = useDispatch()


  const handleSwap = () => {
    setActives(0)
    dispatch(setResetStates())
  }


  const OnSubmit = async (data) => {
    setSearchLoading(true)
    let ques = renterQuestion?.OnCampus?.priorites?.map((qu) => {
      return {
        "question_id": qu?.question?.id,
        "option_id": qu?.option?.id
      }
    })

    if (topRef.current) {
      topRef.current.scrollIntoView({ block: "start", behavior: "smooth" });
    }
    let payload = {
      "on_campus": rommate?.on_campus,
      "college_student": rommate?.college_student,
      'university_id': data?.university_name?.value,
      'university_name': data?.university_name?.label,
      'domm_name': data?.domm_name,
      'major': data?.major || universityInfo?.major,
      'grade_level': data?.grade_level || universityInfo?.grade_level,
      'length_of_stay': active === 0 ? "One Semester" : "Two Semester",
      'interests': Intr,
      "priorities": ques,
      'extra_curricular_activities': Act
    }
    let uniInfo = {
      "on_campus": rommate?.on_campus,
      "college_student": rommate?.college_student,
      'university_id': data?.university_name?.value,
      'university_name': data?.university_name?.label,
      'domm_name': data?.domm_name,
      'major': data?.major || universityInfo?.major,
      'grade_level': data?.grade_level,
      'length_of_stay': active === 0 ? "One Semester" : "Two Semester",
    }
    dispatch(setUniversity(uniInfo))
    let { res } = await (location === "/reazy" ? FilterRommateReazyOnCampus(payload) : location === "/flash" ? FilterRommateFlashOnCampus(payload) : FilterRommateOnCampus(payload))
    if (res) {
      let obj = Object.assign({}, ...res)
      setSearchLoading(false)
      const reversedRoommates = [...obj.roommates].reverse();
      setListing(reversedRoommates);
      setShowList(true)
      if (obj.roommates?.length === 0) {
        setAfterSearch(true)
      }
      else {
        setAfterSearch(false)
      }
    }
  }

  const OnSubmitAfter = async (data) => {
    let ques = renterQuestion?.OnCampus?.priorites?.map((qu) => {
      return {
        "question_id": qu?.question?.id,
        "option_id": qu?.option?.id
      }
    })

    let payload = {
      "on_campus": rommate?.on_campus,
      "college_student": rommate?.college_student,
      'university_id': data?.university_name?.value || universityInfo?.university_id,
      'university_name': data?.university_name?.label || universityInfo?.university_name,
      'domm_name': data?.domm_name || universityInfo?.university_name,
      'major': data?.major || universityInfo?.domm_name,
      'grade_level': data?.grade_level || universityInfo?.grade_level,
      'length_of_stay': active === 0 ? "One Semester" : "Two Semester",
      'interests': Intr,
      "priorities": ques,
      'extra_curricular_activities': Act
    }
    let { res } = await (location === "/reazy" ? FilterRommateReazyOnCampus(payload) : location === "/flash" ? FilterRommateFlashOnCampus(payload) : FilterRommateOnCampus(payload))
    if (res) {
      let obj = Object.assign({}, ...res)
      const reversedRoommates = [...obj.roommates].reverse();
      setListing(reversedRoommates);
      setShowList(true)
    }
  }

  //handle Next and Previus Property base on like and dislike button
  const handleNextProperty = (type) => {
    if (type === "like") {
      setSwapSlider("like")
      swipeRight()
    }
    else {
      setSwapSlider("dislike")
      swipeLeft()
    }
  }

  // Function to handle left swipe
  const swipeLeft = async (id) => {
    const numberOfElementsToKeep = 1;
    const filteredData = listing.filter((item, index) => index >= listing.length - numberOfElementsToKeep);
    let obj = Object.assign({}, ...filteredData)
    const updatedData = listing.filter((item, i) => item?.id !== obj?.id)
    let check = originalLists.find((f) => f.id === obj.id)
    if (!check) {
      setOriginalLists((prev) => [...prev, ...filteredData])
    }
    setListing(updatedData)
  };

  // Function to handle right swipe
  const swipeRight = async () => {
    const numberOfElementsToKeep = 1;
    const filteredData = listing.filter((item, index) => index >= listing.length - numberOfElementsToKeep);
    let obj = Object.assign({}, ...filteredData)
    const updatedData = listing.filter((item, i) => item?.id !== obj?.id)
    setListing(updatedData)
    let check = originalLists.find((f) => f.id === obj.id)
    if (!check) {
      setOriginalLists((prev) => [...prev, ...filteredData])
    }
    setShow(true)
    setProperty(obj)
  };

  // Revert property

  const RevertProperty = () => {
    const numberOfElementsToKeep = 1;
    const filteredData = originalLists.filter((item, index) => index >= originalLists.length - numberOfElementsToKeep);
    let obj = Object.assign({}, ...filteredData)
    const lastElement = points.pop();
    setSavedPositions((prev) => [...prev, lastElement])
    setListing((prev) => [...prev, obj]);
    let check = originalLists.filter((f) => f.id !== obj?.id)
    setOriginalLists(check)
  }

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // //console.log("agte", afterSearch)

  useEffect(() => {
    if (Object.keys(universityInfo)?.length > 0) {
      let payload = {
        university_name: {
          value: universityInfo?.university_id,
          label: universityInfo?.university_name
        },
        domm_name: universityInfo?.domm_name || "", // Ensure all expected fields are included
        major: universityInfo?.major || "",
        grade_level: universityInfo?.grade_level || "",
      }
      reset(payload)
      setActive(universityInfo?.length_of_stay === "One Semester" ? 0 : 1)

    }
  }, [])



  // useEffect(() => {
  //   setListing(ConstData)
  // },[])


  return (
    <div className='overflow-hidden h-full' ref={containerRef} >
      {/* <div ></div> */}
      {detail && <DynamicPopup modalOpen={detail} data={<PropertyOnCampus swipeLeft={(val) => swipeLeft(val)} detail={detail} close={() => setDetail("")} />} close={() => setDetail("")} />}
      {isCredit && <DynamicPopup data={<CreditConfirm purchaseType={purchaseType} close={() => setIsCredit(false)} />} modalOpen={isCredit} close={() => setIsCredit(false)} />}
      {

        <>
          {show && <LikeDislikePopup setShowPost={setShowPost} propertyType="rommate" setPurchaseType={setPurchaseType} permition={show} Toggle={setShow} type={type} property={property} setIsCredit={setIsCredit} />}
          {showPost && <PostAppointment propertyType="rommate" swipeLeft={(val) => swipeLeft(val)} permition={showPost} Toggle={setShowPost} type={type} property={property} />}
          <form className=' shadow-md  rounded-sm' onSubmit={handleSubmit(OnSubmit)}>

            <div className="container mx-auto pt-10 ">
              <div className="xl:grid lg:grid md:grid hidden grid-cols-4 gap-3">
                <div className={`${location === "/reazy" || location === "/flash" ? 'border' : 'xl:mt-5 lg:mt-5 md:mt-5 border p-5'}`}>
                  {location === "/filterRommate" ? '' :
                    <div className='border-b border-[#F79F15] flex '>
                      <div onClick={() => handleSwap()} className={`flex justify-center mt-3 pb-1  cursor-pointer items-center w-full `}>
                        <h2 className={`text-center text-[18px] font-semibold`}>{t('servicer_rentals')}</h2>
                      </div>
                      <div className={`flex justify-center mt-3 pb-1 cursor-pointer items-center w-full border-b-2 border-[#F79F15]`}>
                        <h2 className={` text-[#F79F15] text-center text-[18px] font-semibold`}>{t('rommates')}</h2>
                      </div>
                    </div>
                  }
                  <div className={`${location === "/reazy" || location === "/flash" ? 'p-5' : 'p-0'}`}>
                    <OnCampusSidebar
                      t={t}
                      register={register}
                      control={control}
                      error={error}
                      errors={errors}
                      setAct={setAct}
                      setIntr={setIntr}
                      Act={Act}
                      Intr={Intr}
                      setActive={setActive}
                      active={active}
                      searchLoading={searchLoading}
                      setLoading={setLoading}
                      loading={loading}
                    />
                  </div>
                </div>
                <div className="w-full col-span-3">
                  {location === "/reazy" &&
                    <div className='flex justify-end gap-2 items-end pb-5 mt-5 '>
                      <button type="button" onClick={() => handleOpen("setting")} className='bg-[#B913F0] text-white px-3 py-2 rounded-md'>{t('setting_avil')}</button>
                      <button type="button" onClick={() => handleOpen("filter")} className='bg-[#B913F0] text-white px-3 py-2 rounded-md'>{t('r_personal_setting')}</button>
                    </div>
                  }
                  {searchLoading ?
                    location === "/reazy" ? (
                      <div className='flex justify-center items-center '>
                        <Loader type={location === "/reazy" || location === "/flash" ? "loader" : "loadData"} />
                        <div className='px-5'>
                          <MdClose className='cursor-pointer' onClick={() => handleClose()} />
                        </div>
                      </div>
                    ) : (
                      <div className='flex justify-center items-center'>
                        <Loader type="loadData" />
                      </div>
                    )

                    :

                    <div className={`max-w-full h-full w-[800px] mx-auto   `} ref={topRef}>
                      {location === "/reazy" || location === "/flash" && (
                        <div className="bg-orange-50 border  px-4 py-3 rounded relative" role="alert">
                          <div className='flex justify-between'>
                            <div className='flex items-center'>
                              <img src={location === "/flash" ? flash : Resey} alt="flash" className='object-cover w-[10%]' />
                              <h2 className='underline font-semibold text-black text-[20px] mx-2'> {location === "/flash" ? t('flash_lane') : t('REsy_lane')}</h2>
                            </div>
                            <div>
                            </div>
                          </div>
                          <div className='pt-2'>
                            <ul>
                              <li className="text-[16px] flex items-center  text-black font-medium ">
                                1. {location === "/flash" ? t('flash_lane_info') : t('reasy_lane_info')}
                                {location === "/flash" &&
                                  <div className='px-2'>
                                    {selectedItem?.flashlane?.days > 0 ? <div className='flex items-center gap-2 px-2' > {selectedItem?.flashlane?.days}d
                                      {` ${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}
                                    </div>
                                      :
                                      `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`
                                    }
                                  </div>
                                }
                              </li>
                              <li className='flex items-center text-[16px] text-black font-medium'>2. {location === "/flash" ? t('expire_flash') : t('resazy_sub')} <div className='bg-white text-gray-300 shadow-md rounded-md px-6 py-2 ml-2 text-[16px] flex items-center gap-2 '>
                                {location === "/reazy" &&
                                  info?.days > 0 && <div>{info?.days}d</div>
                                }
                                <Countdown selected={info?.time || "00:00:00"} />
                              </div></li>

                            </ul>
                          </div>
                        </div>
                      )}
                      {
                        location === "/reazy" ? (
                          <>
                            {location === "/reazy" &&
                              <div className='flex flex-col pt-4'>
                                <h2 className='text-[16px] font-semibold '>{t('auto_secd')}</h2>
                                <label className="relative inline-flex items-center cursor-pointer mt-2">
                                  <input
                                    checked={autoSelect}
                                    onChange={handleUpdate}
                                    type="checkbox"
                                    name="automatically_accept_appointments"
                                    className="sr-only peer" />
                                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                </label>
                              </div>
                            }
                            {
                              !loading && listing?.length === 0 ? (
                                <div className='flex flex-col mt-5'>
                                  <div className="flex flex-row justify-center items-center">
                                    <div className='xl:flex lg:flex md:flex hidden justify-center items-center rotate-90'>
                                      <img src={Arrow} alt="home" />
                                    </div>
                                    <div className="flex flex-col justify-center items-center">
                                      <img src={propImg} className='w-[40%]' alt="property_img" />
                                      <h2 className='font-semibold text-[20px] pt-3 max-w-[250px] text-center'>{t('rommate_filter')}</h2>
                                    </div>

                                  </div>
                                  <div className='w-[200px] mx-auto'>
                                    <button type='submit' className='border xl:mx-10 lg:mx-10 md:mx-10  w-full mt-5 py-2 px-6 rounded-md text-white   bg-[#F79F15]'>{afterSearch ? t('re-search') : t('search_lab')}</button>
                                  </div>
                                </div>
                              )
                                :
                                <Flashpass listType="rommate" loading={loading} properties={listing} FetchFilterProperty={OnSubmitAfter} />
                            }
                          </>
                        ) :
                          (
                            <>
                              <div className={`flex  mt-4 justify-center items-center`}>
                                {
                                  listing && listing?.length > 0 &&
                                  Icons.map((icon, i) => (
                                    <div className=''>
                                      {icon.link ? <Link to={icon?.link}>
                                        <img className='cursor-pointer' key={i} src={icon.img} alt={icon.img} />
                                      </Link> :
                                        icon?.img === "" && originalLists?.length > 0 ?
                                          <div className='border h-[55px] mb-[8px] w-[65px] rounded-md cursor-pointer flex justify-center items-center' onClick={() => RevertProperty()}>
                                            <FaFastBackward size={22} />
                                          </div>

                                          :
                                          <img className='cursor-pointer' onClick={() => handleNextProperty(icon?.type)} key={i} src={icon.img} alt={icon.img} />
                                      }
                                    </div>
                                  ))
                                }
                              </div>
                              <div className="w-full max-w-[600px] mx-auto">
                                {
                                  listing && listing?.length > 0 ?
                                    // <div className={`${listing?.length > 15 && 'mt-[3rem] m-auto'}  h-full `}>
                                    <Deck rommateType="oncampus" currentIndex={currentIndex} swapSlider={swapSlider} toggle={setShow} listing={listing} setSavedPositions={setSavedPositions} savedPositions={savedPositions} setPoints={setPoints} setListing={(val) => setListing(val)} setDetail={setDetail} originalLists={originalLists} setOriginalLists={setOriginalLists} setProperty={setProperty} />
                                    // </div>
                                    :
                                    loading ? <div className='flex justify-center items-center pt-10'> {`${t('wait_lab')} .....`}</div> :
                                      <div className='flex flex-col mt-5'>
                                        <div className="flex flex-row justify-center items-center">
                                          <div className='xl:flex lg:flex md:flex hidden justify-center items-center rotate-90'>
                                            <img src={Arrow} alt="home" />
                                          </div>
                                          <div className="flex flex-col justify-center items-center">
                                            <img src={propImg} className='w-[40%]' alt="property_img" />
                                            <h2 className='font-semibold text-[20px] pt-3 max-w-[250px] text-center'>{t('rommate_filter')}</h2>
                                          </div>

                                        </div>
                                        <div className='w-[200px] mx-auto'>
                                          <button type='submit' className='border xl:mx-10 lg:mx-10 md:mx-10  w-full mt-5 py-2 px-6 rounded-md text-white   bg-[#F79F15]'>{afterSearch ? t('re-search') : t('search_lab')}</button>
                                        </div>
                                      </div>

                                }
                              </div>
                            </>
                          )}


                    </div>
                  }
                </div>

              </div>


            </div>
          </form>

          {/* Mobile*/}
          <OnCampusMobile />
        </>
      }
    </div >
  )
}

export default OnCampus