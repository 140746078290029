import React from 'react'
import sales from '../../assests/images/sales.png'
const CommentForm = () => {
    return (
        <>
            {/* comments form  */}
            <div className='w-full ' >
                <h2 className='text-[20px] font-semibold underline'>Leave a Comment</h2>
                <div className='grid lg:grid-cols-3 gap-5'>
                    <div className='col-span-2'>
                        <form className='my-5 lg:mx-0 mr-4'>
                            <div className='grid grid-cols-2 gap-5'>
                                <input type="text" className='border rounded-md w-full p-2' name="username" placeholder='Name' />
                                <input type="email" className='border  rounded-md w-full p-2' name="email" placeholder='Email' />
                            </div>
                            <textarea cols={5} rows={5} placeholder="Comment" name="comment" className="border my-4 rounded-md w-full p-2"></textarea>

                        </form>
                    </div>
                    <div>
                        <img src={sales} className="lg:w-[90%] w-full" alt="comment_form" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommentForm