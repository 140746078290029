import React, { useState } from 'react'
import CheckCollege from './CheckCollege'
import CheckCampus from './CheckCampus'
import { Loader } from '../../../utils/loaderButton'
import CheckPriorities from '../../ServicerPanelComp/RoomateComp/CheckPriorities'

function Decions({ questions, loading }) {
    const [active, setActive] = useState(0)
    return (
        <>
            {loading ?
                <Loader type="loadData" />
                :
                <div>
                    {active === 0 ?
                        <CheckCollege setActives={setActive} />
                        : active === 1 ?
                            <CheckCampus questions={questions} setActives={setActive} />
                            :
                            <CheckPriorities />
                    }
                </div>
            }
        </>
    )
}

export default Decions