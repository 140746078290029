export function isOver18(dateOfBirth) {
    // Convert the date of birth string to a Date object
    const dob = new Date(dateOfBirth);
    
    // Calculate the current date
    const currentDate = new Date();
    
    // Calculate the difference in years
    const age = currentDate.getFullYear() - dob.getFullYear();
    
    // Check if the person is over 18
    if (age > 18) {
      return true;
    } else if (age === 18) {
      // If the person is exactly 18, check if the current month and day are after their birthday
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
      const birthMonth = dob.getMonth();
      const birthDay = dob.getDate();
      
      if (currentMonth > birthMonth || (currentMonth === birthMonth && currentDay >= birthDay)) {
        return true;
      }
    }
    
    return false;
  }
  