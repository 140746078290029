import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ForgotUser, ResetPassword, SendOtp, SignInUser, VerifyPasswords } from '../../../lib/Api/UserApi'
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Loader } from '../../../utils/loaderButton';
import { IoMdEye } from 'react-icons/io';
import { IoEyeOff } from 'react-icons/io5';
import { toast } from 'react-toastify';
import OTPInput from "otp-input-react";


const PasswordVerify = ({ close }) => {
    const { t } = useTranslation()
    const user = useSelector((state) => state?.userAuth?.userInfo)
    const lng = useSelector((state) => state?.userAuth?.language)
    const [loading, setLoading] = useState(false)
    const [showpass, setShowPass] = useState(false)
    const [forgotStep, setForgotStep] = useState(0)
    const [otp, setOtp] = useState('')
    const [otpLoading, setOtpLoading] = useState(false)

    const schemaPassword = yup.object({
        password: yup.string().required(t('invalid_password'))
    })
    const schemaResetPassword = yup.object({
        password: yup.string()
            .required(t('invalid_password'))
            .min(3, t('password_charctor')),
        confirmpassword: yup.string()
            .required(t('invalid_password'))
            .oneOf([yup.ref('password')], t('match_password')),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(forgotStep === 0 ? schemaPassword : schemaResetPassword) });

    let Step00 = async () => {
        let payload = {
            lang: lng,
            email: user?.email
        }
        setForgotStep(1)
        setOtpLoading(true)
        let { res } = await ForgotUser(payload)
        if (res) {
            setOtpLoading(false)
        }

    }

    let Step0 = async (payload) => {
        setLoading(true)
        let { res } = await VerifyPasswords(payload)
        if (res) {
            setLoading(false)
            close()
        }
        else {
            setLoading(false)
        }
    }
    let Step1 = async () => {
        let data = {
            email: user?.email,
            otp: otp
        }

        if (otp !== '') {
            setLoading(true)
            let { res, error } = await SendOtp(data)
            if (res) {
                setLoading(false)
                setForgotStep(2)
            }
            else if (error) {
                setLoading(false)
            }
        }
        else {
            toast.error(t('verify_code'))
        }
    }
    let Step2 = async (payload) => {
        setLoading(true)
        let { res } = await ResetPassword(payload)
        if (res) {
            setLoading(false)
            close()
        }
        else {
            setLoading(false)
        }
    }

    const OnSubmit = async (data) => {
        let payload = {
            email: user?.email,
            password: data?.password,
            // lang: lng,
            // type: "verify"
        }
        let payload2 = {
            email: user?.email,
            password: data?.password,
            r_password: data?.confirmpassword,
            lang: lng,

        }

        forgotStep === 0 ? Step0(payload) : Step2(payload2)

    }


    return (
        <>
            <form onSubmit={handleSubmit(OnSubmit)}>
                <div className='flex flex-col'>
                    {forgotStep === 0 ?
                        <>
                            <h1 className='text-[18px] font-medium'>{t('verify_pass')}</h1>
                            <div className='border rounded-md flex items-center px-2 '>
                                <input type={showpass ? 'text' : 'password'} {...register('password')} placeholder={t('password')} className='   rounded focus:outline-none h-[40px]' />
                                {showpass ? <IoMdEye onClick={() => setShowPass(false)} size={20} className='cursor-pointer' /> : <IoEyeOff onClick={() => setShowPass(true)} size={20} className='cursor-pointer' />}
                            </div>
                            <button type='submit' className='font-semibold bg-[#F79F15] mt-4 px-6 py-1.5 text-white rounded-md'>
                                {
                                    loading ?
                                        <Loader />
                                        : t('verify')
                                }
                            </button>
                        </>
                        : forgotStep === 1 ?
                            <>
                                {
                                    otpLoading ?
                                        <Loader type="loadData" />
                                        :
                                        <>
                                            <div className='pt-6'>
                                                <h2 className='lg:text-[2rem] text-[1.5rem] font-bold'>{t('verification_code_text')}</h2>
                                                <p className='text-[14px]'>
                                                    {t('verification_code')}
                                                </p>
                                            </div>
                                            <div className=' w-full flex justify-center'>
                                                <OTPInput
                                                    value={otp}
                                                    onChange={setOtp}
                                                    autoFocus
                                                    inputClassName="border-b-2 fous:border-transparent focus:ring-0"
                                                    OTPLength={4}
                                                    otpType="number"
                                                    disabled={false}
                                                    secure={false} />
                                            </div>
                                            <div onClick={() => Step1()} className='font-semibold text-center bg-[#F79F15] mt-4 px-6 py-1.5 text-white rounded-md'>
                                                {
                                                    loading ?
                                                        <Loader />
                                                        : t('send')
                                                }
                                            </div>
                                        </>
                                }
                            </>
                            :
                            <>
                                <h1 className='text-[18px] font-medium'>{t('forgot_pass')}</h1>
                                <div className='border rounded-md flex items-center px-2 mt-2'>
                                    <input type={showpass ? 'text' : 'password'} {...register('password')} placeholder={t('password')} className='   rounded focus:outline-none h-[40px]' />
                                    {showpass ? <IoMdEye onClick={() => setShowPass(false)} size={20} className='cursor-pointer' /> : <IoEyeOff onClick={() => setShowPass(true)} size={20} className='cursor-pointer' />}
                                </div>
                                <div className='border rounded-md flex items-center px-2 mt-2'>
                                    <input type={showpass ? 'text' : 'password'} {...register('confirmpassword')} placeholder={t('password')} className='   rounded focus:outline-none h-[40px]' />
                                    {showpass ? <IoMdEye onClick={() => setShowPass(false)} size={20} className='cursor-pointer' /> : <IoEyeOff onClick={() => setShowPass(true)} size={20} className='cursor-pointer' />}
                                </div>
                                <button type='submit' className='font-semibold bg-[#F79F15] mt-4 px-6 py-1.5 text-white rounded-md'>
                                    {
                                        loading ?
                                            <Loader />
                                            : t('save')
                                    }
                                </button>
                            </>


                    }
                    {forgotStep === 0 &&
                        <div onClick={() => Step00()}>
                            <div className='flex justify-end items-end p-2 text-[14px] cursor-pointer'>
                                <p >{t('forgot_pass')}</p>
                            </div>
                        </div>
                    }


                </div>
            </form>
        </>
    )
}

export default PasswordVerify