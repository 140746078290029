import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import { GetViewRentalApp } from '../../../lib/Api/RantelServiceApi';
import { Loader } from '../../../utils/loaderButton';
import { toast } from 'react-toastify';
import GaugeChart from './CreditGuage';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const ViewRentalApp = ({ list }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState("")
    const [type, setType] = useState("")
    const [credit, setCredit] = useState("")
    const [loading, setLoading] = useState(false)


    const handleLoardPdf = async (reportType, types) => {
        setType(types)
        // if (types === "rentalapp") {
        //     setFile(list?.rental_app_sent?.pdf)
        // }
        // else {
        try {
            let payload = {
                "list_id": list?.id,
                "report_type": reportType
            }
            setLoading(true)
            let res = await GetViewRentalApp(payload)
            console.log("REs", res)
            if (res) {
                let obj = Object.assign({}, ...res)
                setLoading(false)
                if (obj?.report_url === null) {
                    toast.error("Report not Available")
                }
                else if (reportType === "credit_report") {
                    if (obj?.report !== null) {
                        setCredit(obj?.report?.score)
                    }else{
                        setCredit(400)
                    }
                }
                else {
                    setFile(obj?.report_url)
                }
            }
        }
        catch (err) { }
        // }
    }

    // Pdf Views
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const handleNextpage = () => {
        let val = pageNumber
        setPageNumber(val + 1)
    }
    const handlePrv = () => {
        let val = pageNumber
        setPageNumber(val - 1)
    }


    const { t } = useTranslation()

    return (
        <>
            {
                file !== "" ?
                    <>
                        {file !== "" &&
                            <div className='cursor-pointer font-bold pb-2' onClick={() => setFile('')}>
                                {t('Back')}
                            </div>
                        }
                        <Document
                            file={file}
                            className="flex justify-center h-auto  items-center"
                            onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} renderTextLayer={false} />
                        </Document>
                        <div className='flex justify-center  items-center flex-col'>
                            <p>
                                {t('page')} {pageNumber} {t('of')} {numPages}
                            </p>
                            <div className='flex items-center justify-center'>
                                {pageNumber > 1 &&
                                    <button className='font-semibold mx-3 bg-[#F79F15] px-6 py-2 text-white rounded-md' onClick={() => handlePrv()}>{t('prev_lbl')}</button>
                                }
                                {pageNumber < numPages &&
                                    <button className='font-semibold bg-[#F79F15] px-6 py-2 text-white rounded-md' onClick={() => handleNextpage()}>{t('next_lbl')}</button>
                                }
                            </div>
                        </div>
                    </>
                    :
                    credit !== "" ?
                        <div className='flex justify-center items-center'>
                            <GaugeChart credit={credit} setCredit={setCredit} />
                        </div>
                        :
                        <div className="flex flex-col justify-between items-center p-2">
                            <ul className='mt-5'>
                                {/* <li onClick={() => handleLoardPdf("liens_and_judgements", "liens")} className='h-[60px] cursor-pointer text-[18px] flex justify-center items-center border-b pb-2'>
                                    {loading && type === "liens" ? <Loader type="loadData" /> : "View Liens & Judgements"}
                                </li> */}
                                <li onClick={() => handleLoardPdf("criminal_report", "criminal")} className='h-[60px] cursor-pointer text-[18px] flex justify-center items-center border-b  '>
                                    {loading && type === "criminal" ? <Loader type="loadData" /> : t('report_1')}
                                </li>
                                <li onClick={() => handleLoardPdf("credit_report", "credit")} className='h-[60px] cursor-pointer text-[18px] flex justify-center items-center border-b  '>
                                    {loading && type === "credit" ? <Loader type="loadData" /> : t('report_2')}
                                </li>
                                <li onClick={() => handleLoardPdf("evictions", "evictions")} className='h-[60px] cursor-pointer text-[18px] flex justify-center items-center border-b  '>
                                    {loading && type === "evictions" ? <Loader type="loadData" /> : t('report_3')}
                                </li>
                                <li onClick={() => handleLoardPdf("rental_application", "rentalapp")} className='h-[60px] cursor-pointer text-[18px] flex justify-center items-center  '>
                                    {loading && type === "rentalapp" ? <Loader type="loadData" /> : t('report_4')}

                                </li>
                            </ul>
                        </div>
            }
        </>
    )
}

export default ViewRentalApp