import React from 'react'
// import { callApi } from '../../utils/CallApi';
// import { useForm } from "react-hook-form";
// import { toast, Toast container mx-auto xl:px-[110px] } from 'react-toastify';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { MdClose } from 'react-icons/md';



const DynamicPopup = ({ modalOpen, data, close }) => {


    return (
        <>
            <Modal open={modalOpen} center onClose={() => close(false)}>

                <div className="bg-white rounded shadow-lg overflow-auto p-5">

                    {/* Modal header */}

                    <div className='flex justify-end' onClick={() => close(false)}>
                        <MdClose  className='cursor-pointer'/>
                    </div>
                    <div className=''>
                        {data}
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default DynamicPopup