import React, { useState, useEffect } from 'react'
import { AddRentalVideo, DelRentalImage, DelRentalVideo, UploadRentalImage } from '../../../../lib/Api/RantelServiceApi';
import { Loader } from '../../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircle } from 'react-icons/io';
import { toast } from "react-toastify"
import { FaRegImages } from 'react-icons/fa';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { S3_BUCKET, myBucket } from '../../../../config/Aws'


const EditPropertyPic = ({ data, setProperties }) => {
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        Url: [],
        codeFile: '',
        threeDFloor: []
    });
    const [loadingVideo, setLoadingVideo] = useState(false)

    const handleImageChange = (event, type) => {
        let fileLen = event.target.files
        if (type === "3dFloor" && fileLen?.length > 5) {
            toast.error(t('upload_img_info'))
        }
        else if (type !== "3dFloor" && fileLen?.length > 15) {
            toast.error(t('upload_3d_info'))

        }
        else {
            const files = Array.from(event.target.files);
            const acceptedFileTypes = ['image/jpeg', 'image/png']; // Add more accepted file types as needed
            const imageFiles = files.filter(file => acceptedFileTypes.includes(file.type));
            Promise.all(
                imageFiles.map(file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                        reader.readAsDataURL(file);
                    });
                })
            )
                .then(async (base64Images) => {

                    if (type === "3dFloor") {
                        let arr = []
                        for (let index = 0; index < base64Images.length; index++) {
                            const element = base64Images[index];
                            let payload = {
                                image: element,
                            }
                            arr.push(payload)

                        }
                        setFormData((prevObject) => ({
                            ...prevObject,
                            threeDFloor: [...formData?.threeDFloor, ...arr],
                        }));
                        let paylaod = {
                            "property_id": data?.id,
                            "images": base64Images,
                            "type": "floor_plan"
                        }

                        let { res } = await UploadRentalImage(paylaod)
                        if (res) {
                            setProperties(res[0].property)

                        }


                    }
                    else {
                        let arr = []
                        for (let index = 0; index < base64Images.length; index++) {
                            const element = base64Images[index];
                            let payload = {
                                image: element,
                            }
                            arr.push(payload)

                        }
                        setFormData((prevObject) => ({
                            ...prevObject,
                            Url: [...formData?.threeDFloor, ...arr],
                        }));
                        let paylaod = {
                            "property_id": data?.id,
                            "images": base64Images,
                            "type": "image"
                        }

                        let { res } = await UploadRentalImage(paylaod)
                        if (res) {
                            setProperties(res[0].property)

                        }

                    }

                })
                .catch(error => {
                    //console.log('Error occurred while converting images to base64:', error);
                });
        }
    };

    const RemoveVideo = async (id) => {
        let payload = {
            "property_id": id
        }
        setLoadingVideo(true)
        let { res } = await DelRentalVideo(payload)
        if (res) {
            setLoadingVideo(false)
            setProperties(res[0].property)
            setFormData((prevObject) => ({
                ...prevObject,
                codeFile: null,
            }));
        }
        else {
            setLoadingVideo(false)
        }
    }

    const RemoveImage = async (type, id, rentalId) => {
        if (type === "3dfloor") {
            let ImagesList = formData?.threeDFloor?.filter((_, i) => i !== id)
            setFormData((prev) => ({
                ...prev,
                threeDFloor: ImagesList
            }))
            if (rentalId) {
                let { res } = await DelRentalImage(rentalId)
                if (res) {
                    setProperties(res[0].property)
                }
            }
        }
        else {
            let ImagesList = formData?.Url?.filter((_, i) => i !== id)
            setFormData((prev) => ({
                ...prev,
                Url: ImagesList
            }))
            if (rentalId) {
                let { res } = await DelRentalImage(rentalId)
                if (res) {
                    setProperties(res[0].property)
                }
            }
        }

    }


    const handleCodeFile = async (evnt) => {
        const file = evnt.target.files[0]
        if (!file.name.match(/.(mp4|mov|wmv|avi|avchd|flv|mkv|mpeg-2)$/i)) {
            toast.error("Only Video File is Required")
        } else {
            setLoadingVideo(true)
            const params = {
                ACL: 'private',
                Body: file,
                Bucket: S3_BUCKET,
                Key: file?.name
            };

            myBucket.putObject(params, async (err, result) => {

                if (err) {
                    setLoadingVideo(false)
                    toast.error(err)
                } else {
                    let payload = {
                        "property_id": data?.id,
                        "video": file?.name
                    }

                    let res = await AddRentalVideo(payload)
                    setFormData((prev) => ({ ...prev, codeFile: file?.name }))
                    // setErrors((prev) => ({ ...prev, video: '' }))
                    setLoadingVideo(false)
                }
            });
        }
    }





    useEffect(() => {
        let arr = []
        let arr2 = []

        for (let index = 0; index < data?.images.length; index++) {
            const element = data?.images[index];
            if (element?.type === "image") {
                arr.push(element)
            }
            else {
                arr2.push(element)

            }

        }
        setFormData((prev) => ({
            ...prev,
            Url: arr,
            threeDFloor: arr2,
            codeFile: data?.video
        }))
    }, [])



    const { t } = useTranslation()

    return (
        <div className='p-5 max-w-[600px]'>
            <h2 className='font-semibold text-[20px]'>{t('img_lab')}</h2>
            <div className='border py-3 pl-3 flex items-center h-auto '>
                <label htmlFor='upload' className={`${formData?.Url?.length === 15 ? 'cursor-not-allowed' : 'cursor-pointer'} bg-gray-100 cursor-pointer border rounded-md px-6 h-[60px] flex justify-center items-center`}>
                    <div className='flex items-center text-gray-400  ' >
                        <FaRegImages />
                        <h2 className='px-2'>{t('uplod_lbl')}</h2>
                    </div>
                </label>
                <input type="file" id="upload" className='hidden' disabled={formData?.Url?.length === 15 ? true : false} name="image_upload" multiple onChange={(e) => handleImageChange(e, "images")} />
                <div className=' ml-3  grid grid-cols-5 gap-2 '>
                    {
                        formData?.Url?.map((u, i) => {
                            return (
                                <div className='relative mx-2 border rounded-md' key={i}>
                                    {u?.image &&
                                        <img src={u?.image} alt="user_image" className='object-cover w-[120px] h-[60px] rounded-md ' />}
                                    <div className='absolute right-1 top-1'>
                                        <IoMdCloseCircle className='text-red-500 cursor-pointer' onClick={() => RemoveImage("", i, u?.id)} />
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
            <span className='text-sm text-red-500'> {errors.Image ? errors.Image : t('invaild_img')} </span>
            <h2 className='font-semibold text-[20px]'>{t('3d_lab')} {(t('optional'))}</h2>
            <div className='border py-3 pl-3 flex items-center '>
                <label htmlFor='uploads' className={`${formData?.threeDFloor?.length === 5 ? 'cursor-not-allowed' : 'cursor-pointer'} bg-gray-100 cursor-pointer border rounded-md px-6 h-[60px] flex justify-center items-center`}>
                    <div className='flex items-center text-gray-400 '>
                        <FaRegImages />
                        <h2 className='px-2'>{t('uplod_lbl')}</h2>
                    </div>
                </label>
                <input type="file" id="uploads" className='hidden' disabled={formData?.threeDFloor?.length === 5 ? true : false} name="image_upload" multiple onChange={(e) => handleImageChange(e, "3dFloor")} />

                <div className=' ml-3  grid grid-cols-5 gap-2 '>
                    {
                        formData?.threeDFloor?.map((u, i) => {
                            return (
                                <div className='relative mx-2 border rounded-md' key={i}>
                                    {u?.image &&
                                        <img src={u?.image} alt="user_image" className='object-cover w-[120px] h-[60px] rounded-md ' />}
                                    <div className='absolute right-1 top-1'>
                                        <IoMdCloseCircle className='text-red-500 cursor-pointer' onClick={() => RemoveImage("3dfloor", i, u?.id)} />
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>

            </div>
            <span className='text-sm text-red-500'> {errors.Image ? errors.Image : t('invaild_imgs')} </span>
            {/* <div className='border p-3 my-3'>
        <input type="file" onChange={handleChangeImage} />
      </div> */}
            <h2 className='font-semibold text-[20px]'>{t('vide_lab')} {(t('optional'))}</h2>
            <div className='border p-3 mt-3 '>
                {
                    loadingVideo ?
                        <Loader type="loadData" />
                        :
                        <>
                            {formData.codeFile === null ?
                                <input type="file" onChange={handleCodeFile} />
                                :
                                <div className='flex items-center'>
                                    <BsFillCheckCircleFill className='text-green-500' />
                                    <p className='px-4'>{formData.codeFile}</p>
                                    <div className=''>
                                        <IoMdCloseCircle className='text-red-500 cursor-pointer ' size={20} onClick={() => RemoveVideo(data?.id)} />
                                    </div>
                                </div>
                            }
                        </>
                }
            </div>

            {/* <div className='flex items-center py-5 justify-end '>
                <button type="button" onClick={() => close(false)} className='w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>{t('cancel')}</button>
                {
                    loading ? <div className='ml-5'><Loader type="saved" /></div>
                        :
                        <button onClick={() => handleUpdate()} className='w-[138px] py-2 ml-5 rounded-md bg-[#FF8C00] text-white'>{t('save')}</button>
                }
            </div> */}
        </div>
    )
}

export default EditPropertyPic