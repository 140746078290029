import React, { useState } from 'react'
import CheckCollege from './CheckCollege'
import CheckCampus from './CheckCampus'

function Decision({ setShow, setStatus, updateStatus, setRommate }) {
    const [active, setActive] = useState(0)
    return (
        <>
            {active === 0 ?
                <CheckCollege setShow={setShow} updateStatus={updateStatus} setRommate={setRommate} setStatus={setStatus} setActives={setActive} />
                :
                <CheckCampus setShow={setShow} updateStatus={updateStatus} setRommate={setRommate} setStatus={setStatus} setActives={setActive} />
            }
        </>
    )
}

export default Decision