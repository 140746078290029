import React, { useState, useEffect } from 'react'
// import user_img from '../../../assests/images/user_img.png'
import flash from '../../../assests/images/dummy.png'
// import RexyLane from '../../../assests/images/f6.png'
import { useTranslation } from 'react-i18next'
import { GetPaymentHistory } from '../../../lib/Api/RentalAppApi'
import { Loader } from '../../../utils/loaderButton'
import moment from 'moment/moment'
const Method = () => {
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let fetchData = async () => {
      setLoading(true)
      let res = await GetPaymentHistory()
      if (res) {
        let UserData = Object.assign({}, ...res)
        setHistory(UserData?.transactions)
        setLoading(false)
      } else {
        setLoading(false)
      }
      // setCardList(UserData?.cards)
    }
    fetchData()
  }, [])

  //console.log("list", history )

  let { t } = useTranslation()
  return (
    <>

      <h2 className='text-[20px] font-bold'>{t('payment_history')}</h2>
      {loading && <Loader type="loadData" />}
      {
        !loading && history.map((list, i) => (
          <div className=' my-5 mx-3 flex items-center justify-between' key={i}>
            <div className='flex items-center'>
              <img src={list?.metadata?.image || flash } alt="user_img " className='object-cover w-[50px] h-[50px]' />
              <div className='mx-2'>
                <h2 className='font-semibold text-[18px]'>{list?.description || "N/A"} </h2>
                {/* <h2 className='font-medium text-[18px]'>{list?.heading} </h2> */}
                <p className='text-[#CDD4D9] text-[12px]'>{moment(list?.metadata?.created_at).format('MM/DD/YYYY')}</p>
              </div>
            </div>
            <div className='flex items-center gap-10'>
              <div className='flex flex-col'>
                <h2 className='text-[18px] font-semibold text-right'>${list?.amount / 100}</h2>
                <p className='text-[#CDD4D9] text-[12px] text-right'>{list?.metadata?.time || "N/A"}</p>
              </div>
              {/* <div>
                <a href={list?.receipt_url} target='_black' className='border px-5 rounded-md py-1 cursor-pointer hover:bg-[#F79F15] hover:text-white'>{t('view')}</a>
              </div> */}
            </div>
          </div>
        ))
      }
      {
       !loading && history?.length === 0 &&
        <div className='pt-10'>{t('record_not_found')}</div>
      }
    </>
  )
}

export default Method