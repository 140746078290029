import React from 'react'
import Banner from '../../components/RommatesComp/Banner'
import Hosting from '../../components/LitsComp/Hosting'
import Shop from '../../components/LitsComp/Shop'
import Vacancy from '../../components/LitsComp/Vacancy'
import Layout from '../../components/Layout/Layout'
// import host1 from '../../assests/images/host1.png'
// import host2 from '../../assests/images/host2.png'
// import host3 from '../../assests/images/host3.png'
// import host4 from '../../assests/images/host4.png'
import rom1 from '../../assests/images/search2.png'
import rom2 from '../../assests/images/rom2.png'
import rom3 from '../../assests/images/rom3.png'
import rom4 from '../../assests/images/rom4.png'
import rom5 from '../../assests/images/search.png'
import rom6 from '../../assests/images/rom6.png'
import rom7 from '../../assests/images/rom7.png'
import rom8 from '../../assests/images/rom8.png'
import rombg1 from '../../assests/images/rent55.png'
import rombg2 from '../../assests/clientImgs/Common Traits.png'
import rombg3 from '../../assests/clientImgs/Calendar.png'
import rombg4 from '../../assests/clientImgs/Match.png'
import { useTranslation } from 'react-i18next'


const RentRommate = () => {

    const { t } = useTranslation()
    let space = {
        heading:t('web_rental_1'),
        lists: [t('web_rommate_9'), t('web_rommate_10'), t('web_rommate_11')],
        title: "",
        icons: [{ name:t('web_rommate_5'), original: rom5, hoverImg: rom1 }, { name:t('web_rommate_6'), original: rom6, hoverImg: rom2 }, { name: t('web_rommate_7'), original: rom7, hoverImg: rom3 }, { name:t('web_rommate_8'), original: rom8, hoverImg: rom4 }],
    }

    let shopData = {
        heading: t('web_list_rommate_10'),
        lists: [
            {
                title: t('web_rommate_13'),
                original: rom1,
                name: t('web_rommate_9'),
                hoverImg: rombg1
            },
            {
                title: t('web_rommate_14'),
                name:t('web_rommate_10'),
                original: rom2,
                hoverImg: rombg2
            },
            {
                title: t('web_rommate_15'),
                original: rom3,
                name:t('web_rommate_11'),
                hoverImg: rombg3
            },
            {
                title:t('web_rommate_16'),
                original: rom4,
                name: t('web_rommate_12'),
                hoverImg: rombg4
            },
        ]

    }




    return (
        <Layout>
            <Banner />
            <Hosting space={space} />
            <Shop shopData={shopData} />
            <Vacancy />
        </Layout>
    )
}

export default RentRommate