import React, { useState } from 'react'
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/es/styles-compiled.css';
import { MdClose } from 'react-icons/md';
import { Modal } from 'react-responsive-modal';

const AddPaymentPopup = ({ open, setOpen }) => {
    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;

        setState((prev) => ({ ...prev, [name]: value }));
    }

    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }

    const { cvc, expiry, focus, name, number } = state

    return (
        <>

            <Modal open={open} siz={'lg'}>
                <div className='flex justify-end p-5 cursor-pointer ' onClick={() => setOpen(false)}>
                    <MdClose className='text-[25px] border rounded-md' />
                </div>
                <div id="PaymentForm " className='w-[500px] p-5'>
                    <Cards
                        cvc={cvc}
                        expiry={expiry}
                        focused={focus}
                        name={name}
                        number={number}
                    />
                    <form className=' py-3 px-[20px] '>
                        <input
                            type="tel"
                            name="number"
                            maxLength={16}
                            placeholder="Card Number"
                            className=' p-2 w-full rounded-md bg-[#F5F6FC] focus:outline-none'
                            value={number}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />
                        <div className='grid grid-cols-2 gap-4 pt-2 justify-center'>
                            <input
                                type="tel"
                                maxLength={4}
                                name="expiry"
                                placeholder="MM/YY"
                                className=' p-2 w-full rounded-md bg-[#F5F6FC] focus:outline-none'
                                value={expiry}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                            />

                            <input
                                type="tel"
                                maxLength={3}
                                name="cvc"
                                placeholder="Cvc"
                                className=' p-2 w-full rounded-md bg-[#F5F6FC] focus:outline-none'
                                value={cvc}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                            />
                        </div>

                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            className='mt-3 p-2 w-full rounded-md bg-[#F5F6FC] focus:outline-none'
                            value={name}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                        />

                        <div className='my-4'>
                            <button className='w-full disabled:opacity-[0.5] py-3 bg-[#F79F15] text-white text-[18px] rounded-md'>ADD</button>
                        </div>

                    </form>
                </div>
            </Modal>

        </>
    )
}

export default AddPaymentPopup