
import React, { useEffect, useState } from 'react'
import GooglePlacesAutocomplete from "react-google-autocomplete";
import bed from '../../../assests/images/bed.png'
import bath from '../../../assests/images/bath.png'
import { GetPropertyType, ValidateAddress } from '../../../lib/Api/RantelServiceApi';
import { useForm, Controller } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Loader } from '../../../utils/loaderButton';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { API } from '../../../config/GoolePlace';
// import { toast } from 'react-toastify';
// import { ImGift } from 'react-icons/im';

const Information = ({ handleNext, handleBack, State, t }) => {
  const [type, setType] = useState("")
  const [broker_fe, setBroker_fe] = useState("")
  const schema = yup.object({
    number_of_beds: yup.string().required(t('invalid_bed')),
    number_of_baths: yup.string().required(t('invalid_bath')),
    security_deposit: yup.string().required(t('invalid_sec')),
    rent: yup.string(),
    // .required(t('invalid_rent')),
    city: yup.string(),
    // .required(t('city_msg')),
    state: yup.string(),
    // .required(t('state_msg')),
    street: yup.string(),
    // .required(t('street_msg')),
    zipcode: yup.string(),
    // .required(t('zipcode_msg')),
    apt: yup.string().optional(),
    broker_fee_type: yup.string().optional(),
    broker_fee_percentage: yup
      .number()
      .typeError(t('broker_fee_number')) // Ensure it's a valid number
      .min(0, t('broker_fee_less')) // Minimum value of 0
      .max(100, t('broker_fee_exceed')) // Maximum value of 100 for percentage
      .test(
        'is-decimal',
        t('broker_fee_decimal'),
        (value) => (value ? /^\d+(\.\d{1,2})?$/.test(value) : true) // Allow up to 2 decimal places or no value
      )
      .transform((value) => (isNaN(value) ? undefined : parseFloat(value))), // Ensure it's a float,
    broker_fee: yup
      .number()
      .typeError(t('broker_fee_number')) // Ensure it's a valid number
      .min(0, t('broker_fee_less')) // Minimum value of 0
      .test(
        'is-decimal',
        t('broker_fee_decimal'),
        (value) => (value ? /^\d+(\.\d{1,2})?$/.test(value) : true) // Allow up to 2 decimal places or no value
      )
      .transform((value) => (isNaN(value) ? undefined : parseFloat(value))), // Ensure it's a float
  });
  const [propType, setPropType] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingInfo, setLoadingInfo] = useState(false)
  const [Errors, SetErrors] = useState({})
  // const [googleLoaded, setGoogleLoaded] = useState(false);
  // const [locate, setlocate] = useState()
  const [defaultValue, setDefaultValue] = useState("")
  const [formData, setFormData] = useState({
    active: null,
    locate: {},
  });
  const [suggestions, setSuggestions] = useState([]);
  const { register, control, handleSubmit, reset, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const lng = useSelector((state) => state?.userAuth?.language)

  const formatCurrency = (value) => {
    if (!value || isNaN(value)) {
      return '$0'; // Return an empty string if value is not a valid number
    }
    if (!State) {
      const numericValue = value?.replace(/[^0-9]/g, '');
      // Format the value with commas
      const formattedValue = new Intl.NumberFormat('en-US').format(numericValue);
      // Add the dollar sign
      return `$${formattedValue}`;
    }
    else {
      const formattedValue = new Intl.NumberFormat('en-US').format(value);
      // Add the dollar sign
      return `$${formattedValue}`;
    }
  };

  const formatFloatCurrency = (value) => {
    if (!value || isNaN(value)) {
      return '$0.00'; // Ensure it's always properly formatted even if invalid
    }

    // Ensure the value is a float for currency formatting
    const numericValue = parseFloat(value);

    // Format the value with commas and two decimal places
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2, // Always show two decimal places
      maximumFractionDigits: 2
    }).format(numericValue);

    return formattedValue;
  };


  const validateForm = () => {
    const newErrors = {};

    if (!Object.keys(formData.locate).length > 0) {
      newErrors.location = t('invalid_addres')
    }
    if (formData.active === null) {
      newErrors.property = t('invalid_prop')
    }
    return newErrors
  }



  const OnSubmit = async (data) => {
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
    let rentPrice = parseFloat(data?.rent)
    let SecuityPrice = parseFloat(data?.security_deposit)
    let payload = {
      "rent": rentPrice,
      "security_deposit": SecuityPrice,
      "property_type_id": formData.active,
      "number_of_baths": data.number_of_baths,
      "number_of_beds": data.number_of_beds,
      "latitude": formData?.locate.geometry?.location?.lat() || formData?.locate?.latitude,
      "longitude": formData?.locate.geometry?.location?.lng() || formData?.locate?.longitude,
      "city": data?.city,
      "state": data?.state,
      "street": data?.street,
      "zipcode": data?.zipcode,
      "apt": data?.apt,
      "broker_fee_type": data?.broker_fee_type,
      "broker_fee": type === "percent" ? data?.broker_fee_percentage : Number(broker_fe)
      
    }
    setLoadingInfo(true)
    let res = await ValidateAddress(payload, t)
    if (res) {
      setLoadingInfo(false)
      handleNext(payload)
    } else {
      setLoadingInfo(false)
    }

    }
    else {
      SetErrors(validationErrors);
    }


  }


  const autocompleteOptions = {
    types: ['address'],
    componentRestrictions: { country: 'us' }
  };

  const handleSuggestions = (predictions) => {
    // Filter suggestions based on whether they have a city and zip code
    const filteredSuggestions = predictions.filter((prediction) => {
      if (prediction.address_components) {
        const hasCity = prediction.address_components.some(
          (component) => component.types.includes('locality')
        );

        const hasZipCode = prediction.address_components.some(
          (component) => component.types.includes('postal_code')
        );

        return hasCity && hasZipCode;
      }

      return false;
    });

    setSuggestions(filteredSuggestions);
  };


  useEffect(() => {

    let getType = async () => {
      setLoading(true)
      let { res } = await GetPropertyType()
      let ConvertObj = Object.assign({}, ...res)
      if (res) {
        setLoading(false)
        setPropType(ConvertObj.property_type)

      }
      else {
        setLoading(false)

      }
    }
    setTimeout(() => {

      getType()
    }, 1000);

  }, [lng])


  useEffect(() => {
    reset(State)
    if (State?.property_type_id) {
      setFormData((prev) => ({
        ...prev, active: State?.property_type_id,
        locate: { latitude: State?.latitude, longitude: State?.longitude }
      }))
      const fetchPlaceDetails = async () => {
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${State?.latitude},${State?.longitude}&key=AIzaSyBD-xaHdgobMyufM5tW3whuusr8l5J57X0`
          );
          const results = response.data.results;
          if (results.length > 0) {
            let locationData = results[0]
            // setPlace(results[0])
            setDefaultValue(locationData?.formatted_address);
            let payload = {
              latitude: State.latitude,
              longitude: State.longitude
            }
            setFormData({ locate: payload })
          }
        } catch (error) {
          //console.error('Error fetching place details:', error);
        }
      };
      fetchPlaceDetails();
    }

  }, [State])

  console.log("type", broker_fe)

  return (
    <>

      <h2 className='font-semibold text-[20px]'>{t('address')}</h2>
      <form onSubmit={handleSubmit(OnSubmit)}>
        <div>
          <GooglePlacesAutocomplete
            apiKey={"AIzaSyCpBFsGdMzJ_qo1PPGUM6i0g__WTZuzTQk"}
            options={autocompleteOptions}
            onSuggestions={handleSuggestions}
            suggestions={suggestions}
            defaultValue={defaultValue}
            onPlaceSelected={async (place) => {
              setFormData((prev) => ({ ...prev, locate: place }))
              let city = "";
              let state = "";
              let zipcode = "";
              let street = "";
              let propertyId = "";
              let country = "";

              // Iterate through the address components
              place?.address_components.forEach(component => {
                const types = component.types;
                if (types.includes("locality")) {
                  city = component.long_name;
                } else if (types.includes("administrative_area_level_1")) {
                  state = component.short_name;
                } else if (types.includes("postal_code")) {
                  zipcode = component.long_name;
                } else if (types.includes("route")) {
                  street = component.long_name;
                } else if (types.includes("street_number")) {
                  propertyId = component.long_name;
                }
              });

              if (state !== "MA" && state !== "CT") {
                toast.error(t('loca_erro'));
                return;
              }

              let PropertyId = `${propertyId} ${street}`
              let payload = {
                city,
                state,
                zipcode,
                street: PropertyId

              }
              reset(payload)

            }}
            className='border p-2 w-full focus:outline-none rounded-md my-2'
            placeholder={t('location_search')}
          />


          {Errors && (
            <p className="text-red-500 text-sm ">{Errors?.location}</p>
          )}

        </div>

        <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 gap-2 my-2">
          <div >
            <h2 className='text-[16px] font-semibold mb-2 '>{t('street')}</h2>
            <input type="text" {...register('street')} placeholder={t('street')} className='border px-2 h-[40px] w-full focus:outline-none rounded-md' />
            {errors.street && (
              <p className="text-red-500 text-sm ">{errors.street.message}</p>
            )}
          </div>
          <div >
            <h2 className='text-[16px] font-semibold mb-2 '>{t('city')}</h2>
            <input type="text" {...register('city')} placeholder={t('city')} className='border px-2 h-[40px] w-full focus:outline-none rounded-md' />
            {errors.city && (
              <p className="text-red-500 text-sm ">{errors.city.message}</p>
            )}
          </div>
          <div >
            <h2 className='text-[16px] font-semibold mb-2 '>{t('state')}</h2>
            <input type="text" {...register('state')} placeholder={t('state')} className='border px-2 h-[40px] w-full focus:outline-none rounded-md' />
            {errors.state && (
              <p className="text-red-500 text-sm ">{errors.state.message}</p>
            )}
          </div>
          <div >
            <h2 className='text-[16px] font-semibold mb-2 '>{t('zipcode')}</h2>
            <input type="number" {...register('zipcode')} placeholder={t('zipcode')} className='border px-2 h-[40px] w-full focus:outline-none rounded-md' />
            {errors.zipcode && (
              <p className="text-red-500 text-sm ">{errors.zipcode.message}</p>
            )}
          </div>


        </div>

        <div className='mt-2'>
          <h2 className='text-[16px] font-semibold mb-2 '>{t('aptitude_lbl')}</h2>
          <input type="text" {...register('apt')} placeholder='Apt' className='border px-2 h-[40px] w-full focus:outline-none rounded-md' />

        </div>

        <h2 className='font-semibold text-[20px] mt-5'>{t('rent_amount')}</h2>
        <div className='grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 xl:gap-5 lg:gap-5 md:gap-5  my-5'>
          <div className='flex flex-col mb-4 '>
            <label className='mb-2 text-[16px] font-semibold'> {t('list_rent')} </label>
            <Controller
              name="rent"
              control={control}
              defaultValue=''
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className=' p-2 rounded-md bg-white border focus:outline-none'
                  value={formatCurrency(field.value)}
                  onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                />
              )}
            />
            {errors.rent && (
              <p className="text-red-500 text-sm ">{errors.rent.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('sec_dep_lbl')}</label>
            <Controller
              name="security_deposit"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  type="text"
                  className=' p-2 rounded-md bg-white border focus:outline-none'
                  value={formatCurrency(field.value)}
                  onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                />
              )}
            />
            {errors.security_deposit && (
              <p className="text-red-500 text-sm ">{errors.security_deposit.message}</p>
            )}
          </div>
          <div>
            {
              <div className='flex flex-col mb-4 '>
                <label className='mb-2 text-[16px] font-semibold'> { State?.lister_type === "owner" ? t('service_fee_op')  : t('broker_fee_op') } </label>
                <div className='flex gap-3'>
                  <div>
                    <select {...register('broker_fee_type')} className='border py-2 px-2 focus:outline-none rounded-md' onChange={(e) => setType(e.target.value)}>
                      <option value="">{t('please_select')}</option>
                      <option value="absolute">{t('absolute')}</option>
                      <option value="percent">{t('percentage')}</option>
                    </select>
                  </div>
                  {type !== "" &&
                    <div>
                      {type === "percent" ? (
                        <div className='flex  gap-2'>
                          <div>
                            <Controller
                              name="broker_fee_percentage"
                              control={control}
                              defaultValue=''
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="text"
                                  placeholder='0%'
                                  className=' p-2 rounded-md bg-white border focus:outline-none xl:w-[150px] lg:w-[150px]'
                                  value={field.value || ''} // Ensure the value is controlled
                                  onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9.]/g, '');
                                    if (value.split('.').length <= 2) {
                                      field.onChange(value);
                                    }
                                  }}
                                />
                              )}
                            />
                            {errors.broker_fee_percentage && (
                              <p className="text-red-500 text-sm ">{errors.broker_fee_percentage.message}</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <Controller
                            name="broker_fee"
                            control={control}
                            defaultValue=''
                            render={({ field }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder='$0.00'
                                className=' p-2 rounded-md bg-white border focus:outline-none xl:w-[150px] lg:w-[150px]'
                                value={field.value || ''} // Ensure the value is controlled
                                onChange={(e) => {
                                  const value = e.target.value.replace(/[^0-9.]/g, '');
                                  if (value.split('.').length <= 2) {
                                    field.onChange(value);
                                    setBroker_fe(e.target.value)
                                  }
                                }}
                                onBlur={(e) => {
                                  field.onChange(formatFloatCurrency(e.target.value));
                                }}
                              />
                            )}
                          />
                        </>
                      )}
                    </div>
                  }
                </div>

              </div>
            }
          </div>
        </div>


        <div>
          <h2 className='text-[20px] font-semibold my-4'>{t('property_labl')}</h2>
          {
            loading ?
              <div className='flex items-start justify-start'>
                <Loader type="loadData" />
              </div>
              :
              <div className='flex flex-wrap items-center w-full max-h-[200px] overflow-y-auto '>
                {
                  propType?.map((p, i) => (
                    <button key={i} type="button" onClick={() => setFormData((prev) => ({ ...prev, active: p.id }))} className={`border ${formData?.active === p?.id ? 'bg-[#F79F15] text-white' : 'bg-white text-black'} rounded-md px-4 py-1.5 mx-2 my-2`}>{p?.name}</button>
                  ))
                }
                {/* <button className='border mx-2 my-1 bg-[#F79F15] rounded-md px-4 py-1.5 text-white'>Home</button>
          <button className='border bg-white text-black rounded-md px-4 py-1.5 '>Town House</button>
        <button className='border my-1 mx-2 bg-[#F79F15] rounded-md px-4 py-1.5 text-white'>Apartment</button> */}


              </div>

          }
          {Errors && (
            <p className="text-red-500 text-sm ">{Errors?.property}</p>
          )}

        </div>

        <div>
          <h2 className='text-[20px] font-semibold my-4'>{t('bed_bath_lab')}</h2>
          <div className='px-2 '>
            <div className='flex flex-wrap items-center'>
              <div>
                <div className='flex items-center'>
                  <img src={bed} alt="beds" />
                  <h2 className='px-2'>{t('bedRom')}</h2>
                  <input type="number" min={0} {...register('number_of_beds')} className='border px-2 w-[50px] focus:outline-none rounded-md' />
                </div>
                {errors.number_of_beds && (
                  <p className="text-red-500 text-sm ">{errors.number_of_beds.message}</p>
                )}
              </div>
              <div>
                <div className='flex  items-center xl:mx-3 lg:mx-3 md:mx-3 xl:mt-0 lg:mt-0 md:mt-0 mt-2'>
                  <img src={bath} alt="beds" />
                  <h2 className='px-2'>{t('bathRom')}</h2>
                  <input type="number" min={0} {...register('number_of_baths')} className='border px-2 w-[50px] focus:outline-none rounded-md' />
                </div>
                {errors.number_of_baths && (
                  <p className="text-red-500 text-sm mx-5 ">{errors.number_of_baths.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className='flex justify-between py-3'>
            <button type='button' onClick={() => handleBack()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
            {/* <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Next')}</button> */}
            <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{
              loadingInfo ? <Loader type="socialLoder" /> : t('Next')
            }</button>
          </div>
        </div>
      </form>

    </>
  )
}

export default Information