import React from 'react'
import blogImge from '../../assests/images/blogImg.png'
import Comments from './Comments'

const Left = () => {
    return (
        <>
            <div>
                <img src={blogImge} className="object-cover lg:w-full " alt="detail_image" />
                <p className='py-5'>
                    Nam tempus turpis at metus scelerisque placerat nulla deumantos sollicitudin felis. Pellentesque diam dolor, elementum et lobortis at, mollis ut risus. Sedcuman des faucibus sullamcorper mattis fusce molestee lit a lorem tempus scelerisque blandit est cursus. Quisque volutpat orci ut metus malesuada in interdumos lectus anmos scelerisque lorem. Praesent deu odio des nisios dullamcorper sultricies. Cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus.  lorem. Praesent deu odio des nisios dullamcorper sultricies. Cum sociis natoque penatibus et magnis dis parturient montes nascetur ridiculus...
                </p>
                <div>
                    <h2 className='font-semibold text-[20px]'>Simple unorder list</h2>
                    <ul className='list-disc px-4 '>
                        <li className='py-1'>Loremous tempor ullamcorper iaculis.
                        </li>
                        <li className='py-1'>Loremous tempor ullamcorper iaculis.
                        </li>
                        <li className='py-1'>Loremous tempor ullamcorper iaculis.
                        </li>
                        <li className='py-1'>Loremous tempor ullamcorper iaculis.
                        </li>
                    </ul>
                </div>
                <div>
                    <Comments />
                </div>
            </div>

        </>
    )
}

export default Left