import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CreateFilterPrefferance, GetFilterPrefferance } from '../../../lib/Api/RantelServiceApi';
import { Loader } from '../../../utils/loaderButton';
import MultiRangeSlider from "multi-range-slider-react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const FilterPreferance = ({ close, type, setActive }) => {
    const [loading, setLoading] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [formData, setFormData] = useState({
        automatically_accept_appointments: false,
        with_rental_app: false,
        ideal_score_min: 0,
        ideal_score_max: 0,
        income_min: 0,
        income_max: 0,
        afford_min: 0,
        afford_max: 0,
        pets_allowed: false,
        for_children: false,
        section_candidates: false,
        smoking_allowed: false
    });
    const { userRole } = useSelector((state) => state?.userAuth?.userInfo)

    const navigate = useNavigate()


    const fetch = async () => {
        setLoading(true)
        let res = await GetFilterPrefferance()
        if (res) {
            setLoading(false)
            let obj = Object.assign({}, ...res)
            if (obj?.settings !== null) {
                const data = {
                    automatically_accept_appointments: obj.settings?.automatically_accept_appointments === 1 ? true : false,
                    with_rental_app: obj.settings?.with_rental_app === 1 ? true : false,
                    ideal_score_min: obj.settings?.ideal_score_min,
                    ideal_score_max: obj.settings?.ideal_score_max,
                    income_min: obj.settings?.income_min,
                    income_max: obj.settings?.income_max,
                    afford_min: obj.settings?.afford_min,
                    afford_max: obj.settings?.afford_max,
                    pets_allowed: obj.settings?.pets_allowed === 1 ? true : false,
                    for_children: obj.settings?.for_children === 1 ? true : false,
                    section_candidates: obj.settings?.section_candidates === 1 ? true : false,
                    smoking_allowed: obj.settings?.smoking_allowed === 1 ? true : false,
                };
                setFormData(data);
            }
        } else {
            setLoading(false)
        }
    }


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : Number(value)
        }));
    };

    const handleMultiRangeChange = (minValue, maxValue, name) => {
        const step = name === 'ideal_score' ? 10 : 100;  // Define step values based on the slider type
        const adjustedMinValue = Math.round(minValue / step) * step;
        const adjustedMaxValue = Math.round(maxValue / step) * step;

        setFormData(prevState => ({
            ...prevState,
            [`${name}_min`]: adjustedMinValue,
            [`${name}_max`]: adjustedMaxValue
        }));
    };

    const handleSave = async () => {
        setLoadingSave(true)
        let res = await CreateFilterPrefferance(formData)
        if (res) {
            setLoadingSave(false)
            if (userRole === "servicer") {
                navigate('/servicer_reazy')
                close()
            } else {
                close()
            }
        }
    }

    useEffect(() => {
        fetch()
    }, [])


    const { t } = useTranslation()
    return (
        <div className='p-3'>
            {loading ? <Loader type="loadData" /> :
                <div>
                    {/* <div className='flex flex-col'>
                        <h2 className='text-[16px] font-semibold '>{t('fiter_pr_10')}</h2>
                        <label className="relative inline-flex items-center cursor-pointer mt-2">
                            <input
                                checked={formData.automatically_accept_appointments}
                                onChange={handleChange}
                                type="checkbox"
                                name="automatically_accept_appointments"
                                className="sr-only peer" />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                {formData?.automatically_accept_appointments ? t('yes') : t('no')}
                            </span>
                        </label>
                    </div> */}
                    <div className='flex flex-col'>
                        <h2 className='text-[16px] font-semibold '>{t('fiter_pr_1')}</h2>
                        <label className="relative inline-flex items-center cursor-pointer mt-2">
                            <input
                                type="checkbox"
                                checked={formData.with_rental_app}
                                name="with_rental_app"
                                onChange={handleChange}
                                className="sr-only peer" />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                {formData.with_rental_app ? t('yes') : t('no')}
                            </span>
                        </label>
                    </div>
                    <div>
                        <h2 className='text-[16px] font-semibold pt-4'>{t('fiter_pr_2')}</h2>
                        <div className='flex  items-center gap-2  '>
                            <span>{formData?.ideal_score_min}</span>
                            <MultiRangeSlider
                                min={0}
                                max={850}
                                step={10}
                                stepOnly={true}
                                minValue={Math.round(formData?.ideal_score_min)}
                                maxValue={Math.round(formData?.ideal_score_max)}
                                barInnerColor={"#176B87"}
                                labels={false}
                                ruler={false}
                                label={false}
                                onChange={({ minValue, maxValue }) =>
                                    handleMultiRangeChange(minValue, maxValue, 'ideal_score')
                                }
                            />
                            <p>{formData?.ideal_score_max}</p>
                        </div>
                    </div>
                    <div>
                        <h2 className='text-[16px] font-semibold pt-4'>{t('fiter_pr_3')}</h2>
                        <div className='flex  items-center gap-2 '>
                            <span>{formData?.income_min}</span>
                            <MultiRangeSlider
                                min={0}
                                max={10000}
                                step={100}
                                stepOnly={true}
                                minValue={formData?.income_min}
                                maxValue={formData?.income_max}
                                barInnerColor={"#176B87"}
                                labels={false}
                                ruler={false}
                                label={false}
                                onChange={({ minValue, maxValue }) =>
                                    handleMultiRangeChange(minValue, maxValue, 'income')
                                }
                            />
                            <p>{formData.income_max}</p>
                        </div>
                    </div>
                    <div>
                        <h2 className='text-[16px] font-semibold pt-4'>{t('fiter_pr_4')}</h2>
                        <div className='flex  items-center gap-2  '>
                            <span>{formData?.afford_min}</span>
                            <MultiRangeSlider
                                min={0}
                                max={10000}
                                step={100}
                                stepOnly={true}
                                minValue={formData?.afford_min}
                                maxValue={formData?.afford_max}
                                barInnerColor={"#176B87"}
                                labels={false}
                                ruler={false}
                                label={false}
                                onChange={({ minValue, maxValue }) =>
                                    handleMultiRangeChange(minValue, maxValue, 'afford')
                                }
                            />
                            <p>{formData.afford_max}</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 py-2'>
                        <div className='flex flex-col'>
                            <h2 className='text-[16px] font-semibold '>{t('fiter_pr_5')}</h2>
                            <label className="relative inline-flex items-center cursor-pointer mt-2">
                                <input type="checkbox"
                                    checked={formData.pets_allowed}
                                    name="pets_allowed"
                                    onChange={handleChange}
                                    className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                    {formData.pets_allowed ? t('yes') : t('no')}
                                </span>
                            </label>
                        </div>
                        <div className='flex flex-col'>
                            <h2 className='text-[16px] font-semibold '>{t('fiter_pr_6')}</h2>
                            <label className="relative inline-flex items-center cursor-pointer mt-2">
                                <input
                                    type="checkbox"
                                    name="for_children"
                                    checked={formData.for_children}
                                    onChange={handleChange}
                                    className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                    {formData.for_children ? t('yes') : t('no')}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='flex flex-col'>
                            <h2 className='text-[16px] font-semibold '>{t('fiter_pr_7')}</h2>
                            <label className="relative inline-flex items-center cursor-pointer mt-2">
                                <input
                                    checked={formData.section_candidates}
                                    onChange={handleChange}
                                    type="checkbox"
                                    name="section_candidates"
                                    className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                    {formData?.section_candidates ? t('yes') : t('no')}
                                </span>
                            </label>
                        </div>
                        <div className='flex flex-col'>
                            <h2 className='text-[16px] font-semibold '>{t('fiter_pr_9')}</h2>
                            <label className="relative inline-flex items-center cursor-pointer mt-2">
                                <input
                                    checked={formData.smoking_allowed}
                                    onChange={handleChange}
                                    name="smoking_allowed"
                                    type="checkbox"
                                    className="sr-only peer" />
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
                                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
                                    {formData?.smoking_allowed ? t('yes') : t('no')}
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className='pt-4 flex justify-end items-end'>
                        <button onClick={() => handleSave()} className='bg-[#B913F0] text-white px-5 py-2 rounded-md'>{
                            loadingSave ? <Loader type="socialLoder" /> : t('save')
                        }</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default FilterPreferance