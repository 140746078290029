import React from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useEffect } from 'react';
import { UpdateRental } from '../../../../lib/Api/RantelServiceApi';
import { useState } from 'react';
import { Loader } from '../../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';

const schema = yup.object({
  description: yup.string().required(),


});
const EditDesc = ({ close, data , setEditType}) => {
  const [loading, setLoading] = useState(false)
  const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });


  const onSubmit = async (data) => {
    data.images = null

    setLoading(true)
    let { res, error } = await UpdateRental(data)
    if (res) {
      setLoading(false)
      close(false)
      setEditType('')
    }
    else if (error) {
      setLoading(false)

    }
  }

  useEffect(() => {
    reset(data)
  }, [data])


  const {t} = useTranslation()

  return (
    <>
      <div className='p-5 '>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <label>Description</label>
            <textarea {...register('description')} placeholder='description' rows={10} className={`focus:outline-none py-2 px-2 border ${errors.description && 'border-red-500'} rounded-md w-full`}></textarea>
            {errors.description && (
              <p className="text-red-500 text-sm text-left pt-2">{errors.description.message}</p>
            )}
          </div>


          <div className='flex items-center py-5 justify-end '>
            <button type="button" onClick={() => close(false)} className='w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>{t('cancel')}</button>
            {
              loading ? <div className='ml-5'><Loader type="saved" /></div>
                :
                <button className='w-[138px] py-2 ml-5 rounded-md bg-[#FF8C00] text-white'>{t('save')}</button>
            }
          </div>
        </form>

      </div>
    </>
  )
}

export default EditDesc