import React, { useEffect, useState } from 'react'
import { CreateRommateOffCampus, CreateRommateOnCampus, GetCampusInterest } from '../../../lib/Api/RantelServiceApi'
import { Loader } from '../../../utils/loaderButton'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { IoArrowBackSharp } from 'react-icons/io5'

function Interests() {
    const [activity, setActivity] = useState([])
    const [Act, setAct] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [error, setError] = useState("")

    const { rommateList, rommateId } = useSelector((state) => state?.rommate)
    const navigate = useNavigate()

    const HandleInterestChecks = (id) => {
        if (Act?.includes(id)) {
            let l = Act.filter((f) => f !== id)
            setAct(l)
        }
        else {
            setAct((prev) => ([...prev, id]))
        }
    }
    let GetActivityInterest = async () => {
        setLoading(true)
        let { resInterest } = await GetCampusInterest()
        const ConvertActi = Object.assign({}, ...resInterest)
        if (resInterest) {
            setLoading(false)
            setActivity(ConvertActi.interests)
        }
        else {
            setLoading(false)
        }
    }

    const handleUpdate = async () => {
        try {
            if (Act?.length === 0) {
                setError(t('rommate_intr'))
            } else {
                setLoadingCreate(true)
                let formData = new FormData()
                formData.append('roommate_id', rommateList?.id || rommateId)
                formData.append('university_id', rommateList?.university_details?.university_id)
                Act.forEach((file, index) => {
                    formData.append(`interests[${index}]`, file);
                });
                let check = rommateList?.college_student === 1 && rommateList?.on_campus === 1 ? CreateRommateOnCampus(formData) : CreateRommateOffCampus(formData)
                let res = await check
                if (res) {
                    setLoadingCreate(false)
                    navigate('/roommatesList')
                }
            }
        }
        catch (err) {

        }
    }

    const handleMove = () => {
        navigate('/roommatesList')
    }

    useEffect(() => {
        GetActivityInterest()
        if (rommateList) {
            let getIds = rommateList?.interests?.map((intr) => (intr?.id))
            setAct(getIds)
        }
    }, [])

    const { t } = useTranslation()

    return (
        <div>
            <div className='xl:hidden lg:hidden md:hidden block'>
                <Link to="/roommatesList">
                    <IoArrowBackSharp size={20} />
                </Link>
            </div>
            <h2 className='text-[20px] font-semibold my-4'>{t('interested')}</h2>
            {
                loading ?
                    <div className='flex justify-start items-start'>
                        <Loader type="loadData" />
                    </div>
                    :
                    <div className='flex flex-col'>
                        <div className='flex flex-wrap items-centerxl:max-h-[200px] xl:overflow-y-auto lg:max-h-[200px] lg:overflow-y-auto md:max-h-[200px] md:overflow-y-auto'>
                            {
                                activity?.map((c, i) => {
                                    let d = Act.find((f) => f === c.id)
                                    return (
                                        <div className='flex items-center mx-1' key={i}>
                                            <input id={c?.id} name="interst" type="checkbox" onChange={() => HandleInterestChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                                            <label htmlFor={c?.id} name="interst" className='mx-1 text-[16px]'>{c.interest}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {error && (
                            <p className="text-red-500 text-sm pt-2 ">{error}</p>
                        )}
                    </div>
            }

            <div className='flex justify-between py-3 gap-2'>
                <div>
                    {rommateList?.interests?.length === 0 &&
                        <button onClick={() => handleMove()} type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                            {`${t(`back`)}`}</button>
                    }
                </div>

                <div className='flex justify-end py-3 gap-2'>
                    {rommateList?.interests?.length > 0 &&
                        <Link to="/roommatesList">
                            <button type="button" className='font-semibold border-[#F79F15] border text-[#F79F15]  px-6 py-1.5  rounded-md'>{t('cancel')}</button>
                        </Link>
                    }
                    <button onClick={() => handleUpdate()} type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{loadingCreate ? <Loader type="saveLoder" /> : t('save')}</button>

                </div>
            </div>
        </div>
    )
}

export default Interests