import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Loader } from '../../../utils/loaderButton';
import { useEffect } from 'react';
import { CreateCurrentList } from '../../../lib/Api/RantelServiceApi';
// import { FaRegImages } from 'react-icons/fa';
// import { MdArrowBackIos } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { AddRommateIndex } from '../../../Redux/UserAuthSlice/RommateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import { IoArrowBackSharp } from 'react-icons/io5';


const CurrentList = () => {
  const [latitude, setLatutude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [loadingSave, setLoadingSave] = useState(false)

  const [defaultValue, setDefaultValue] = useState("")

  const { t } = useTranslation()

  const schema = yup.object({
    landlord_first_name: yup.string().required(t('land_fname')),
    landlord_last_name: yup.string().required(t('land_lname')),
    // rent: yup.string().required(t('rent_am_r')),
    landlord_phone_number: yup.string().required(t('land_phone')),
    // city: yup.string().required(t('city_msg')),
    // state: yup.string().required(t('state_msg')),
    // street: yup.string().required(t('street_msg')),
    // zipcode: yup.string().required(t('zipcode_msg')),
    // electricity: yup.number().typeError(t('energy_invalid')).required(t('energy_invalid')),
    // wifi: yup.number().typeError(t('wifi_invalid')).required(t('wifi_invalid')),
    // cable_bill: yup.number().typeError(t('cable_invalid')).required(t('cable_invalid')),
    // gas: yup.number().typeError(t('gass_invalid')).required(t('gass_invalid')),
    electricity: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? 0 : value)),
    wifi: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? 0 : value)),
    cable_bill: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? 0 : value)),
    gas: yup
      .number()
      .nullable()
      .transform((value, originalValue) => (originalValue === "" ? 0 : value)),
  });

  const { register, control, watch, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { rommate, rommateList, college, campus, rommateId, isNewRommate } = useSelector((state) => state?.rommate)
  let checkList = Object.keys(rommateList ?? {}).length > 0

  const formatCurrency = (value) => {
    if (!rommateList) {
      const numericValue = value?.replace(/[^0-9]/g, '');
      const formattedValue = new Intl.NumberFormat('en-US').format(numericValue);
      return `$${formattedValue}`;
    }
    else {
      const formattedValue = new Intl.NumberFormat('en-US').format(value);
      return `$${formattedValue}`;
    }
  };

  let checkQues = college === 0 && campus === 0 || rommateList?.college_student === 0 && rommateList?.on_campus === 0
  let checkQues2 = college === 1 && campus === 0 || rommateList?.college_student === 1 && rommateList?.on_campus === 0


  const OnSubmit = async (data) => {

    setLoadingSave(true)
    let formData = new FormData()
    formData.append('roommate_id', rommate?.roommate_id || rommateId)
    formData.append('landlord_first_name', data?.landlord_first_name)
    formData.append('landlord_last_name', data?.landlord_last_name)
    formData.append('landlord_phone_number', data?.landlord_phone_number)
    formData.append('contact_landlord', data?.contact_landlord === true ? 1 : 0)
    formData.append('cable_bill', data?.cable_bill)
    formData.append('wifi', data?.wifi)
    formData.append('gas', data?.gas)
    formData.append('electricity', data?.electricity)
    setLoadingSave(true)
    let res = await CreateCurrentList(formData)
    if (res) {
      setLoadingSave(false)
      if (!isNewRommate) {
        navigate('/roommatesList')
      }
      else {
        dispatch(AddRommateIndex(5))
      }
    }
  }

  const handleMove = () => {
    if (rommateList?.current_information === null) {
      navigate('/roommatesList')
    }
    else if (!checkList) {
      if (checkQues) {
        dispatch(AddRommateIndex(4))
      }
      else {
        dispatch(AddRommateIndex(9))
      }
    }

  }



  let fetch = async () => {
    const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBD-xaHdgobMyufM5tW3whuusr8l5J57X0`);
    const result = res?.data?.results[0]?.formatted_address;
    setDefaultValue(result)
  }


  useEffect(() => {
    if (!isNewRommate) {
      let list = rommateList?.current_information
      reset(rommateList?.current_information)
      setLatutude(list?.latitude)
      setLongitude(list?.longitude)
      fetch(list?.latitude, list?.longitude)
    }
  }, [])


  return (
    <>
      <div className='xl:hidden lg:hidden md:hidden block'>
        <Link to="/roommatesList">
          <IoArrowBackSharp size={20} />
        </Link>
      </div>
      <h1 className='text-center text-[25px] font-semibold'>{t('current_info')}</h1>
      <form className='mt-5 xl:pb-auto lg:pb-auto md:pb-auto pb-10 border shadow-md p-5 rounded-sm' onSubmit={handleSubmit(OnSubmit)}>
        <div >
          <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 lg:gap-20 md:gap-20 xl:my-8 lg:my-8 md:my-8'>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('current_land_fname_lbl')}</label>
              <input type="text" placeholder={t('current_land_fname_lbl')}
                name={`landlord_first_name`}
                {...register(`landlord_first_name`)}
                className=' p-2 rounded-md  focus:outline-none  w-full border' />
              {errors.landlord_first_name && (
                <span className='text-red-500 text-sm '>{errors.landlord_first_name.message}</span>
              )}

            </div>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('current_land_lname_lbl')}</label>
              <input type="text" placeholder={t('current_land_lname_lbl')}
                name={`landlord_last_name`}
                {...register(`landlord_last_name`)}
                // defaultValue={field.landlord_name}
                className=' p-2 rounded-md  focus:outline-none  w-full border' />
              {errors.landlord_last_name && (
                <span className='text-red-500 text-sm '>{errors.landlord_last_name.message}</span>
              )}

            </div>
          </div>
          <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 lg:gap-20 md:gap-20 xl:my-8 lg:my-8 md:my-8 '>

            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('current_land_phone_lbl')}</label>
              <Controller
                name={`landlord_phone_number`}
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    country={'us'}
                  />
                )}
              />
              {errors.landlord_phone_number && (
                <span className='text-red-500 text-sm '>{errors.landlord_phone_number.message}</span>
              )}
            </div>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('current_is_rent_lbl')}</label>
              <div className="flex justify-start">
                <label className="relative inline-flex items-center cursor-pointer">
                  <Controller
                    name={`contact_landlord`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          role="switch" id="flexSwitchCheckDefault"
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>
                        <label className="form-check-label inline-block text-gray-800 ms-3" for="flexSwitchCheckDefault">{watch('contact_landlord') ? t('yes') : t('no')}</label>
                      </>
                    )}
                  />

                </label>
              </div>

            </div>
          </div>
          <div className='grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 xl:gap-5 lg:gap-5 md:gap-5 mt-5'>
            <div className='flex flex-col mb-4 '>
              <label className='mb-2 text-[16px] font-semibold'>{t('cabl_lbl')} </label>
              <Controller
                name="cable_bill"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className=' p-2 rounded-md bg-white border focus:outline-none'
                    value={formatCurrency(field.value)}
                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                  />
                )}
              />
              {errors.cable_bill && (
                <p className="text-red-500 text-sm ">{errors.cable_bill.message}</p>
              )}
            </div>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('wifi_lbl')}  </label>
              <Controller
                name="wifi"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className=' p-2 rounded-md bg-white border focus:outline-none'
                    value={formatCurrency(field.value)}
                    placeholder='$50'
                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                  />
                )}
              />

              {errors.wifi && (
                <p className="text-red-500 text-sm ">{errors.wifi.message}</p>
              )}
            </div>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('gas_bil_lbl')}</label>
              <Controller
                name="gas"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className=' p-2 rounded-md bg-white border focus:outline-none'
                    value={formatCurrency(field.value)}
                    placeholder='$50'
                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                  />
                )}
              />
              {errors.gas && (
                <p className="text-red-500 text-sm ">{errors.gas.message}</p>
              )}
            </div>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('en_bil_lbl')}  </label>
              <Controller
                name="electricity"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    className=' p-2 rounded-md bg-white border focus:outline-none'
                    value={formatCurrency(field.value)}
                    placeholder='$50'
                    onChange={(e) => field.onChange(e.target.value.replace(/[^0-9]/g, ''))}
                  />
                )}
              />
              {errors.electricity && (
                <p className="text-red-500 text-sm ">{errors.electricity.message}</p>
              )}
            </div>


          </div>
        </div>



        <div>

          <div className='flex justify-between py-3 gap-2'>
            <div>
              {rommateList?.current_information === null &&
                <button onClick={() => handleMove()} type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                  {`${t(`back`)}`}</button>
              }
            </div>
            <div className='flex items-center gap-2'>
              {rommateList?.current_information !== null &&
                <Link to="/roommatesList">
                  <button type="button" className='font-semibold border-[#F79F15] border text-[#F79F15]  px-6 py-1.5  rounded-md'>{t('cancel')}</button>
                </Link>
              }
              <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                {loadingSave ? <Loader type="saveLoder" /> : `${t(`save`)}`}</button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default CurrentList