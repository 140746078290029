import React, { useEffect, useState } from 'react'
import Header from '../components/Header/PanelHeader'
import Sidebar from '../components/Sidebar/PanelSidebar'
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { RentalPanel, ServicerPanel } from './SidebarObjects';
import { useDispatch, useSelector } from 'react-redux';
import DynamicPopup from '../utils/Popup/DynamicPopup';
import LogoutLoader from './LogoutLoader';
import NotificationChecker from './NotificationChecker';
import BookAppointment from '../components/RenatalPanelComp/Dashboard/BookAppointment';
import Feedback from '../components/GenericComp/Feedback';
import { database } from '../config/Firebase';
import { ref, get, onChildAdded, query, orderByChild, equalTo, onChildChanged } from 'firebase/database';
import { setUsers } from '../Redux/UserAuthSlice/UserAuthSlice';
import { GetSingleUser } from '../components/GenericComp/GetcChatUser';
const Wrapper = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isOutSide, setIsOutSide] = useState(true);
    const [show, setShow] = useState(false);
    const [property, setproperty] = useState({});
    const [type, setType] = useState("");
    const { userRole, userid } = useSelector((state) => state.userAuth.userInfo)
    // const allUsers = useSelector((state) => state?.userAuth?.chatUsers)


    const location = useLocation().pathname
    const id = useParams().id

    const dispatch = useDispatch()

    useEffect(() => {
        setIsOutSide(true)
    }, [])

    function generateUniqueID(senderID, receiverID) {
        const combinedStr = senderID.toString() + receiverID.toString();
        const sortedStr = combinedStr.split('').sort().join('');
        return sortedStr;
    }



    useEffect(() => {
        if (userid) {
            fetchAllUsersWithConversations(userid);
             setUpFirebaseListeners(userid);
            // return () => {
            //     off(listener);
            // };
        }
    }, [userid, location, database]);

    const fetchAllUsersWithConversations = async (userid) => {
        const messagesRef = ref(database, `Messages/${userid}`);
        let conversations = [];
        const chatSnapshot = await get(messagesRef);
        const chats = chatSnapshot.val();

        if (chats) {
            const chatPromises = Object.values(chats).map(async (chat) => {
                const chatUser = await GetSingleUser(chat.senderID === userid ? chat.receiverID : chat.senderID);
                let chatObj = {
                    user: chatUser,
                    image: chatUser?.image,
                    first_name: chatUser?.first_name,
                    receiverID: chat.receiverID,
                    senderID: chat.senderID,
                    lastMessage: chat.lastMessage,
                    otherUserId: chat.senderID === userid ? chat.receiverID : chat.senderID,
                    chatID: chat.chatID,
                    timestamp: chat.timestamp,
                    receiver_sender: generateUniqueID(chat.senderID, chat.receiverID),
                };

                const unreadMessagesQuery = query(ref(database, `Chat/${chat?.chatID}`), orderByChild("isRead"), equalTo(false));
                const unreadMessagesSnapshot = await get(unreadMessagesQuery);
                let unreadMessagesCount = 0;
                // Calculate unread count
                if (unreadMessagesSnapshot.exists()) {
                    // console.log("unred")
                    unreadMessagesSnapshot.forEach((childSnapshot) => {
                        const msg = childSnapshot.val();
                        // console.log("msg", msg)
                        if (msg.receiverID === userid) {
                            unreadMessagesCount++;
                        }
                    });
                }
                // console.log("unreadMessagesCount", unreadMessagesCount)
                chatObj.unReadCount = unreadMessagesCount;
                return chatObj;
            });

            conversations = await Promise.all(chatPromises);
            conversations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            dispatch(setUsers(conversations));
        }
    };


    const setUpFirebaseListeners = (userid) => {
        const messagesRef = ref(database, `Messages/${userid}`);
        // Listener for new messages and updates in conversations
        // onValue(messagesRef, (snapshot) => {
        //     if (snapshot.exists()) {
        //         fetchAllUsersWithConversations(userid);
        //     }
        // });
        // Listen for new conversations
        onChildAdded(messagesRef, (snapshot) => {
            fetchAllUsersWithConversations(userid); 
        });

        // Listen for updates in existing chats
        onChildChanged(messagesRef, (snapshot) => {
            fetchAllUsersWithConversations(userid); 
        });
        const chatRef = ref(database, `Chat`);
        // Listener for new messages
        onChildAdded(chatRef, (snapshot) => {
            if (snapshot.exists()) {
                const newMessage = snapshot.val();
                if (newMessage.receiverID === userid || newMessage.senderID === userid) {
                    // Small timeout for Firebase sync delay
                    setTimeout(() => {
                        fetchAllUsersWithConversations(userid);
                    }, 1000);  // Delay of 1 second
                }
            }
        });

        onChildChanged(chatRef, (snapshot) => {
            if (snapshot.exists()) {
                const updatedMessage = snapshot.val();
                if (updatedMessage.receiverID === userid || updatedMessage.senderID === userid) {
                    setTimeout(() => {
                        fetchAllUsersWithConversations(userid);
                    }, 1000);
                }
            }
        });

        // onChildAdded(chatRef, (snapshot) => {
        //     if (snapshot.exists()) {
        //         const newMessage = snapshot.val();
        //         if (newMessage.receiverID === userid || newMessage.senderID === userid) {
        //             fetchAllUsersWithConversations(userid);
        //         }
        //     }
        // });
        // // Listener for message updates (if existing message gets updated)
        // onChildChanged(chatRef, (snapshot) => {
        //     if (snapshot.exists()) {
        //         const updatedMessage = snapshot.val();
        //         if (updatedMessage.receiverID === userid || updatedMessage.senderID === userid) {
        //             fetchAllUsersWithConversations(userid);
        //         }
        //     }
        // });
    };



    // useEffect(() => {
    //     // if (userid && location !== `/message/${id}`) {
    //     const messagesRef = ref(database, `Messages/${userid}`);
    //     let conversations = [];
    //     // Fetch all conversations asynchronously
    //     get(messagesRef).then(async (chatSnapshot) => {
    //         const chats = chatSnapshot.val();

    //         // Create an array of promises for each chat
    //         const chatPromises = Object.values(chats).map(async (chat) => {
    //             const chatRef = ref(database, `Chat/${chat.chatID}`);
    //             // Add listener for new messages
    //             onChildAdded(chatRef, async (newMessage) => {
    //                 const newMessageObj = newMessage.val();
    //                 const chatId = generateUniqueID(newMessageObj.senderID, newMessageObj.receiverID);
    //                 const chatIndex = allUsers?.findIndex(chat => chat.receiverID === newMessageObj?.receiverID);
    //                 if (chatIndex !== -1) {
    //                     const chatUser = await GetSingleUser(chat.senderID === userid ? chat.receiverID : chat.senderID);
    //                     // Create chat object
    //                     let chatObj = {
    //                         user: chatUser,
    //                         image: chatUser?.image,
    //                         first_name: chatUser?.first_name,
    //                         receiverID: chat.receiverID,
    //                         senderID: chat.senderID,
    //                         lastMessage: newMessageObj.message,
    //                         otherUserId: chat.senderID === userid ? chat.receiverID : chat.senderID,
    //                         chatID: chat.chatID,
    //                         timestamp: newMessageObj.time,
    //                         receiver_sender: generateUniqueID(chat.senderID, chat.receiverID)
    //                     };

    //                     // Query to filter unread messages
    //                     const unreadMessagesQuery = query(chatRef, orderByChild("isRead"), equalTo(false));

    //                     // Get the count of unread messages
    //                     const unreadMessagesSnapshot = await get(unreadMessagesQuery);
    //                     let unreadMessagesCount = 0;
    //                     if (unreadMessagesSnapshot.exists()) {
    //                         unreadMessagesSnapshot.forEach((childSnapshot) => {
    //                             const messageData = childSnapshot.val();
    //                             if (messageData.receiverID === userid) {
    //                                 unreadMessagesCount++; // Increment count if receiverID matches
    //                             }
    //                         });
    //                     }
    //                     // Add unread count to chat object
    //                     chatObj.unReadCount = unreadMessagesCount;
    //                     let updatedArray = allUsers?.map((user) =>
    //                         user?.receiver_sender == chatObj?.receiver_sender ? chatObj : user
    //                     );
    //                     updatedArray.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    //                     dispatch(setUsers(updatedArray));
    //                 } 
    //             });
    //             // Fetch the user for this chat
    //             const chatUser = await GetSingleUser(chat.senderID === userid ? chat.receiverID : chat.senderID);

    //             // Create chat object
    //             let chatObj = {
    //                 user: chatUser,
    //                 image: chatUser?.image,
    //                 first_name: chatUser?.first_name,
    //                 receiverID: chat.receiverID,
    //                 senderID: chat.senderID,
    //                 lastMessage: chat.lastMessage,
    //                 otherUserId: chat.senderID === userid ? chat.receiverID : chat.senderID,
    //                 chatID: chat.chatID,
    //                 timestamp: chat.timestamp,
    //                 receiver_sender: generateUniqueID(chat.senderID, chat.receiverID)
    //             };

    //             // Query to filter unread messages
    //             const unreadMessagesQuery = query(chatRef, orderByChild("isRead"), equalTo(false));

    //             // Get the count of unread messages
    //             const unreadMessagesSnapshot = await get(unreadMessagesQuery);

    //             let unreadMessagesCount = 0;
    //             if (unreadMessagesSnapshot.exists()) {
    //                 unreadMessagesSnapshot.forEach((childSnapshot) => {
    //                     const messageData = childSnapshot.val();
    //                     if (messageData.receiverID === userid) {
    //                         unreadMessagesCount++; // Increment count if receiverID matches
    //                     }
    //                 });
    //             }

    //             // Add unread count to chat object
    //             chatObj.unReadCount = unreadMessagesCount;

    //             // Return the chat object
    //             return chatObj;
    //         });

    //         // Wait for all chat promises to resolve
    //         const resolvedChats = await Promise.all(chatPromises);

    //         // Assign resolved chats to conversations array
    //         conversations = resolvedChats;

    //         // Dispatch once all chats are processed
    //         conversations.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    //         dispatch(setUsers(conversations));
    //     });


    //     return () => {
    //         off(messagesRef, 'value');
    //     };
    //     // }
    // }, [userid, location, dispatch, database]);


    return (
        <>
            {show && <DynamicPopup modalOpen={show} data={type === "logout" ? <LogoutLoader setShow={setShow} /> : type === "appointment" ? <BookAppointment property={property} /> : type === "feedback" ? <Feedback setShow={setShow} /> : <NotificationChecker data={property} setShow={setShow} setType={setType} type={type} />} close={() => setShow(false)} />}
            <div className="h-screen overflow-hidden max-w-[2000px] m-auto  ">
                <Header setShow={setShow} sidebarOpen={sidebarOpen} setType={setType} setproperty={setproperty} setSidebarOpen={setSidebarOpen} />
                <div className="lg:grid lg:grid-cols-5 bg-white">
                    <div className={`lg:block lg:h-screen lg:pb-20 bg-[#fcf8f4]  lg:w-full border-r overflow-y-auto pt-10 `} >
                        <Sidebar list={userRole === "servicer" ? ServicerPanel : RentalPanel} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} setShow={setShow} setType={setType} />
                    </div>
                    <div className={`col-span-4 overflow-y-auto ${location === `/message/${id}` ? 'h-auto' : 'h-screen'} overflow-x-hidden mb-20 pb-20 pt-10`} >
                        <div className='lg:px-5 px-5 py-5 relative h-full'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Wrapper