import React, { useState } from 'react'
import Saved from './Saved'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Dashboard = () => {
  const [list , setlists] = useState([])
  const {t} = useTranslation()
  
  return (
    <>

      <div className='lg:p-8 p-2 relative'>
        <Saved setlists={setlists} />
        <div className='flex justify-center items-center'>
          <Link to="/register" className={`bg-[#F79F15] text-white text-[16px] fixed bottom-3 ${list?.length > 0 ?  'left-[24%]' :'right-[24%]'} rounded-md px-4 py-1`}>
            + {t('add_new_property')}
          </Link >
        </div>
      </div>

    </>
  )
}

export default Dashboard