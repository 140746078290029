import sl1 from '../../assests/images/slider3.png'
import logo from '../../assests/images/logo.png'
import { AiOutlineClose } from 'react-icons/ai'
// import DynamicDropdonw from '../../utils/DaynamicDropdown';
import { MdAlternateEmail, MdArrowLeft } from 'react-icons/md';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ForgotUser } from '../../lib/Api/UserApi'
import { useState } from 'react';
import { Loader } from '../../utils/loaderButton';
import { useSelector } from 'react-redux';



const Forgot = ({ close, setActive, setEmail , t  }) => {
  const {language} = useSelector((state) => state.userAuth)
  
  const schema = yup.object({
    email: yup.string().email(t('invalid_email')).required(t('emial_req')),
  });
  const [loading, setLoading] = useState(false)
  const { register, watch, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = async (data) => {
    setLoading(true)
    let payload ={
      lang: language ? language : "",
      email : data?.email
    }
    let { res , error } = await ForgotUser(payload)
    // let UserData = Object.assign({}, ...res)
    if (res) {
      setEmail(data?.email)
      setLoading(false)
      setActive(2)
      reset()
    }
    else if(error) {
      setLoading(false)

    }
  }





  return (
    <>

      <div className='lg:flex'>
        <div className='lg:w-[70%] w-full relative hidden lg:block'>
          <img src={sl1} className=" h-full object-cover" alt="slider" />
          <div className='absolute bottom-[10%] left-[20%]'>
            <h2 className='text-[20px] text-white font-semibold'>{t('welcome')}</h2>
          </div>

        </div>
        <div className='w-full px-5 pb-5 '>
          <div className='flex justify-between items-center '>
            <img src={logo} className="h-[95px] object-contain" alt="logo" />
            {/* <div>
              <DynamicDropdonw />
            </div> */}
          </div>
          <div className=' flex justify-between items-end cursor-pointer' onClick={() => setActive(0)} >
            <div className='border w-[35px] rounded-md shadow-md p-2'>
              <MdArrowLeft />
            </div>
            <div className='border w-[35px] rounded-md shadow-md p-2' onClick={() => close()}>
              <AiOutlineClose />
            </div>


          </div>
          <div className='mt-5'>
            <h2 className='lg:text-[2rem] text-[1.5rem] font-bold'>{t('forgot_pass')}</h2>
            <p className='text-[14px] text-gray-300'>
              {t('forgot_email_send')}
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mt-5'>
              <div className={`flex flex-col border-b w-full ${watch('email') && ' border-b border-[#F79F15]'}`}>
                <label>{t('email')}</label>
                <div className='flex items-center w-full'>
                  <MdAlternateEmail />
                  <input type="email" {...register('email')} className='p-2 w-full focus:outline-none' placeholder={`${t('email')}`} />
                </div>
              </div>
              {errors.email && (
                <p className="text-red-500 text-sm mt-2">{errors.email.message}</p>
              )}

              <div className='pt-6'>
                {
                  loading ?
                    <Loader />
                    :

                    <button className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white' >{t('continue')}</button>
                }
              </div>

            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Forgot