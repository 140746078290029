import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Loader } from '../../../utils/loaderButton';
import { useEffect } from 'react';
import { CreateRommateOnCampus, GetCampusActivities, GetCampusInterest, GetCountries } from '../../../lib/Api/RantelServiceApi';
import { AddRommateIndex } from '../../../Redux/UserAuthSlice/RommateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import Select from 'react-select'
import { IoArrowBackSharp } from 'react-icons/io5';
const OnCampus = () => {
  const [intrests, setInterest] = useState([])
  const [activity, setActivity] = useState([])
  const [Intr, setIntr] = useState([])
  const [Act, setAct] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingCon, setLoadingCon] = useState(false)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [active, setActive] = useState(null)
  const [options, setOptions] = useState([]);
  const [error, setError] = useState({
    stay: "",
    active: "",
    intrest: "",
    imags: ""
  })

  const { t } = useTranslation()
  const gradle = [
    { heading: t('gradle_op1'), key: "Freshmen" },
    { heading: t('gradle_op2'), key: "Sophomore" },
    { heading: t('gradle_op3'), key: "Junior" },
    { heading: t('gradle_op4'), key: "Senior" },
    { heading: t('gradle_op5'), key: "Post-Graduate" },
    { heading: t('gradle_op6'), key: "Graduate" },
    { heading: t('gradle_op7'), key: "Other" }
  ]

  const { rommateId, rommateList, isNewRommate } = useSelector((state) => state?.rommate)

  const schema = yup.object({
    // university_name: yup.string().required(t('rommate_uni')),
    university_name: yup
      .object({
        value: yup.string().required(t('rommate_uni')),
        label: yup.string().required(t('rommate_uni')),
      })
      .nullable()
      .required(t('rommate_uni')),
    domm_name: yup.string().required(t('rommate_uni_dorm')),
    // room: yup.string().required(t('rommate_uni_room')),
    // age: yup.string().required(t('rommate_uni_dob')),
    gender: yup.string().nullable().required(t('gender_req')),
    major: yup.string().required(t('rommate_uni_major')),
    grade_level: yup.string().required(t('rommate_uni_grad')),
  });

  const { register, handleSubmit, reset, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // let checkList = Object.keys(rommateList ?? {}).length > 0

  const HandleInterestChecks = (id) => {
    if (Intr?.includes(id)) {
      let l = Intr.filter((f) => f !== id)
      setIntr(l)
    }
    else {
      setIntr((prev) => ([...prev, id]))
    }

  }
  const handleActivityChecks = (id) => {
    if (Act?.includes(id)) {
      let l = Act?.filter((f) => f !== id)
      setAct(l)
    }
    else {
      setAct((prev) => ([...prev, id]))
    }
  }




  let GetActivityInterest = async () => {
    setLoading(true)
    let { resActivity } = await GetCampusActivities()
    let { resInterest } = await GetCampusInterest()
    const ConvertActi = Object.assign({}, ...resActivity)
    const ConvertIntr = Object.assign({}, ...resInterest)
    if (resActivity && resInterest) {
      setLoading(false)
      setActivity(ConvertActi.activities)
      setInterest(ConvertIntr.interests)
    }
    else {
      setLoading(false)
    }
  }


  let getCountries = async () => {
    try {
      setLoadingCon(true)
      let res = await GetCountries()
      if (res) {
        setLoadingCon(false)
        const formattedOptions = res.map(item => ({ label: item.name, value: item.id }));
        setOptions(formattedOptions);
      }
    }
    catch (err) { }
  }

  const OnSubmit = async (data) => {

    if (Intr?.length === 0 && rommateList?.university_details === null) {
      setError((prev) => ({
        ...prev,
        intrest: t('rommate_intr')
      }))
    }
    else if (Act?.length === 0 && rommateList?.university_details === null) {
      setError((prev) => ({
        ...prev,
        active: t('rommate_active')
      }))
    }
    else if (active === null) {
      setError((prev) => ({
        ...prev,
        stay: t('stay_required')
      }))
    }
    else {
      setLoadingCreate(true)
      let formData = new FormData()
      formData.append('roommate_id', rommateId)
      formData.append('university_id', data?.university_name?.value)
      formData.append('domm_name', data?.domm_name)
      formData.append('room', data?.room)
      formData.append('gender', data?.gender)
      formData.append('major', data?.major)
      formData.append('grade_level', data?.grade_level)
      formData.append('length_of_stay', active)
      formData.append('style', "style")
      if (rommateList?.university_details === null) {
        Intr.forEach((file, index) => {
          formData.append(`interests[${index}]`, file);
        });
        Act.forEach((file, index) => {
          formData.append(`extra_curricular_activities[${index}]`, file);
        });
      }
      let res = await CreateRommateOnCampus(formData)
      if (res) {
        setLoadingCreate(false)
        if (isNewRommate === false) {
          navigate('/roommatesList')
        } else {
          dispatch(AddRommateIndex(5))
        }
      }

    }

  }

  const handleMove = () => {
    if (rommateList?.university_details === null) {
      navigate('/roommatesList')
    }
    else {
      dispatch(AddRommateIndex(1))
    }

  }



  useEffect(() => {
    GetActivityInterest()
    getCountries()
    if (!isNewRommate) {
      let list = rommateList?.university_details
      let romateAmi = list?.extra_curricular_activities.map((am) => am.id)
      let getIds = rommateList?.interests?.map((intr) => (intr?.id))
      let data = rommateList?.university_details
      let val = {
        label: data?.university_name || "",
        value: data?.university_id || ""
      }
      let obj = {
        "university_name": val,
        'domm_name': data?.domm_name,
        'room': data?.room,
        'gender': data?.gender,
        'major': data?.major,
        'grade_level': data?.grade_level,

      }
      reset(obj)
      // setActive(list?.property_type?.id)
      setActive(list ? list?.length_of_stay === "1" ? 1 : 2 : null)
      setAct(romateAmi || [])
      setIntr(getIds || [])
    }
  }, [])


  return (
    <>
      <div className='xl:hidden lg:hidden md:hidden block'>
        <Link to="/roommatesList">
          <IoArrowBackSharp size={20} />
        </Link>
      </div>
      <h1 className='text-center text-[25px] font-semibold'>{t('rommate_uni_detail')}</h1>
      <form className='mt-5 border shadow-md p-5 xl:pb-auto lg:pb-auto md:pb-auto pb-10 rounded-sm' onSubmit={handleSubmit(OnSubmit)}>
        {/* <h2 className='font-semibold text-[20px] pb-4 '>University</h2> */}
        <div className='grid xl:grid-cols-2 xl:gap-5 lg:grid-cols-2 lg:gap-5 md:grid-cols-2 md:gap-5'>
          <div className='flex flex-col mb-4'>
            <label className='font-medium' htmlFor="">{t('rommate_uni')}</label>
            <Controller
              name="university_name"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className="rounded-md mt-1  focus:outline-none"
                  placeholder={t('search_lab')}
                  isClearable={true}
                  isSearchable={true}
                  isLoading={loadingCon}
                  options={options}
                />
              )}
            />
            {/* <input {...register('university_name')} type='text' placeholder={t('rommate_uni')} className='p-2 rounded-md border focus:outline-none' /> */}
            {errors.university_name && (
              <p className="text-red-500 text-sm ">{errors.university_name.message}</p>
            )}
          </div>
          <div className='flex flex-col '>
            <label className='font-medium' htmlFor="">{t('rommate_uni_dorm')}</label>
            <input {...register('domm_name')} type='text' placeholder={t('rommate_uni_dorm')} className='p-2 rounded-md border focus:outline-none' />
            {errors.domm_name && (
              <p className="text-red-500 text-sm ">{errors.domm_name.message}</p>
            )}
          </div>
          {/* <div className='flex flex-col'>
            <label className='font-medium' htmlFor="">{t('rommate_uni_room')}</label>
            <input {...register('room')} type='number' placeholder={t('rommate_uni_room')} className='p-2 rounded-md border focus:outline-none' />
            {errors.room && (
              <p className="text-red-500 text-sm ">{errors.room.message}</p>
            )}
          </div> */}
          {/* <div className='flex flex-col'>
            <label className='font-medium' htmlFor="">{t('rommate_uni_dob')}</label>
            <input {...register('age')} type='number' placeholder={t('rommate_uni_dob')} className='p-2 rounded-md border focus:outline-none' />
            {errors.age && (
              <p className="text-red-500 text-sm ">{errors.age.message}</p>
            )}
          </div> */}
        </div>
        <div className='grid xl:grid-cols-3 xl:gap-5 lg:grid-cols-3 lg:gap-5 md:grid-cols-3 md:gap-5 my-5'>
          <div className='flex flex-col mb-4 '>
            <label className='font-medium' htmlFor="">{t('rommate_uni_major')}</label>
            <input {...register('major')} type='text' placeholder={t('rommate_uni_major')} className='p-2 rounded-md border focus:outline-none' />
            {errors.major && (
              <p className="text-red-500 text-sm ">{errors.major.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <label className='font-medium' htmlFor="">{t('rommate_uni_grad')}</label>
            <select
              className='p-2 rounded-md border focus:outline-none'
              {...register('grade_level')}>
              <option>{t('please_select')}</option>
              {
                gradle?.map((grad, index) => (
                  <option key={index} value={grad?.key}>{grad?.heading}</option>
                ))
              }

            </select>
            {/* <input  type='text' placeholder={t('rommate_uni_grad')} className='p-2 rounded-md border focus:outline-none' /> */}
            {errors.grade_level && (
              <p className="text-red-500 text-sm ">{errors.grade_level.message}</p>
            )}
          </div>
          <div>
            <label className='font-medium' htmlFor="">{t('gender')}</label>
            <div className='flex items-center justify-between mt-3'>
              <div className='flex items-center gap-2'>
                <input type='radio' id="male" name="male" value={'male'}  {...register('gender')} className='' />
                <label className='cursor-pointer' htmlFor="male">{t('g_male')}</label>
              </div>
              <div className='flex items-center gap-2'>
                <input type='radio' id="fmale" name="female" value={'female'}  {...register('gender')} />
                <lable className='cursor-pointer' htmlFor="fmale">{t('g_fmale')}</lable>
              </div>
              <div className='flex items-center gap-2'>
                <input type='radio' id="trans" name="trans" value={'trans'}  {...register('gender')} />
                <lable className='cursor-pointer' htmlFor="trans">{t('g_tran')}</lable>
              </div>
              <div className='flex items-center gap-2'>
                <input type='radio' id="other" name="other" value={'other'} {...register('gender')} />
                <lable className='cursor-pointer' htmlFor="other">{t('g_other')}</lable>
              </div>
            </div>
            {errors.gender && (
              <p className="text-red-500 text-sm ">{errors.gender.message}</p>
            )}
          </div>
        </div>

        <div>
          <h2 className='text-[20px] font-semibold my-4'>{t('rommate_uni_stay')}</h2>
          <div className='flex items-center xl:gap-2 lg:gap-2 md:gap-2  '>
            <div onClick={() => setActive(0)} className={`${active === 0 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium text-[14px] shadow-md border  rounded-md p-2 cursor-pointer`}>
              {t('rommate_uni_one_sm')}
            </div>
            <div onClick={() => setActive(1)} className={`${active === 1 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium text-[14px] shadow-md xl:mx-5 lg:mx-5 md:mx-5 ml-[10px] border rounded-md p-2 cursor-pointer`}>
              {t('rommate_uni_two_sm')}
            </div>
          </div>
          {/* <div className='flex items-center'>
            <div onClick={() => setActive(1)} className={`${active === 1 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium  shadow-md border  rounded-md p-5 cursor-pointer`}>
              {t('rommate_uni_one_sm')}
            </div>
            <div onClick={() => setActive(2)} className={`${active === 2 ? 'border-[#F79F15] border-2' : 'border-gray-100'} bg-white font-medium  shadow-md mx-5 border rounded-md p-5 cursor-pointer`}>
              {t('rommate_uni_two_sm')}
            </div>
          </div> */}
          {error && (
            <p className="text-red-500 text-sm pt-2 ">{error.stay}</p>
          )}
        </div>

        <div>
          {
            rommateList?.university_details === null &&
            <>
              <h2 className='text-[20px] font-semibold my-4'>{t('rommate_ex_acti')}</h2>
              {
                loading ?
                  <div className='flex justify-start items-start'>
                    <Loader type="loadData" />
                  </div>
                  :
                  <div className='flex flex-col'>
                    <div className='flex flex-wrap items-center max-h-[200px] overflow-y-auto'>
                      {
                        activity?.map((c, i) => {
                          let d = Act?.find((f) => f === c.id)
                          return (
                            <div className='flex items-center mx-1' key={i}>
                              <input id={c?.id} type="checkbox" onChange={() => handleActivityChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                              <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.activity}</label>
                            </div>
                          )
                        })
                      }
                    </div>
                    {error && (
                      <p className="text-red-500 text-sm pt-2 ">{error.active}</p>
                    )}
                  </div>
              }
            </>
          }
          {
            rommateList?.university_details === null &&
            <>
              <h2 className='text-[20px] font-semibold my-4'>{t('interested')}</h2>
              {
                loading ?
                  <div className='flex justify-start items-start'>
                    <Loader type="loadData" />
                  </div>
                  :
                  <div className='flex flex-col'>

                    <div className='flex flex-wrap items-center max-h-[200px] overflow-y-auto'>
                      {
                        intrests?.map((c, i) => {
                          let d = Intr?.find((f) => f === c.id)
                          return (
                            <div className='flex items-center mx-1' key={i}>
                              <input id={c?.id} name="interst" type="checkbox" onChange={() => HandleInterestChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                              <label htmlFor={c?.id} name="interst" className='mx-1 text-[16px]'>{c.interest}</label>
                            </div>
                          )
                        })
                      }
                    </div>
                    {error && (
                      <p className="text-red-500 text-sm pt-2 ">{error.intrest}</p>
                    )}
                  </div>
              }
            </>
          }

          <div className='flex justify-between items-center'>
            <div>
              {rommateList?.university_details === null &&
                <div>
                  <button onClick={() => handleMove()} type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                    {`${t(`Back`)}`}</button>
                </div>
              }
            </div>
            <div className='flex justify-end  py-3 gap-2'>
              {rommateList?.university_details !== null &&
                <Link to="/roommatesList">
                  <button type="button" className='font-semibold border-[#F79F15] border text-[#F79F15]  px-6 py-1.5  rounded-md'>{t('cancel')}</button>
                </Link>
              }
              <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{loadingCreate ? <Loader type="saveLoder" /> : t('save')}</button>

            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default OnCampus