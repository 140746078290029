import React from 'react'
import Method from '../../components/RenatalPanelComp/Payment/Method'
import History from '../../components/RenatalPanelComp/Payment/History'

const Payment = () => {
    return (
        <>
            <div className='px-[5rem] py-10 h-full'>
                <History />
                <Method />
            </div>
        </>
    )
}

export default Payment