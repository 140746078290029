import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from '../../../assests/images/logobg.png'
const SettingInformation = () => {
    const { t } = useTranslation()

    const abutData = [t('about_1'), t('about_2'), t('about_3'),]
    return (
        <>

            <div className='container m-auto py-4 px-5 xl:pb-0 lg:pb-0 md:pb-0 pb-10'>
                {/* <div className='grid lg:grid-cols-1 '> */}
                <div className='flex justify-center items-center'>
                    <img src={logo} alt="logo" className='xl:w-auto lg:w-auto md:w-auto w-[40%]' />
                </div>
                <div>
                    <h2 className='text-center font-bold xl:text-[2.5rem] lg:text-[2.5rem] md:text-[2.5rem] text-[20px] '>{t('about_us')}</h2>
                    <div className=''>
                        {
                            abutData?.map((list, i) => (
                                <p key={i} className='text-justify py-3 text-[17px]'> {list}</p>
                            ))
                        }
                    </div>
                </div>

                {/* </div> */}
            </div>

        </>
    )
}

export default SettingInformation