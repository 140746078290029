import React, { useState, useEffect } from 'react';
export const Countdown = ({ selected }) => {
  const [timeLeft, setTimeLeft] = useState(parseTime(selected));

  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => calculateTimeLeft(prevTime));
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  function parseTime(timeString) {
    if (typeof timeString !== 'string') {
      throw new Error('Invalid time format. Expected a string in HH:MM:SS format.');
    }

    const parts = timeString.split(':');

    if (parts.length !== 3) {
      throw new Error('Invalid time format. Expected HH:MM:SS format.');
    }

    const [hours, minutes, seconds] = parts.map(Number);

    if (
      isNaN(hours) || isNaN(minutes) || isNaN(seconds) ||
      hours < 0 || minutes < 0 || seconds < 0 ||
      minutes >= 60 || seconds >= 60
    ) {
      throw new Error('Invalid time values.');
    }

    return {
      hours,
      minutes,
      seconds,
    };
  }

  function calculateTimeLeft({ hours, minutes, seconds }) {
    if (seconds > 0) {
      seconds -= 1;
    } else if (minutes > 0) {
      seconds = 59;
      minutes -= 1;
    } else if (hours > 0) {
      seconds = 59;
      minutes = 59;
      hours -= 1;
    } else {
      return {
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }

    return {
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
    };
  }

  return (
    <span>{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}</span>
  );
};



export const calculateTimeLefts = ({ hours, minutes, seconds }) => {
  if (seconds > 0) {
    seconds -= 1;
  } else if (minutes > 0) {
    seconds = 59;
    minutes -= 1;
  } else if (hours > 0) {
    seconds = 59;
    minutes = 59;
    hours -= 1;
  } else {
    return {
      hours: "00",
      minutes: "00",
      seconds: "00",
    };
  }

  return {
    hours: String(hours).padStart(2, '0'),
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0'),
  };
};

export const parseTimes = (timeString) => {
  if (typeof timeString !== 'string') {
    throw new Error('Invalid time format. Expected a string in HH:MM:SS format.');
  }

  const parts = timeString.split(':');

  if (parts.length !== 3) {
    throw new Error('Invalid time format. Expected HH:MM:SS format.');
  }

  const [hours, minutes, seconds] = parts.map(Number);

  if (
    isNaN(hours) || isNaN(minutes) || isNaN(seconds) ||
    hours < 0 || minutes < 0 || seconds < 0 ||
    minutes >= 60 || seconds >= 60
  ) {
    throw new Error('Invalid time values.');
  }

  return {
    hours,
    minutes,
    seconds,
  };
};
