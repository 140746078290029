import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreateEmergancyContect } from '../../../lib/Api/RentalAppApi';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { addRentals } from '../../../Redux/UserAuthSlice/RentalSlice';
import { API } from '../../../config/GoolePlace';





const EmergencyInfo = ({ handleNext, handleBack  }) => {
  const [suggestions, setSuggestions] = useState([]);


  const { rentalData } = useSelector((state) => state?.rental)
  let rentalApp = rentalData?.emergency_contact
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const schema = yup.object({
    name: yup.string().required(t('nam_invalid')),
    relation: yup.string().required(t('rel_invalid')),
    address: yup.string().required(t('add_invalid')),
    phone: yup.string().required(t('invalid_phone'))

  });

  const { register, handleSubmit, setValue, control, reset, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)


  const OnSubmit = async (data) => {
    const areEqual = Object.keys(data).every(key => data[key] === formData[key]);
    if (areEqual === false) {
      try {
        let paylod = {
          "app_id": rentalData?.id,
          "name": data?.name,
          "phone": data?.phone,
          "relation": data?.relation,
          "address": data?.address
        }
        setLoading(true)
        let { res } = await CreateEmergancyContect(paylod)
        if (res) {
          setLoading(false)
          dispatch(addRentals(res[0]?.rental_application))
          handleNext(8)
        }
      }
      catch (err) {
        setLoading(false)
      }
    }
    else {
      handleNext(8)

    }
  }


  const autocompleteOptions = {
    types: ['address'],
    componentRestrictions: { country: 'us' }
  };

  const handleSuggestions = (predictions) => {
    // Filter suggestions based on whether they have a city and zip code
    const filteredSuggestions = predictions.filter((prediction) => {
      if (prediction.address_components) {
        const hasCity = prediction.address_components.some(
          (component) => component.types.includes('locality')
        );

        const hasZipCode = prediction.address_components.some(
          (component) => component.types.includes('postal_code')
        );

        return hasCity && hasZipCode;
      }

      return false;
    });

    setSuggestions(filteredSuggestions);
  };


  useEffect(() => {
    if (rentalApp) {
      setFormData(rentalApp)
      reset(rentalApp)
    }
  }, [reset])


  return (
    <>
      <form onSubmit={handleSubmit(OnSubmit)}>
        <h2 className='text-[20px] font-bold text-center p-8'>{t('em_info')}</h2>

        <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('fullname')} <span className='text-red-500'>*</span></label>
            <input type="text"  {...register('name')} placeholder={t('fullname')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
            {errors.name && (
              <p className="text-red-500 text-sm ">{errors.name.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('rel_lbl')}<span className='text-red-500'>*</span></label>
            <input type="text" placeholder={t('rel_lbl')}  {...register('relation')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
            {errors.relation && (
              <p className="text-red-500 text-sm ">{errors.relation.message}</p>
            )}
          </div>
        </div>
        <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('contact_phone')}<span className='text-red-500'>*</span></label>
            <Controller
              name={`phone`}
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country={'us'}
                />
              )}
            />
            {/* <input type="tel"  {...register('phone')} placeholder='+1865134498' className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' /> */}
            {errors.phone && (
              <p className="text-red-500 text-sm ">{errors.phone.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('address')}<span className='text-red-500'>*</span></label>
            <Controller
              control={control}
              name="address"
              render={({ field }) => {
                // //console.log("Res", field)
                return (
                  <GooglePlacesAutocomplete
                    className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none'
                    apiKey={API}
                    options={autocompleteOptions}
                    defaultValue={field?.value}
                    onSuggestions={handleSuggestions}
                    suggestions={suggestions}
                    placeholder={t('address')}
                    onPlaceSelected={async (place) => {
                      const address = place.formatted_address || "";
                      setValue('address', address);
                    }}
                  />
                )
              }}
            />
            {/* <GooglePlacesAutocomplete
              apiKey={"AIzaSyBD-xaHdgobMyufM5tW3whuusr8l5J57X0"}
              options={autocompleteOptions}
              onSuggestions={handleSuggestions}
              suggestions={suggestions}
               
              // onPlaceSelected={(place) => index === 0 ? handlePlaceSelectedIndex0(place) : null}
              // value={address}
              onPlaceSelected={async (place) => {
                const address = place.formatted_address || "";
                setValue(`address`, address);
              }}
              className='border p-2 w-full focus:outline-none rounded-md my-2'
              placeholder={t('location_search')}
            /> */}
            {errors.address && <p>{errors.address.message}</p>}
          </div>
        </div>


        <div className='flex justify-between items-center'>
          <div className='flex justify-end py-3'>
            <button onClick={() => handleBack(8)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
          </div>

          <div className='flex justify-end py-3'>
            <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>

              {
                loading ? <Loader type="saveLoder" />
                  :
                  t('Next')
              }
            </button>
          </div>
        </div>
      </form>

    </>
  )
}

export default EmergencyInfo