import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdArrowBackIos } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { AddRommate, AddRommateIndex, AddRommates, IspopUp, AddCollege , AddCampus } from '../../../Redux/UserAuthSlice/RommateSlice'
import { useNavigate } from 'react-router-dom'
import { CreateRommateProfile } from '../../../lib/Api/RantelServiceApi'
import { Loader } from '../../../utils/loaderButton'
import {setUserRommate} from '../../../Redux/UserAuthSlice/UserAuthSlice'

const CheckCampus = ({ setActives, setStatus, updateStatus, setRommate }) => {
  const [active, setActive] = useState(null)
  const [type, setType] = useState("")
  const [loading, setLoading] = useState(false)
  const { rommate, isNewRommate,
    college,
    campus, } = useSelector((state) => state?.rommate)

  const user = useSelector((state) => state?.userAuth?.userInfo)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  let formData = new FormData()
  formData.append('first_name', user?.fname)
  formData.append('last_name', user?.lname)
  formData.append('email', user?.email)
  formData.append('phone', user?.phone)

  const handleCheckNext = async (value) => {
    setActive(value)
    if (value === 0) {
      if (!isNewRommate) {
        dispatch(AddRommate(AddCampus(0)))
        formData.append('college_student', college)
        formData.append('on_campus', 1)
        setType("yes")
        setLoading(true)
        let res = await CreateRommateProfile(formData)
        if (res) {
          setLoading(false)
          let obj = Object.assign({}, ...res)
          const info = {
            college_student: obj?.roommate?.college_student,
            on_campus: obj?.roommate?.on_campus
          };
          updateStatus(info, obj?.status)
          dispatch(AddRommates(obj?.roommates))
          dispatch(setUserRommate(info))
          setStatus(obj?.status)
          setType("yes")
          // setShow(false)
          dispatch(IspopUp(false))
          navigate('/roommatesList')
        }
      }
      else {
        dispatch(IspopUp(false))
        dispatch(AddRommateIndex(2))
        dispatch(AddCampus(1))
        navigate('/CreateRoommate')
      }

    }
    else {
      if (!isNewRommate) {
        dispatch(AddCampus(0))
        formData.append('college_student', college)
        formData.append('on_campus', 0)
        setType("no")
        setLoading(true)
        let res = await CreateRommateProfile(formData)
        if (res) {
          setLoading(false)
          let obj = Object.assign({}, ...res)
          setRommate(obj?.roommate)
          const info = {
            college_student: obj?.roommate?.college_student,
            on_campus: obj?.roommate?.on_campus
          };
          updateStatus(info, obj?.status)
          dispatch(AddRommates(obj?.roommates))
          dispatch(setUserRommate(info))
          setStatus(obj?.status)
          setType("no")
          // setShow(false)
          dispatch(IspopUp(false))
          navigate('/roommatesList')
        }
      }
      else {
        dispatch(IspopUp(false))
        dispatch(AddCampus(0))
        navigate('/CreateRoommate')
        dispatch(AddRommateIndex(2))
      }
    }
  }
  const { t } = useTranslation()
  return (
    <>
      <div className='flex items-center font-medium cursor-pointer' onClick={() => setActives(0)}>
        <MdArrowBackIos className='text-[20px]' />
        {t('back')}
      </div>
      <h2 className='text-center mt-5 text-[20px] font-bold'>
        {t('live_campus')}</h2>
      <div className="grid lg:grid-cols-2 gap-5  pt-[10px] ">
        <div className='group'>
          <div onClick={() => handleCheckNext(0)} className={`${active === 0 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  border max-w-[300px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <h2 className='text-[1rem] font-bold'>{loading && type === "yes" ? <Loader type="socialLoder" /> : t('yes')} </h2>

          </div>
        </div>
        <div className='group'>
          <div onClick={() => handleCheckNext(1)} className={`${active === 1 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  border max-w-[300px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <h2 className='text-[1rem] font-bold '>{loading && type === "no" ? <Loader type="socialLoder" /> : t('no')}</h2>
          </div>
        </div>
      </div>


    </>
  )
}

export default CheckCampus