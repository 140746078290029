import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    rommate: {},
    rommateList: {},
    romateIndex: 0,
    college: null,
    campus: null,
    rommateId: null,
    isNewRommate: false,
    isPopupShow: false,
    renterQuestion: {},
    viewReport: {
        criminal: "",
        eviction: "",
        liens: ""
    },
    universityInfo: {},
    r_t_rommate: ""


}


export const RommateSlice = createSlice({
    name: 'RommateSlice',
    initialState,
    reducers: {
        AddRommate: (state, action) => {
            if (!state.rommate) {
                state.rommate = {};
            }
            state.rommate = { ...state.rommate, ...action.payload };
        },

        AddCollege: (state, action) => {
            state.college = action.payload;
        },
        AddCampus: (state, action) => {
            state.campus = action.payload;
        },
        AddRommateId: (state, action) => {
            state.rommateId = action.payload;
        },
        clearRommate: (state, action) => {
            state.rommate = action.payload
        },
        AddRommateIndex: (state, action) => {
            state.romateIndex = action.payload
        },
        AddRommates: (state, action) => {
            state.rommateList = action.payload
        },
        UpdateRommates: (state, action) => {
            state.rommateList[action.payload?.name] = action.payload?.value;
        },
        RommateNew: (state, action) => {
            state.isNewRommate = action.payload
        },
        IspopUp: (state, action) => {
            state.isPopupShow = action.payload
        },
        RentQuestion: (state, action) => {
            state.renterQuestion = action.payload
        },
        setReport: (state, action) => {
            const { viewReport } = state;
            const { payload } = action;
            if (payload?.criminal) {
                return {
                    ...state,
                    viewReport: { ...viewReport, criminal: payload?.criminal },
                };
            } else if (payload?.eviction) {
                return {
                    ...state,
                    viewReport: { ...viewReport, eviction: payload?.eviction },
                };
            } else {
                return {
                    ...state,
                    viewReport: { ...viewReport, liens: payload?.liens },
                };
            }
        },
        setReportInitial: (state, action) => {
            return {
                ...state,
                viewReport: {
                    criminal: "",
                    eviction: "",
                    liens: ""
                }

            }
        },
        setResetStates: (state, action) => {
            state.rommate = {}
            state.romateIndex = null
            state.r_t_rommate = "" 
        },
        setUniversity: (state, action) => {
            state.universityInfo = action.payload
        },
        setReazyToRommate: (state, action) => {
            state.r_t_rommate = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { AddRommate, AddRommateIndex, AddRommates, setUniversity, UpdateRommates, RommateNew, IspopUp, clearRommate, RentQuestion, AddRommateId, AddCollege, AddCampus, setReport, setReportInitial, setReazyToRommate , setResetStates } = RommateSlice.actions

export default RommateSlice.reducer