import React, { useState } from 'react'
import OTPInput from "otp-input-react";
import { Loader } from '../loaderButton';
import { toast } from 'react-toastify';

const PhoneVerify = ({ result, close, setVerify, type }) => {
    const [otp, setOtp] = useState('')
    const [otpError, setOtpError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        if (otp !== '') {
            setLoading(true)
            setOtpError('')
            // let { res , error } = await result.confirm(otp);
            await result.confirm(otp).then(async (result) => {
                setVerify(false)
                close()
            }).catch((err) => {
                setLoading(false)
                toast.error("Invalid Code")
            })

        }
        else {
            setLoading(false)
            setOtpError('Verification code in Required ')
        }
    }




    return (
        <div>

            {type !== "loader" ?
                <>
                    <div className='pt-6'>
                        <h2 className='lg:text-[2rem] text-[1.5rem] font-bold'>Enter Verification Code</h2>
                        <p className='text-[14px]'>
                            We sent you a verification code via Sms.
                        </p>
                    </div>
                    <div className='mt-5'>
                        <div className=' w-full flex justify-center'>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                autoFocus
                                inputClassName="border-b-2 fous:border-transparent focus:ring-0"
                                OTPLength={6}
                                otpType="number"
                                disabled={false}
                                secure={false} />
                        </div>

                        {otpError && <p className='text-red-500 text-[14px] pt-2 text-center'>{otpError}</p>}

                        <div className='pt-6'>
                            {
                                loading ?
                                    <Loader />
                                    :
                                    <button className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white' onClick={(e) => handleSubmit(e)}>Verify</button>
                            }
                        </div>
                    </div>
                </>
                :
                <div className='p-5'>
                    <Loader type="saveLoder" />
                </div>
            }
        </div>
    )
}

export default PhoneVerify