import React from 'react'
import Layout from '../../components/Layout/Layout'
import ExploreBanner from '../../components/LandingComp/ExploreBanner'
import Explore from '../../components/LandingComp/Explore'
import Services from '../../components/LandingComp/Services'
import Rentals from '../../components/LandingComp/Rentals'
import Feature from '../../components/LandingComp/Feature'
// import SignUpForm from '../../components/AuthComp/SignUpForm'

const Home = () => {
    return (
        <Layout>
            <ExploreBanner name="home"/>
            <Explore/>
            <Services/>
            <Rentals/>
            <Feature/>

        </Layout>
    )
}

export default Home