import React from 'react'
import { useTranslation } from 'react-i18next'
import { Truncate } from '../../utils/TrucateString'
import avatar from '../../assests/images/f10.png'
export const MylistDetail = ({ list }) => {
    const { t } = useTranslation()
    return (
        <>
            {list?.roommate === null ?
                <div className=' absolute bottom-1 flex justify-between w-full items-center '>
                    <div className='text-white px-2  '>
                        <h2 className=' text-[18px]'>{t('list_rent')}</h2>
                        <h2 className=' text-[18px]'>${list?.property?.rent}/<span className='text-[12px]'>{t('Month')}</span></h2>
                        <p className='text-[12px]'>{t('sequiry')} ${list?.property?.security_deposit}</p>
                    </div>
                    <div className='text-white  px-1'>
                        <h2 className=' text-[13px]'>{list?.property?.complete_address}</h2>
                        <p className='text-[13px]'>{list?.human_readable_time}</p>
                    </div>
                </div>
                :
                list?.roommate !== null && list?.roommate?.property_details === null ?
                    <div className=' absolute bottom-1 flex justify-between w-full items-center '>
                        <div className='text-white px-2 w-[120px] '>
                            <h2 className=' text-[14px]'>{t('rommate_uni_dor')} : {list?.roommate?.university_details?.domm_name}</h2>
                            <p className='text-[12px]'>{t('rommate_uni_major')} : {list?.roommate?.university_details?.major}</p>
                        </div>
                        <div className='text-white  px-1'>
                            <h2 className=' text-[12px]'>{list?.roommate?.university_details?.university_name}</h2>
                            <p className='text-[13px]'>{list?.human_readable_time}</p>
                        </div>
                    </div>
                    :
                    <div className=' absolute bottom-1 flex justify-between w-full items-center '>
                        <div className='text-white px-2  '>
                            <h2 className=' text-[18px]'>{t('list_rent')}</h2>
                            <h2 className=' text-[18px]'>${list?.roommate?.property_details?.rent}/<span className='text-[12px]'>{t('Month')}</span></h2>
                            <p className='text-[12px]'>{t('sequiry')} ${list?.roommate?.property_details?.security_deposit}</p>
                        </div>
                        <div className='text-white  px-1'>
                            <h2 className=' text-[13px]'>{`${list?.roommate?.property_details?.street} ${list?.roommate?.property_details?.city} ${list?.roommate?.property_details?.state}`}</h2>
                            <p className='text-[13px]'>{list?.human_readable_time}</p>
                        </div>
                    </div>
            }
        </>
    )
}

export const MylistUserInfo = ({ list }) => {
    return (
        <div className='flex items-center w-[80%] '>
            <img src={
                list?.property !== null ? list?.property?.user?.user_image ? list?.property?.user?.user_image : avatar : list?.roommate.user?.user_image ? list?.roommate?.user?.user_image : avatar
            }
                className="w-[40px] h-[35px] border object-cover rounded-full" alt="user" />
            <h2 className='font-bold mx-2 text-[14px]  max-w-[120px] '>{Truncate(list?.property !== null ? list?.property?.user?.first_name : list?.roommate?.user?.first_name, 10)}</h2>
        </div>
    )
}