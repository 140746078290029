import React from 'react'
import video from '../../assests/clientImgs/About US.jpeg'
import videoIcon from '../../assests/images/videoIcon.png'
const Information = ({ t }) => {
    const abutData = [t('about_1'), t('about_2'), t('about_3'),]
    return (
        <>

            <div className='container m-auto py-4'>
                <h2 className='text-center font-bold text-[2.5rem]'>{t('about_us')}</h2>
                <div className='grid lg:grid-cols-2 gap-9 lg:px-0 px-4 pt-[2rem]'>
                    <div>
                        <h2 className='text-[30px] font-semibold'>{t('about_leased')}</h2>
                        <div className=''>
                            {
                                abutData?.map((list, i) => (
                                    <p key={i} className='text-justify py-3 text-[17px]'> {list}</p>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <div className='pt-3 relative'>
                            <img src={video} className="w-full h-[400px] object-cover" alt="video" />
                            {/* <div className='absolute left-0 right-0 top-[19%] flex justify-center'>
                                <img src={videoIcon} className="w-[9%]" alt="video-icon" />
                            </div> */}
                            {/* <div className=''>
                                {
                                    abutData?.map((list, i) => (
                                        <p key={i} className='text-justify py-3 text-[17px]'> {list}</p>
                                    ))
                                }
                            </div> */}
                        </div>


                    </div>
                </div>
            </div>

        </>
    )
}

export default Information