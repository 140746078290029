import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../utils/loaderButton'
import moment from 'moment';
import videojs from 'video.js';
import { FiCheck } from 'react-icons/fi'
import 'video.js/dist/video-js.css';
import { SechedualAppointment, SaveForLater, GetTimeSlot, ConfirmListCancel, ConfirmContact, GetRoommateDetail, FlagRoommate } from '../../lib/Api/RentalAppApi';
import { ConfirmListDecline, ShareContact } from '../../lib/Api/RantelServiceApi';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { AddRentalAppUser } from '../../Redux/UserAuthSlice/RentalSlice';
import { useNavigate } from 'react-router-dom';
import OwnerDetail from '../ServicerPanelComp/MyList/OwnerDetail';
import { toast } from 'react-toastify';
const PostAppHowWork = ({ property, setShowPost, setShow }) => {
    const [step, setStep] = useState(0)
    const [step2type, setStep2type] = useState('')
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const today = currentDate.getDate();
    // Calculate the first day of next month
    const nextMonthDate = new Date(currentYear, currentMonth, today);
    const nextYear = nextMonthDate.getFullYear();
    const nextMonth = nextMonthDate.getMonth() + 1; // Months are zero-based, so add 1
    // Format the dates in 'yyyy-mm-dd' format
    const minDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${today.toString().padStart(2, '0')}`;
    const maxDate = `${nextYear}-${nextMonth.toString().padStart(2, '0')}-${today.toString().padStart(2, '0')}`;
    const defaultDate = minDate;

    const [secduleDate, setSecduleDate] = useState(defaultDate);
    const [secduleTime, setSecduleTime] = useState('')
    const [activeTime, setActiveTime] = useState(null)
    const [timeSlot, setTimeSlots] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingSave, setLoadingSave] = useState(false)
    const [type, setType] = useState('')
    const [list, setList] = useState()
    const [rommateData, setRommateData] = useState({})
    const [timeloading, setTimeLoading] = useState(false)
    const [share, setShare] = useState(false)
    const [loadRommate, setLoadRommate] = useState(false)
    const [rommateType, setRommmateType] = useState('')


    const user = useSelector((state) => state.userAuth?.userInfo)
    const navigate = useNavigate()
    const dispatch = useDispatch()


    let fetchData = async (changeFormate) => {
        let payload = {
            "date_of_appointment": changeFormate,
            'roommate_id': property?.property_id
        }


        setTimeLoading(true)
        let { res } = await GetTimeSlot(payload)
        let obj = Object.assign({}, ...res)
        setTimeSlots(obj?.available_times)
        if (res) {
            setTimeLoading(false)
        }
    }

    let checkRommate = async () => {
        try {
            setLoadRommate(true)
            let res = await GetRoommateDetail(property?.property_id)
            if (res) {
                setLoadRommate(false)
                setRommateData(res.roommates)
                let obj = Object.assign({}, ...res)
                if (obj.roommates?.college_student === 1 && obj.roommates?.on_campus === 1) {
                    setRommmateType('oncampus')
                }
                else if (obj.roommates?.college_student === 0 && obj.roommates?.on_campus === 0) {
                    setRommmateType('local')
                }
                else {
                    setRommmateType('offcampus')
                }
            }
            else {
                setLoadRommate(false)
            }
        }
        catch (err) { }
    }


    const handleDateChange = (date) => {
        let changeFormate = moment(date).format('MM/DD/YYYY')
        let inputValue = moment(date).format('YYYY-MM-DD')
        setSecduleDate(inputValue);
        fetchData(changeFormate)


    };

    const handleSecdule = async () => {
        let changeFormate = moment(secduleDate).format('MM/DD/YYYY')
        setLoading(true)
        let payload = {
            roommate_id: property?.property_id,
            appointment_type: step2type,
            date_of_appointment: changeFormate,
            time_of_appointment: secduleTime
        }
        let { res, error } = await SechedualAppointment(payload)
        if (res) {
            setLoading(false)
            setStep(4)
        }
        else if (error) {
            setLoading(false)
        }

    }


    const handleSaveLater = async () => {
        setType("saveLater")
        setLoadingSave(true)
        let payload = {
            "roommate_id": property?.property_id,
            list: "saved"
        }
        let res = await SaveForLater(payload)
        if (res) {
            setLoadingSave(false)
            setShow(false)
        }
    }

    const NotIntersted = async () => {
        try {
            let payloadRenter = {
                "roommate_id": property?.property_id
            }
            let payload = {
                "list_id": property?.model_data?.list_id
            }
            let check = user?.userRole === "renter" ? ConfirmListCancel(payloadRenter) : ConfirmListDecline(payload)
            setType("notInterested")
            setLoadingSave(true)
            let res = await check
            if (res) {
                setLoadingSave(false)
                setShow(false)
            }
        }
        catch (err) {

        }

    }

    const NotShow = async () => {
        try {
            let payloadRenter = {
                "roommate_id": property?.property_id
            }
            let payload = {
                "list_id": property?.model_data?.list_id
            }
            setType("notShow")
            setLoadingSave(true)
            let check = user?.userRole === "renter" ? FlagRoommate(payloadRenter) : ConfirmListDecline(payload)
            let res = await check
            if (res) {
                setLoadingSave(false)
                setShow(false)
            }
        }
        catch (err) {

        }

    }

    const ShareOwner = async () => {
        // setList({ roommate_id: property?.property_id })
        // setStep(null)
        // setShare(true)
        try {
            setType("share")
            let payload = {
                "list_id": property?.model_data?.list_id
            }
            setLoadingSave(true)
            let res = await ShareContact(payload)
            if (res) {
                toast.success(t('Owner_info'))
                setLoadingSave(false)
                setShow(false)
            }
        }
        catch (err) { }
    }

    const ContactOwner = async () => {
        try {
            setType("contact")
            let payload = {
                "roommate_id": property?.property_id
            }
            setLoadingSave(true)
            let res = await ConfirmContact(payload)
            if (res) {
                toast.success(t('cutomer_info'))
                setLoadingSave(false)
                setShow(false)
            }
        }
        catch (err) { }
    }

    const handleVideoEnd = () => {
        setShowPost(true)
        // Toggle(false)

    };

    const VideoPlayer = ({ source, autoPlay }) => {
        const videoRef = useRef(null);
        let player = null;

        useEffect(() => {
            // Initialize the Video.js player when the component mounts
            player = videojs(videoRef.current, {
                autoplay: autoPlay,

                controls: true, // You can customize this as needed
                sources: [
                    {
                        src: source,
                        type: 'video/mp4', // Adjust the type based on your video format
                    },
                ],
            });
            player.on('ended', handleVideoEnd);
            return () => {
                // Clean up the Video.js player when the component unmounts
                if (player) {
                    player.dispose();
                }
            };
        }, [source, autoPlay]);

        return (
            <div data-vjs-player>
                <video ref={videoRef}
                    width="640"
                    preload='auto'
                    height="360"
                    className="video-js vjs-default-skin" />
            </div>
        );
    };


    const handlePersonal = () => {
        setStep(2)
        setStep2type('in-person')

    }

    const handlevertiual = () => {
        setStep(3)
        setStep2type('virtual')

    }

    const handleStep = () => {
        setStep(1)
    }


    const sendMessage = () => {
        setShow(false)
        dispatch(AddRentalAppUser(property?.user))
        handleSaveLater()
        navigate(`/message/${property.user?.id}`)
    }

    useEffect(() => {
        checkRommate()
    }, [])


    useEffect(() => {

        if (step === 2) {
            const currentDateTime = new Date();
            const currentHour = currentDateTime.getHours();
            const currentMinutes = currentDateTime.getMinutes();
            if (currentHour > 20 || (currentHour === 20 && currentMinutes >= 30)) {
                const nextDay = new Date(currentDateTime);
                nextDay.setDate(currentDateTime.getDate() + 1);
                const formattedDate = moment(nextDay).format('YYYY-MM-DD');
                setSecduleDate(formattedDate);
                let changeFormate = moment(currentDateTime).format('MM/DD/YYYY')
                fetchData(changeFormate)
            } else {
                let changeFormate = moment(currentDateTime).format('MM/DD/YYYY')
                fetchData(changeFormate)
            }

        }
    }, [step])

    const { t } = useTranslation()
    return (
        <div>
            {
                loadRommate && rommateType === "" ?
                    <div>
                        <Loader type="loadData" />
                    </div>
                    :
                    <div>
                        {share ?
                            <div>
                                <OwnerDetail list={list} close={setShow} />
                            </div>
                            :
                            <div>
                                {step === 0 &&
                                    <div className='pt-5 text-center'>
                                        <h1 className='text-[18px] font-bold '>{t('how_its_work')}</h1>
                                    </div>
                                }
                                {step === 0 &&
                                    <ul>

                                        {rommateType !== "" && rommateType !== "oncampus"  &&
                                            <li className="border-b cursor-pointer h-full pt-4 pb-4">
                                                {user?.userRole !== "renter" ?
                                                    <div className='flex items-center border py-2 rounded-md  text-black px-2 justify-center' onClick={() => ShareOwner()} >
                                                        {
                                                            loadingSave && type === "share" ?
                                                                <Loader type="loadData" />
                                                                :
                                                                <h2 className='text-[16px] mx-2 font-semibold'>{t('share_owner_contact')}</h2>
                                                        }

                                                    </div>
                                                    :
                                                    <div className='flex items-center border py-2 rounded-md  text-black px-2 justify-center' onClick={() => ContactOwner()} >
                                                        {
                                                            loadingSave && type === "contact" ?
                                                                <Loader type="loadData" />
                                                                :
                                                                <h2 className='text-[16px]  mx-2 font-semibold'>{t('contact_owner')}</h2>
                                                        }
                                                    </div>

                                                }
                                            </li>
                                        }

                                        {user?.userRole === "renter" &&
                                            <li className="border-b cursor-pointer h-full pt-4 pb-4">
                                                <div className='flex items-center border py-2 rounded-md  text-black px-2 justify-center' onClick={() => handleStep()} >
                                                    <h2 className='text-[16px] mx-2 font-semibold'>{t('reschedule_App')}</h2>
                                                </div>
                                            </li>
                                        }

                                        {user?.userRole === "renter" &&
                                            <li className="border-b cursor-pointer h-full pt-4 pb-4">
                                                <div className='flex items-center border py-2 rounded-md  text-black px-2 justify-center' onClick={() => handleSaveLater()}>
                                                    {
                                                        loadingSave && type === "saveLater" ?
                                                            <Loader type="loadData" />
                                                            :
                                                            <h2 className='text-[16px]  mx-2 font-semibold'>{t('save_later')}</h2>
                                                    }
                                                </div>
                                            </li>
                                        }

                                        <li className="border-b cursor-pointer h-full pt-4 pb-4">
                                            <button onClick={() => NotIntersted()} className='flex items-center w-full justify-center py-2 px-2  text-black border rounded-md '>
                                                {loadingSave && type === "notInterested" ?
                                                    <Loader type="loadData" />
                                                    :
                                                    <h2 className='text-[16px] mx-2 font-semibold'>{t('not_int')}</h2>
                                                }
                                            </button>
                                        </li>
                                        <li className="border-b cursor-pointer w-full h-full pt-4 pb-4 flex justify-center items-center">
                                            <div className='flex items-center border w-full py-2 rounded-md  text-black px-2 justify-center' onClick={() => sendMessage()}>
                                                <h2 className='text-[16px] mx-2 font-semibold'>{t('send_message')}</h2>
                                            </div>
                                        </li>
                                        <li className=" cursor-pointer h-full pt-4 pb-4">
                                            <div className='flex items-center border py-2 rounded-md  text-black px-2 justify-center' onClick={() => NotShow()}>
                                                {
                                                    loadingSave && type === "notShow" ?
                                                        <Loader type="loadData" />
                                                        :
                                                        <h2 className='text-[16px] mx-2 font-semibold'>{t('not_show')}</h2>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                }
                                {
                                    step === 1 &&
                                    <div className='px-10 pb-5'>

                                        <div onClick={() => handlePersonal()} className='border cursor-pointer p-5 w-[200px] text-center font-semibold bg-white shadow-md rounded-md'>
                                            {t('in_person')}
                                        </div>
                                        <div onClick={() => handlevertiual()} className='border cursor-pointer my-5 p-5 w-[200px] text-center font-semibold bg-white shadow-md rounded-md'>
                                            {t('virtual')}
                                        </div>

                                    </div>
                                }
                                {
                                    step === 2 &&
                                    <div className={` px-10 pb-5`}>

                                        <div className='border rounded-md flex items-center p-2 border-[#F79F15]'>
                                            <h2 className='font-semibold w-[40%] '>{t('slect_dat')}</h2>
                                            <input type="date"
                                                min={minDate}
                                                max={maxDate}
                                                value={secduleDate} pattern="\d{1,2}/\d{1,2}/\d{4}" onChange={(e) => handleDateChange(e.target.value)} className='mx-2 border w-full p-2 focus:outline-none' />


                                        </div>
                                        {
                                            timeloading ?
                                                <div className='pt-5'>
                                                    <Loader type="loadData" />
                                                </div>
                                                :
                                                <>
                                                    {timeSlot?.length > 0 &&
                                                        <h2 className='font-semibold py-4'>{t('slect_time')}</h2>
                                                    }
                                                    <div className='flex flex-wrap xl:justify-between md:justify-between lg:justify-between xl:max-w-[400px]  lg:max-w-[400px] md:max-w-[400px]  items-center'>
                                                        {
                                                            timeSlot.map((t, i) => (
                                                                <div onClick={() => { return setActiveTime(i), setSecduleTime(t?.booked === false ? t?.time : "") }} className={`border relative rounded-md xl:w-[90px] lg:w-[90px] md:w-[90px] w-[80px] xl:mx-0 lg:mx-0 md:mx-0 mx-1 text-[14px] cursor-pointer text-center py-2 my-2 ${activeTime === i && t?.booked === false ? 'bg-[#F79F15] text-white' : 'bg-white text-[#F79F15] border border-[#F79F15]'}`} key={i}>{t?.time}
                                                                    {t?.booked ?
                                                                        <div className='absolute top-1 left-7'>
                                                                            <MdClose size={30} className='text-black' />
                                                                        </div>
                                                                        : ""}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center xl:gap-0 lg:gap-0 md:gap-0 gap-2 pt-3'>
                                                        <div className='flex items-center gap-2'>
                                                            <div className='border flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                                                <MdClose size={20} />
                                                            </div>
                                                            <p className='text-[16px] font-medium'>{t('booked')}</p>
                                                        </div>
                                                        <div className='flex items-center gap-2'>
                                                            <div className='border border-[#F79F15] flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                                            </div>
                                                            <p className='text-[16px] font-medium'>{t('avail')}</p>
                                                        </div>
                                                        <div className='flex items-center gap-2'>
                                                            <div className='border flex justify-center items-center rounded-md w-[30px] bg-[#F79F15] h-[30px]'>
                                                            </div>
                                                            <p className='text-[16px] font-medium'>{t('selected')}</p>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                        {timeSlot?.length > 0 &&
                                            <div className='flex justify-center items-center mt-5'>

                                                {
                                                    loading ?
                                                        <Loader type="saved" />
                                                        :
                                                        <button disabled={secduleTime !== "" ? false : true} onClick={() => handleSecdule()} className='text-center disabled:bg-opacity-[0.5] rounded-md bg-[#F79F15] w-full py-2 text-white'>{t('done')}</button>
                                                }
                                            </div>
                                        }

                                    </div>
                                }
                                {
                                    step === 3 &&
                                    <div className='px-10 w-full' style={{ height: '300px' }}>
                                        <VideoPlayer source={property?.video} autoPlay={true} />

                                    </div>
                                }
                                {
                                    step === 4 &&
                                    <div className='px-10 pb-5'>
                                        <div className='bg-gray-100 rounded-md p-5 '>
                                            <div className='flex justify-center items-center'>
                                                <div className='border bg-green-600 rounded-full p-3'>
                                                    <FiCheck className="text-[30px] text-white" />
                                                </div>
                                            </div>
                                            <h2 className='text-center pt-5 font-semibold text-[20px]'>{t('deliver')}</h2>
                                            <p className='max-w-[300px] m-auto text-center'>{t('deliver_thank_you_roomate')}</p>

                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
            }

        </div>
    )
}

export default PostAppHowWork