import OTPInput from "otp-input-react";
import { useState } from 'react';
import { Loader } from '../../utils/loaderButton';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../Redux/UserAuthSlice/UserAuthSlice';
import { EditUserProfile } from "../../lib/Api/UserApi";
const VerifyPhoneOtpWithoutStrip = ({ setActiveTab, setActiveTabText, result, profileData }) => {
  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState('')
  const [loading, setLoading] = useState(false)


  const dispatch = useDispatch()

  const handleSubmitUser = async () => {
    let { res, error } = await EditUserProfile(profileData)
    if (res) {
      let UserData = Object.assign({}, ...res)
      dispatch(updateUser(UserData))
      setActiveTab(0)
      setActiveTabText("Profile")
      return UserData
      // setLoading(false)
    }
    else if (error) {
      // setLoading(false)

    }
  }



  const handleSubmit = async (e) => {

    if (otp !== '') {
      setLoading(true)
      setOtpError('')
      // let { res , error } = await result.confirm(otp);
      await result.confirm(otp).then(async (result) => {
        setLoading(false)
        await handleSubmitUser()
      }).catch((err) => {
        setLoading(false)
        toast.error("Invalid Code ")
      })


      // }
      // let confirmationResult = window.confirmationResult;
      // result.confirm(otp).then((result) => {
      //   // User signed in successfully.
      //   const user = result.user;
      //   // ...
      // }).catch((error) => {
      //   // User couldn't sign in (bad verification code?)
      //   // ...
      // });
    }
    else {
      setLoading(false)
      setOtpError('Verification code in Required ')
    }
  }

  return (

    <>

      <div className='lg:flex pt-20'>
        <div className='w-full px-5 pb-5 '>
          <div className='pt-6'>
            <h2 className='lg:text-[2rem] text-[1.5rem] font-bold'>Enter Verification Code</h2>
            <p className='text-[14px]'>
              We sent you a verification code via Sms.
            </p>
          </div>
          <div className='mt-5'>
            <div className=' w-full flex justify-center'>
              <OTPInput
                value={otp}
                onChange={setOtp}
                autoFocus
                inputClassName="border-b-2 fous:border-transparent focus:ring-0"
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure={false} />
            </div>

            {otpError && <p className='text-red-500 text-[14px] pt-2 text-center'>{otpError}</p>}

            <div className='pt-6 max-w-[700px] max-auto '>
              <div className="flex justify-center items-center">
                {
                  loading ?
                    <div className="max-w-[900px] max-auto">
                      <Loader type="saved" />
                    </div>
                    :
                    <button className='text-center rounded-md bg-[#F79F15] w-[50%]  py-2 text-white' onClick={(e) => handleSubmit(e)}>Submit</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyPhoneOtpWithoutStrip