import React, { useState, useEffect } from 'react'
import { CreateRommateOnCampus, GetCampusActivities } from '../../../lib/Api/RantelServiceApi'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../../utils/loaderButton'
import { Link, useNavigate } from 'react-router-dom'
import { IoArrowBackSharp } from 'react-icons/io5'

function ExActivities() {
    const [activity, setActivity] = useState([])
    const [Act, setAct] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [error, setError] = useState("")

    const { rommateList, rommateId } = useSelector((state) => state?.rommate)
    const navigate = useNavigate()


    const handleActivityChecks = (id) => {
        if (Act?.includes(id)) {
            let l = Act?.filter((f) => f !== id)
            setAct(l)
        }
        else {
            setAct((prev) => ([...prev, id]))
        }
    }
    let GetActivityInterest = async () => {
        setLoading(true)
        let { resActivity } = await GetCampusActivities()
        const ConvertActi = Object.assign({}, ...resActivity)
        if (resActivity) {
            setLoading(false)
            setActivity(ConvertActi.activities)
        }
        else {
            setLoading(false)
        }
    }

    const handleUpdate = async () => {
        try {
            if (Act?.length === 0) {
                setError(t('rommate_active'))
            } else {
                setLoadingCreate(true)
                let formData = new FormData()
                formData.append('roommate_id', rommateList?.id || rommateId)
                formData.append('university_id', rommateList?.university_details?.university_id)
                Act.forEach((file) => {
                    formData.append(`extra_curricular_activities[]`, file);
                });
                // let check = rommateList?.college_student === 1 && rommateList?.on_campus === 1 ?  : CreateRommateOffCampus(formData)
                let res = await CreateRommateOnCampus(formData)
                if (res) {
                    setLoadingCreate(false)
                    navigate('/roommatesList')
                }
            }
        }
        catch (err) {

        }
    }


    const handleMove = () => {
        navigate('/roommatesList')
    }

    useEffect(() => {
        GetActivityInterest()
        if (rommateList) {
            let getIds = rommateList?.university_details?.extra_curricular_activities?.map((intr) => (intr?.id))
            if (getIds?.length > 0) {
                setAct(getIds)
            }
            else {
                setAct([])
            }
        }
    }, [])

    const { t } = useTranslation()

    return (
        <div>
            <div className='xl:hidden lg:hidden md:hidden block'>
                <Link to="/roommatesList">
                    <IoArrowBackSharp size={20} />
                </Link>
            </div>
            <div className='xl:h-[400px] lg:h-[400px]'>

                <h2 className='text-[20px] font-semibold my-4'>{t('rommate_ex_acti')}</h2>
                {
                    loading ?
                        <div className='flex justify-start items-start'>
                            <Loader type="loadData" />
                        </div>
                        :
                        <div className='flex flex-col'>
                            <div className='flex flex-wrap items-center max-h-[200px] overflow-y-auto'>
                                {
                                    activity?.map((c, i) => {
                                        let d = Act?.find((f) => f === c.id)
                                        return (
                                            <div className='flex items-center mx-1' key={i}>
                                                <input id={c?.id} type="checkbox" onChange={() => handleActivityChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                                                <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.activity}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {error && (
                                <p className="text-red-500 text-sm pt-2 ">{error}</p>
                            )}
                        </div>
                }
            </div>
            <div className='flex justify-between py-3 gap-2'>
                <div>
                    {rommateList?.university_details === null &&
                        <button onClick={() => handleMove()} type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                            {`${t(`back`)}`}</button>
                    }
                </div>

                <div className='flex justify-end py-3 gap-2'>
                    {rommateList?.university_details !== null &&
                        <Link to="/roommatesList">
                            <button type="button" className='font-semibold border-[#F79F15] border text-[#F79F15]  px-6 py-1.5  rounded-md'>{t('cancel')}</button>
                        </Link>
                    }

                    <button onClick={() => handleUpdate()} type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{loadingCreate ? <Loader type="saveLoder" /> : t('save')}</button>
                    {/* <Link to="/roommatesList">
                    <button type="button" className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('cancel')}</button>
                </Link> */}
                </div>
            </div>
        </div>
    )
}

export default ExActivities