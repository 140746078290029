import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
function NotFound({ type }) {
    const { t } = useTranslation()
    return (
        <>
            {type === "mainPage" ?
                <div className="flex items-center justify-center min-h-screen bg-indigo-500  bg-fixed bg-cover bg-bottom error-bg">

                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 text-gray-50 text-center -mt-52">
                                <div className="relative ">
                                    <h1 className="relative text-9xl tracking-tighter-less text-shadow font-sans font-bold">
                                        <span>4</span><span>0</span><span>4</span></h1>
                                    <span className="absolute  top-0   -ml-12  text-gray-300 font-semibold">Oops!</span>
                                </div>
                                <h5 className="text-gray-300 font-semibold -mr-10 -mt-3">Page not found</h5>
                                <p className="text-gray-100 mt-2 mb-6">we are sorry, but the page you requested was not found</p>
                                <Link to="/"
                                    className="bg-green-400  px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-gray-50 rounded-full hover:shadow-lg">
                                   {t('go_home')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <section className="bg-white dark:bg-gray-900 ">
                    <div className="container  px-6 py-12 mx-auto lg:flex lg:items-center lg:gap-12">
                        <div className="w-full flex justify-center items-center flex-col">
                            <p className="text-2xl font-bold text-blue-500 dark:text-blue-400">404</p>
                            <h1 className="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">Page not found</h1>
                            <p className="mt-4 text-gray-500 dark:text-gray-400">Sorry, the page you are looking for doesn't exist</p>

                            <div className="flex items-center mt-6 gap-x-3">

                                <Link to="/dashboard/all" className="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
                                    {t('go_home')}
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default NotFound