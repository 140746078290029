import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreateLeased } from '../../../lib/Api/LeasedBuilder';
import { GetLeasedsProperty } from '../../../lib/Api/LeasedBuilder';
import { Loader } from '../../../utils/loaderButton';
import { ConvertBase64 } from '../../../utils/ConvBase64';
import { Link, useNavigate } from 'react-router-dom';
// import { MdArrowBackIos } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
// import HowItsWork from '../../GenericComp/HowItsWork';
// import SetPassword from '../../GenericComp/SetPassword';
// import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import DynamicPopup from '../../../utils/Popup/DynamicPopup';
import PasswordVerify from '../RentalRegister/PasswordVerify';
// import { setUploadLease } from '../../../Redux/UserAuthSlice/UserAuthSlice';
import NotificationChecker from '../../../Partials/NotificationChecker';
import { setLeased, setLocateNotification } from '../../../Redux/UserAuthSlice/UserAuthSlice';
const schema = yup.object({
    property_id: yup.string().required("Property field is required"),
});





const CreateLeasd = () => {
    const [properies, setProperties] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingProperty, setLoadingProperty] = useState(false)
    const [pdf, setPdf] = useState({})
    const [file, setFile] = useState(null);
    const [show, setShow] = useState(false);
    const [type, setType] = useState("");
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isNotLeasedBuild, leasebuildData, isLeasedCreate } = useSelector((state) => state?.userAuth)

    const handleChange = async (e) => {
        let file = e.target.files[0]
        if (file) {
            let result = await ConvertBase64(file)
            setPdf(result)
            const fileExtension = file.name.split('.').pop();
            setFile(fileExtension);

            // You can also set the file in state if needed
        }
    }




    // const checkVerify = () => {
    //     const hasDeniedPermission = sessionStorage.getItem('hasWatchLeaseApp');
    //     const hasVerify = sessionStorage.getItem('hasVerifyLeased');
    //     if (!hasDeniedPermission) {
    //         setShow(true)
    //     }
    //     else if (!hasVerify) {
    //         setShow(true)
    //     }

    // }

    const verifyUser = () => {
        // sessionStorage.setItem('hasWatchLeaseApp', 'true');
        // sessionStorage.setItem('hasVerifyLeased', 'true');
        setShow(false)
        // navigate("/")
    }



    const OnSubmit = async (data) => {
        let propertyId = Number(data.property_id)
        let formData = new FormData()
        formData.append('property_id', propertyId)
        formData.append('pdf', pdf)
        formData.append('extension', file)
        setLoading(true)
        let { res } = await CreateLeased(formData)
        if (res) {
            setLoading(false)
            if (isNotLeasedBuild === true) {
                setShow(true)
                // dispatch(setUploadLease(false))
                setType("notification")
            }
            else if (isLeasedCreate.data === true) {
                dispatch(setLocateNotification(isLeasedCreate?.page))
                dispatch(setLeased(false))
                navigate('/mylist')

            }
            else {
                navigate('/leasedBuilder')
            }
        }
        else {
            setLoading(false)
        }
    }

    const id = useParams().id


    useEffect(() => {
        // checkVerify()
        let getApi = async () => {
            setLoadingProperty(true)
            let { res } = await GetLeasedsProperty()
            if (res) {
                setLoadingProperty(false)
                setProperties(res)

                if (id) {
                    let payload = {
                        property_id: id
                    }
                    reset(payload)
                }
            }
            else {
                setLoadingProperty(false)
            }
        }
        getApi()
    }, [])
    // useEffect(() => {
    //     const hasVerify = sessionStorage.getItem('hasVerifyLeased');
    //     if (!hasVerify) {
    //         setShow(true)
    //     }
    // }, [show])


    const { t } = useTranslation()

    if (show) {
        return (<DynamicPopup modalOpen={show} data={type === "notification" ? <NotificationChecker setShow={setShow} data={leasebuildData} /> : <PasswordVerify close={() => verifyUser()} />}
            close={() => setShow()}
        />)
    }

    else {
        return (
            <>

                <Link to="/leasedBuilder">
                    <div className='flex items-center font-semibold cursor-pointer'>
                        {/* <MdArrowBackIos className='text-[20px]' /> */}
                        {t('Back')}
                    </div>
                </Link>
                <div className='pt-5'>
                    {/* {activeIndex === 0 && <HowItsWork handleNext={handleNext} />} */}
                    {/* {activeIndex === 1 && <SetPassword handleNext={handleNext} userid={userid} />} */}
                    {/* {activeIndex === 2 && */}
                    <form onSubmit={handleSubmit(OnSubmit)}>
                        <div className='xl:p-5 lg:p-5 md:p-5'>
                            <div>
                                <h2 className='text-[18px] font-semibold'>{t('lease_heading')}</h2>
                                <p>{t('lease_para')}</p>
                            </div>
                            <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 lg:gap-20 md:gap-20  my-5'>
                                <div className='flex flex-col mb-4 '>
                                    <label className='mb-2 text-[16px] font-semibold'>{t('property_text')}</label>
                                    <select  {...register('property_id')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none'>
                                        <option value="">{loadingProperty ? "Loading...." : properies && properies?.length > 0 ? t('select_property') : t('no_property')}</option>
                                        {
                                            properies?.map((item) => (
                                                <option key={item?.id} value={item?.id} >{item?.complete_address}</option>
                                            ))
                                        }

                                    </select>
                                    {errors.property_id && (
                                        <p className="text-red-500 text-sm ">{errors.property_id.message}</p>
                                    )}
                                </div>
                                <div className='flex flex-col mb-4  '>
                                    <label className='mb-2 text-[16px] font-semibold'>{t('pdf_file')} </label>
                                    <input type='file' accept=".pdf, .docx" onChange={(e) => handleChange(e)} name="pdf" className='border rounded-md p-1' />
                                </div>

                            </div>

                            <div className='flex justify-end py-3'>
                                <button className='font-semibold  mx-2 text-[#F79F15] border-[#F79F15] border bg-white px-6 py-1.5  rounded-md'>
                                    {
                                        loading ? <Loader type="loadData" /> : t('create_property')
                                    }
                                </button>


                            </div>
                        </div>
                    </form>
                    {/* } */}
                </div>
            </>
        )
    }
}

export default CreateLeasd