import React from 'react'
import { useTranslation } from 'react-i18next'

const LocationAccess = ({ close }) => {
    const { t } = useTranslation()
    return (
        <div className='p-5'>
            <h2 className='text-[18px] font-medium max-w-[500px] mx-auto'>{t('allow_location')}</h2>
            <div className='flex justify-end items-end gap-5 pt-5'>
                <button onClick={() => close()} className='xl:w-[20%] lg:w-[20%] md:w-[20%] w-full  py-1 bg-blue-500 text-white text-[18px] rounded-md'>{t('cancel')}</button>
                <button onClick={() => close()} className='xl:w-[20%] lg:w-[20%] md:w-[20%] w-full  py-1 bg-[#F79F15] text-white text-[18px] rounded-md'>{t('ok')}</button>
            </div>
        </div>
    )
}

export default LocationAccess