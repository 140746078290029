import React, { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Loader } from '../../../utils/loaderButton';
import { useEffect } from 'react';
import { CreateRommateOnCampus, GetCampusActivities, GetCampusInterest, GetCountries } from '../../../lib/Api/RantelServiceApi';
import { AddRommateIndex } from '../../../Redux/UserAuthSlice/RommateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import Select from 'react-select'
import { IoArrowBackSharp } from 'react-icons/io5';
const UniversityDetail = () => {
  const [Act, setAct] = useState([])
  const [loading, setLoading] = useState(false)
  const [activity, setActivity] = useState([])
  const [loadingCon, setLoadingCon] = useState(false)
  const [loadingCreate, setLoadingCreate] = useState(false)
  // const [active, setActive] = useState(null)
  const [options, setOptions] = useState([]);
  const [error, setError] = useState({
    active: "",
  })

  const { t } = useTranslation()
  const { rommateId, rommateList, isNewRommate } = useSelector((state) => state?.rommate)

  const gradle = [
    { heading: t('gradle_op1'), key: "Freshmen" },
    { heading: t('gradle_op2'), key: "Sophomore" },
    { heading: t('gradle_op3'), key: "Junior" },
    { heading: t('gradle_op4'), key: "Senior" },
    { heading: t('gradle_op5'), key: "Post-Graduate" },
    { heading: t('gradle_op6'), key: "Graduate" },
    { heading: t('gradle_op7'), key: "Other" }
  ]

  const schema = yup.object({
    // university_name: yup.string().required(t('rommate_uni')),
    university_name: yup
      .object({
        value: yup.string().required(t('rommate_uni')),
        label: yup.string().required(t('rommate_uni')),
      })
      .nullable()
      .required(t('rommate_uni')),
    // domm_name: yup.string().required(t('rommate_uni_dorm')),
    // room: yup.string().required(t('rommate_uni_room')),
    // age: yup.string().required(t('rommate_uni_dob')),
    // gender: yup.string().nullable().required(t('gender_req')),
    major: yup.string().required(t('rommate_uni_major')),
    grade_level: yup.string().required(t('rommate_uni_grad')),
  });

  const { register, handleSubmit, reset, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const dispatch = useDispatch()
  const navigate = useNavigate()


  let getCountries = async () => {
    try {
      setLoadingCon(true)
      let res = await GetCountries()
      if (res) {
        setLoadingCon(false)
        const formattedOptions = res.map(item => ({ label: item.name, value: item.id }));
        setOptions(formattedOptions);
      }
    }
    catch (err) { }
  }

  const handleActivityChecks = (id) => {
    if (Act?.includes(id)) {
      let l = Act?.filter((f) => f !== id)
      setAct(l)
    }
    else {
      setAct((prev) => ([...prev, id]))
    }
  }



  const OnSubmit = async (data) => {
    setLoadingCreate(true)
    let formData = new FormData()
    formData.append('roommate_id', rommateId)
    formData.append('university_id', data?.university_name?.value)
    // formData.append('domm_name', data?.domm_name)
    // formData.append('room', data?.room)
    // formData.append('gender', data?.gender)
    formData.append('major', data?.major)
    formData.append('grade_level', data?.grade_level)
    // formData.append('length_of_stay', active)
    formData.append('style', "style")
    //   Intr.forEach((file, index) => {
    //     formData.append(`interests[${index}]`, file);
    //   });
    Act.forEach((file, index) => {
      formData.append(`extra_curricular_activities[${index}]`, file);
    });
    let res = await CreateRommateOnCampus(formData)
    if (res) {
      setLoadingCreate(false)
      if (isNewRommate === false) {
        navigate('/roommatesList')
      } else {
        dispatch(AddRommateIndex(8))
      }
    }

  }


  let GetActivityInterest = async () => {
    setLoading(true)
    let { resActivity } = await GetCampusActivities()
    const ConvertActi = Object.assign({}, ...resActivity)
    if (resActivity) {
      setLoading(false)
      setActivity(ConvertActi.activities)
    }
    else {
      setLoading(false)
    }
  }

  useEffect(() => {
    GetActivityInterest()

    getCountries()
    if (!isNewRommate) {
      let list = rommateList?.university_details
      // let romateAmi = list?.extra_curricular_activities.map((am) => am.id)
      // let getIds = rommateList?.interests?.map((intr) => (intr?.id))
      let data = rommateList?.university_details
      let val = {
        label: data?.university_name || "",
        value: data?.university_id || ""
      }
      let obj = {
        "university_name": val,
        // 'domm_name': data?.domm_name,
        // 'room': data?.room,
        // 'gender': data?.gender,
        'major': data?.major,
        'grade_level': data?.grade_level,

      }
      reset(obj)

    }
  }, [])




  return (
    <>
      <div className='xl:hidden lg:hidden md:hidden block'>
        <Link to="/roommatesList">
          <IoArrowBackSharp size={20} />
        </Link>
      </div>
      <h1 className='text-center text-[25px] font-semibold'>{t('rommate_uni_detail')}</h1>
      <form className='mt-5 border shadow-md p-5 rounded-sm' onSubmit={handleSubmit(OnSubmit)}>
        {/* <h2 className='font-semibold text-[20px] pb-4 '>University</h2> */}
        <div className='grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3  xl:gap-5 lg:gap-5 md:gap-5'>
          <div className='flex flex-col mb-4'>
            <label className='font-medium' htmlFor="">{t('rommate_uni')}</label>
            <Controller
              name="university_name"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  className="rounded-md mt-1  focus:outline-none"
                  placeholder={t('search_lab')}
                  isClearable={true}
                  isSearchable={true}
                  isLoading={loadingCon}
                  options={options}
                />
              )}
            />
            {/* <input {...register('university_name')} type='text' placeholder={t('rommate_uni')} className='p-2 rounded-md border focus:outline-none' /> */}
            {errors.university_name && (
              <p className="text-red-500 text-sm ">{errors.university_name.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4 '>
            <label className='font-medium' htmlFor="">{t('rommate_uni_major')}</label>
            <input {...register('major')} type='text' placeholder={t('rommate_uni_major')} className='p-2 rounded-md border focus:outline-none' />
            {errors.major && (
              <p className="text-red-500 text-sm ">{errors.major.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <label className='font-medium' htmlFor="">{t('rommate_uni_grad')}</label>
            <select
              className='p-2 rounded-md border focus:outline-none'
              {...register('grade_level')}>
              <option>{t('please_select')}</option>
              {
                gradle?.map((grad, index) => (
                  <option key={index} value={grad?.key}>{grad?.heading}</option>
                ))
              }
            </select>
            {/* <input  type='text' placeholder={t('rommate_uni_grad')} className='p-2 rounded-md border focus:outline-none' /> */}
            {errors.grade_level && (
              <p className="text-red-500 text-sm ">{errors.grade_level.message}</p>
            )}
          </div>
        </div>

        <div>
          {
            rommateList?.university_details === null &&
            <>
              <h2 className='text-[20px] font-semibold my-4'>{t('rommate_ex_acti')}</h2>
              {
                loading ?
                  <div className='flex justify-start items-start'>
                    <Loader type="loadData" />
                  </div>
                  :
                  <div className='flex flex-col'>
                    <div className='flex flex-wrap items-center xl:max-h-[200px] xl:overflow-y-auto lg:max-h-[200px] lg:overflow-y-auto md:max-h-[200px] md:overflow-y-auto'>
                      {
                        activity?.map((c, i) => {
                          let d = Act?.find((f) => f === c.id)
                          return (
                            <div className='flex items-center mx-1' key={i}>
                              <input id={c?.id} type="checkbox" onChange={() => handleActivityChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                              <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.activity}</label>
                            </div>
                          )
                        })
                      }
                    </div>
                    {error && (
                      <p className="text-red-500 text-sm pt-2 ">{error.active}</p>
                    )}
                  </div>
              }
            </>
          }
        </div>
        <div className='flex justify-between items-center'>
          <div>
            {rommateList?.university_details === null &&
              <div>
                <Link to="/roommatesList">
                  <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                    {`${t(`Back`)}`}</button>
                </Link>
              </div>
            }
          </div>
          <div className='flex justify-end  py-3 gap-2'>
            {isNewRommate === false &&
              <Link to="/roommatesList">
                <button type="button" className='font-semibold border-[#F79F15] border text-[#F79F15]  px-6 py-1.5  rounded-md'>{t('cancel')}</button>
              </Link>
            }
            <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{loadingCreate ? <Loader type="saveLoder" /> : t('save')}</button>

          </div>
        </div>


      </form>
    </>
  )
}

export default UniversityDetail