import { toast } from "react-toastify"
import { callPrivateApi, callPublicApi } from "../Apiendpoint"
import moment from "moment"
// Get Srtripe session
export const GetStripeSession = async () => {

    try {
        let response = await callPrivateApi('/user/check-for-unique', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}


// SignUp as a Servicer 
export const CheckUserRegister = async (data) => {
    try {
        let changeFormate = moment(data?.dateofbirth).format('MM/DD/YYYYY')
        let payload = {
            "phone_number": changeFormate,
            "email": data?.email,
            "password": data?.password,
            lang: data?.lang === "sp" ? "es" : data.lang

        }

        let response = await callPublicApi('/user/check-for-unique', 'post', payload)
        if (response.success === true) {
            // toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// SignUp as a Servicer 
export const SignUpUser = async (data) => {
    //  let { firstname , lastname , dateofbirth , phone  , email , password , confirmpassword} = data 
    try {

        let changeFormate = moment(data?.dateofbirth).format('MM/DD/YYYY')
        const lang = data?.lang === "sp" ? "es" : data.lang
        let payload = {
            "first_name": data?.fname,
            "last_name": data?.lname,
            "date_of_birth": changeFormate,
            // "date_of_birth": "10/25/1989",
            "phone_number": data?.phone,
            "email": data?.email,
            "password": data?.password,
            "password_confirmation": data?.confirmPwd,

        }



        let checkRole = data.role === "Servicer" ? `/user/register/servicer?lang=${lang}` : `/user/register/renter?lang=${lang}`
        let response = await callPublicApi(checkRole, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// SignIn User 

export const SignInUser = async (data) => {
    let { email, password } = data
    const lang = data?.lang === "sp" ? "es" : data.lang

    try {
        let payload = {
            "email": email,
            "password": password,
        }
        let checkRole = data.role === "Servicer" ? `/user/login/servicer?lang=${lang}` : `/user/login/renter?lang=${lang}`
        let response = await callPublicApi(checkRole, 'post', payload)
        if (response.success === true && data.type !== "verify") {
            toast.success(response.message)
            return { res: response.data }
        }
        else if(response.success === true && data.type === "verify"){
            return { res: response.data }
        }
        else {
            toast.error(response.message)
            return { error: response.message }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const VerifyPasswordRenalApp = async (data) => {
    let { email, password } = data
    const lang = data?.lang === "sp" ? "es" : data.lang

    try {
        let payload = {
            "email": email,
            "password": password,
            lang: lang === "sp" ? "es" : lang
        }

        let response = await callPublicApi(`/user/login?lang=${lang}`, 'post', payload)
        if (response.success === true && data.type !== "verify") {
            return { res: response.data }
        }
        else if(response.success === true && data.type === "verify"){
            return { res: response.data }
        }
        else {
            return { error: response.message }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// SignIn User With Social 

export const SignInUserSocialy = async (data) => {
    let { email, phoneNumber, providerId, uid, displayName, first_name, role } = data
    // console.log("payload console in api " , data)
    try {
        // const lang =  data?.lang === "sp" ? "es" : data.lang
        let checkProvider = providerId === "google.com" ? { "google_id": uid } : providerId === "facebook.com" ? { "facebook_id": uid } : providerId === "apple.com" ? { "apple_id": uid } : ""
        let payload = {
            "first_name": first_name || displayName,
            "phone_number": phoneNumber,
            "email": email,
            ...checkProvider
        }
        let checkRole = role === "Servicer" || role === "servicer" ? `/user/socialite/servicer` : `/user/socialite/renter`

        let response = await callPublicApi(checkRole, 'post', payload)
        if (response.success === true) {
            // toast.success(response.message)
            return { resUser: response.data }
        }
        else {
            toast.error(response.message)
            return { error: response.message }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const CheckUserSocialy = async (data) => {
    let { email, phoneNumber, uid, displayName, checkProvider } = data
    try {


        // let d = checkProvider === "google_user_id" ? { "google_id": uid } : checkProvider === "fb_user_id" ? { "facebook_id": uid } : checkProvider === "apple_user_id" ? { "apple_id": uid } : ""

        let payload = {
            "first_name": displayName,
            "phone_number": phoneNumber,
            "email": email,
            // ...d,
            ...checkProvider === "google_user_id" ? { "google_id": uid } : checkProvider === "fb_user_id" ? { "facebook_id": uid } : checkProvider === "apple_user_id" ? { "apple_id": uid } : ""
        }
        // let checkRole = role === "Servicer" ? "/user/socialite/servicer" : "/user/socialite/renter"

        let response = await callPublicApi('/user/check-social-user', 'post', payload)
        if (response.success === true) {
            // toast.success(response.message)
            return { res: response.data }
        }
        else {
            toast.error(response.message)
            return { error: response.message }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// forgot 
export const ForgotUser = async (data) => {
    let { email } = data
    try {
        const lang = data?.lang === "sp" ? "es" : data.lang

        let payload = {
            email: email,
            // lang: lang
        }

        let response = await callPublicApi(`/user/reset-password/step-1?lang=${lang}`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return { res: response.data }
        }
        else {
            toast.error(response.message)
            return { error: response.data }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// otp send 
export const SendOtp = async (data) => {
    try {
        const lang = data?.lang === "sp" ? "es" : data.lang

        let payload = {
            "email": data.email,
            "pin_code": data.otp,
            // lang: data?.lang === "sp" ? "es" : data.lang
        }

        let response = await callPublicApi(`/user/reset-password/step-2?lang=${lang}`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return { res: response.data }
        }
        else {
            toast.error(response.message)
            return { error: response.data }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// otp send 
export const ResetPassword = async (data) => {
    try {
        const lang = data?.lang === "sp" ? "es" : data.lang
        let payload = {
            "email": data?.email,
            "new_pass": data?.password,
            "r_new_pass": data?.r_password,

        }

        let response = await callPublicApi(`/user/reset-password/step-3?lang=${lang}`, 'post', payload)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// otp send 
export const EditUserProfile = async (data) => {
    try {
        let response = await callPrivateApi(`/user/edit-profile`, 'post', data)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Change language api //
export const ChangeLanguage = async (lang) => {
    try {
        const language =  lang === "sp" ? "es" :lang
        let response = await callPrivateApi(`/user/set-locale/${language}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
           
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Logout api //
export const logoutUser = async (lang) => {
    try {
        let response = await callPrivateApi(`/user/logout`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
           
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const VerifyPasswords = async (data) => {
    let { email, password } = data

    try {
        let payload = {
            "email": email,
            "password": password,
        }

        let response = await callPrivateApi(`/user/authenticate-password`, 'post', payload)
        if (response.success === true ) {
            // toast.success(response.message)
            return { res: response.data }
        }
        else {
            toast.error(response.message)
            return { error: response.message }
        }
    }
    catch (err) {
        toast.error(err)

    }
}