import React, { useState, useEffect } from 'react'
import sl1 from '../../assests/images/slider1.png'
import logo from '../../assests/images/logo.png'
// import f from '../../assests/images/f.png'
import g from '../../assests/images/g.png'
import i from '../../assests/images/i.png'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { AiFillEye, AiFillEyeInvisible, AiOutlineClose, AiOutlineKey } from 'react-icons/ai'
// import DynamicDropdonw from '../../utils/DaynamicDropdown';
import { MdAlternateEmail } from 'react-icons/md';
import { CheckUserSocialy, SignInUser, SignInUserSocialy } from '../../lib/Api/UserApi'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signin } from '../../Redux/UserAuthSlice/UserAuthSlice'
import { Loader } from '../../utils/loaderButton'
import { signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { auth } from '../../config/Firebase'
import LanguageDropdonw from '../../utils/Dropdowns/LanguageDropdonw'
import { isMacOs } from 'react-device-detect';
import { toast } from 'react-toastify'
import StripLoader from '../GenericComp/StripLoader'



const SignInForm = ({ close, setLoader, login, setMedia, setActive, setSignUp, permistion, mediaName, setMediaName, t, userSocial, userObject, setUserObject, setUserSocial }) => {
    const [showPass, setShowPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const [signInLoading, setSignInLoading] = useState({ google: false, facebook: false, mac: false })
    const [striploading, setStripLoading] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const { language } = useSelector((state) => state.userAuth)

    const schema = yup.object({
        email: yup.string().email(t('invalid_email')).required(t('emial_req')),
        password: yup.string().required(t('pass_req')),
        // Role: yup.string().required(),
    });


    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { register, watch, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    // firebase auth
    const firebaseAuth = auth;
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        prompt: 'select_account'
    });
    // const Fbprovider = new FacebookAuthProvider();
    // Fbprovider.setCustomParameters({
    //     prompt: 'select_account'
    // });
    const Approvider = new OAuthProvider('apple.com');
    // Approvider.setCustomParameters({
    //     prompt: 'select_account'
    // });
    // Social signin functions

    const signInGog = async () => {
        if (permistion === null) {
            const { user } = await signInWithPopup(firebaseAuth, provider);
            const { providerData } = user;
            let obj = Object.assign({}, ...providerData)
            let checkProvider = obj?.providerId === "google.com" ? `google_user_id` : null
            let newObj = { ...obj, checkProvider }
            setLoader(true)
            let { res } = await CheckUserSocialy(newObj)
            let UserData = Object.assign({}, ...res)
            setMedia(true)
            if (UserData?.is_new_user === false) {
                // setSignInLoading({ google: true })
                let payload = {
                    role: UserData?.user?.role,
                    lang: language ? language : "",
                    ...newObj
                }
                let { resUser } = await SignInUserSocialy(payload)
                let signInData = Object.assign({}, ...resUser)
                setLoader(false)
                // setSignInLoading({ google: false })
                if (signInData?.user?.role === "servicer") {
                    dispatch(signin(signInData))
                    navigate('/servicerPanel')
                    toast.success(t('login_suc'))

                }
                else {
                    dispatch(signin(signInData))
                    navigate('/dashboard')
                    toast.success(t('login_suc'))
                }
            }
            else {
                setUserObject(obj)
                setUserSocial(checkProvider)
                setLoader(false)
                setMedia(true)
            }
        }
        else {
            let payload = {
                "first_name": userObject?.displayName,
                "phone_number": userObject?.phoneNumber,
                "email": userObject?.email,
                "uid": userObject?.uid,
                "role": permistion,
                "providerId": userObject?.providerId,
                lang: language ? language : "",
            }
            setLoader(true)
            let { resUser } = await SignInUserSocialy(payload)
            let UserData = Object.assign({}, ...resUser)
            if (resUser) {
                dispatch(signin(UserData))
                setUserInfo(UserData)
                setLoader(false)
                setStripLoading(true)
            }

        }

    };

    // const signInFb = async () => {
    //     if (permistion === null) {
    //         const { user } = await signInWithPopup(firebaseAuth, Fbprovider);
    //         const { providerData } = user;
    //         let obj = Object.assign({}, ...providerData)
    //         // let role = { role: permistion }
    //         let checkProvider = obj?.providerId === "facebook.com" ? 'fb_user_id' : null
    //         let newObj = { ...obj, checkProvider }
    //         setLoader(true)
    //         let { res } = await CheckUserSocialy(newObj)
    //         let UserData = Object.assign({}, ...res)
    //         setMedia(true)
    //         if (UserData?.is_new_user === false) {
    //             // setSignInLoading({ google: true })
    //             let payload = {
    //                 role: UserData?.user?.role,
    //                 lang: language ? language : "",
    //                 ...newObj
    //             }
    //             let { resUser } = await SignInUserSocialy(payload)
    //             let signInData = Object.assign({}, ...resUser)
    //             setLoader(false)
    //             // setSignInLoading({ google: false })
    //             if (signInData?.user?.role === "servicer") {
    //                 dispatch(signin(signInData))
    //                 navigate('/servicerPanel')
    //                 toast.success(t('login_suc'))

    //             }
    //             else {
    //                 dispatch(signin(signInData))
    //                 navigate('/dashboard')
    //                 toast.success(t('login_suc'))
    //             }
    //         }
    //         else {
    //             setUserObject(obj)
    //             setUserSocial(checkProvider)
    //             setLoader(false)
    //             setMedia(true)

    //         }
    //     }
    //     else {
    //         let payload = {
    //             "first_name": userObject?.displayName,
    //             "phone_number": userObject?.phoneNumber,
    //             "email": userObject?.email,
    //             "google_id": userSocial,
    //             "role": permistion,
    //             lang: language ? language : "",

    //         }
    //         setLoader(true)
    //         let { resUser } = await SignInUserSocialy(payload)
    //         let UserData = Object.assign({}, ...resUser)
    //         if (resUser) {
    //             dispatch(signin(UserData))
    //             setUserInfo(UserData)
    //             setLoader(false)
    //             setStripLoading(true)
    //         }
    //         // if (resUser !== undefined) {
    //         //     if (UserData?.user?.role === "servicer") {
    //         //         dispatch(signin(UserData))
    //         //         setUserInfo(UserData)
    //         //         navigate('/servicerPanel')
    //         //         toast.success(t('login_suc'))

    //         //     }
    //         //     else {
    //         //         dispatch(signin(UserData))
    //         //         setUserInfo(UserData)
    //         //         navigate('/dashboard')
    //         //         toast.success(t('login_suc'))
    //         //     }
    //         // }
    //         // else {
    //         //     setLoading(false)
    //         // }
    //     }

    // };

    const signInAp = async () => {
        if (permistion === null) {
            const { user } = await signInWithPopup(firebaseAuth, Approvider);
            const { providerData } = user;
            let obj = Object.assign({}, ...providerData)
            let checkProvider = obj?.providerId === "apple.com" ? 'apple_user_id' : null
            let newObj = { ...obj, checkProvider }

            setLoader(true)
            let { res } = await CheckUserSocialy(newObj)
            let UserData = Object.assign({}, ...res)

            setMedia(true)

            if (UserData?.is_new_user === false) {
                let payload = {
                    role: UserData?.user?.role,
                    lang: language ? language : "",
                    providerId: obj?.providerId,
                    uid: obj?.uid,
                    email: obj?.email || "",
                    displayName: obj?.displayName || ""
                }
                let { resUser } = await SignInUserSocialy(payload)
                let signInData = Object.assign({}, ...resUser)
                setLoader(false)
                if (signInData?.user?.role === "servicer") {
                    dispatch(signin(signInData))
                    navigate('/servicerPanel')
                    toast.success(t('login_suc'))

                }
                else {
                    dispatch(signin(signInData))
                    navigate('/dashboard')
                    toast.success(t('login_suc'))
                }
            }
            else {
                setUserObject(obj)
                setUserSocial(checkProvider)
                setLoader(false)
                setMedia(true)

            }
        }
        else {
            let payload = {
                "first_name": userObject?.displayName,
                "phone_number": userObject?.phoneNumber,
                "email": userObject?.email,
                "uid": userObject?.uid,
                "role": permistion,
                "providerId": userObject?.providerId,
                lang: language ? language : "",
            }
            setLoader(true)
            let { resUser } = await SignInUserSocialy(payload)
            let UserData = Object.assign({}, ...resUser)
            if (resUser) {
                dispatch(signin(UserData))
                setUserInfo(UserData)
                setLoader(false)
                setStripLoading(true)
            }
            // let payload = {
            //     "first_name": userObject?.displayName,
            //     "phone_number": userObject?.phoneNumber,
            //     "email": userObject?.email,
            //     "apple_id": apple_user_id,
            //     "role": permistion,
            //     lang: language ? language : "",

            // }
            // setLoader(true)

            // console.log("payload here: ", payload)

            // let { resUser } = await SignInUserSocialy(payload)
            // let UserData = Object.assign({}, ...resUser)
            // if (resUser) {
            //     dispatch(signin(UserData))
            //     setUserInfo(UserData)
            //     setLoader(false)
            //     setStripLoading(true)
            // }
        }


    };

    const onSubmit = async (data) => {
        setLoading(true)
        let payload = {
            lang: language ? language : "",
            role :permistion,
            ...data
        }
        let { res } = await SignInUser(payload)
        if (res !== undefined) {
            let UserData = Object.assign({}, ...res)
            if (UserData?.user?.role === "servicer") {
                reset()
                setLoading(false)
                dispatch(signin(UserData))
                navigate('/servicerPanel')
            }
            else {
                dispatch(signin(UserData))
                navigate('/dashboard')
            }
        }

        else {
            setLoading(false)

        }
    }

    useEffect(() => {
        // if (mediaName === 0) {
        //     signInFb()
        // }
        if (mediaName === 0) {
            signInGog()
        }
        else if (mediaName === 1) {
            signInAp()
        }
    }, [mediaName, permistion])



    return (
        <>

            {striploading ? <StripLoader UserInfo={userInfo} login={login} setStripLoading={setStripLoading} /> : ""}
            {
                striploading ?
                    ""
                    :
                    <div className='lg:flex md:w-[700px] lg:w-full signIn_screen mx-0'>
                        <div className='lg:w-[70%] w-full relative hidden lg:block'>
                            <img src={sl1} className=" h-full object-cover" alt="slider" />
                            <div className='absolute bottom-[10%] left-[20%]'>
                                <h2 className='text-[20px] text-white font-semibold'>{t('welcome')}</h2>
                            </div>

                        </div>
                        <div className='w-full px-5 '>
                            <div className='flex justify-between items-center '>
                                <img src={logo} className="h-[95px] object-contain" alt="logo" />
                                <div>
                                    {/* <DynamicDropdonw /> */}
                                    <LanguageDropdonw />
                                </div>
                            </div>
                            <div className=' flex justify-end items-end cursor-pointer' onClick={() => close()}>
                                <div className='border w-[35px] rounded-md shadow-md p-2'>
                                    <AiOutlineClose />
                                </div>

                            </div>
                            <div>
                                <h2 className='lg:text-[2.5rem] text-[2rem] font-bold'>{t('get_started')}</h2>
                                <p className='text-[14px]'>
                                    {t('sign_up')} <span className='text-[16px] underline text-[#F79F15] font-bold cursor-pointer' onClick={() => { return setSignUp(true), close() }}>{t('sign_up_register')}</span>
                                </p>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='mt-5'>
                                    <div className={`flex flex-col border-b w-full ${watch('email') && ' border-b border-[#F79F15]'}`}>
                                        <label>{t('login_email')}</label>
                                        <div className='flex items-center w-full'>
                                            <MdAlternateEmail />
                                            <input type="email" {...register('email')} className='p-2  w-full focus:outline-none' name="email" placeholder={`${t('login_email')}`} />
                                        </div>
                                    </div>
                                    {errors.email && (
                                        <p className="text-red-500 text-sm ">{errors.email.message}</p>
                                    )}
                                    <div className={`flex flex-col pt-2 border-b w-full ${watch('password') && ' border-b border-[#F79F15]'}`}>
                                        <label>{t('login_password')}</label>
                                        <div className='flex items-center'>
                                            <AiOutlineKey />
                                            <input type={showPass ? "text" : "password"}  {...register('password')} className='p-2  w-full focus:outline-none ' name="password" placeholder={`${t('login_password')}`} />
                                            {
                                                showPass ?
                                                    <AiFillEye onClick={() => setShowPass(false)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                                                    :
                                                    <AiFillEyeInvisible onClick={() => setShowPass(true)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />

                                            }

                                        </div>
                                    </div>
                                    {errors.password && (
                                        <p className="text-red-500 text-sm ">{errors.password.message}</p>
                                    )}

                                    {/* <div className={`flex flex-col pt-2 border-b w-full ${watch('Role') && ' border-b border-[#F79F15]'}`}>
                                        <label>{t('login_as_a')}</label>
                                        <div className=''>
                                            <select {...register('Role')} className='w-full focus:outline-none p-1 '>
                                                <option value="">{t('please_select')}</option>
                                                <option >{t('servicer')}</option>
                                                <option >{t('rentals')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    {errors.Role && (
                                        <p className="text-red-500 text-sm ">{errors.Role.message}</p>
                                    )} */}


                                    <div className="flex justify-end items-end">
                                        <p className='text-[13px] w-max py-1   text-gray-400 cursor-pointer' onClick={() => setActive(1)}>{t('forgot_pass')}</p>

                                    </div>
                                    <div className='pt-6'>
                                        {
                                            loading ?
                                                <Loader />
                                                :
                                                <button className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white'>{t('login')}</button>
                                        }
                                    </div>
                                    <div className='flex items-center justify-center pt-6'>
                                        <span className='border lg:w-[35%] w-[25%] h-0'></span>
                                        <h2 className='text-[13px] px-2 font-semibold text-center'>{t('or_sign_up')} </h2>
                                        <span className='border lg:w-[35%] w-[25%] h-0'></span>
                                    </div>

                                    <div className='flex items-center mt-5 justify-center '>

                                        {/* {signInLoading?.facebook ? <div className='border rounded-full w-12 h-12 flex justify-center items-center'> <Loader type="socialLoder" /> </div> : <div onClick={() => setMediaName(0)}> <img src={f} alt="f_image" /></div>} */}
                                        {signInLoading?.google ? <div className='border rounded-full w-12 h-12 flex justify-center items-center'> <Loader type="socialLoder" /> </div> : <div onClick={() => setMediaName(0)}><img src={g} alt="f_image" /></div>}
                                        {isMacOs ? (
                                            signInLoading?.mac ? <div className='border rounded-full w-12 h-12 flex justify-center items-center'> <Loader type="socialLoder" /> </div> : <div onClick={() => setMediaName(1)} > <img src={i} alt="f_image" /></div>
                                        ) : ""}



                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            }

        </>
    )
}

export default SignInForm