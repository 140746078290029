import React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/LitsComp/Banner'
import Hosting from '../../components/LitsComp/Hosting'
import Shop from '../../components/LitsComp/Shop'
import Vacancy from '../../components/LitsComp/Vacancy'
import rom1 from '../../assests/images/list1.png'
import rom2 from '../../assests/images/list2.png'
import rom3 from '../../assests/images/list3.png'
import rom4 from '../../assests/images/list4.png'
import rom5 from '../../assests/images/host1.png'
import rom6 from '../../assests/images/host2.png'
import rom7 from '../../assests/images/host3.png'
import rom8 from '../../assests/images/host4.png'

import rombg1 from '../../assests/clientImgs/Upload Apartment Rental.png'
import rombg2 from '../../assests/clientImgs/Lease Agreement.png'
import rombg3 from '../../assests/clientImgs/Messaging.png'
import rombg4 from '../../assests/clientImgs/Calendar.png'
import { useTranslation } from 'react-i18next'
// import Banner from '../../components/RommatesComp/Banner'
const List = () => {
    const { t } = useTranslation()

    let space = {
        heading: t('web_list_rental_1'),
        lists: [t('web_list_rental_2'), t('web_list_rental_3'), t('web_list_rental_4')],
        title: "",
        icons: [{ name: t('web_list_rental_6'), original: rom5, hoverImg: rom1 }, { name: t('web_list_rental_7'), original: rom6, hoverImg: rom3 }, { name: t('web_list_rental_8'), original: rom7, hoverImg: rom2 }, { name: t('web_list_rental_9'), original: rom8, hoverImg: rom4 }],
    }

    let shopData = {
        heading: t('web_list_rommate_9'),
        lists: [
            {
                title: t('web_list_rental_14'),
                original: rom1,
                name: t('web_list_rental_10'),
                hoverImg: rombg1
            },
            {
                title: t('web_list_rental_15'),
                name: t('web_list_rental_11'),
                original: rom2,
                hoverImg: rombg2
            },
            {
                title: t('web_list_rental_16'),
                original: rom3,
                name: t('web_list_rental_12'),
                hoverImg: rombg3
            },
            {
                title: t('web_list_rental_17'),
                original: rom4,
                name: t('web_list_rental_13'),
                hoverImg: rombg4
            },
        ]

    }

    return (
        <Layout>
            <Banner list={true} />
            <Hosting space={space} list={true} />
            <Shop shopData={shopData} list={true} />
            <Vacancy type={"list"}/>
        </Layout>
    )
}

export default List