import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLang } from '../../Redux/UserAuthSlice/UserAuthSlice'
import { useEffect } from 'react'
import i18next from "i18next"
import { ChangeLanguage } from '../../lib/Api/UserApi'
import { useTranslation } from 'react-i18next'
const LanguageDropdonw = ({ profile }) => {

    const dispatch = useDispatch()
    
    const lng = useSelector((state) => state?.userAuth?.language)
    const { token } = useSelector((state) => state?.userAuth?.loginInfo)
    const handleChangeLanguage = async (check) => {
        await ChangeLanguage(check)
    }

    const handleChange = (e) => {
        const { value } = e.target
        dispatch(changeLang(value))
        localStorage.setItem('language', lng);
        let check = value === "sp" ? "es" : "en";
        if (token) {
            handleChangeLanguage(check)
        }

    }
    const { t } = useTranslation()

    useEffect(() => {
        i18next.changeLanguage(lng)
    }, [lng])
    


    return (
        <>
            {profile ?
                <select onChange={handleChange} value={lng} className='w-full border-0 normal-case text-left text-[16px] font-semibold focus:outline-none bg-white text-black h-[40px] px-2'>
                    <option value="" disabled>{t('language_sel')} </option>
                    <option value="en">English</option>
                    <option value="sp">Spanish</option>
                </select>
                :
                <select onChange={handleChange} value={lng}>
                    <option value="en">English</option>
                    <option value="sp">Spanish</option>
                </select>
            }
        </>
    )
}

export default LanguageDropdonw