import React from 'react'
import Layout from '../../components/Layout/Layout'
import Left from '../../components/BlogDetailComp/Left'
import Right from '../../components/BlogDetailComp/Right'
import CommentForm from '../../components/BlogDetailComp/CommentForm'
import RelatedBlogs from '../../components/BlogDetailComp/RelatedBlogs'
const BlogDetail = () => {
  return (
    <Layout>
      <div className='container mx-auto'>
        <div className="grid lg:grid-cols-4  gap-4 mt-[4rem]">
          <div className="lg:col-span-3  lg:mx-0 mx-2 ">
            <Left />
          </div>
          <div className="lg:border-l w-full ">
            <Right />
          </div>
        </div>
        <div className='w-full lg:mx-0 mx-2'>
          <CommentForm />
        </div>
        <div>
          <h2 className='text-[30px] px-4 font-bold my-6 '>Related Blogs</h2>
          <RelatedBlogs />
        </div>
      </div>

    </Layout>
  )
}

export default BlogDetail