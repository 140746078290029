import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import OTPInput from "otp-input-react";
import { ForgotUser, ResetPassword, SendOtp } from '../../../lib/Api/UserApi';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../../utils/loaderButton';
import { AiFillEye, AiFillEyeInvisible, AiOutlineKey } from 'react-icons/ai';
import { signin } from '../../../Redux/UserAuthSlice/UserAuthSlice';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";



const ForgotPassword = ({ close }) => {
    const [otp, setOtp] = useState('')
    const [otpError, setOtpError] = useState('')
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState(0)
    const [showPass, setShowPass] = useState(false)
    const [showPass2, setShowPass2] = useState(false)

    const user = useSelector((state) => state.userAuth.userInfo)
    const { t } = useTranslation()

    const schema = yup.object({
        password: yup.string()
            .required(t('invalid_password'))
            .min(3, t('password_charctor')),
        confirmpassword: yup.string()
            .required(t('invalid_password'))
            .oneOf([yup.ref('password')], t('match_password')),

    });
    const { register, watch, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });



    const dispatch = useDispatch()


    const { language } = useSelector((state) => state.userAuth)



    const handleSubmitOtp = async (e) => {
        e.preventDefault()
        let data = {
            email: user?.email,
            otp: otp
        }
        if (otp !== '') {
            setLoading(true)
            let { res, error } = await SendOtp(data)
            if (res) {
                setLoading(false)
                setStep(1)
            }
            else if (error) {
                setLoading(false)
            }
        }
        else {
            setOtpError(t('verify_code'))
        }
    }


    const onSubmit = async (data) => {
        let payload = {
            email: user?.email,
            password: data?.password,
            r_password: data?.confirmpassword,
            lang: language ? language : "",

        }
        setLoading(true)
        let { res, error } = await ResetPassword(payload)

        if (res !== undefined) {
            reset()
            let UserData = Object.assign({}, ...res)
            reset()
            setLoading(false)
            dispatch(signin(UserData))
            close()

        }
        else if (error) {
            setLoading(false)

        }

        else {
            setLoading(false)

        }
    }


    useEffect(() => {
        let SendEmailOtp = async () => {
            let payload = {
                lang: language ? language : "",
                email: user?.email
            }
            let { res, error } = await ForgotUser(payload)
        }
        SendEmailOtp()
    }, [])


    return (
        <>
            {step === 0 ?
                <>
                    <div className='pt-6'>
                        <h2 className='lg:text-[2rem] text-[1.5rem] font-bold'>{t('verification_code_text')}</h2>
                        <p className='text-[14px]'>
                            {t('verification_code')}
                        </p>
                    </div>
                    <div className='mt-5'>
                        <div className=' w-full flex justify-center'>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                autoFocus
                                inputClassName="border-b-2 fous:border-transparent focus:ring-0"
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                                secure={false} />
                        </div>

                        {otpError && <p className='text-red-500 text-[14px] pt-2 text-center'>{otpError}</p>}

                        <div className='pt-6'>
                            {
                                loading ?
                                    <Loader />
                                    :
                                    <button type='button' className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white' onClick={(e) => handleSubmitOtp(e)}>{t('continue')}</button>
                            }
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='pt-5'>
                        <h2 className='lg:text-[2rem] text-[1.5rem] font-bold'>{t('new_pass')}</h2>
                        <p className='text-[14px]'>
                            {t('chose_pass')}
                        </p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='mt-5'>
                            <div className='flex flex-col border-b w-full pt-3'>
                                <label className='font-semibold'>{t('password')}</label>
                                <div className='flex items-center'>
                                    <AiOutlineKey />
                                    <input type={showPass ? 'text' : 'password'} {...register('password')} className='p-2  w-full focus:outline-none' placeholder={`${t('password')}`} />
                                    {
                                        showPass ?
                                            <AiFillEyeInvisible onClick={() => setShowPass(false)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                                            :
                                            <AiFillEye onClick={() => setShowPass(true)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />

                                    }
                                </div>
                                {errors.password && (
                                    <p className="text-red-500 text-sm ">{errors.password.message}</p>
                                )}
                            </div>
                            <div className={`flex flex-col pt-3 border-b w-full ${watch('confirmpassword') && ' border-b border-[#F79F15]'}`}>
                                <label className='font-semibold'>{t('c_password')}</label>
                                <div className='flex items-center'>
                                    <AiOutlineKey />
                                    <input type={showPass2 ? 'text' : 'password'} {...register('confirmpassword')} className='p-2  w-full focus:outline-none' placeholder={`${t('c_password')}`} />
                                    {
                                        showPass2 ?
                                            <AiFillEyeInvisible onClick={() => setShowPass2(false)} className={`${watch('confirmpassword') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                                            :
                                            <AiFillEye onClick={() => setShowPass2(true)} className={`${watch('confirmpassword') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />

                                    }
                                </div>
                                {errors.confirmpassword && (
                                    <p className="text-red-500 text-sm ">{errors.confirmpassword.message}</p>
                                )}
                            </div>


                            <div className='pt-6'>
                                {
                                    loading ?
                                        <Loader />
                                        :
                                        <button className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white'>{t('already_registered_singin')}</button>
                                }
                            </div>



                        </div>
                    </form>
                </>
            }
        </>
    )
}

export default ForgotPassword