import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import ExploreBanner from '../../components/LandingComp/ExploreBanner'
import Explore from '../../components/LandingComp/Explore'
import Services from '../../components/LandingComp/Services'
import Rentals from '../../components/LandingComp/Rentals'
import Feature from '../../components/LandingComp/Feature'
// import Header from '../../components/Header/Header'
import SignUp from '../AuthPages/SignUp'



const Landing = () => {
  const [signUp, setSignUp] = useState(false)
  return (
    <Layout>
      {signUp && <SignUp modalOpen={signUp} onCloseModal={() => setSignUp(false)} />}
      <ExploreBanner setSignUp={setSignUp} />
      <Explore />
      <Services />
      <Rentals />
      <Feature />
    </Layout>
  )
}

export default Landing