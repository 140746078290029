import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddRommateIndex, IspopUp } from '../../../Redux/UserAuthSlice/RommateSlice'
import { useTranslation } from 'react-i18next'

const CheckPriorities = () => {

    // const { rommate  } = useSelector((state) => state?.rommate)
    const dispatch = useDispatch()

    const handleEdit = () => {
        dispatch(AddRommateIndex(3))
        dispatch(IspopUp(false))
    }
    const handleNext = () => {
        // if (rommate?.college_student === 1 && rommate?.on_campus === 1) {
            dispatch(AddRommateIndex(4))
            dispatch(IspopUp(false))
        // }
        // else {
        //     dispatch(AddRommateIndex(2))
        //     dispatch(IspopUp(false))
        // }
    }
    const { t } = useTranslation()
    return (
        <div className="p-3">
            <h3 className='text-center text-[16px] font-bold'>{t('all_question')}</h3>
            <p className='p-3'>{t('question_filter')}</p>
            <div className='p-3 flex justify-between items-center text-[16px] font-bold cursor-pointer'>
                <p onClick={() => handleEdit()}>{t('edit_ans')}</p>
                <p onClick={() => handleNext()}>{t('continue')}</p>
            </div>
        </div>
    )
}

export default CheckPriorities

