import React, { useState, useEffect, useRef } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { MdClose } from 'react-icons/md';
import logo from '../../assests/images/logobg.png'
import pic1 from '../../assests/images/Group 4200.png'
import pic2 from '../../assests/images/Group 4201.png'
import pic3 from '../../assests/images/Group 4202.png'
import flash from '../../assests/images/f5.png'
import reasy from '../../assests/images/f6.png'
import { FiCheck } from 'react-icons/fi'
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { SechedualAppointment, SaveForLater, GetTimeSlot, SubmitRentalAppointment, CheckRentalApp } from '../../lib/Api/RentalAppApi';
import { Loader } from '../loaderButton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AddIndex, AddRentalAppUser, SendRent } from '../../Redux/UserAuthSlice/RentalSlice';
import DynamicPopup from './DynamicPopup';
import IdVerified from '../../components/GenericComp/IdVerified';
const LikeDislikePopup = ({ permition, Toggle, type, propertyType, property, setShowPost, setIsCredit, setPurchaseType, setIsLogIn }) => {
    const [show] = useState(permition)
    const [step, setStep] = useState(0)
    const [step2type, setStep2type] = useState('')

    // const currentDate = new Date().toISOString().split('T')[0];
    // const [secduleDate, setSecduleDate] = useState(currentDate)

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const today = currentDate.getDate();

    // Calculate the first day of next month
    const nextMonthDate = new Date(currentYear, currentMonth, today);
    const nextYear = nextMonthDate.getFullYear();
    const nextMonth = nextMonthDate.getMonth() + 1; // Months are zero-based, so add 1

    // Format the dates in 'yyyy-mm-dd' format
    const minDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${today.toString().padStart(2, '0')}`;
    const maxDate = `${nextYear}-${nextMonth.toString().padStart(2, '0')}-${today.toString().padStart(2, '0')}`;
    const defaultDate = minDate;

    const [secduleDate, setSecduleDate] = useState(defaultDate);

    const [secduleTime, setSecduleTime] = useState('')
    const [activeTime, setActiveTime] = useState(null)
    const [timeSlot, setTimeSlots] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [timeloading, setTimeLoading] = useState(false)
    const [showVerify, setShowVerify] = useState(false)
    const [load, setLoad] = useState(false)


    const handleClose = () => {
        Toggle(false)
    };
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { rentAppSend } = useSelector((state) => state?.rental)
    const { token } = useSelector((state) => state?.userAuth?.loginInfo)
    const isVerify = useSelector((state) => state.userAuth.userInfo?.idVerified)


    let fetchData = async (changeFormate) => {
        let payload = {
            "date_of_appointment": changeFormate,
        }
        if (propertyType === "rommate") {
            payload.roommate_id = property?.id
        }
        else {
            payload.property_id = property?.id
        }

        setTimeLoading(true)
        let { res } = await GetTimeSlot(payload)
        let obj = Object.assign({}, ...res)
        setTimeSlots(obj?.available_times)
        if (res) {
            setTimeLoading(false)
        }
    }


    const handleDateChange = (date) => {
        let changeFormate = moment(date).format('MM/DD/YYYY')
        let inputValue = moment(date).format('YYYY-MM-DD')
        setSecduleDate(inputValue);
        fetchData(changeFormate)


    };

    const handleSecdule = async () => {
        if (token) {
            let changeFormate = moment(secduleDate).format('MM/DD/YYYY')
            setLoading(true)
            let payload = {
                // property_id: property.id,
                // appointment_type: step2type,
                date_of_appointment: changeFormate,
                time_of_appointment: secduleTime
            }
            if (propertyType === "rommate") {
                payload.roommate_id = property?.id
                payload.appointment_type=  "in-person"
                payload.match = property?.match_int

                
            }
            else {
                payload.property_id = property?.id
                payload.appointment_type = step2type

            }
            let { res, error } = await SechedualAppointment(payload)
            if (res) {
                setLoading(false)
                setStep(4)
            }
            else if (error) {
                setLoading(false)
            }
        }
        else {
            setIsLogIn(true)
        }
    }


    let sendRent = async () => {
        if (token) {
            if (isVerify === null) {
                setShowVerify(true)
            }
            else {
                setLoadingSubmit(true)
                let payload = {
                }
                if (propertyType === "rommate") {
                    payload.roommate_id = property?.id
                }
                else {
                    payload.property_id = property?.id || rentAppSend?.id
                }
                let { res, error } = await SubmitRentalAppointment(payload)
                if (res) {
                    setLoadingSubmit(false)
                    dispatch(SendRent({}))
                    handleClose()
                }
                else if (error) {
                    setLoadingSubmit(false)
                }
            }
        }
        else {
            setIsLogIn(true)
        }
    }

    const handleSaveLater = async () => {
        if (token) {
            if (isVerify === null) {
                setShowVerify(true)
            }
            else {
                setLoading(true)
                let payload = {
                    // property_id: property?.id,
                    list: "saved"
                }
                if (propertyType === "rommate") {
                    payload.roommate_id = property?.id
                    payload.match = property?.match_int
                }
                else {
                    payload.property_id = property?.id
                }
                let { res, error } = await SaveForLater(payload)
                if (res) {
                    setLoading(false)
                    handleClose()
                }
                else if (error) {
                    setLoading(false)
                }
            }
        }
        else {
            setIsLogIn(true)
        }
    }

    let SendMessage = () => {
        if (isVerify === null) {
            setShowVerify(true)
        }
        else {
            dispatch(AddRentalAppUser(property?.user))
            handleSaveLater()
            navigate(`/message/${property?.user?.id}`)
        }
    }




    const handleSubmitRental = async () => {
        if (token) {
            if (isVerify === null) {
                setShowVerify(true)
            }
            else {
                setLoadingSubmit(true)
                let { response } = await CheckRentalApp()
                if (response?.purchased === true) {
                    let credetCheck = response?.rental_application?.credits
                    const isAnyFlagFalse = Object.values(response?.status).some(flag => flag === false);
                    if (isAnyFlagFalse) {
                        dispatch(AddIndex(2))
                        navigate('/rentalApp')
                        setLoadingSubmit(false)
                    }
                    else if (credetCheck === 0) {
                        dispatch(SendRent({ id: property?.id, type: "explore" }))
                        setIsCredit(true)
                        setLoadingSubmit(false)
                    }
                    else {
                        sendRent()
                    }
                }

                else {
                    setPurchaseType("notPurchsed")
                    setIsCredit(true)
                    setLoadingSubmit(false)

                }
            }
        }
        else {
            setIsLogIn(true)
        }
    }


    const handleVideoEnd = () => {
        // Perform actions or trigger functions when the video ends
        //console.log('Video ended! Triggering other function...');
        // Call your other function here
        setShowPost(true)
        Toggle(false)

    };

    const VideoPlayer = ({ source, autoPlay }) => {
        const videoRef = useRef(null);
        let player = null;

        useEffect(() => {
            // Initialize the Video.js player when the component mounts
            player = videojs(videoRef.current, {
                autoplay: autoPlay,

                controls: true, // You can customize this as needed
                sources: [
                    {
                        src: source,
                        type: 'video/mp4', // Adjust the type based on your video format
                    },
                ],
            });
            player.on('ended', handleVideoEnd);
            return () => {
                // Clean up the Video.js player when the component unmounts
                if (player) {
                    player.dispose();
                }
            };
        }, [source, autoPlay]);

        return (
            <div data-vjs-player>
                <video ref={videoRef}
                    width="640"
                    preload='auto'
                    height="360"
                    className="video-js vjs-default-skin" />
            </div>
        );
    };


    const handlePersonal = () => {
        if (token) {
            setStep(2)
            setStep2type('in-person')
        }
        else {
            setIsLogIn(true)
        }
    }

    const handlevertiual = () => {
        if (token) {
            setStep(3)
            setStep2type('virtual')
        } else {
            setIsLogIn(true)

        }
    }

    const handleStep = () => {

        if (token) {
            if (isVerify === null) {
                setShowVerify(true)
            }
            else if(propertyType === "rommate"){
                setStep(2)
            }
            else{
                setStep(1)

            }
        }
        else {
            setIsLogIn(true)
        }
    }



    useEffect(() => {
        if (step === 2) {
            let changeFormate = moment(currentDate).format('MM/DD/YYYY')
            fetchData(changeFormate)
        }
    }, [step])

    useEffect(() => {
        if (rentAppSend?.id) {
            sendRent()
        }
    }, [])



    const { t } = useTranslation()

    return (
        <div>
            {showVerify && <DynamicPopup modalOpen={showVerify} data={<IdVerified setLoading={setLoad} close={() => setShowVerify(false)} />} close={() => setShowVerify(false)} />}
            <Modal open={show} onClose={() => handleClose()} center>
                {
                    type === "dislike" ?
                        <div className='p-20' >
                            <h1 className='text-[30px] font-bold'> {t('are_you_sure')} </h1>
                            <p className='text-[15px] font-samibold mt-2'> {t('del_this')} </p>
                            <div className='flex flex-col'>
                                <button onClick={() => handleClose()} className='bg-blue-500 w-[100%] py-2 text-white'>{t('yes')} </button>
                                <button onClick={() => handleClose()} className='bg-red-500 py-2 my-2 text-white'>{t('no')}</button>
                            </div>
                        </div>
                        :
                        <div className={`relative ${timeSlot?.length > 0 ? '' : 'h-[500px]'}`} >
                            <div className=' flex justify-end p-3' onClick={() => handleClose()}>

                                <MdClose className='border rounded-md text-[30px] cursor-pointer' />
                            </div>
                            {
                                step === 0 &&
                                <div className='xl:px-10 lg:px-10 md:px-10 px-5 pb-5'>
                                    <div className='flex items-center justify-center'>
                                        <img src={logo} alt="scdule" className='w-[20%] object-cover' />
                                    </div>
                                    <ul>
                                        <li className="border-b cursor-pointer h-full pt-4">
                                            <div className='flex items-center' onClick={() => handleStep()}>
                                                <img src={pic1} alt="scdule" className='w-[20%] object-cover' />
                                                <h2 className='xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] mx-2 font-semibold'>{t('sch_appoint')}</h2>
                                            </div>
                                        </li>
                                        {propertyType === "rommate" ?
                                            <li className="border-b cursor-pointer h-full pt-4" onClick={() => SendMessage()}>
                                                <div className='flex items-center'>
                                                    <img src={pic2} alt="scdule" className='w-[20%] object-cover' />
                                                    <h2 className='xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] mx-2 font-semibold'>{t('send_message')}</h2>

                                                </div>
                                            </li>
                                            :
                                            <li className="border-b cursor-pointer h-full pt-4" onClick={() => handleSubmitRental()}>
                                                <div className='flex items-center'>
                                                    <img src={pic2} alt="scdule" className='w-[20%] object-cover' />
                                                    {
                                                        loadingSubmit ?
                                                            <Loader type="loadData" />
                                                            :
                                                            <h2 className='xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] mx-2 font-semibold'>{t('sub_appoit')}</h2>
                                                    }
                                                    {/* <h2 className='xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] mx-2 font-semibold'>{t('sub_appoit')}</h2> */}
                                                </div>
                                            </li>

                                        }
                                        <li onClick={() => handleSaveLater()} className="border-b cursor-pointer h-full pt-4">
                                            <div className='flex items-center'>
                                                <img src={pic3} alt="scdule" className='w-[20%] object-cover' />
                                                {
                                                    loading ?
                                                        <Loader type="loadData" />
                                                        :
                                                        <h2 className='xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] mx-2 font-semibold'>{t('save_later')}</h2>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                    <div className=' py-3 '>
                                        <h1 className='text-center font-semibold'>{t('need_fast')}</h1>
                                        <p className={`text-[16px]  text-center`}>{t('try_out_pass')}.</p>
                                        <div className='flex justify-between items-center'>
                                            <div className=' text-white rounded-md py-2 bg-gradient-to-r from-[#FFC000] to-orange-500 max-w-[190px] m-auto my-4'>
                                                <div className='flex items-center'>
                                                    <img src={flash} alt="scdule" className='w-[20%] mx-2 object-cover' />
                                                    {t('flash_lane')}
                                                </div>
                                            </div>
                                            <div className={`flex items-center ml-2 text-white rounded-md py-2 bg-gradient-to-r from-[#E63ECD] to-[#B913F0] max-w-[180px]  m-auto my-4`}>
                                                <img src={reasy} alt="scdule" className='w-[20%] mx-2 object-cover' />
                                                {t('r_eazy')}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                            {
                                step === 1 &&
                                <div className='xl:px-10 lg:px-10 md:px-10 px-5 pb-5'>

                                    <div onClick={() => handlePersonal()} className='border cursor-pointer p-5 w-[200px] text-center font-semibold bg-white shadow-md rounded-md'>
                                        {t('in_person')}
                                    </div>
                                   
                                        <div onClick={() => handlevertiual()} className='border cursor-pointer my-5 p-5 w-[200px] text-center font-semibold bg-white shadow-md rounded-md'>
                                            {t('virtual')}
                                        </div>
                                    

                                </div>
                            }
                            {
                                step === 2 &&
                                <div className={` xl:px-10 lg:px-10 md:px-10 px-5 pb-5`}>

                                    <div className='border rounded-md flex items-center p-2 border-[#F79F15]'>
                                        <h2 className='font-semibold w-[40%] '>{t('slect_dat')}</h2>
                                        <input type="date"
                                            value={secduleDate}
                                            pattern="\d{1,2}/\d{1,2}/\d{4}"
                                            min={minDate}
                                            max={maxDate}
                                            onChange={(e) => handleDateChange(e.target.value)}
                                            className='mx-2 border w-full p-2 focus:outline-none' />


                                    </div>
                                    {
                                        timeloading ?
                                            <div className='pt-5'>
                                                <Loader type="loadData" />
                                            </div>
                                            :
                                            <>
                                                {timeSlot?.length > 0 &&
                                                    <h2 className='font-semibold py-4'>{t('slect_time')}</h2>
                                                }
                                                <div className='flex flex-wrap xl:justify-between md:justify-between lg:justify-between xl:max-w-[400px]  lg:max-w-[400px] md:max-w-[400px]  items-center'>
                                                    {
                                                        timeSlot.map((t, i) => (
                                                            <div onClick={() => { return setActiveTime(i), setSecduleTime(t?.booked === false ? t?.time : "") }} className={`border relative rounded-md xl:w-[90px] lg:w-[90px] md:w-[90px] w-[80px] xl:mx-0 lg:mx-0 md:mx-0 mx-1 text-[14px] cursor-pointer text-center py-2 my-2 ${activeTime === i && t?.booked === false ? 'bg-[#F79F15] text-white' : 'bg-white text-[#F79F15] border border-[#F79F15]'}`} key={i}>{t?.time}
                                                                {t?.booked ?
                                                                    <div className='absolute top-1 left-7'>
                                                                        <MdClose size={30} className='text-black' />
                                                                    </div>
                                                                    : ""}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center xl:gap-0 lg:gap-0 md:gap-0 gap-2 pt-3'>
                                                    <div className='flex items-center gap-2'>
                                                        <div className='border flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                                            <MdClose size={20} />
                                                        </div>
                                                        <p className='text-[16px] font-medium'>{t('booked')}</p>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <div className='border border-[#F79F15] flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                                        </div>
                                                        <p className='text-[16px] font-medium'>{t('avail')}</p>
                                                    </div>
                                                    <div className='flex items-center gap-2'>
                                                        <div className='border flex justify-center items-center rounded-md w-[30px] bg-[#F79F15] h-[30px]'>
                                                        </div>
                                                        <p className='text-[16px] font-medium'>{t('selected')}</p>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                    {timeSlot?.length > 0 &&
                                        <div className='flex justify-center items-center mt-5'>

                                            {
                                                loading ?
                                                    <Loader type="saved" />
                                                    :
                                                    <button disabled={secduleTime !== "" ? false : true} onClick={() => handleSecdule()} className='text-center disabled:bg-opacity-[0.5] rounded-md bg-[#F79F15] w-full py-2 text-white'>{t('done')}</button>
                                            }
                                        </div>
                                    }

                                </div>
                            }
                            {
                                step === 3 &&
                                <div className='px-10 w-full' style={{ height: '300px' }}>
                                    <VideoPlayer source={property?.video} autoPlay={true} />
                                    {/* {
                                        property?.video ?
                                        <video
                                            ref={videoRef}
                                            className="video-js"
                                            width="640"
                                            height="360"
                                        >
                                            
                                            <source src={property?.video} type="video/mp4" />
                                        </video>
                                        :
                                        <div className='xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] font-semibold'> {t('video_labl')}</div>
                                    } */}
                                </div>
                            }
                            {
                                step === 4 &&
                                <div className='px-10 pb-5'>
                                    <div className='bg-gray-100 rounded-md p-5 '>
                                        <div className='flex justify-center items-center'>
                                            <div className='border bg-green-600 rounded-full p-3'>
                                                <FiCheck className="text-[30px] text-white" />
                                            </div>
                                        </div>
                                        <h2 className='text-center pt-5 font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px]'>{t('deliver')}</h2>
                                        <p className='max-w-[300px] m-auto text-center'>{
                                            propertyType === "rommate" ? t('deliver_thank_you_roomate') :
                                                t('deliver_thank_you')}</p>

                                    </div>
                                </div>
                            }
                        </div>


                }
            </Modal>


        </div >
    )
}

export default LikeDislikePopup
