import React, { useEffect, useState } from 'react'
import { Loader } from '../utils/loaderButton'
import { useTranslation } from 'react-i18next'
import { ChangeNotificationStil, DeleteRommate, GetSingleProperty, NotInterestedProperty, PenddingSendLease, RequestRentalApp } from '../lib/Api/RantelServiceApi'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmRequestLease, NotInterestPropertyRenter, SubmitRentalAppointment } from '../lib/Api/RentalAppApi'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { setRemoveId, setUploadLease, setUploadLeaseData } from '../Redux/UserAuthSlice/UserAuthSlice'
import PostAppHowWork from '../components/GenericComp/PostAppHowWork'
const NotificationChecker = ({ setShow, data, setType }) => {
    const [isPermssion, setIsPermission] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [detail, setDetail] = useState({})
    const [types, setTypes] = useState("")
    const { t } = useTranslation()

    const user = useSelector((state) => state?.userAuth.userInfo)
    const uploadlease = useSelector((state) => state?.userAuth.isNotLeasedBuild)


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const id = useParams().id
    const location = useLocation().pathname



    const handleUploadLease = () => {
        dispatch(setUploadLeaseData(data))
        setShow(false)
        dispatch(setUploadLease(true))
        navigate(`/createBuilder/${detail?.id}`)
    }


    const NotificationInactive = async (action) => {
        setIsPermission(true)
        dispatch(setRemoveId(data?.property_id))
        let payload = {
            property_id: data?.property_id,
            active: action
        }
        let res = await ChangeNotificationStil(payload)
        if (res) {
            setShow(false)
            setIsPermission(false)

        }
    }

    const NotificationRoomate = async () => {
        setLoading(true)
        let res = await DeleteRommate(Number(data?.property_id))
        if (res) {
            setLoading(false)
            setShow(false)

        }
    }
    const getPropertyDetial = async () => {
        setIsPermission(true)
        let res = await GetSingleProperty(Number(data?.property_id))
        if (res) {
            setIsPermission(false)
            setDetail(res[0]?.property)
        }
        else {
            setIsPermission(false)
        }
    }
    // Servicer 
    const sendLease = async () => {

        if (user?.userRole === "servicer") {
            let payload = {
                "list_id": data?.model_data?.list_id
            }
            setTypes("lease")
            setLoadingSubmit(true)
            let res = await PenddingSendLease(payload)
            if (res) {
                setLoadingSubmit(false)
                setShow(false)
            }
        }
        else {
            sendApp()
        }
    }

    const RequestApp = async () => {
        if (user?.userRole === "servicer") {
            let payload = {
                "property_id": data?.property_id
            }
            setTypes("request")
            setLoadingSubmit(true)
            let res = await RequestRentalApp(payload)
            if (res) {
                setLoadingSubmit(false)
                setShow(false)
            }
        }
        else {
            setType("appointment")
            // setShow()
        }
    }

    const RequestLease = async () => {
        let payload = {
            "property_id": data?.property_id
        }
        setTypes("leaseReq")
        setLoadingSubmit(true)
        let res = await ConfirmRequestLease(payload)
        if (res) {
            setLoadingSubmit(false)
            setShow(false)
        }

    }
    const NotInterested = async () => {
        if (user?.userRole === "servicer") {
            let payload = {
                "list_id": data?.model_data.list_id
            }
            setTypes("notInterested")
            setLoadingSubmit(true)
            let res = await NotInterestedProperty(payload)
            if (res) {
                setLoadingSubmit(false)
                setShow(false)
            }
        }
        else {
            NotInterestedRenter()
        }
    }

    //Renter
    const sendApp = async () => {
        let payload = {
            "property_id": data?.property_id
        }
        setTypes("lease")
        setLoadingSubmit(true)
        let res = await SubmitRentalAppointment(payload)
        if (res) {
            setLoadingSubmit(false)
            setShow(false)
        }
    }



    const NotInterestedRenter = async () => {
        let payload = {
            "property_id": data?.property_id
        }
        setTypes("notInterested")
        setLoadingSubmit(true)
        let res = await NotInterestPropertyRenter(payload)
        if (res) {
            setLoadingSubmit(false)
            setShow(false)
        }
    }




    useEffect(() => {
        if (user?.userRole !== "renter") {
            getPropertyDetial()
        }
    }, [])

    useEffect(() => {
        if (location === `/createBuilder/${id}` && uploadlease === true) {
            sendLease()
            dispatch(setUploadLease(false))
        }
    }, [])

    // //console.log("data", data)


    return (
        <div className='p-5 bg-white'>
            {
                isPermssion ?
                    <>
                        <Loader type="loadData" />
                    </>
                    :
                    data.screen === "post_appointment_alert" ?
                        <>
                            <h2 className='text-[18px] font-medium max-w-[500px] mx-auto'>{data?.title}</h2>
                            <h2 className='text-[14px] font-medium '>{detail?.complete_address}</h2>
                            <div className='flex justify-end items-end gap-5 pt-5'>
                                <button onClick={() => NotificationInactive(false)} className='w-full  py-1 bg-blue-500 text-white text-[18px] rounded-md'>{t('No')}</button>
                                <button onClick={() => NotificationInactive(true)} className='w-full  py-1 bg-[#F79F15] text-white text-[18px] rounded-md'>{t('yes')}</button>
                            </div>
                        </>
                        :
                        data.screen === "post_appointment_roommate_alert" ?
                            <>
                                <h2 className='text-[18px] text-center font-medium max-w-[500px] mx-auto'>{data?.title}</h2>
                                <h2 className='text-[14px] font-medium '>{t('select_yes')}</h2>
                                <h2 className='text-[14px] font-medium '>{t('select_no')}</h2>
                                <div className='flex justify-end items-end gap-5 pt-5'>
                                    <button onClick={() => setShow(false)} className='w-full  py-1 bg-blue-500 text-white text-[18px] rounded-md'>{t('No')}</button>
                                    <button onClick={() => NotificationRoomate()} className='w-full  py-1 bg-[#F79F15] text-white text-[18px] rounded-md'>{loading  ? <Loader tyep="saveLoder"/> : t('yes')}</button>
                                </div>
                            </>
                            :
                            data?.screen === "post_appointment_roommate" ?
                                <>
                                    <PostAppHowWork property={data} setShow={setShow} />
                                </>
                                :
                                <>
                                    <h2 className='text-[18px] text-center font-medium max-w-[500px] mx-auto'>{t('how_did')}</h2>
                                    <div>
                                        <ul>
                                            <li className="  cursor-pointer h-full pt-4">
                                                <div
                                                    onClick={() => detail?.lease_built === null ? handleUploadLease() : sendLease()}
                                                    className='flex items-center border py-2 rounded-md bg-[#F79F15] text-white px-2' >
                                                    {
                                                        loadingSubmit && types === "lease" ?
                                                            <Loader />
                                                            :
                                                            user?.userRole === "renter" ?
                                                                <h2 className='text-[16px]  mx-2 font-semibold'>{t('send_rent_ap')}
                                                                </h2>
                                                                :
                                                                <h2 className='text-[16px]  mx-2 font-semibold'>{t('send_lease')}
                                                                </h2>
                                                    }
                                                </div>
                                            </li>
                                            <li className=" cursor-pointer h-full pt-4">
                                                <div onClick={() => RequestApp()} className='flex items-center border py-2 bg-[#F79F15] text-white rounded-md px-2' >
                                                    {
                                                        loadingSubmit && types === "request" ?
                                                            <Loader />
                                                            :
                                                            user?.userRole === "renter" ?
                                                                <h2 className='text-[16px]  mx-2 font-semibold'>
                                                                    {t('reschedule_App')}
                                                                </h2>
                                                                :
                                                                <h2 className='text-[16px]  mx-2 font-semibold'>
                                                                    {t('snd_req_rnt_ap')}
                                                                </h2>
                                                    }

                                                </div>
                                            </li>
                                            {user?.userRole === "renter" &&
                                                <li className=" cursor-pointer h-full pt-4">
                                                    <div onClick={() => RequestLease()} className='flex items-center border py-2 bg-[#F79F15] text-white rounded-md px-2' >
                                                        {
                                                            loadingSubmit && types === "leaseReq" ?
                                                                <Loader />
                                                                :
                                                                <h2 className='text-[16px]  mx-2 font-semibold'>
                                                                    {t('req_lease')}
                                                                </h2>
                                                        }

                                                    </div>
                                                </li>
                                            }


                                            <li className=" cursor-pointer h-full  pt-4">
                                                <button onClick={() => user?.userRole === "servicer" ? NotInterested() : NotInterestedRenter()} className='flex items-center w-full justify-center py-2 px-2  text-black border rounded-md border-[#F79F15]'>
                                                    {loadingSubmit && types === "notInterested" ?
                                                        <Loader type="loadData" />
                                                        :
                                                        <h2 className='text-[16px] mx-2 font-semibold'>{t('not_int')}</h2>
                                                    }
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </>
            }

        </div >
    )
}

export default NotificationChecker