import React, { useState, useEffect } from 'react'
import { GetPropertyAmenities, UpdateRental } from '../../../../lib/Api/RantelServiceApi'
import { Loader } from '../../../../utils/loaderButton'
import { useTranslation } from 'react-i18next'

const EditAminty = ({ close, data , setEditType }) => {
    const [amenities, setAmenities] = useState([])
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [lists, setLists] = useState(data?.amenities)

    const onSubmit = async (e) => {
        e.preventDefault()
        const obj = data;

        obj.amenities = lists;
        let payload = {
            ...obj,

        }
        setLoader(true)
        data.images = null

        let { res, error } = await UpdateRental(payload)
        if (res) {
            setLoader(false)
            close(false)
            setEditType('')
        }
        else if (error) {
            setLoader(false)

        }
    }


    const HandleChecks = (id) => {
        let find = lists?.find((f) => f.id === id)
        if (find) {
            let l = lists.filter((f) => f.id !== id)
            setLists(l)
        }
        else {
            setLists((prev) => ([...prev, id]))
        }

    }


    // //console.log("lists", lists)


    useEffect(() => {

        let getType = async () => {
            setLoading(true)
            let { res } = await GetPropertyAmenities()
            const ConvertObj = Object.assign({}, ...res)
            if (res) {
                setLoading(false)
                setAmenities(ConvertObj.amenities)

            }
            else {
                setLoading(false)
            }
        }
        getType()

    }, [])
    

    const {t } = useTranslation()

    return (
        <div className='p-5 max-w-[600px]'>
            {
                loading ? <Loader type="loadData" />
                    :
                    <form onSubmit={onSubmit}>
                        <div className='flex flex-wrap items-center overflow-auto'>
                            {
                                amenities?.map((c, i) => {
                                    let d = data?.amenities.find((f) => f.id === c.id)
                                    return (
                                        <div className='flex items-center mx-1' key={i}>
                                            <input id={c?.id} type="checkbox" onChange={() => HandleChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                                            <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.name}</label>
                                        </div>
                                    )
                                })
                            }

                        </div>

                        <div className='flex items-center py-5 justify-end '>
                            <button onClick={() => close(false)} className='w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>{t('cancel')}</button>
                            {
                                loader ? <div className='ml-5'><Loader type="saved" /></div>
                                    :
                                    <button className='w-[138px] py-2 ml-5 rounded-md bg-[#FF8C00] text-white'>{t('save')}</button>
                            }
                        </div>
                    </form>
            }
        </div>
    )
}

export default EditAminty