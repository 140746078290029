import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useSelector } from 'react-redux';





const Condition = ({ State, handleNext, handleBack, t }) => {
  const schema = yup.object({
    owner_email: yup.string().email(t('invalid_email')).required(t('owner_email')),
    professional_license: yup.string(),
    owner_name: yup.string().required(t('owner_name_lab')),
    company_name: yup.string(),
  });

  const schema3 = yup.object({
    yes_owner_email: yup.string().email(t('invalid_email')).required(t('owner_email')),
    yes_realtor_email: yup.string().email(t('invalid_email')).required(t('reltor_email')),
    yes_realtor_license: yup.string(),
    professional_license: yup.string(),
    company_name: yup.string(),
    yes_owner_name: yup.string().required(t('owner_name')),
    yes_realtor_name: yup.string().required(t('reltor_email')),
    yes_sponsoring_broker: yup.string(),
  });

  const schema2 = yup.object({
    realtor_email: yup.string().email(t('invalid_email')).required(t('emial_req')),
    realtor_license: yup.string().required(t('realtor_lab_req')),
    realtor_name: yup.string().required(t('reltor_name')),
    sponsoring_broker: yup.string().required(t('sponser_broker_required'))
  });

  const [active, setActive] = useState(0)
  const [isDisabled, setIsDisabaled] = useState({ name: true, email: true })

  const user = useSelector((state) => state.userAuth?.userInfo)

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(State.lister_type === "owner" && active === 1 ? schema3 : State.lister_type === "owner" && active === 0 ? schema : schema2) });


  const OnSubmit = (data) => {
    let payload = {
      "listed_with_realtor": active,
      "owner_name": data.owner_name,
      "owner_email": data.owner_email,
      "professional_license": data.professional_license,
      "company_name": data.company_name,
    }

    let payload2 = {
      "listed_with_realtor": active,
      "realtor_name": data.yes_realtor_name,
      "realtor_email": data.yes_realtor_email,
      "realtor_license": data.yes_realtor_license,
      "sponsoring_broker": data.yes_sponsoring_broker,
      "owner_name": data?.yes_owner_name,
      "owner_email": data?.yes_owner_email,
      "company_name": data?.company_name,
      "professional_license": data?.professional_license,
    }
    let payload3 = {
      "listed_with_realtor": active,
      "realtor_name": data.realtor_name,
      "realtor_email": data.realtor_email,
      "realtor_license": data.realtor_license,
      "sponsoring_broker": data.sponsoring_broker,
    }
    handleNext(State.lister_type === "owner" && active === 0 ? payload : State.lister_type === "owner" && active === 1 ? payload2 : payload3)

  }

  const handleChangeToggle = (e) => {
    if (e.target.checked === true) {
      setActive(1)
    }
    else {
      setActive(0)

    }

  }


  useEffect(() => {
    if (State?.listed_with_realtor) {
      setActive(State?.listed_with_realtor)
    }

    else {
      if (Object.keys(State).length > 1) {
        reset(State)
        setActive(0)
      }
      else {
        if (State.lister_type === "owner") {
        
          let payload = { owner_name: user.fname, owner_email: user?.email }
          let payload2 = { yes_owner_name: user.fname, yes_owner_email: user?.email }
          if (user.fname  === "") {
            setIsDisabaled((prev) => ({
              ...prev,
              name: false
            }))
          }
          if (user?.email === "") {
            setIsDisabaled((prev) => ({
              ...prev,
              email: false
            }))
          }

          reset(active === 0 ? payload : payload2)
        }
        else {
          let payload3 = { realtor_name: user.fname, realtor_email: user?.email }
          reset(payload3)
          
          if (user.fname === "") {
            setIsDisabaled((prev) => ({
              ...prev,
              name: false
            }))
          }
          if (user?.email === "") {
            setIsDisabaled((prev) => ({
              ...prev,
              email: false
            }))
          }
        }
      }
    }
  }, [active])


  // let { owner_email, realtor_name, realtor_license, sponsoring_broker } = formData

  return (
    <>

      <h2 className='text-[20px] font-semibold'>{t('have_you')}</h2>
      {
        State.lister_type === "owner" &&

        <label className="relative inline-flex items-center cursor-pointer mt-2">
          <input type="checkbox" value={active === 1 ? true : false} onChange={handleChangeToggle} className="sr-only peer" />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#F79F15] dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>
          <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 px-3">
            {active === 1 ? t('yes') : t('no')}
          </span>
        </label>
        // <div className='flex my-2'>
        //   <div className='flex items-center mx-2'>
        //     <input id="radio_no" className='w-[20px] h-[20px]' onClick={() => setActive(0)} type="radio" checked={active === 0 ? true : false} name="no" />
        //     <label htmlFor='radio_no' className='px-2 text-[18px]'>{t('no')}</label>
        //   </div>
        //   <div className='flex items-center'>
        //     <input id="radio_yes" className='w-[20px] h-[20px]' onClick={() => setActive(1)} type="radio" checked={active === 1 ? true : false} name="yes" />
        //     <label  htmlFor='radio_yes' className='px-2 text-[18px]'>{t('yes')}</label>
        //   </div>

        // </div>
      }
      {

        State.lister_type === "owner" ?
          <form onSubmit={handleSubmit(OnSubmit)}>
            {active === 1 &&
              <>
                <div>
                  <h2 className='text-xl pt-4'>{t('realtor_info')}</h2>
                  <div className='mt-6'>
                    <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('reltor_name')}</label>
                        <input type="text" placeholder={t('reltor_name')} {...register('yes_realtor_name')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                        {errors.yes_realtor_name && (
                          <p className="text-red-500 text-sm ">{errors.yes_realtor_name.message}</p>
                        )}
                      </div>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('reltor_licence')} </label>
                        <input type="text" placeholder={t('reltor_licence')} {...register('yes_realtor_license')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                        {errors.yes_realtor_license && (
                          <p className="text-red-500 text-sm ">{errors.yes_realtor_license.message}</p>
                        )}
                      </div>
                    </div>
                    <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'> {t('realtor_email_lab')} </label>
                        <input type="email" placeholder={t('realtor_email_lab')} {...register('yes_realtor_email')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                        {errors.yes_realtor_email && (
                          <p className="text-red-500 text-sm ">{errors.yes_realtor_email.message}</p>
                        )}
                      </div>
                      <div className='flex flex-col mb-4'>
                        <label className='mb-2 text-[16px] font-semibold'>{t('sponser_broker')}</label>
                        <input type="text" placeholder={t('sponser_broker')} {...register('yes_sponsoring_broker')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                        {errors.yes_sponsoring_broker && (
                          <p className="text-red-500 text-sm ">{errors.yes_sponsoring_broker.message}</p>
                        )}
                      </div>
                    </div>


                  </div>
                </div>
                <div className='mt-6'>
                  <h2 className='text-xl py-4'>{t('owner_info')}</h2>
                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('owner_name_lab')}</label>
                      <input type="text" disabled={isDisabled?.name} placeholder={t('owner_name_lab')} {...register('yes_owner_name')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                      {errors.yes_owner_name && (
                        <p className="text-red-500 text-sm ">{errors.yes_owner_name.message}</p>
                      )}
                    </div>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('professional')}</label>
                      <input type="text" placeholder={t('professional')} {...register('professional_license')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                      {/* {errors.professional_license && (
                        <p className="text-red-500 text-sm ">{errors.professional_license.message}</p>
                      )} */}
                    </div>
                  </div>
                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'> {t('owner_email_lab')}</label>
                      <input disabled={isDisabled?.email} type="email" placeholder={t('owner_email_lab')} {...register('yes_owner_email')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                      {errors.yes_owner_email && (
                        <p className="text-red-500 text-sm ">{errors.yes_owner_email.message}</p>
                      )}
                    </div>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('association')}</label>
                      <input type="text" placeholder={t('association')} {...register('company_name')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                      {/* {errors.company_name && (
                        <p className="text-red-500 text-sm ">{errors.company_name.message}</p>
                      )} */}
                    </div>
                  </div>


                </div>
              </>
            }
            {
              active === 0 &&
              <div className='mt-6'>
                <h2 className='text-xl py-4'>{t('owner_info')}</h2>

                <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'>{t('owner_name_lab')}</label>
                    <input type="text" disabled={isDisabled?.name} placeholder={t('owner_name_lab')} {...register('owner_name')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                    {errors.owner_name && (
                      <p className="text-red-500 text-sm ">{errors.owner_name.message}</p>
                    )}
                  </div>
                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'> {t('professional')} </label>
                    <input type="text" placeholder={t('professional')} {...register('professional_license')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                    {errors.professional_license && (
                      <p className="text-red-500 text-sm ">{errors.professional_license.message}</p>
                    )}
                  </div>
                </div>
                <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'> {t('owner_email_lab')}</label>
                    <input disabled={isDisabled?.email} type={t('owner_email_lab')} placeholder='doe' {...register('owner_email')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                    {errors.owner_email && (
                      <p className="text-red-500 text-sm ">{errors.owner_email.message}</p>
                    )}
                  </div>
                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'>{t('association')}</label>
                    <input type="text" placeholder={t('association')} {...register('company_name')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                    {errors.company_name && (
                      <p className="text-red-500 text-sm ">{errors.company_name.message}</p>
                    )}
                  </div>
                </div>


              </div>

            }


            <div className='flex justify-between py-3'>
              <button type='button' onClick={() => handleBack()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
              <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Next')}</button>
            </div>


          </form>
          :
          <form onSubmit={handleSubmit(OnSubmit)}>
            <div className='mt-6'>
              <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
                <div className='flex flex-col mb-4'>
                  <label className='mb-2 text-[16px] font-semibold'>{t('realtor_name_lab')} </label>
                  <input type="text" disabled={isDisabled?.name} placeholder={t('realtor_name_lab')} {...register('realtor_name')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                  {errors.realtor_name && (
                    <p className="text-red-500 text-sm ">{errors.realtor_name.message}</p>
                  )}
                </div>
                <div className='flex flex-col mb-4'>
                  <label className='mb-2 text-[16px] font-semibold'>{t('realtor_lab')}</label>
                  <input type="text" placeholder={t('realtor_lab')} {...register('realtor_license')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                  {errors.realtor_license && (
                    <p className="text-red-500 text-sm ">{errors.realtor_license.message}</p>
                  )}
                </div>
              </div>
              <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
                <div className='flex flex-col mb-4'>
                  <label className='mb-2 text-[16px] font-semibold'> {t('realtor_email_lab')} </label>
                  <input disabled={isDisabled?.email} type="email" placeholder={t('realtor_email_lab')} {...register('realtor_email')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                  {errors.realtor_email && (
                    <p className="text-red-500 text-sm ">{errors.realtor_email.message}</p>
                  )}
                </div>
                <div className='flex flex-col mb-4'>
                  <label className='mb-2 text-[16px] font-semibold'>{t('sponser_broker')}</label>
                  <input type="text" placeholder={t('sponser_broker')} {...register('sponsoring_broker')} className=' p-2 rounded-md bg-white border focus:outline-none' />
                  {errors.sponsoring_broker && (
                    <p className="text-red-500 text-sm ">{errors.sponsoring_broker.message}</p>
                  )}
                </div>
              </div>


            </div>

            <div className='flex justify-between py-3'>
              <button type='button' onClick={() => handleBack()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
              <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Next')}</button>
            </div>

          </form>
        // : null
      }

      {/* {active === false && State.lister_type === "owner" &&
        <div div className='flex justify-end py-3'>
          <button onClick={() => Submit()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{`${`Next >`}`}</button>
        </div>
      } */}
    </>
  )
}

export default Condition