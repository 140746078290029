import React from 'react'
import blog from '../../assests/images/blogright.png'
import blogRight from '../../assests/images/blogpink.png'
import blogUser from '../../assests/images/user.png'
const OurBlog = () => {
    return (
        <>
            <div className='bg-[#F5F6FC] h-full py-20'>
                <div className=' h-auto max-w-[910px] m-auto border rounded-2xl bg-white'>
                    <div className="relative overflow-hidden">
                        <img src={blog} className="  object-contain  w-full lg:hidden block" alt="blog" />
                        <div className='w-full flex flex-col lg:relative absolute top-0'>

                            <h1 className='underline uppercase leading-none ml-8 lg:py-9 pt-[5rem] md:pt-[10rem] font-bold lg:text-[5rem] text-[4rem] md:text-[8rem] flex flex-col'>
                                Our
                                <span className='text-[#F79F15] underline decoration-[#F79F15]'>Latest</span>
                                blog
                            </h1>
                            <p className='lg:max-w-[500px] ml-8  lg:p-2 pt-8  pb-6 lg:text-[15px] md:text-[2rem] lg:text-black text-white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting...</p>

                        </div>
                        <div className='absolute lg:flex justify-end right-0 top-0 hidden '>
                            <img  src={blog} className="  object-contain w-[57%]" alt="blog" />
                        </div>
                        <div className='absolute top-[13%] right-[18%] hidden lg:block'>

                            <img  src={blogRight} className="object-cover w-[70%] " alt="blog_right" />

                        </div>
                        <div className='absolute top-[16%] right-[19%] hidden lg:block'>
                            <img  src={blogUser} className="object-cover w-[70%] " alt="blog_right" />

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OurBlog