
import React, { useState } from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import SignUpForm from '../../components/AuthComp/SignUpForm';
// import VerifyPhone from '../../components/AuthComp/VerifyPhone';
import VerifyPhoneOtp from '../../components/AuthComp/VerifyPhoneOtp';
import { SignUpUser } from '../../lib/Api/UserApi';
import CheckRole from './RoleCheck';
import { stripePromise } from '../../config/Stripe'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SignUp = ({ modalOpen, onCloseModal, setSignIn }) => {
  const [active, setActive] = useState(0)
  const [result, setResult] = useState("");
  const [formData, setFormData] = useState({});
  const [permistion, setPermission] = useState(null)
  const [media, setMedia] = useState(true)
  const [userObject, setUserObject] = useState({})
  const [userSocial, setUserSocial] = useState("")
  const [mediaName, setMediaName] = useState(null)
  const language = useSelector((state) => state.userAuth?.language)

  const handleSubmitForm = async () => {
    formData.role = permistion
    let payload = {
      lang: language ? language : "",
      ...formData

    }
    let { res, error } = await SignUpUser(payload)
    if (res) {
      return res
    }
    else {
      return error
    }

  }

  const { t } = useTranslation()


  return (
    <>

      <Modal open={modalOpen} onClose={onCloseModal} classNames="!w-[100%]" size={"lg"} center>
        {media === false ?
          <>

            {
              active === 0 &&
              <SignUpForm
                setResult={setResult}
                setMedia={setMedia}
                setMediaName={setMediaName}
                onCloseModal={onCloseModal}
                formData={formData}
                mediaName={mediaName}
                setFormData={setFormData}
                permistion={permistion}
                setUserObject={setUserObject}
                userObject={userObject}
                userSocial={userSocial}
                setUserSocial={setUserSocial}
                setSignIn={setSignIn}
                setActive={setActive} />
              
            }
            {/* {
          active === 1 &&
          <VerifyPhone setActive={setActive} setResult={setResult} onCloseModal={onCloseModal} />
        } */}
            {
              active === 1 &&
              <VerifyPhoneOtp handleSubmitForm={handleSubmitForm} result={result} setActive={setActive} onCloseModal={onCloseModal} stripePromise={stripePromise} t={t} />
            }
          </>
          :
          <CheckRole title={t('select_role_text')} buttonTitle={t('continue')} close={onCloseModal} setMedia={setMedia} setPermission={setPermission} t={t} />
        }
      </Modal>
    </>
  )
}

export default SignUp