import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AddIndex } from '../../Redux/UserAuthSlice/RentalSlice'

const CreditConfirm = ({ close, purchaseType }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()


    const handleMove = () => {
        dispatch(AddIndex(1))
        navigate('/rentalApp')
    }

    const { t } = useTranslation()
    return (
        <div className='p-5'>
            {purchaseType !== "notPurchsed" ?
                <>
                    <h2 className='text-[18px] font-medium max-w-[500px] mx-auto'>{t('credit_out')}</h2>
                    <div className='flex justify-end items-end gap-5 pt-5'>
                        <button onClick={() => close()} className='w-[35%]  py-1 bg-blue-500 text-white text-[18px] rounded-md'>{t('cancel')}</button>
                        <button onClick={() => handleMove()} className='w-[35%]  py-1 bg-[#F79F15] text-white text-[18px] rounded-md'>{t('R_fill')}</button>
                    </div>
                </>
                :
                <>
                    <h2 className='text-[18px] font-medium max-w-[500px] mx-auto'>{t('need_purchase')}</h2>
                    <div className='flex justify-end items-end gap-5 pt-5'>
                        <button onClick={() => close()} className='w-[35%]  py-1 bg-blue-500 text-white text-[18px] rounded-md'>{t('cancel')}</button>
                        <button onClick={() => handleMove()} className='w-[35%]  py-1 bg-[#F79F15] text-white text-[18px] rounded-md'>{t('creat_purchase')}</button>
                    </div>
                </>
            }
        </div>
    )
}

export default CreditConfirm