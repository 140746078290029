import React, { useState } from 'react'
import listImg from '../../assests/clientImgs/Roommate-Renter2.jpeg'
import rommateImg from '../../assests/clientImgs/rommate.png'
import rommate2Img from '../../assests/clientImgs/Share Your Space.png'

import { useTranslation } from 'react-i18next'
import SignUp from '../../pages/AuthPages/SignUp'
import SignIn from '../../pages/AuthPages/SignIn'
import { useDispatch, useSelector } from 'react-redux'
import { addPopup } from '../../Redux/UserAuthSlice/RentalSlice'
import { Link } from 'react-router-dom'

const Hosting = ({ space, list, list2 }) => {
    const [signIn, setSignIn] = useState(false)
    const [signUp, setSignUp] = useState(false)

    const popup = useSelector((state) => state?.rental?.isLoginPop)
    const token = useSelector((state) => state?.userAuth?.loginInfo?.token)
    const user = useSelector((state) => state?.userAuth.userInfo)

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(addPopup(false))
        setSignIn(false)
    }

    const { t } = useTranslation()
    return (
        <>
            {signUp && <SignUp modalOpen={signUp} setSignIn={setSignIn} onCloseModal={() => setSignUp(false)} />}
            {signIn === true || popup === true ? <SignIn modalOpen={signIn || popup} setSignUp={setSignUp} onCloseModal={() => handleClose()} /> : null}
            <div className="container mx-auto">
                <div className="grid lg:grid-cols-2 pt-[3rem] gap-8 lg:mx-0 mx-4 ">

                    <div className='py-2'>
                        <h2 className='lg:text-[2.7rem] text-[2rem] font-bold'>{space?.heading}</h2>
                        <ul className='list-disc mx-5 font-bold text-[18px]'>
                            {/* <li>Live Vacancy Free.</li>
                            <li>Locate Great Tenants.</li>
                            <li>Get Paid On Time.</li> */}
                            {
                                space?.lists?.map((item, i) => (
                                    <li key={i}>
                                        {item}
                                    </li>
                                ))
                            }
                        </ul>

                        <div className='flex flex-col  items-start my-4'>
                            {token ?
                                <Link className='px-8 py-3 text-white bg-[#F79F15] rounded-md ' to={user.userRole === "servicer" ? '/servicerPanel' :'/dashboard'}>{t('get_started')}</Link> :
                                <button onClick={() => setSignUp(true)} className='px-8 py-3 text-white bg-[#F79F15] rounded-md '>{t('get_started')}</button>
                            }
                            <p className='py-5 font-medium text-gray-400 text-[14px]'>{list ? t('web_list_rental_5') : t('web_rommate_4')} </p>
                        </div>
                    </div>

                    <div>
                        <img src={list ? listImg : list2 ? rommate2Img : rommateImg} className="rounded-xl object-cover w-full h-[400px]" alt="list_image" />
                    </div>

                </div>
                <div className='grid lg:grid-cols-4  gap-4 lg:mx-0 mx-4 my-[2rem]'>
                    {
                        space.icons?.map((item, i) => (
                            <div className='group'>
                                <div className='border cursor-pointer  bg-gradient-to-r group-hover:from-[#FCCB4A] group-hover:to-[#F79F15] group-hover:text-white flex-col items-center shadow-md rounded-md px-6 h-[300px] flex justify-center bg-white' key={i}>
                                    <img src={item.original} alt="origal_image" className='group-hover:hidden mt-5 opacity-100' />
                                    <img src={item.hoverImg} alt="origal_image" className='hidden group-hover:block' />
                                    <h2 className='py-2 font-bold text-[20px]'>{item.name}</h2>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>

        </>
    )
}

export default Hosting