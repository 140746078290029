import React, { useEffect, useState } from 'react'
// import { BsPlus } from 'react-icons/bs'
import master from '../../../assests/images/master.png'
import visa from '../../../assests/images/visa.png'
// import { Link } from 'react-router-dom'
import { GetCards } from '../../../lib/Api/RentalAppApi'
import { useTranslation } from 'react-i18next'
const History = () => {
  const [cardList, setCardList] = useState([])

  useEffect(() => {
    let fetchData = async () => {
      let { res } = await GetCards()
      let UserData = Object.assign({}, ...res)
      setCardList(UserData?.cards)
    }
    fetchData()
  }, [])


  let { t } = useTranslation()

  return (
    <>
      {/* <h2 className='text-[20px] font-bold'>Your Saved Payment Methods</h2> */}
      <div className=' my-5 lg:flex  items-center'>
        {/* <Link to='/createPayment' className='border rounded-md shadow-md p-1' >
          <BsPlus className='text-[40px] text-[#CDD4D9]' />
        </Link> */}
        {
          cardList?.filter((f) => f.brand === "Visa").map((item, i) => (
            <div key={i} className='border relative mx-5 rounded-xl bg-white shadow-md w-full h-[200px]'>
              <div className='py-4 px-3'>
                <img src={master} className="w-[20%]" alt="master" />

                <div className=' absolute bottom-10 right-3 left-3'>
                  <div className='flex justify-between'>
                    <p className='text-[14px] text-[#A5A5A5]'>{t('available_balance')}</p>
                    <div className='flex flex-col'>
                      <h2 className='text-[20px] font-bold leading-none'>${item?.wallet === null ? 0 : item?.wallet}</h2>
                      <span className='text-[12px] -mt-[2px] text-[#CDD4D9]'>Master Card</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          ))
        }
        {
          cardList?.filter((f) => f.brand === "Visa").map((item, i) => (
            <div key={i} className=' rounded-xl relative bg-[#28267E] shadow-md w-full h-[200px]'>
              <div className='py-4 px-3'>
                <img src={visa} className="w-[20%]" alt="master" />

                <div className=' absolute bottom-10 right-3 left-3'>
                  <div className='flex justify-between'>
                    <p className='text-[14px] text-[#A5A5A5]'>{t('available_balance')}</p>
                    <div className='flex flex-col'>
                      <h2 className='text-[20px] font-bold leading-none'>${item?.wallet === null ? 0 : item?.wallet}</h2>
                      <span className='text-[12px] -mt-[2px] text-[#CDD4D9]'>Visa Card</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          ))
        }
      </div>
    </>
  )
}

export default History