import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { EditUserProfile } from '../../../lib/Api/UserApi';
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DynamicPopup from '../../../utils/Popup/DynamicPopup';
import ForgotPassword from './ForgotPassword'

// const schema = yup.object({
//   email: yup.string().email("Invalid email format").required(),
//   password: yup.string()
//     .required('Password is mendatory')
//     .min(3, 'Password must be at 8 char long'),
//   password_confirmation: yup.string()
//     .required('Password is mendatory')
//     .oneOf([yup.ref('password')], 'Passwords does not match'),
// });


const LoginInfo = () => {
  const [show, setShow] = useState(false)

  const { t } = useTranslation()



  const schema = yup.object({

    email: yup.string().email(t('invalid_email')).required(t('emial_req')),
    password: yup.string()
      .required(t('invalid_password'))
      .min(3, t('password_charctor')),
    password_confirmation: yup.string()
      .required(t('invalid_c_password'))
      .oneOf([yup.ref('password')], t('match_password')),
    // Role: yup.string().required(),
  });

  const [loading, setLoading] = useState(false)

  const lng = useSelector((state) => state?.userAuth?.language)
  const user = useSelector((state) => state?.userAuth?.userInfo)




  const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });



  const onSubmit = async (list) => {

    setLoading(true)

    let { res, error } = await EditUserProfile(list)
    if (res) {
      let UserData = Object.assign({}, ...res)
      // dispatch(updateUser(UserData))
      setLoading(false)
    }
    else if (error) {
      setLoading(false)

    }

  }

  useEffect(() => {
    reset({ email: user?.email })
  }, [])



  return (
    <>
      {show && <DynamicPopup modalOpen={show} data={<ForgotPassword  close={() => setShow(false)} />} close={() => setShow(false)} />}
      <div className='xl:pt-[4rem] lg:pt-[4rem] md:pt-[4rem] pt-2 xl:pb-0 lg:pb-0 md:pb-0 pb-10 px-4'>
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('email')} </label>
            <input type="email"  {...register('email')} placeholder='jhon.doe@gmail.com' className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.email ? 'border border-red-500' : ''}`} />
            {errors.email && (
              <p className="text-red-500 text-sm ">{errors.email.message}</p>
            )}
          </div>

          <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-5 lg:gap-5 md:gap-5 mb-4'>
            <div>
              <label className='mb-2 text-[16px] font-semibold'>{t('password')}   </label>
              <input type="password" placeholder='password'  {...register('password')} className={`p-2 w-[100%] rounded-md bg-[#F5F6FC] focus:outline-none  ${errors.password ? 'border border-red-500' : ''}`} />
              {errors.password && (
                <p className="text-red-500 text-sm ">{errors.password.message}</p>
              )}
            </div>
            <div>
              <label className='mb-2 text-[16px] font-semibold  mt-3'>{t('c_password')} </label>
              <input type="password" placeholder='confirm password'  {...register('password_confirmation')} className={`p-2 w-[100%] focus:outline-none rounded-md bg-[#F5F6FC]  ${errors.password_confirmation ? 'border border-red-500' : ''}`} />
              {errors.password_confirmation && (
                <p className="text-red-500 text-sm ">{errors.password_confirmation.message}</p>
              )}
            </div>
          </div>
          <p className='text-[13px] w-auto py-1 text-right text-gray-400 cursor-pointer' onClick={() => setShow(true) }>{t('forgot_pass')}</p>
          <div className='flex items-center'>
            <input type="checkbox" className={` focus:outline-none rounded-md bg-[#F5F6FC]`} />
            <p className='px-2 '>{t('privacy_policy_agree')}
              <Link target='_blank' to={lng === "en" ? "/terms" : "/sp/terms"} ><span className='text-[#F79F15] px-2'>{t('login_terms')}</span></Link>
              {t('login_info_agree')}</p>
          </div>

          <div className='flex justify-end my-5'>
            {
              loading ?
                <Loader type="saved" />
                :
                <button className='px-8 py-1.5 text-white  rounded-md bg-[#F79F15]'>{t('login_info_done')}</button>
            }
          </div>
        </form>
      </div>
    </>
  )
}

export default LoginInfo