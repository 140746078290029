import React, { useEffect, useState, useRef } from 'react';
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { FaRegImages } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdOutlinePhotoCamera } from 'react-icons/md'
import avatar from '../../../assests/images/f10.png'
import { CreateRommateProfile, DeleteRommateImage } from '../../../lib/Api/RantelServiceApi';
// import { MultiSelect } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
// import { MdArrowBackIos } from 'react-icons/md';
import { AddRommateId, AddRommateIndex, AddRommates, IspopUp } from '../../../Redux/UserAuthSlice/RommateSlice';
import { ConvertBase64 } from '../../../utils/ConvBase64';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import { updateUser } from '../../../Redux/UserAuthSlice/UserAuthSlice';
import { Link, useNavigate } from 'react-router-dom';
import { EditUserProfile } from '../../../lib/Api/UserApi'
import { IoArrowBackSharp } from 'react-icons/io5';
const PersonInfo = () => {
    const [loading, setLoading] = useState(false)
    const [ImgLoading, setImgLoading] = useState(false)
    const [ImgUpload, setImgUpload] = useState(false)
    const [images, setImages] = useState([])
    const [fileImages, setFileImages] = useState([])
    const [ImageObj, setImgObj] = useState({})
    const [profileImg, setProfileImg] = useState("")
    const [profileImgUrl, setProfileImgUrl] = useState("")
    // const [intrests, setInterest] = useState([])
    // const [activity, setActivity] = useState([])
    // const [selected, setSelected] = useState([]);
    // const [selected2, setSelected2] = useState([]);
    const { t } = useTranslation()
    const inputFile = useRef(null)

    const user = useSelector((state) => state?.userAuth?.userInfo)
    const { rommate, college, campus, rommateList, isNewRommate } = useSelector((state) => state?.rommate)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let checkList = Object.keys(rommateList ?? {}).length > 0


    const schema = yup.object({
        fname: yup.string().required(t('invalid_fname')),
        lname: yup.string().required(t('invalid_lname')),
        email: yup.string().email(t('invalid_email')).required(t('emial_req')),
        phone: yup.string().required(t('invalid_phone')),
        description: yup.string().required(t('invalid_desc'))
    });
    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    // let GetActivityInterest = async () => {
    //     let { resActivity } = await GetCampusActivities()
    //     let { resInterest } = await GetCampusInterest()
    //     const ConvertActi = Object.assign({}, ...resActivity)
    //     const ConvertIntr = Object.assign({}, ...resInterest)
    //     if (resActivity && resInterest) {
    //         let updatedAc = ConvertActi?.activities.map(obj => {
    //             return {
    //                 label: obj.activity, value: obj?.id
    //             }
    //         })
    //         setActivity(updatedAc)
    //         let updatedIn = ConvertIntr.interests.map(obj => {
    //             return {
    //                 label: obj.interest, value: obj?.id
    //             }
    //         })
    //         setInterest(updatedIn)
    //     }

    // }


    const handleImage = async (e) => {
        const file = e.target.files[0]
        let formData = new FormData
        formData.append('user_image', file)
        const convert = await ConvertBase64(file)
        setImgUpload(true)
        let { res, error } = await EditUserProfile(formData)
        if (res) {
            let UserData = Object.assign({}, ...res)
            dispatch(updateUser(UserData))
            setImgUpload(false)
            setProfileImgUrl(convert)
        }
    }


    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        let fileLeng = images?.length
        let count = files?.length + fileLeng
        if (!isNewRommate && count > 5) {
            toast.error(t('upload_img_info'))
        }
        else if (files?.length > 5) {
            toast.error(t('upload_img_info'))
        }
        else {
            const acceptedFileTypes = ['image/jpeg', 'image/png']; // Add more accepted file types as needed
            const imageFiles = files.filter(file => acceptedFileTypes.includes(file.type));
            setFileImages(imageFiles)
            Promise.all(
                imageFiles.map(file => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                        reader.readAsDataURL(file);
                    });
                })
            )
                .then(base64Images => {
                    if (!isNewRommate) {
                        if (rommateList?.images?.length > 0) {
                            setImages((prev) => [...prev, base64Images])
                        }
                        else {
                            setImages(base64Images)
                        }
                    }
                    else {
                        setImages(base64Images)
                    }
                }
                )
        }
    }

    const RemoveImage = async (index) => {
        setImgLoading(true)
        setImgObj(index)
        let findList = images?.find((f, i) => i === index)
        let ImagesList = images?.filter((_, i) => i !== index)
        if (findList) {
            let res = await DeleteRommateImage(findList?.id)
            setImgLoading(false)
            setImages(ImagesList)
        }
        else {
            setTimeout(() => {
                setImgLoading(false)
                setImages(ImagesList)
            }, 2000);
        }

    }

    const onButtonClick = () => {
        inputFile.current.click();

    }

    let stu = checkList ? rommateList?.college_student : college
    let camp = checkList ? rommateList?.on_campus : campus

    const OnSubmit = async (data) => {
        try {
            setLoading(true)
            let formData = new FormData()
            if (profileImg !== "") {
                formData.append('user_image', profileImg)
            }
            formData.append('first_name', data?.fname)
            formData.append('last_name', data?.lname)
            formData.append('email', data?.email)
            formData.append('phone', data?.phone)
            formData.append('description', data?.description)
            fileImages.forEach((file, index) => {
                formData.append(`images[${index}]`, file);
            });
            formData.append('college_student', stu)
            formData.append('on_campus', camp)
            let res = await CreateRommateProfile(formData)
            if (res) {
                setLoading(false)
                let UserData = Object.assign({}, ...res)
                dispatch(updateUser(UserData?.roommate))
                if (!isNewRommate) {
                    navigate('/roommatesList')
                }
                else {
                    dispatch(AddRommates(UserData?.roommate))
                    dispatch(AddRommateId(UserData?.roommate?.id))
                    if (college === 1 && campus === 1) {
                        dispatch(AddRommateIndex(3))
                    }
                    else {
                        dispatch(AddRommateIndex(4))
                    }
                }
            }
        }
        catch (err) { }

    }



    const handleMove = () => {
        dispatch(IspopUp(true))
        navigate('/roommatesList')

    }



    useEffect(() => {
        let payload = {
            fname: user?.fname,
            lname: user?.lname,
            email: user?.email,
            phone: user?.phone,
            description: rommateList?.description || ""
        }
        reset(payload)
        setProfileImgUrl(user?.userimg !== "https://leased.s3.amazonaws.com/" ? user?.userimg : avatar)
        if (!isNewRommate) {
            setImages(rommateList?.images)
        }
        else {
            setImages([])
        }
        // reset({ description: rommateList?.description })
    }, [reset])

    return (
        <>
            <div className='xl:hidden lg:hidden md:hidden block'>
                <Link to="/roommatesList">
                    <IoArrowBackSharp size={20} />
                </Link>
            </div>
            <h1 className='text-center text-[25px] font-semibold'>{t('rommate_p_detail')}</h1>
            <form onSubmit={handleSubmit(OnSubmit)}>
                <div className='mt-5 border shadow-md p-5 xl:pb-auto lg:pb-auto md:pb-auto pb-10 rounded-sm'>
                    <div className='flex justify-center items-center relative  max-w-[180px] mx-auto'>
                        <div className='xl:w-[150px] lg:w-[15px] md:w-[150px] w-[75px] xl:h-[150px] lg:h-[150px] md:h-[150px] h-[75px] border rounded-full'>
                            <img src={profileImgUrl} alt="profile" className="xl:w-[150px] lg:w-[15px] md:w-[150px] w-[75px] xl:h-[150px] lg:h-[150px] md:h-[150px] h-[75px] rounded-full" />
                        </div>
                        <div className='absolute top-5 left-5 '>
                            {
                                ImgUpload &&
                                <div className=' absolute top-9 left-14'>
                                    <Loader type="socialLoder" />
                                </div>
                            }
                        </div>
                        <div className='absolute  top-[40%] xl:right-[0%] lg:right-[0%] md:right-[0%] right-10  cursor-pointer'>
                            <div onClick={onButtonClick} className='border bg-white bg-opacity-[0.7] p-1 rounded-full'>
                                <MdOutlinePhotoCamera className='text-[15px] text-[#F79F15]' />
                            </div>
                            <input type="file" ref={inputFile} onChange={handleImage} className='hidden' />
                        </div>
                    </div>
                    <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 lg:gap-20 md:gap-20 mt-2'>
                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>{t('contact_first_name')} </label>
                            <input type="text" {...register('fname')} placeholder={t('contact_first_name')} className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.fname ? 'border border-red-500' : ''}`} />

                            {errors.fname && (
                                <p className="text-red-500 text-sm ">{errors.fname.message}</p>
                            )} </div>
                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>{t('contact_last_name')} </label>
                            <input type="text"  {...register('lname')} placeholder={t('contact_last_name')} className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.lname ? 'border border-red-500' : ''}`} />
                            {errors.lname && (
                                <p className="text-red-500 text-sm ">{errors.lname.message}</p>
                            )}
                        </div>
                    </div>
                    <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 lg:gap-20 md:gap-20">
                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>{t('email')} </label>
                            <input type="email"  {...register('email')} placeholder={t('email')} className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.email ? 'border border-red-500' : ''}`} />
                            {errors.email && (
                                <p className="text-red-500 text-sm ">{errors.email.message}</p>
                            )}
                        </div>
                        <div>
                            <label className='mb-2 text-[16px] font-semibold'>{t('rommate_p_upload_p')} </label>
                            <div className='border py-3 pl-3 flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center'>
                                <label htmlFor='upload' className={`${images.length === 15 ? 'cursor-not-allowed' : 'cursor-pointer'} bg-gray-100 cursor-pointer border rounded-md px-6 h-[35px] flex justify-center items-center`}>
                                    <div className='flex items-center text-gray-400  ' >
                                        <FaRegImages />
                                        <h2 className='px-2'>{t('uplod_lbl')}</h2>
                                    </div>
                                </label>
                                <input type="file" id="upload" className='hidden' disabled={images.length === 15 ? true : false} name="image_upload" accept='*/images' multiple onChange={(e) => handleImageChange(e, "images")} />
                                <div className='xl:ml-3 lg:ml-3 md:ml-3 xl:mt-0 lg:mt-0 md:mt-0 mt-2  grid xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 grid-cols-2 gap-2'>
                                    {
                                        images.map((u, i) => (
                                            <div className='relative xl:first:mx-2 lg:first:mx-2 md:first:mx-2 first:mx-0 x border rounded-md' key={i}>
                                                <img src={u?.image ? u?.image : u} alt="user_image" className='object-cover w-[120px] h-[40px] rounded-md ' />
                                                {
                                                    ImgLoading && ImageObj === i ?
                                                        <div className='absolute top-1 flex justify-center items-center'>
                                                            <Loader type="socialLoder" />
                                                        </div>
                                                        :
                                                        <div className='absolute right-1 top-1' onClick={() => RemoveImage(i)}>
                                                            <IoMdCloseCircle className='text-red-500 cursor-pointer' />
                                                        </div>
                                                }
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-20 lg:gap-20 md:gap-20'>
                        <div className='flex flex-col mt-2'>
                            <label className='mb-2 text-[16px] font-semibold'>{t('phone_lbl')} </label>
                            <Controller
                                name={`phone`}
                                control={control}
                                // defaultValue={field.phone}
                                render={({ field }) => (
                                    <PhoneInput
                                        {...field}
                                        country={'us'}
                                    />
                                )}
                            />
                            {errors.phone && (
                                <span className='text-red-500 text-sm '>{errors.phone.message}</span>
                            )}
                        </div>
                        <div></div>
                    </div>

                    <div>
                        <div className='flex mt-5 flex-col '>
                            <label className=' text-[16px] font-semibold'>{rommate?.college_student && rommate.on_campus === 1 ? t('rommate_p_des_you') : t('desc_info')} </label>
                            <textarea rows={8}  {...register('description')} placeholder={rommate?.college_student && rommate.on_campus === 1 ? t('rommate_p_des_you') : t('desc_info')} className={`p-2 border rounded-md focus:outline-none ${errors.email ? 'border border-red-500' : ''}`}></textarea>
                            {errors.description && (
                                <p className="text-red-500 text-sm ">{errors.description.message}</p>
                            )}
                        </div>
                    </div>
                    <div className='flex justify-between items-center'>
                        <div>
                            {!checkList &&
                                <button onClick={() => handleMove()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                    {t('Back')}
                                </button>
                            }
                        </div>
                        <div className='flex justify-end items-end mt-5 gap-2'>
                            {!isNewRommate &&
                                <Link to="/roommatesList">
                                    <button type="button" className='font-semibold border-[#F79F15] border text-[#F79F15]  px-6 py-1.5  rounded-md'>{t('cancel')}</button>
                                </Link>
                            }
                            <button className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                {loading ? <Loader type="saveLoder" /> : t('save')}
                            </button>

                        </div>
                    </div>

                </div>
            </form>

            <div>


            </div>
        </>
    )
}

export default PersonInfo