import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useState } from 'react';
import SignInForm from '../../components/AuthComp/SignInForm';
import VerifyOtp from '../../components/AuthComp/VerifyOtp';
// import VerifyPhone from '../../components/AuthComp/VerifyPhone';
import NewPassword from '../../components/AuthComp/NewPassword';
import Forgot from '../../components/AuthComp/Forgort';
import CheckRole from './RoleCheck';
import { useTranslation } from 'react-i18next'
import { stripePromise } from '../../config/Stripe'
import { Loader } from '../../utils/loaderButton';

const SignIn = ({ modalOpen, onCloseModal, setSignUp }) => {
    const [active, setActive] = useState(0)
    const [email, setEmail] = useState('')
    const [permistion, setPermission] = useState(null)
    const [media, setMedia] = useState(true)
    const [loader, setLoader] = useState(false)
    const [mediaName, setMediaName] = useState(null)
    const [userObject, setUserObject] = useState({})
    const [userSocial, setUserSocial] = useState("")

    // let  userObject = {}

    const { t } = useTranslation();


    // //console.log("loader", loader)

    return (
        <>



            <Modal open={modalOpen} onClose={onCloseModal} classNames="!w-[90%] " size={"lg"} center>
                {
                    media === false ?
                        <>
                            {
                                active === 0 ? <SignInForm login={"login"} stripePromise={stripePromise} setMedia={setMedia} t={t} setMediaName={setMediaName} mediaName={mediaName} permistion={permistion} close={onCloseModal} setUserObject={setUserObject} userObject={userObject} userSocial={userSocial} setUserSocial={setUserSocial} setSignUp={setSignUp} setActive={setActive} setLoader={setLoader} />
                                    : active === 1 ? <Forgot t={t} close={onCloseModal} setActive={setActive} setEmail={setEmail} setUserSocial={setUserSocial} userObject={userObject} />
                                        // : active === 2 ? <VerifyPhone close={onCloseModal} setActive={setActive} email={email} />
                                        : active === 2 ? <VerifyOtp t={t} close={onCloseModal} setActive={setActive} email={email} />
                                            : active === 3 && <NewPassword t={t} close={onCloseModal} setActive={setActive} email={email} />
                            }
                        </>
                           :
                             loader ? <div className='p-10'> <Loader type="saveLoder" /></div>
                            :
                            <CheckRole title={"Please Select Login As a"} buttonTitle={"Login"} close={onCloseModal} setMedia={setMedia} setPermission={setPermission} t={t} />
                }
            </Modal>

        </>
    )
}

export default SignIn