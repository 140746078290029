import React, { useEffect, useState } from 'react'
import { CreateCurrentList } from '../../../lib/Api/RantelServiceApi'
import { useForm, Controller } from "react-hook-form";
import { yupResolver, } from '@hookform/resolvers/yup';
import * as yup from "yup";
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../utils/loaderButton';


const OwnerDetail = ({ type, close, list }) => {
    const [loadingSave, setLoadingSave] = useState(false)
    const { t } = useTranslation()
    const schema = yup.object({
        landlord_first_name: yup.string().required(t('land_name')),
        landlord_last_name: yup.string().required(t('land_name')),
        landlord_phone_number: yup.string().required(t('land_phone')),
        email: yup.string().required(t('email'))
    })
    const { register,reset ,  control, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const OnSubmit = async (data) => {

        let formData = new FormData()
        formData.append('roommate_id', list?.roommate_id)
        formData.append('landlord_first_name', data?.landlord_first_name)
        formData.append('landlord_last_name', data?.landlord_last_name)
        formData.append('landlord_phone_number', data?.landlord_phone_number)
        formData.append('email', data?.email)
        setLoadingSave(true)
        let res = await CreateCurrentList(formData)
        if (res) {
            setLoadingSave(false)
            close()
        }
    }

useEffect(() =>{
    if(type){
        let payload= {
            "landlord_first_name" :list?.roommate?.current_information?.landlord_first_name,
            "landlord_last_name" :list?.roommate?.current_information?.landlord_last_name,
            "landlord_phone_number" :list?.roommate?.current_information?.landlord_phone_number,
            "email":list?.roommate?.current_information?.landlord_email
        }
        reset(payload)
    }
},[])


    return (
        <div>
            <h1 className='text-center text-[25px] font-semibold'>{t('share_info')}</h1>
            <form className='mt-5 border shadow-md p-5 rounded-sm' onSubmit={handleSubmit(OnSubmit)}>
                <div>
                    <div className='grid grid-cols-2 gap-20 my-8'>
                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>{t('contact_first_name')}</label>
                            <input type="text" placeholder={t('contact_first_name')}
                                name={`landlord_first_name`}
                                disabled={type ? true : false}
                                {...register(`landlord_first_name`)}
                                className=' p-2 rounded-md  focus:outline-none  w-full border' />
                            {errors.landlord_first_name && (
                                <span className='text-red-500 text-sm '>{errors.landlord_first_name.message}</span>
                            )}

                        </div>
                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>{t('contact_last_name')}</label>
                            <input type="text" placeholder={t('contact_last_name')}
                                name={`landlord_last_name`}
                                disabled={type ? true : false}
                                {...register(`landlord_last_name`)}
                                className=' p-2 rounded-md  focus:outline-none  w-full border' />
                            {errors.landlord_last_name && (
                                <span className='text-red-500 text-sm '>{errors.landlord_last_name.message}</span>
                            )}

                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-20 my-8 '>
                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>{t('contact_email')}</label>
                            <input type="text" placeholder={t('contact_email')}
                                name={`email`}
                                disabled={type ? true : false}
                                {...register(`email`)}
                                className=' p-2 rounded-md  focus:outline-none  w-full border' />
                            {errors.email && (
                                <span className='text-red-500 text-sm '>{errors.email.message}</span>
                            )}

                        </div>
                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>{t('contact_phone')}</label>
                            <Controller
                                name={`landlord_phone_number`}
                                control={control}
                                disabled={type ? true : false}
                                // defaultValue={field?.landlord_phone}
                                render={({ field }) => (
                                    <PhoneInput
                                        {...field}
                                        country={'us'}
                                    />
                                )}
                            />
                            {errors.landlord_phone_number && (
                                <span className='text-red-500 text-sm '>{errors.landlord_phone_number.message}</span>
                            )}
                        </div>

                    </div>

                </div>

                <div>
                    {!type &&
                        <div className='flex justify-center items-center py-3 gap-2'>
                            <button type='submit' className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                {loadingSave ? <Loader type="saveLoder" /> : `${t(`share_labl`)}`}</button>

                        </div>
                    }
                </div>
            </form>
        </div>
    )
}

export default OwnerDetail