import React, { useState } from 'react'
import { MdArrowBackIos } from 'react-icons/md'
import { AddRommate, AddRommateIndex, IspopUp, RommateNew } from '../../../Redux/UserAuthSlice/RommateSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const CheckCampus = ({ setActives }) => {
  const [active, setActive] = useState(null)

  const { renterQuestion } = useSelector((state) => state.rommate)

  const dispatch = useDispatch()

  const handleCheckNext = (value) => {
    setActive(value)
    if (value === 0) {
      // setActive(0)
      // dispatch(AddRommateIndex(4))
      // dispatch(IspopUp(false))
      if (renterQuestion?.onCampus?.is_complete === true) {
        setActives(2)
      } else {
        dispatch(RommateNew(true))
        dispatch(AddRommateIndex(3))
        dispatch(IspopUp(false))

      }
      dispatch(AddRommate({
        "on_campus": 1
      }))
      // setActivess(0)
      // setShow(false)
    }
    else {
      // setActive(1)
      // dispatch(AddRommateIndex(4))
      // dispatch(IspopUp(false))
      if (renterQuestion?.offCampus?.is_complete === true) {
        setActives(2)
      }
      else {
        dispatch(RommateNew(true))
        dispatch(AddRommateIndex(3))
        dispatch(IspopUp(false))
      }
      // dispatch(AddRommateIndex(1))
      dispatch(AddRommate({
        "on_campus": 0
      }))
    }
  }
  const { t } = useTranslation()
  return (
    <>
      <div className='flex items-center font-medium cursor-pointer' onClick={() => setActives(0)}>
        <MdArrowBackIos className='text-[20px]' />
        {t('back')}
      </div>
      <h2 className='text-center mt-5 text-[20px] font-bold'>
        {t('live_campus')}</h2>
      <div className="grid lg:grid-cols-2 gap-5  pt-[10px] ">
        <div className='group'>
          <div onClick={() => handleCheckNext(0)} className={`${active === 0 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  border max-w-[300px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <h2 className='text-[1rem] font-bold'>{t('yes')} </h2>

          </div>
        </div>
        <div className='group'>
          <div onClick={() => handleCheckNext(1)} className={`${active === 1 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  border max-w-[300px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <h2 className='text-[1rem] font-bold '>{t('no')}</h2>
          </div>
        </div>
      </div>

    </>
  )
}

export default CheckCampus