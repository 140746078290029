import React, { useEffect, useState } from 'react'
import RoommateList from '../../components/ServicerPanelComp/RoomateComp/RoommateList'
import { useSelector } from 'react-redux'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import IdVerified from '../../components/GenericComp/IdVerified'
import { Loader } from '../../utils/loaderButton'

const RommatePage = () => {
    const [showVerify, setShowVerify] = useState(false)
    const [loading, setLoading] = useState(false)

    const isVerify = useSelector((state) => state.userAuth.userInfo?.idVerified)
    useEffect(() => {
        if (isVerify === null) {
            setShowVerify(true)
        }
    }, [isVerify])
    return (
        <>
            {showVerify && <DynamicPopup modalOpen={showVerify} data={<IdVerified type="verify" setLoading={setLoading} close={() => setShowVerify(false)} />} close={() => setShowVerify(false)} />}
            {loading ? <Loader type="loadData" /> : <RoommateList /> }
        </>
    )
}

export default RommatePage