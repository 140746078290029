import React, { useState, useRef, useEffect } from 'react';
import Transition from './Transition';
import { IoMdArrowDropdown } from 'react-icons/io'
import { useDispatch } from 'react-redux';
import { addExplore } from '../Redux/UserAuthSlice/RentalSlice';
function DynamicDropdonw({ text, list, setFormData }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [max, setMax] = useState(null);
    const [min, setMin] = useState(null);
    const [bed, setBed] = useState(0);
    const [bath, setBath] = useState(0);
    const [property, setProperty] = useState();
    const [leasedType, setLeasedType] = useState();
    const [aminity, setAminity] = useState();

    const trigger = useRef(null);
    const dropdown = useRef(null);
    const dispatch = useDispatch()
    const handleSelect = (obj, type) => {
        if (obj.type === "bath") {
            setBath(obj.period)
            setSelected(obj?.id)
            dispatch(addExplore({ propertyName: "bath", value: obj?.id }))
        }
        else if (obj?.type === "bed") {
            setBed(obj?.period)
            setSelected(obj?.id)
            dispatch(addExplore({ propertyName: "bed", value: obj?.id }))
        }
        else if (obj.type === "propertyType") {
            setProperty(obj)
            dispatch(addExplore({ propertyName: "property", value: obj }))
        }
        else {
            setLeasedType(obj)
            dispatch(addExplore({ propertyName: "leasedPeriod", value: obj }))

        }
    }

    const handleChnage = (e) => {
        let { name, value } = e.target
        if (name === "min") {
            setMin(value)
            dispatch(addExplore({ propertyName: "min", value: value }))
        }
        else {
            setMax(value)
            dispatch(addExplore({ propertyName: "max", value: value }))

        }
    }



    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    return (
        <div className="relative">
            <button
                ref={trigger}
                className={` ${text && 'border rounded-md w-full'} px-2 py-1 w-full  flex  flex-col justify-between  bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600`}

                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                {text &&
                    <div className='flex justify-between w-full'>
                        <p className='text-left text-[13px] '>
                            {text}
                        </p>
                        <IoMdArrowDropdown className={`text-[22px] ${text && 'text-black '} text-[#F79F15]`} />
                    </div>
                }
                <div className='flex'>
                    <span className="flex items-center text-[#707070]">
                        {bath ?
                            <span className='text-[16px]'>{bath}</span>
                            : bed ?
                                <span className='text-[16px]'>{bed}</span>
                                :
                                max || min ?
                                    <span className='text-[16px]'>{min}-{max}</span>
                                    :
                                    leasedType ?
                                        <span className='text-[16px]'>{leasedType?.title}</span>
                                        :
                                        <span className='text-[16px]'>{property?.name}</span>
                        }

                    </span>

                </div>
            </button >
            <Transition
                show={dropdownOpen}
                tag="div"
                className={`z-10 absolute top-full ${list?.length > 10 ? 'h-[300px] overflow-y-auto' : 'h-auto'} right-0 w-full  bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1`}
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-slate-600 "
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    {list !== "input" ?
                        list?.map(option => {
                            return (
                                <button
                                    key={option.id}
                                    tabIndex="0"
                                    className={`flex items-center justify-center w-full  hover:bg-slate-50 py-1 px-3 cursor-pointer`}
                                    onClick={() => { handleSelect(option); setDropdownOpen(false); }}
                                >
                                    <span>{option.period || option?.name || option.title}</span>
                                </button>
                            )
                        })
                        :
                        <div className='grid grid-cols-2 gap-2 px-1'>
                            <input type="text" value={min} onChange={handleChnage} className='border focus:outline-none ' name="min" placeholder='0' />
                            <input type="text" name="max" value={max} onChange={handleChnage} className='border focus:outline-none ' placeholder='10000' />
                        </div>

                    }
                </div>
            </Transition>
        </div >
    );
}

export default DynamicDropdonw;


