import React from 'react'

import f1 from '../../assests/images/f1.png'
import f2 from '../../assests/images/f2.png'
import f3 from '../../assests/images/f3.png'
import f4 from '../../assests/images/f4.png'
import f5 from '../../assests/images/f5.png'
import f6 from '../../assests/images/f6.png'
import f7 from '../../assests/images/f7.png'
import f8 from '../../assests/images/f8.png'
import f9 from '../../assests/images/f9.png'
import f10 from '../../assests/images/f10.png'
import f11 from '../../assests/images/f11.png'
import f12 from '../../assests/images/f12.png'
import { useTranslation } from 'react-i18next'


const Feature = () => {
    const { t } = useTranslation()
    const featureImage = [
        { heading: t('footer_rental'), text: t('footer_rental_text'), img: f1 },
        { heading: t('rommates'), text: t('locate_housmate'), img: f2 },
        { heading: t('rental_application'), text: t('save_time'), img: f3 },
        { heading: t('lead_upload'), text: t('upload_exist'), img: f4 },
        { heading: t('flash_lane'), text: t('beat_line'), img: f5 },
        { heading: t('r_eazy'), text: t('an_automate'), img: f6 },
        { heading: t('in_app'), text: t('connect_other'), img: f7 },
        { heading: t('verify_user'), text: t('all_users'), img: f8 },
        { heading: t('onlione_scheduler'), text: t('book_and_track'), img: f9 },
        { heading:t('screening'), text: t('rental_app'), img: f10 },
        { heading: t('3d_floor'), text: t('upload_3d'), img: f11 },
        { heading: t('virtual_tour'), text: t('add_video'), img: f12 },
    ]
    return (
        <>
            <div className='container mx-auto'>
                <h2 className='text-center text-[2.5rem] font-bold p-[2rem]'>{t('feature')}</h2>
                <div className="grid lg:grid-cols-4 grid-cols-2 gap-4 items-center">
                    {
                        featureImage.map((list, i) => (
                            <div className='group' key={i}>
                                <div className='flex justify-center group-hover:border group-hover:bg-white rounded-md group-hover:shadow-md flex-col  items-center p-7' key={i}>
                                    <img src={list.img} className="object-cover lg:w-[25%] w-[50px] " alt="feature_image" />
                                    <h2 className='font-bold py-2 lg:text-auto text-[14px]'>{list.heading}</h2>
                                    <p className='text-center text-[12px]  font-semibold'>{list.text}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </>
    )
}

export default Feature