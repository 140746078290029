import { useEffect, useState } from 'react'
import flash from '../../assests/images/f5.png'
import Resey from '../../assests/images/f6.png'
import blog from '../../assests/images/blogImg.png'
import person from '../../assests/svg/Icon ionic-ios-person.svg'
import locat from '../../assests/svg/Icon material-location-on.svg'
import desc from '../../assests/svg/Icon material-description-yellow.svg'
// import propImg from '../../assests/svg/home.svg'
// import Arrow from '../../assests/svg/arrow.svg'
// import gp4 from '../../assests/svg/Group 4188.svg'
import {
  GetREazyProperty,
  GetServicerProperty,
  GetServicerFlashProperty,
  GetREazyPropertys,
  CreateFilterPrefferance,
  GetFilterPrefferance,
  GetServicerReazyRomate,
  GetServicerFlashRomate
} from '../../lib/Api/RantelServiceApi'
import { Loader } from '../../utils/loaderButton'
import { Countdown } from '../../hooks/TimeCountDown'
// import ReactSelect from 'react-select'
import { Truncate, Truncate2 } from '../../utils/TrucateString'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import BlockTimeSettings from '../../components/GenericComp/BlockTimeSetting'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import REasypass from './EasyPass'
import { FaPlus } from 'react-icons/fa'
import { GetSubscriptionbyId } from '../../lib/Api/RentalAppApi'
import FilterPreferance from '../../components/ServicerPanelComp/ReazyLane/FilterPreferance'
import ReazyRentalsDetail from './ReazyPropertyDetail'
// import listindata from '../../contant/Constant.json'
const ServicerFlashpass = ({ info, setPurchaseInfo, setActivesTab ,handleToggle, activesTab, setReazyHistory }) => {
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])
  const [isSettings, setIsSettings] = useState(false)
  const [loadingPro, setLoadingPro] = useState(false)
  const [allProperty, setAllProperty] = useState([])
  const [selectedProperty, setSelectProperty] = useState({})
  const [propertyinfo, setPropertyInfo] = useState({})
  const [property, setProperty] = useState({})
  const [type, setType] = useState("")
  const [activeTab, setActiveTab] = useState(null)
  const [formData, setFormData] = useState({
    automatically_accept_appointments: true,
    with_rental_app: false,
    ideal_score_min: 0,
    ideal_score_max: 0,
    income_min: 0,
    income_max: 0,
    afford_min: 0,
    afford_max: 0,
    pets_allowed: false,
    for_children: false,
    section_candidates: false,
    smoking_allowed: false
  });
  // const [active, setActive] = useState(0)


  const user = useSelector((state) => state?.userAuth?.userInfo)
  const userRomate = useSelector((state) => state?.userAuth?.userRommate)

  const location = useLocation().pathname

  let fetchReazy = async () => {
    let res = await GetFilterPrefferance()
    let obj = Object.assign({}, ...res)
    if (obj?.settings !== null) {
      const data = {
        automatically_accept_appointments: obj.settings?.automatically_accept_appointments === 1 ? true : false,
        with_rental_app: obj.settings?.with_rental_app === 1 ? true : false,
        ideal_score_min: obj.settings?.ideal_score_min,
        ideal_score_max: obj.settings?.ideal_score_max,
        income_min: obj.settings?.income_min,
        income_max: obj.settings?.income_max,
        afford_min: obj.settings?.afford_min,
        afford_max: obj.settings?.afford_max,
        pets_allowed: obj.settings?.pets_allowed === 1 ? true : false,
        for_children: obj.settings?.for_children === 1 ? true : false,
        section_candidates: obj.settings?.section_candidates === 1 ? true : false,
        smoking_allowed: obj.settings?.smoking_allowed === 1 ? true : false,
      };
      setFormData(data);
    }
  }

  let fetchRommateReazy = async () => {
    try {
      setLoadingPro(true)
      let res = await (location === "/servicer_reazy" ? GetServicerReazyRomate() : GetServicerFlashRomate())
      if (res) {
        let obj = Object.assign({}, ...res)
        setLoadingPro(false)
        setProperties(obj.listings);
        // }
      } else {
        setLoadingPro(false)
      }
    }
    catch (err) {
      setLoadingPro(false)
    }
  }


  let fetchProperty = async () => {
    try {
      setLoading(true)
      let res = await
        (location === "/servicer_reazy" ? GetREazyPropertys() :
          GetServicerFlashProperty())
      if (res) {
        setLoading(false)
        let ObjProperty = Object.assign({}, ...res)
        const reversedRoommates = ObjProperty?.my_rentals
        if (reversedRoommates?.length === 0) {
          setActiveTab(0)
          setPurchaseInfo({})
        } else {
          setActiveTab(0)
          setAllProperty(reversedRoommates);
          if (reversedRoommates?.length > 0) {
            handleGetProperty(ObjProperty?.my_rentals[0]?.id)
            setSelectProperty(ObjProperty?.my_rentals[0])
          }
        }
      }
    }
    catch (err) { }
  }

  const getPropertyInfo = async (id) => {
    let { res } = await GetSubscriptionbyId(id)
    if (res) {
      setPropertyInfo(res)
    }
  }

  const handleOpen = (type) => {
    setType(type)
    setIsSettings(true)
  }

  const handleUpdate = async (value, name) => {
    console.log("value", value)
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    let res = await CreateFilterPrefferance(formData)
    if (res) {
      await fetchProperty()
    }
  }

  const handleGetProperty = async (id) => {
    if (location === "/servicer_reazy" || location === "/servicer_flash") {
      let property = allProperty?.find((f) => f.id === Number(id))
      setSelectProperty(property)
      setLoadingPro(true)
      let paylaod = {
        "property_id": id
      }
      let res = await (location === "/servicer_reazy" ? GetREazyProperty(paylaod) : GetServicerProperty(paylaod))
      await getPropertyInfo(id)
      if (res) {
        let obj = Object.assign({}, ...res)
        setLoadingPro(false)
        setProperties(obj.listings);
      }
      else {
        setLoadingPro(false)
      }
    }
  }

  let handleAddMore = () => {
    setActivesTab(0)
    setActiveTab(0)
    setPurchaseInfo({})
  }

  useEffect(() => {
    if (activeTab === 1) {
      if (info?.roommate?.purchased === false) {
        setPurchaseInfo({})
        setReazyHistory("enable")
        handleToggle(1)
      }
    }
  }, [activeTab])


  useEffect(() => {
    if (activeTab === 0 ) {
      setLoadingPro(true)
      fetchProperty()
      fetchReazy()
    } else {
      setProperties([])
      setLoadingPro(true)
      fetchRommateReazy()
    }
  }, [activeTab])

  useEffect(() => {
    if (activesTab) {
      setActiveTab(activesTab)
    }
  }, [activesTab])

  useEffect(() => {
    if (type === "filter" && isSettings === false)
      fetchReazy()
  }, [isSettings])

  const { t } = useTranslation()

  return (
    <>
      {isSettings && <DynamicPopup modalOpen={isSettings} data={
        type === "filter" ? <FilterPreferance close={() => setIsSettings(false)} /> : type === "detail" ?
          <ReazyRentalsDetail detail={property} close={() => setIsSettings(false)} /> : <BlockTimeSettings selected={info?.duration} close={() => setIsSettings(false)} />} close={() => setIsSettings(false)} />}
      <div className=' blog_list mt-3'>

        {
          <div className='flex bg-transparent justify-between gap-2 items-end pb-5 '>
            <div>
              <div className={` flex items-center rounded-md border `}>
                <div onClick={() => setActiveTab(0)} className={`${activeTab === 0 ? `${location === "/servicer_reazy" ? "bg-[#B913F0]" : "bg-[#FFDD00]"} rounded-bl-md rounded-tl-md text-white` : 'text-black'} cursor-pointer w-full border-r px-6 h-full py-2 `}>
                  <p>{t('servicer_rentals')}</p>
                </div>
                <div onClick={() => setActiveTab(1)} className={`${activeTab === 1 ? `${location === "/servicer_reazy" ? "bg-[#B913F0]" : "bg-[#FFDD00]"} rounded-tr-md rounded-br-md text-white` : 'text-black'}  cursor-pointer w-full px-6 h-full py-2`}>
                  <p>{t('rommates')}</p>
                </div>
              </div>
            </div>
            {location === "/servicer_reazy" &&
              <div className='flex items-center gap-2'>
                <button onClick={() => handleOpen("setting")} className='bg-[#B913F0] text-white px-3 py-2 rounded-md'>{t('setting_avil')}</button>
                <button onClick={() => handleOpen("filter")} className='bg-[#B913F0] text-white px-3 py-2 rounded-md'>{t('setting_filter')}</button>
              </div>
            }
          </div>
        }

        <div className=' mb-10' >
          <div className='grid grid-cols-2 my-3'>
            <div className='max-w-[400px]'>
              {
                activeTab === 0 && (
                  <select value={selectedProperty?.id} onChange={(e) => handleGetProperty(e.target?.value)} className='w-full border rounded-md px-3 py-2 focus:outline-none'>
                    <option disabled>{t('please_select')}</option>
                    {
                      !loading && allProperty?.map((list) => (
                        <option key={list?.id} value={list?.id}>
                          {`${list?.apt !== null ? `Apt #${list?.apt}` : ''} ${list?.complete_address}`}
                        </option>
                      ))
                    }
                  </select>
                )
              }
            </div>
            <div className=' flex justify-end items-end'>
              <button onClick={() => handleAddMore()} className={`flex items-center gap-2 px-5 py-2 rounded-md  ${location === "/servicer_reazy" ? "bg-[#B913F0]" : "bg-[#FFDD00]"} text-white`}>
                <FaPlus />
                {t('add_labl')}
              </button>
            </div>
          </div>
          {!loadingPro &&
            <div className="bg-orange-50 border  px-4 py-3 rounded relative" role="alert">
              <div className='flex justify-between'>
                <div className='flex items-center'>
                  <img src={location === "/servicer_reazy" || location === "/reazy" ? Resey : flash} alt="flash" className='object-cover w-[10%]' />
                  <h2 className='underline font-semibold text-black text-[20px] mx-2'>{location === "/servicer_reazy" || location === "/reazy" ? t('REsy_lane') : t('flash_lane')}</h2>
                </div>
                <div>
                </div>
              </div>
              <div className='pt-2'>
                <ul>
                  <li className="text-[16px] text-black flex items-center font-medium">1. {location === "/servicer_reazy" || location === "/reazy" ? t('reasy_lane_info_servicer') : t('flash_lane_info_servicer')}</li>

                  <li className='flex items-center text-[16px] text-black font-medium'>2. {location === "/servicer_reazy" || location === "/reazy" ? t('resazy_sub') : t('flash_sub')}
                    {
                      activeTab === 0 ?
                        location === "/servicer_reazy" || location === "/reazy" ?
                          <div className='bg-white text-gray-300 shadow-md rounded-md px-6 py-2 ml-2 text-[16px] '>
                            {selectedProperty?.reazy_lane?.days > 0 ? <div className='flex items-center gap-2' >{selectedProperty?.reazy_lane?.days}d
                              <Countdown selected={selectedProperty?.reazy_lane?.time || "00:00:00"} />
                            </div>
                              :
                              <Countdown selected={selectedProperty && Object.keys(selectedProperty)?.length > 0 && selectedProperty?.reazy_lane?.time || "00:00:00"} />
                            }
                          </div>
                          :
                          <div className='bg-white text-gray-300 shadow-md rounded-md px-6 py-2 ml-2 text-[16px] '>
                            {selectedProperty?.flashlane?.days > 0 ? <div className='flex items-center gap-2' >{selectedProperty?.flashlane?.days}d
                              <Countdown selected={selectedProperty?.flashlane?.time || "00:00:00"} />
                            </div>
                              :
                              <Countdown selected={selectedProperty && Object.keys(selectedProperty)?.length > 0 && selectedProperty?.flashlane?.time || "00:00:00"} />
                            }
                          </div>
                        :
                        <div className='bg-white text-gray-300 shadow-md rounded-md px-6 py-2 ml-2 text-[16px] '>
                          {/* {timeLeft?.hours}:{timeLeft?.minutes}:{timeLeft?.seconds} */}
                          {info?.roommate?.days > 0 ? <div className='flex items-center gap-2' >{info?.roommate?.days || info?.roommate?.days}d
                            <Countdown selected={info?.roommate?.time || "00:00:00"} />
                          </div>
                            :
                            <Countdown selected={info?.roommate?.time || "00:00:00"} />
                          }
                        </div>
                    }
                  </li>

                </ul>
              </div>
            </div>
          }
        </div>
        {location === "/servicer_flash" ?
          <div className='grid lg:grid-cols-3 md:grid-cols-2  gap-4'>
            {!loadingPro && properties?.map((list, index) => {
              return (
                <>
                  <div key={index} className='flex '>
                    {index + 1}.
                    <div className={`rounded-md bg-white border-2 ${list?.id === selectedProperty?.id && activeTab === 0 ? 'border-[#F79F15] ' : user?.userid === list?.user_id ? 'border-[#F79F15]' : 'border-gray-100'} shadow-md pb-4 mb-4 w-full p-2 relative`} >
                      <div className=' w-full '>
                        <div>
                          <div className='relative'>
                            <img src={list?.image || list?.images?.length > 0 && list?.images[0]?.image || blog} alt="blog" className='object-cover w-full h-[300px]' />
                            <div className='flex justify-between w-full items-center absolute top-0  pt-5 px-2'>
                              <div className='bg-gray-100 text-[16px] rounded-full px-2'>
                                {list?.human_readable_time}
                              </div>
                              <div className='bg-gray-100 text-[16px] rounded-md px-2'>
                                {list?.flashlane?.days > 0 ? <div className='flex items-center gap-2' >{list?.flashlane?.days}d
                                  <Countdown selected={list?.flashlane?.time || "00:00:00"} />
                                </div>
                                  :
                                  <Countdown selected={list?.flashlane?.time || "00:00:00"} />
                                }
                              </div>
                            </div>
                            <div className="absolute bottom-5 left-2">
                              <div className='bg-orange-50 h-[30px] flex gap-2 items-center text-[16px] rounded-md px-2'>
                                <h2 className=' text-[#F79F15]'>{list?.applications || 0} {t('applications')} </h2>
                                <span className='border-[#F79F15] border h-[20px] w-[2px]'></span>
                                <h2 className=' text-[#F79F15]'>{list?.appointment || list?.appointments || 0} {t('appointments')} </h2>
                              </div>
                            </div>
                          </div>
                          <div className=' w-full'>
                            {activeTab === 0 ?
                              <div className='flex justify-between  px-1 pt-3 pb-2'>
                                <div>
                                  <ul>
                                    <li>
                                      <div className='flex items-center'>
                                        <img src={person} alt="detail" className='object-cover' />
                                        <h2 className='px-2 text-black text-[16px]'>{Truncate2(list?.owner_name || list?.realtor_name, 15)}</h2>
                                      </div>
                                    </li>
                                    <li>
                                      <div className='flex items-center'>
                                        <img src={locat} alt="detail" className='object-cover' />
                                        <h2 className='px-2 text-black text-[14px]'> {`${list?.city} ${list?.state}`}</h2>
                                      </div>
                                    </li>
                                    <li>
                                      <div className='flex items-center'>
                                        <img src={desc} alt="detail" className='object-cover' />
                                        <h2 className='px-2 text-black text-[14px]'>{Truncate(list?.description, 10)}</h2>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div>
                                  <h2 className='text-[#F79F15] text-[20px]'>${list?.rent}<span className='text-[18px] text-black'>/ {t('month')}</span></h2>
                                  <p className='text-black text-[16px]'>{t('Sec_dp')} ${list?.security_deposit}</p>
                                </div>
                              </div>
                              :
                              <div className='pt-3'>
                                {
                                  userRomate.college_student === 1 && userRomate.on_campus === 1 ?
                                    <div className='  flex justify-between w-full items-center '>
                                      <div className='text-black  px-1'>
                                        <div className='flex items-center'>
                                          <img src={person} alt="detail" className='object-cover' />
                                          <h2 className='px-2 text-black text-[14px]'>{Truncate2(list?.user?.first_name, 15)}</h2>
                                        </div>
                                        <div className='flex items-center'>
                                          <img src={locat} alt="detail" className='object-cover' />
                                          <h2 className='px-2 text-black text-[14px]'> {list?.university_details?.university_name}</h2>
                                        </div>
                                        <div className='flex items-center'>
                                          <img src={desc} alt="detail" className='object-cover' />
                                          <h2 className='px-2 text-black text-[14px]'>{Truncate(list?.description, 10)}</h2>
                                        </div>
                                      </div>
                                      <div className='text-black px-2 w-[120px] '>
                                        <h2 className=' text-[14px]'>{t('rommate_uni_dor')} : {list?.university_details?.domm_name}</h2>
                                        <p className='text-[14px]'>{t('rommate_uni_major')} : {list?.university_details?.major}</p>
                                      </div>

                                    </div>
                                    :
                                    <div className=' flex justify-between w-full items-center '>
                                      <div className='text-black  px-1'>
                                        <div className='flex items-center'>
                                          <img src={person} alt="detail" className='object-cover' />
                                          <h2 className='px-2 text-black text-[14px]'>{Truncate2(list?.user?.first_name, 15)}</h2>
                                        </div>
                                        <div className='flex items-center'>
                                          <img src={locat} alt="detail" className='object-cover' />
                                          <h2 className='px-2 text-black text-[14px]'> {`${list?.property_details?.street} ${list?.property_details?.city} ${list?.property_details?.state}`}</h2>
                                        </div>
                                        <div className='flex items-center'>
                                          <img src={desc} alt="detail" className='object-cover' />
                                          <h2 className='px-2 text-black text-[14px]'>{Truncate(list?.description, 10)}</h2>
                                        </div>
                                      </div>
                                      <div className='text-black px-2  '>
                                        <h2 className=' text-[18px]'>{t('list_rent')}</h2>
                                        <h2 className=' text-[18px]'>${list?.property_details?.rent}/<span className='text-[14px]'>{t('Month')}</span></h2>
                                        <p className='text-[14px]'>{t('sequiry')} ${list?.property_details?.security_deposit}</p>
                                      </div>

                                    </div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </>
              )
            })}
          </div>
          :
          <>
            <div className='flex flex-col'>
              <h2 className='text-[16px] font-semibold '>{t('auto_secd')}</h2>
              <label className="relative inline-flex items-center cursor-pointer mt-2">
                <input
                  checked={formData.automatically_accept_appointments}
                  onChange={(e) => handleUpdate(e.target.checked, "automatically_accept_appointments")}
                  type="checkbox"
                  name="automatically_accept_appointments"
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#B913F0] dark:peer-focus:ring-[#B913F0] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#B913F0]"></div>
              </label>
            </div>
            <REasypass fetchProperty={fetchProperty} activeTab={activeTab} properties={properties} loading={loadingPro} selectedProperty={selectedProperty} />
          </>
        }
        {loadingPro ? 
        <div className="pt-6 flex justify-center items-center"><Loader type="loader" /></div> : properties?.length === 0 && null
        }
      </div>
      {/* {
        !loadingPro && activeTab === 1 && properties?.length === 0 &&
        <div className=' flex justify-center items-center w-full  pt-3'>
          <Loader type="loader" />
        </div>
      } */}
    </>
  )
}

export default ServicerFlashpass