import React from 'react'
import blogImge from '../../assests/images/blogImg.png'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
const Right = () => {
  return (
    <>
      <div className='mx-5 hidden lg:block'>
        <h2 className='text-[30px] font-bold'>News</h2>
        <div className='h-[79vw] overflow-y-auto'>
          {
            Array(5).fill(5).map((list, i) => (
              <div className='my-3 ' key={i}>
                <img src={blogImge} alt="blog_image" />
                <div className='flex items-center py-3'>
                  <span className='bg-blue-500 px-8 py-1 text-white font-semibold'>News</span>
                  <h2 className=' mx-2 text-[18px]'>Construction</h2>
                </div>

                <h2 className='text-[16px] font-semibold'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2>

                <div className='py-4'>
                  <p className='text-[#A5A5A5]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis risus leo, elementum in malesuada an darius ut augue. Cras sit amet lectus et justo feugiat euismod...</p>
                </div>
              </div>
            ))
          }

        </div>
      </div>

      <div className='w-[350px] xs:w-[500px] md:w-[750px]  lg:hidden '>
        <h2 className='text-[30px] font-bol px-4 pb-4 font-semibold'>News</h2>
        <Swiper
          slidesPerView={3}
          breakpoints={{
            350: {
              slidesPerView: 1,
            },
            
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          autoplay={{
            delay: 10000,
          }}
          modules={[Autoplay]}
          className="mySwiper">
          {
            Array(5).fill(5).map((_, i) => (
              <SwiperSlide key={i}>
                <div className='mx-4'>
                  <img src={blogImge} className='w-full' alt="blog_image" />
                  <div className='flex items-center py-3'>
                    <span className='bg-blue-500 px-8 py-1 text-white font-semibold'>News</span>
                    <h2 className=' mx-2 text-[18px]'>Construction</h2>
                  </div>

                  <h2 className='text-[16px] font-semibold'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2>

                  <div className='py-4'>
                    <p className='text-[#A5A5A5]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis risus leo, elementum in malesuada an darius ut augue. Cras sit amet lectus et justo feugiat euismod...</p>
                  </div>
                </div>
              </SwiperSlide>
            ))
          }

        </Swiper>
      </div>
    </>
  )
}

export default Right