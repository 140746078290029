import React from 'react'
import iphone from '../../assests/images/iphone12.png'
// import apple from '../../assests/images/apple.png'
// import android from '../../assests/images/android.png'
import { useTranslation } from 'react-i18next'
// import { MdCheck } from 'react-icons/md'
import { BsCheckCircle } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { SocialButtons } from '../GenericComp/SocialButtons'
const Rentals = () => {
    const { t } = useTranslation()
    return (
        <>

            <div className='mt-[3rem] bg-rental bg-center bg-cover text-white lg:p-6 p-0'>
                <div className='container mx-auto '>
                    <div className='grid lg:grid-cols-2'>
                        <div className='p-7'>
                            <h2 className='underline font-bold  lg:text-[2.3rem] text-[2rem]'>{t('for_rental')}</h2>
                            <span>{t('locate_your_next')}</span>
                            <p className='py-3'>{t('locate_advantage')}</p>
                            <div className='my-2'>
                                <ul>
                                    <li className='my-2'>
                                        <div className='flex items-center'>
                                            <div className='border rounded-full bg-green-500 p-1'>
                                                <BsCheckCircle className='text-white text-[13px]' />
                                            </div>
                                            <h2 className='text-[13px] mx-1 font-semibold'>{t('locate_ad_1')}</h2>
                                        </div>
                                    </li>
                                    <li className='my-2'>
                                        <div className='flex items-center'>
                                            <div className='border rounded-full bg-green-500 p-1'>
                                                <BsCheckCircle className='text-white text-[13px]' />
                                            </div>
                                            <h2 className='text-[13px] mx-1 font-semibold'>{t('locate_ad_2')}</h2>
                                        </div>
                                    </li>
                                    <li className='my-2'>
                                        <div className='flex items-center'>
                                            <div className='border rounded-full bg-green-500 p-1'>
                                                <BsCheckCircle className='text-white text-[13px]' />
                                            </div>
                                            <h2 className='text-[13px] mx-1 font-semibold'>{t('locate_ad_3')}</h2>
                                        </div>
                                    </li>
                                    <li className='my-2'>
                                        <div className='flex items-center'>
                                            <div className='border rounded-full bg-green-500 p-1'>
                                                <BsCheckCircle className='text-white text-[13px]' />
                                            </div>
                                            <h2 className='text-[13px] mx-1 font-semibold'>{t('locate_ad_4')}</h2>
                                        </div>
                                    </li>
                                    <li className='my-2'>
                                        <div className='flex items-center'>
                                            <div className='border rounded-full bg-green-500 p-1'>
                                                <BsCheckCircle className='text-white text-[13px]' />
                                            </div>
                                            <h2 className='text-[13px] mx-1 font-semibold'>{t('locate_ad_5')}</h2>
                                        </div>
                                    </li>

                                </ul>
                                <div className='flex flex-col items-start  '>
                                    <Link to="/rent" className='rounded-full font-semibold px-6 my-4 py-2 bg-[#F79F15] text-white'>{t('locate_ad_6')}</Link>
                                    <Link to="/rent/rommate" className='rounded-full font-semibold px-6 py-2 bg-[#F79F15] text-white'>{t('locate_ad_7')}</Link>
                                </div>
                            </div>

                            <SocialButtons />
                        </div>
                        <div>
                            <img src={iphone} className="w-full object-cover h-full" alt="iphone" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Rentals