import React, { useEffect, useState } from 'react'
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { CreateEmployerInfo } from '../../../lib/Api/RentalAppApi';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { BsTrash } from 'react-icons/bs';
import { Loader } from '../../../utils/loaderButton';
import { useTranslation } from 'react-i18next';
import { compareArrays } from '../../../hooks/ValidateRentalApp';
import GooglePlacesAutocomplete from "react-google-autocomplete";
import { addRentals } from '../../../Redux/UserAuthSlice/RentalSlice';
import { API } from '../../../config/GoolePlace';




const Employee = {
  employer_name: "",
  job_duration_in_months: "",
  duty_hours_per_week: "",
  employer_address: "",
  phone_number: "",
  occupation: "",
  supervisor_name: ""

}



const EmployeInfo = ({ handleNext, handleBack }) => {

  // const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([]);
  const [havePets, setHavePets] = useState(true)


  const { rentalData } = useSelector((state) => state?.rental)
  let rentalApp = rentalData?.employer_info
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const schemawithValidation = yup.object().shape({
    values: yup.array().of(
      yup.object().shape({
        employer_name: yup.string().required(t('emp_name_invalid')),
        job_duration_in_months: yup.string().required(t('job_duration_invalid')),
        duty_hours_per_week: yup.string().required(t('duty_invalid')),
        employer_address: yup.string().required(t('emp_ad_invalid')),
        phone_number: yup.string().required(t('invalid_phone')),
        occupation: yup.string().required(t('oc_invalid')),
        supervisor_name: yup.string().required(t('sup_invalid')),
      })
    ),
  });

  const schemawithoutValidation = yup.object().shape({
    values: yup.array().of(
      yup.object().shape({
        employer_name: yup.string(),
        job_duration_in_months: yup.string(),
        duty_hours_per_week: yup.string(),
        employer_address: yup.string(),
        phone_number: yup.string(),
        occupation: yup.string(),
        supervisor_name: yup.string(),
      })
    ),
  });


  const schema = havePets ? schemawithValidation : schemawithoutValidation;

  const { register, handleSubmit, setValue, reset, control, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema), defaultValues: { values: rentalApp?.length > 0 ? rentalApp : [Employee] } });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'values'
  });

  const OnSubmit = async (data) => {
    let checkEmp = rentalData.have_employment === 0 ? false : true
    const result = !compareArrays(rentalApp, data.values);
    if (result || checkEmp !== havePets) {

      try {
        let payload = {
          "app_id": rentalData?.id,
          "have_employment": havePets,
          "employments":havePets ? data.values : []
        }
        setLoading(true)
        let { res } = await CreateEmployerInfo(payload)
        if (res) {
          dispatch(addRentals(res[0]?.rental_application))
          setLoading(false)
          handleNext(6)
        }
        else {
          setLoading(false)
        }
      }
      catch (err) {
        setLoading(false)

      }
    }
    else {
      handleNext(6)

    }
  }


  const autocompleteOptions = {
    types: ['address'],
    componentRestrictions: { country: 'us' }
  };

  const handleSuggestions = (predictions) => {
    // Filter suggestions based on whether they have a city and zip code
    const filteredSuggestions = predictions.filter((prediction) => {
      if (prediction.address_components) {
        const hasCity = prediction.address_components.some(
          (component) => component.types.includes('locality')
        );

        const hasZipCode = prediction.address_components.some(
          (component) => component.types.includes('postal_code')
        );

        return hasCity && hasZipCode;
      }

      return false;
    });

    setSuggestions(filteredSuggestions);
  };





  useEffect(() => {
    setHavePets(rentalData?.have_employment === 0 ? false : true)
    if (rentalApp > 0) {
      reset(rentalApp)
    }
  }, [rentalApp])




  return (
    <>
      <form onSubmit={handleSubmit(OnSubmit)}>
        <h2 className='text-[20px] font-bold text-center p-8'>{t('e_info')}</h2>

        <div className='flex flex-col mb-4'>
          <label className='mb-2 text-[16px] font-semibold'>{t('are_you_emp')} </label>
          <div className="flex justify-start">
            <label className='relative inline-flex items-center cursor-pointer'>
              <input
                type="checkbox"
                // name={list?.name}
                checked={havePets ? true : false}
                className="sr-only peer"
                defaultValue={havePets}
                onChange={(e) => setHavePets(e.target.checked)}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-[#F79F15] rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#F79F15]"></div>

              <label className="form-check-label inline-block text-gray-800 mx-3">
                {havePets === true ? t('yes') : t('no')}
              </label>

            </label>
          </div>
        </div>
        {havePets &&
          <>
            {
              fields.map((field, index) => (
                <div className='border rounded-md px-4 py-2 my-2' key={index}>
                  <div className='flex justify-end'>
                    {
                      index !== 0 &&
                      <button type='button' onClick={() => remove(index)} className=' p-2 bg-red-500 rounded-md '><BsTrash className='text-white' /></button>
                    }
                  </div>
                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('emp_lbl')}<span className='text-red-500'>*</span> </label>
                      <input type="text"
                        name={`values[${index}].employer_name`}
                        {...register(`values.${index}.employer_name`)}
                        defaultValue={field.employer_name}
                        placeholder={t('emp_lbl')}
                        className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                      {errors.values && errors.values[index] && errors.values[index].employer_name && (
                        <span className='text-red-500 text-sm '>{errors.values[index].employer_name.message}</span>
                      )}

                    </div>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('job_dur_lbl')}<span className='text-red-500'>*</span> </label>
                      <input type="text" placeholder={t('job_dur_lbl')}
                        name={`values[${index}].job_duration_in_months`}
                        {...register(`values.${index}.job_duration_in_months`)}
                        defaultValue={field.job_duration_in_months}
                        className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                      {errors.values && errors.values[index] && errors.values[index].job_duration_in_months && (
                        <span className='text-red-500 text-sm '>{errors.values[index].job_duration_in_months.message}</span>
                      )}
                    </div>
                  </div>
                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('duty_h_lbl')}<span className='text-red-500'>*</span></label>
                      <input type="number"
                        placeholder={t('duty_h_lbl')}
                        name={`values[${index}].duty_hours_per_week`}
                        {...register(`values.${index}.duty_hours_per_week`)}
                        defaultValue={field.duty_hours_per_week}
                        className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                      {errors.values && errors.values[index] && errors.values[index].duty_hours_per_week && (
                        <span className='text-red-500 text-sm '>{errors.values[index].duty_hours_per_week.message}</span>
                      )}
                    </div>
                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('sup_lbl')} <span className='text-red-500'>*</span> </label>
                      <input type="text"
                        name={`values[${index}].supervisor_name`}
                        {...register(`values.${index}.supervisor_name`)}
                        defaultValue={field.supervisor_name}
                        placeholder={t('sup_lbl')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                      {errors.values && errors.values[index] && errors.values[index].supervisor_name && (
                        <span className='text-red-500 text-sm '>{errors.values[index].supervisor_name.message}</span>
                      )}
                    </div>
                  </div>

                  <div className='grid xl:grid-cols-2 lg:grid-cols-2 xl:gap-20 lg:gap-20'>
                    <div className='flex flex-col mt-2'>
                      <label className='mb-2 text-[16px] font-semibold'>{t('contact_phone')}<span className='text-red-500'>*</span> </label>
                      <Controller
                        name={`values[${index}].phone_number`}
                        control={control}
                        defaultValue={field?.phone_number}
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            country={'us'}
                          />
                        )}
                      />
                      {errors.values && errors.values[index] && errors.values[index].phone_number && (
                        <span className='text-red-500 text-sm '>{errors.values[index].phone_number.message}</span>
                      )}
                    </div>

                    <div className='flex flex-col mb-4'>
                      <label className='mb-2 text-[16px] font-semibold'> {t('oc_lbl')}<span className='text-red-500'>*</span> </label>
                      <input type="text"
                        name={`values[${index}].occupation`}
                        {...register(`values.${index}.occupation`)}
                        defaultValue={field.occupation}
                        placeholder={t('oc_lbl')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' />
                      {errors.values && errors.values[index] && errors.values[index].occupation && (
                        <span className='text-red-500 text-sm '>{errors.values[index].occupation.message}</span>
                      )}
                    </div>
                  </div>
                  <div className='flex flex-col mb-4'>
                    <label className='mb-2 text-[16px] font-semibold'>{t('emp_address')}<span className='text-red-500'>*</span></label>
                    <GooglePlacesAutocomplete
                      apiKey={API}
                      options={autocompleteOptions}
                      onSuggestions={handleSuggestions}
                      suggestions={suggestions}
                      defaultValue={field?.employer_address}
                      // onPlaceSelected={(place) => index === 0 ? handlePlaceSelectedIndex0(place) : null}
                      // value={address}
                      onPlaceSelected={async (place) => {
                        const address = place.formatted_address || "";
                        setValue(`values.[${index}].employer_address`, address);
                      }}
                      className='border p-2 w-full focus:outline-none rounded-md my-2'
                      placeholder={t('location_search')}
                    />
                    {/* <input type="text"
                  name={`values[${index}].employer_address`}
                  {...register(`values.${index}.employer_address`)}
                  defaultValue={field.employer_address}
                  placeholder={t('address')} className=' p-2 rounded-md bg-[#F5F6FC] focus:outline-none' /> */}
                    {errors.values && errors.values[index] && errors.values[index].employer_address && (
                      <span className='text-red-500 text-sm '>{errors.values[index].employer_address.message}</span>
                    )}
                  </div>



                </div>
              ))
            }
            <div className='flex justify-end my-4'>
              <button onClick={() => append(Employee)} type='button' className=' px-3 py-2 bg-green-500 rounded-md text-white font-semibold '>{t('add_m_lbl')}</button>
            </div>
          </>
        }

        <div className='flex justify-between items-center'>
          <div className='flex justify-end py-3'>
            <button onClick={() => handleBack(6)} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
          </div>

          <div className='flex justify-end py-3'>
            <button className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
              {
                loading ? <Loader type="saveLoder" />
                  :
                  t('Next')
              }
            </button>
          </div>
        </div>
      </form>

    </>
  )
}

export default EmployeInfo