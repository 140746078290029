import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AddRommate, AddRommateIndex, IspopUp, RommateNew } from '../../../Redux/UserAuthSlice/RommateSlice'
import { useTranslation } from 'react-i18next'

const CheckCollege = ({ setActives }) => {
  const [active, setActive] = useState(null)

  const dispatch = useDispatch()
  const { renterQuestion } = useSelector((state) => state.rommate)


  const handleCheckNext = (value) => {
    setActive(value)
    if (value === 0) {
      setActives(1)
      setActive(0)
      dispatch(AddRommate({
        "college_student": 1
      }))
    }
    else {
      dispatch(AddRommate({
        "college_student": 0
      }))
      dispatch(AddRommate({
        "on_campus": 0
      }))
      // dispatch(AddRommateIndex(4))
      // dispatch(IspopUp(false))
      if (renterQuestion?.offCampus?.is_complete === true) {
        setActives(2)
      }
      else {
        dispatch(RommateNew(true))
        dispatch(AddRommateIndex(3))
        dispatch(IspopUp(false))
        // dispatch(RommateNew(true))
        // dispatch(IspopUp(false))
      }

    }
  }

  const { t } = useTranslation()

  return (
    <>
      <h2 className='text-center mt-5 text-[20px] font-bold'>{t('college_stu')}</h2>
      <div className="grid lg:grid-cols-2 gap-5  pt-[10px] ">
        <div className='group'>
          <div onClick={() => handleCheckNext(0)} className={`${active === 0 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  border max-w-[300px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <h2 className='text-[1rem] font-bold'>{t('yes')} </h2>

          </div>
        </div>
        <div className='group'>
          <div onClick={() => handleCheckNext(1)} className={`${active === 1 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  border max-w-[300px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <h2 className='text-[1rem] font-bold '>{t('no')}</h2>
          </div>
        </div>
      </div>


    </>
  )
}

export default CheckCollege