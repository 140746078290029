import React, { useState } from 'react'
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from "yup";
import { Loader } from '../../../../utils/loaderButton';
import {
    formatCreditCardNumber,
    formatCVC,
    formatMonth,
} from "../../../../utils/CardCheckMethod";
import { RegisterCard } from '../../../../lib/Api/RentalAppApi';

// const schema = yup.object({
//     first_name: yup.string().required(),
//     last_name: yup.string().required(),
//     date_of_birth: yup.string().required(),
//     social_security_number: yup.string().required(),
//     marital_status: yup.string().required(),
//     phone: yup.string().required(),
//     license_number: yup.string().required(),
// });

const cardDetail = {
    number: "",
    experyDate: "",
    SecourtyCode: "",

}

const CreatePayment = () => {
    const [loading, setLoading] = useState(false)
    const [acoountDetail, setAcoountDetail] = useState(cardDetail);

    // const { register, reset, watch, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

    const handleChange = (event) => {
        if (event.target.name === "number") {
            event.target.value = formatCreditCardNumber(event.target.value);
        } else if (event.target.name === "experyDate") {
            event.target.value = formatMonth(event.target.value);
        } else if (event.target.name === "SecourtyCode") {
            event.target.value = formatCVC(event.target.value);
        }
        setAcoountDetail({
            ...acoountDetail,
            [event.target.name]: event.target.value,
        });
    };



    const OnSubmit = async (e) => {
        e.preventDefault()
        let parts = experyDate.split('/');
        let month = parts[0];
        let year = parts[1];

        let detail = {
            card_number: number,
            exp_month: month,
            exp_year: year,
            cvc: SecourtyCode
        }
        setLoading(true)
        let { res, error } = await RegisterCard(detail)
        if (res) {
            setLoading(false)
            setAcoountDetail(cardDetail)
        }
        else if (error) {
            setLoading(false)
        }
    }


    const {
        number,
        experyDate,
        SecourtyCode,

    } = acoountDetail;


    return (
        <>
            <div className='p-8'>
                <form onSubmit={OnSubmit}>
                    <div className='grid grid-cols-2 gap-20'>
                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>Card Number</label>
                            <input
                                pattern="[\d| ]{16,22}"
                                type="tel"
                                className="p-2 rounded-md bg-[#F5F6FC] focus:outline-none"
                                value={number}
                                onChange={(e) => handleChange(e)}
                                name="number" placeholder="Card Number " />
                            {/* {errors.first_name && (
                                <p className="text-red-500 text-sm ">{errors.first_name.message}</p>
                            )} */}
                        </div>
                        <div className='flex flex-col mt-2'>
                            <label className='mb-2 text-[16px] font-semibold'>Expiry Date </label>
                            <input
                                
                                type="tel"
                                value={experyDate}
                                onChange={(e) => handleChange(e)}
                                className='p-2 rounded-md bg-[#F5F6FC] focus:outline-none'
                                name="experyDate" placeholder="mm/yy" />
                            {/* {errors.phone && (
                                <p className="text-red-500 text-sm ">{errors.phone.message}</p>
                            )} */}
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-20'>

                        <div className='flex flex-col mb-4'>
                            <label className='mb-2 text-[16px] font-semibold'>Cvc </label>
                            <input
                                pattern="\d{3,4}"
                                type="number"
                                className='p-2 rounded-md bg-[#F5F6FC] focus:outline-none'
                                value={SecourtyCode}
                                onChange={(e) => handleChange(e)}
                                maxLength={3} name="SecourtyCode" placeholder="Security Code " />
                            {/* {errors.social_security_number && (
                                <p className="text-red-500 text-sm ">{errors.social_security_number.message}</p>
                            )} */}
                        </div>
                    </div>




                    <div className='flex justify-end py-3'>
                        {
                            loading ? <Loader type="saved" />
                                :
                                <button className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{`Submit`}</button>
                        }
                    </div>
                </form>
            </div>

        </>
    )
}

export default CreatePayment