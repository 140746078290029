import React, { useState } from 'react'
import sl1 from '../../assests/images/slider5.png'
import logo from '../../assests/images/logo.png'
import { AiFillEye, AiFillEyeInvisible, AiOutlineClose, AiOutlineKey } from 'react-icons/ai'
// import { MdArrowLeft } from 'react-icons/md';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { ResetPassword } from '../../lib/Api/UserApi';
import { Loader } from '../../utils/loaderButton';
import { useDispatch, useSelector } from 'react-redux';
import { signin } from '../../Redux/UserAuthSlice/UserAuthSlice';
import { useNavigate } from 'react-router-dom';
import LanguageDropdonw from '../../utils/Dropdowns/LanguageDropdonw';




const NewPassword = ({ close, email, t , setActive }) => {
  const schema = yup.object({
    password: yup.string()
      .required(t('invalid_password'))
      .min(3, t('password_charctor')),
    confirmpassword: yup.string()
      .required(t('invalid_password'))
      .oneOf([yup.ref('password')], t('match_password')),
  
  });
  
  const {language} = useSelector((state) => state.userAuth)
  
  const [showPass, setShowPass] = useState(false)
  const [showPass2, setShowPass2] = useState(false)
  const [loading, setLoading] = useState(false)


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { register, watch, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });


  const onSubmit = async (data) => {
    let payload = {
      email: email,
      password: data?.password,
      r_password: data?.confirmpassword,
      lang: language ? language : "",

    }
    setLoading(true)
    let { res, error } = await ResetPassword(payload)

    if (res !== undefined) {
      reset()
      let UserData = Object.assign({}, ...res)
      if (UserData?.user?.role === "servicer") {
        reset()
        setLoading(false)
        dispatch(signin(UserData))
        navigate('/servicerPanel')
      }
      else {
        dispatch(signin(UserData))
        navigate('/dashboard')
      }
    }
    else if (error) {
      setLoading(false)

    }

    else {
      setLoading(false)

    }
  }


  return (
    <>
      <div className='lg:flex'>
        <div className='lg:w-[60%] w-full relative hidden lg:block'>
          <img src={sl1} className=" h-full object-cover" alt="slider" />
          <div className='absolute bottom-[10%] left-[20%]'>
            <h2 className='text-[20px] text-white font-semibold'>{t('welcome')}</h2>
          </div>

        </div>
        <div className='w-full px-5 pb-5'>
          <div className='flex justify-between items-center '>
            <img src={logo} className="h-[95px] object-contain" alt="logo" />
            <div>
              <LanguageDropdonw />
            </div>
          </div>
          <div className=' flex justify-end items-end cursor-pointer' onClick={() => setActive(3)} >
            {/* <div className='border w-[35px] rounded-md shadow-md p-2'>
              <MdArrowLeft />
            </div> */}
            <div className='border w-[35px] rounded-md shadow-md p-2' onClick={() => close()}>
              <AiOutlineClose />
            </div>


          </div>
          <div className='pt-5'>
            <h2 className='lg:text-[2rem] text-[1.5rem] font-bold'>{t('new_pass')}</h2>
            <p className='text-[14px]'>
              {t('chose_pass')}
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='mt-5'>
              <div className='flex flex-col border-b w-full pt-3'>
                <label className='font-semibold'>{t('password')}</label>
                <div className='flex items-center'>
                  <AiOutlineKey />
                  <input type={showPass ? 'text' :'password'} {...register('password')} className='p-2  w-full focus:outline-none' placeholder={`${t('password')}`} />
                  {
                    showPass ?
                      <AiFillEyeInvisible onClick={() => setShowPass(false)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                      :
                      <AiFillEye onClick={() => setShowPass(true)} className={`${watch('password') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />

                  }
                </div>
                {errors.password && (
                  <p className="text-red-500 text-sm ">{errors.password.message}</p>
                )}
              </div>
              <div className={`flex flex-col pt-3 border-b w-full ${watch('confirmpassword') && ' border-b border-[#F79F15]'}`}>
                <label className='font-semibold'>{t('c_password')}</label>
                <div className='flex items-center'>
                  <AiOutlineKey />
                  <input type={showPass2 ? 'text' :'password'} {...register('confirmpassword')} className='p-2  w-full focus:outline-none' placeholder={`${t('c_password')}`} />
                  {
                    showPass2 ?
                      <AiFillEyeInvisible onClick={() => setShowPass2(false)} className={`${watch('confirmpassword') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />
                      :
                      <AiFillEye onClick={() => setShowPass2(true)} className={`${watch('confirmpassword') ? 'text-[#F79F15]' : 'text-black'} text-[23px] mr-2 mt-3`} />

                  }
                </div>
                {errors.confirmpassword && (
                  <p className="text-red-500 text-sm ">{errors.confirmpassword.message}</p>
                )}
              </div>


              <div className='pt-6'>
                {
                  loading ?
                    <Loader />
                    :
                    <button className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white'>{t('already_registered_singin')}</button>
                }
              </div>



            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default NewPassword