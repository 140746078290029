import React, { useState, useEffect } from 'react'
import { GetTimeSlot, SechedualAppointment } from '../../../lib/Api/RentalAppApi'
import { Loader } from '../../../utils/loaderButton'
import { FiCheck } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { MdClose } from 'react-icons/md'
import { useLocation } from 'react-router-dom'

const BookAppointment = ({ property, setIsBooked }) => {
    const [step, setStep] = useState(1)
    const [step2type, setStep2type] = useState('')
    // const currentDate = new Date().toISOString().split('T')[0];
    // const [secduleDate, setSecduleDate] = useState(currentDate)
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    const today = currentDate.getDate();
    // Calculate the first day of next month
    const nextMonthDate = new Date(currentYear, currentMonth, today);
    const nextYear = nextMonthDate.getFullYear();
    const nextMonth = nextMonthDate.getMonth() + 1; // Months are zero-based, so add 1
    // Format the dates in 'yyyy-mm-dd' format
    const minDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${today.toString().padStart(2, '0')}`;
    const maxDate = `${nextYear}-${nextMonth.toString().padStart(2, '0')}-${today.toString().padStart(2, '0')}`;
    const defaultDate = minDate;

    const [secduleDate, setSecduleDate] = useState(defaultDate);


    const [secduleTime, setSecduleTime] = useState('')
    const [activeTime, setActiveTime] = useState(null)
    const [timeSlot, setTimeSlots] = useState([])
    const [loading, setLoading] = useState(false)
    const [timeloading, setTimeLoading] = useState(false)

    const location = useLocation().pathname

    let fetchData = async (changeFormate) => {
        let payload = {
            "date_of_appointment": changeFormate,
            // "property_id": property?.id
        }
        if (property?.property === null) {
            payload.roommate_id = property?.roommate?.id || property?.id
        }
        else {
            payload.property_id = property?.property?.id || property?.id
        }

        setTimeLoading(true)
        let { res } = await GetTimeSlot(payload)
        let obj = Object.assign({}, ...res)
        setTimeSlots(obj?.available_times)
        if (res) {
            setTimeLoading(false)
        }
    }



    const handleDateChange = (event) => {
        const inputValue = event.target.value;
        setSecduleDate(inputValue);
        let changeFormate = moment(inputValue).format('MM/DD/YYYY')
        fetchData(changeFormate)
    };


    const handleSecdule = async () => {
        let changeFormate = moment(secduleDate).format('MM/DD/YYYY')
        setLoading(true)
        // debugger
        let payload = {
            // property_id: property.property_id || property?.id,
            date_of_appointment: changeFormate,
            time_of_appointment: secduleTime

        }
        if (property?.property === null) {
            payload.roommate_id = property?.roommate?.id || property?.id
            payload.appointment_type = "in-person"
        }
        else {
            payload.property_id = property.property_id || property?.id
            payload.appointment_type = step2type

        }
        let { res, error } = await SechedualAppointment(payload)
        if (res) {
            setLoading(false)
            if (location === "/reazy") {
                setIsBooked(true)
            }
            setStep(3)
        }
        else if (error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (property?.roommate && Object.keys(property?.roommate)?.length > 0) {
            setStep(2)
        }
    }, [])

    useEffect(() => {

        if (step === 2) {
            const currentDateTime = new Date();
            const currentHour = currentDateTime.getHours();
            const currentMinutes = currentDateTime.getMinutes();
            if (currentHour > 20 || (currentHour === 20 && currentMinutes >= 30)) {
                const nextDay = new Date(currentDateTime);
                nextDay.setDate(currentDateTime.getDate() + 1);
                const formattedDate = moment(nextDay).format('YYYY-MM-DD');
                setSecduleDate(formattedDate);
                let changeFormate = moment(currentDateTime).format('MM/DD/YYYY')
                fetchData(changeFormate)
            } else {
                let changeFormate = moment(currentDateTime).format('MM/DD/YYYY')
                fetchData(changeFormate)
            }

        }
    }, [step])


    const { t } = useTranslation()
    return (
        <>

            {
                step === 1 &&
                <div className='xl:px-10 lg:px-10 md:px-10 px-5 pb-5'>

                    <div onClick={() => { return setStep(2), setStep2type('in-person') }} className='border cursor-pointer p-5 w-[200px] text-center font-semibold bg-white shadow-md rounded-md'>
                        {t('in_person')}
                    </div>
                    {property?.roommate && Object.keys(property?.roommate)?.length > 0 ? "" :
                        <div onClick={() => { return setStep(2), setStep2type('virtual') }} className='border cursor-pointer my-5 p-5 w-[200px] text-center font-semibold bg-white shadow-md rounded-md'>
                            {t('virtual')}
                        </div>
                    }

                </div>
            }
            {
                step === 2 &&
                <div className='xl:px-10 lg:px-10 md:px-10 px-5 pb-5'>

                    <div className='border rounded-md flex items-center p-2 border-[#F79F15]'>
                        <h2 className='font-semibold w-[40%] '>{t('slect_dat')}</h2>
                        <input type="date"
                            min={minDate}
                            max={maxDate}
                            value={secduleDate} pattern="\d{1,2}/\d{1,2}/\d{4}" onChange={(e) => handleDateChange(e)} className='mx-2 border w-full p-2' />


                    </div>
                    {
                        timeloading ?
                            <div className='pt-5'>
                                <Loader type="loadData" />
                            </div>
                            :
                            <>
                                {timeSlot?.length > 0 &&
                                    <h2 className='font-semibold py-4'>{t('slect_time')}</h2>
                                }
                                <div className='flex flex-wrap xl:justify-between md:justify-between lg:justify-between xl:max-w-[400px]  lg:max-w-[400px] md:max-w-[400px]  items-center'>
                                    {
                                        timeSlot.map((t, i) => (
                                            <div onClick={() => { return setActiveTime(i), setSecduleTime(t?.booked === false ? t?.time : "") }} className={`border relative rounded-md xl:w-[90px] lg:w-[90px] md:w-[90px] w-[80px] xl:mx-0 lg:mx-0 md:mx-0 mx-1 text-[14px] cursor-pointer text-center py-2 my-2 ${activeTime === i && t?.booked === false ? 'bg-[#F79F15] text-white' : 'bg-white text-[#F79F15] border border-[#F79F15]'}`} key={i}>{t?.time}
                                                {t?.booked ?
                                                    <div className='absolute top-1 left-7'>
                                                        <MdClose size={30} className='text-black' />
                                                    </div>
                                                    : ""}
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center xl:gap-0 lg:gap-0 md:gap-0 gap-2 pt-3'>
                                    <div className='flex items-center gap-2'>
                                        <div className='border flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                            <MdClose size={20} />
                                        </div>
                                        <p className='text-[16px] font-medium'>{t('booked')}</p>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <div className='border border-[#F79F15] flex justify-center items-center rounded-md w-[30px] h-[30px]'>
                                        </div>
                                        <p className='text-[16px] font-medium'>{t('avail')}</p>
                                    </div>
                                    <div className='flex items-center gap-2'>
                                        <div className='border flex justify-center items-center rounded-md w-[30px] bg-[#F79F15] h-[30px]'>
                                        </div>
                                        <p className='text-[16px] font-medium'>{t('selected')}</p>
                                    </div>
                                </div>
                            </>
                    }
                    {timeSlot?.length > 0 &&
                        <div className='flex justify-center items-center mt-5'>

                            {
                                loading ?
                                    <Loader type="saved" />
                                    :
                                    <button disabled={secduleTime !== "" ? false : true} onClick={() => handleSecdule()} className='text-center disabled:bg-opacity-[0.5] rounded-md bg-[#F79F15] w-full py-2 text-white'>{t('done')}</button>
                            }
                        </div>
                    }

                </div>
            }
            {
                step === 3 &&
                <div className='xl:px-10 lg:px-10 md:px-10 px-5 pb-5'>
                    <div className='bg-gray-100 rounded-md p-5 '>
                        <div className='flex justify-center items-center'>
                            <div className='border bg-green-600 rounded-full p-3'>
                                <FiCheck className="text-[30px] text-white" />
                            </div>
                        </div>
                        <h2 className='text-center pt-5 font-semibold text-[20px]'>{t('deliver')}</h2>
                        <p className='max-w-[300px] m-auto text-center'>{property?.roommate === null ? t('deliver_thank_you') : t('deliver_thank_you_roomate')}</p>

                    </div>
                </div>
            }

        </>
    )
}

export default BookAppointment