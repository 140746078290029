import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import {  Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import blogImge from '../../assests/images/blogImg.png'
const RelatedBlogs = () => {
    return (
        <>
            <Swiper
                slidesPerView={3}
                autoplay={{
                    delay: 10000,
                }}
                breakpoints={{
                    350: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                modules={[Autoplay]}
                className="mySwiper">
                {
                    Array(20).fill(20).map((_, i) => (
                        <SwiperSlide key={i}>
                            <div className='mx-4'>
                                <img src={blogImge} alt="blog_image" />
                                <div className='flex items-center py-3'>
                                    <span className='bg-blue-500 px-8 py-1 text-white font-semibold'>News</span>
                                    <h2 className=' mx-2 text-[18px]'>Construction</h2>
                                </div>

                                <h2 className='text-[16px] font-semibold'>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2>

                                <div className='py-4'>
                                    <p className='text-[#A5A5A5]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis risus leo, elementum in malesuada an darius ut augue. Cras sit amet lectus et justo feugiat euismod...</p>
                                </div>
                                <button className='px-6 py-2.5 rounded-md bg-[#000000] text-white'>Read more </button>
                            </div>
                        </SwiperSlide>
                    ))
                }

            </Swiper>
        </>
    )
}

export default RelatedBlogs