import React, { useState, useEffect } from 'react'
import { stripePromise } from '../../config/Stripe'
import axios from 'axios'
import { useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../utils/loaderButton'
import { useDispatch, useSelector } from 'react-redux';
import { EditUserProfile } from '../../lib/Api/UserApi'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from 'moment'
import { updateUser } from '../../Redux/UserAuthSlice/UserAuthSlice'


const StripLoader = ({ UserInfo, setStripLoading, login }) => {
    const [strips, setStrips] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [registerForm, setRegisterFrom] = useState(false)

    const dispatch = useDispatch()

    const { t } = useTranslation()
    const language = useSelector((state) => state.userAuth?.language)
    const user = useSelector((state) => state.userAuth?.userInfo)

    const schema = yup.object({
        first_name: yup.string().required(t('invalid_fname')),
        last_name: yup.string().required(t('invalid_lname')),
        email: yup.string().email(t('invalid_email')).required(t('emial_req')),
        password: yup.string()
            .required(t('invalid_password'))
            .min(3, t('password_charctor')),

    });

    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });



    const navigate = useNavigate()


    const UpdateProfile = async (data) => {
        setLoadingUpdate(true)
        let { res } = await EditUserProfile(data)
        if (res) {
            setRegisterFrom(false)
            setStripLoading(false)
            setLoadingUpdate(false)
            if (user?.userRole === "servicer") {
                navigate('/servicerPanel')
            }
            else {
                navigate('/dashboard')
            }
        }
    }


    let updateUserInfo = async () => {
        try {
            const formData = new FormData();
            formData.append('id_verified_at', moment().format('YYYY-MM-DD'))
            let { res } = await EditUserProfile(formData)
            if (res) {
                let UserData = Object.assign({}, ...res)
                dispatch(updateUser(UserData))
                if (user?.userRole === "servicer") {
                    navigate('/servicerPanel')
                }else{
                    navigate('/dashboard')
                }
            }

        }
        catch (err) { }
    }



    const StripFunc = () => {
        setLoading(true)
        axios.get('https://api.leased.homes/index.php/api/v1/user/id-verification-session', {
            headers: {
                'token': UserInfo?.token
            }
        })
            .then(async (res) => {
                const { error } = await strips?.verifyIdentity(res?.data?.client_secret)
                if (error) {
                    setLoading(false)
                    toast.error(error)
                    if (user?.userRole === "servicer") {
                        if (login) {
                            setRegisterFrom(true)
                        }
                        else {
                            navigate('/servicerPanel')
                        }
                    }
                    else {
                        if (login) {
                            setRegisterFrom(true)
                        }
                        else {
                            navigate('/dashboard')
                        }
                    }
                }
                else {
                    // setStripLoading(false)
                    setLoading(false)
                    if (user?.userRole === "servicer") {
                        if (login) {
                            setRegisterFrom(true)
                        }
                        else {
                            updateUserInfo()
                            // navigate('/servicerPanel')
                        }
                    }
                    else {
                        if (login) {
                            setRegisterFrom(true)
                        }
                        else {
                            updateUserInfo()
                            // navigate('/dashboard')
                        }
                    }
                }
            })
            .catch((error) => {
            })
    }


    const handleStrip = () => {
        StripFunc()
    }


    useEffect(() => {
        let getStrip = async () => {
            let strip = await stripePromise
            setStrips(strip)
        }
        let payload = {
            first_name: user?.first_name,
            last_name: user?.last_name,
            email: user?.email,
        }
        reset(payload)
        getStrip()
    }, [])



    return (
        <>

            {registerForm ?
                <div className=''>
                    <div className=' border-b'>
                        <h1 className='font-bold text-[20px] p-3 '>{t('update_profile')}</h1>
                    </div>
                    <form onSubmit={handleSubmit(UpdateProfile)} className='p-5'>
                        <div className='grid grid-cols-2 gap-5'>
                            <div className="">
                                <div className='flex flex-col mb-4'>
                                    <label>{t('contact_first_name')}</label>
                                    <input type="text" {...register('first_name')} placeholder='Jhon' className='w-full border rounded h-[35px] px-2 mt-2' />
                                    {errors.first_name && (
                                        <p className="text-red-500 text-sm ">{errors.first_name.message}</p>
                                    )}
                                </div>
                                <div className='flex flex-col mb-4'>
                                    <label>{t('contact_last_name')}</label>
                                    <input type="text" {...register('last_name')} placeholder='doe' className='w-full border rounded h-[35px] px-2 mt-2' />
                                    {errors.last_name && (
                                        <p className="text-red-500 text-sm ">{errors.last_name.message}</p>
                                    )}
                                </div>
                            </div>
                            <div className="">
                                <div className='flex flex-col mb-4'>
                                    <label>{t('contact_email')}</label>
                                    <input type="text" {...register('email')} placeholder='Jhon@gmail.com' className='w-full border rounded h-[35px] px-2 mt-2' />
                                    {errors.email && (
                                        <p className="text-red-500 text-sm ">{errors.email.message}</p>
                                    )}
                                </div>
                                <div className='flex flex-col mb-4'>
                                    <label>{t('password_text')}</label>
                                    <input type="password" {...register('password')} placeholder='*******' className='w-full border rounded h-[35px] px-2 mt-2' />
                                    {errors.password && (
                                        <p className="text-red-500 text-sm ">{errors.password.message}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-center items-center'>
                            <button type="submit" className='text-center my-3 rounded-md bg-[#F79F15] w-[50%] py-2 text-white'>
                                {
                                    loadingUpdate ?
                                        <Loader />
                                        :
                                        t('update_profile')
                                }
                            </button>
                        </div>
                    </form>
                </div>
                :
                <div className="max-w-[600px] m-auto text-center p-5">
                    <h2 className='font-bold py-4 text-[20px] '>{t('scan_heading')}</h2>
                    <p className='text-justify' >{t('scan_id')}<Link target={"_blank"} className='text-[#F79F15]' to={language === "sp" ? "/sp/privacy" : "/privacy"}>{t('privacy_ploicy')}</Link></p>
                    <button onClick={() => handleStrip()} className='text-center my-3 rounded-md bg-[#F79F15] w-[50%] py-2 text-white'>
                        {
                            loading ?
                                <Loader />
                                :
                                t('continue')}
                    </button>
                </div>
            }





            {/* <StripFunc /> */}
        </>
    )
}

export default StripLoader