import React, { useState, useEffect } from 'react'
import blogImg from '../../assests/images/blogImg.png'
import LikeDislikePopup from '../../utils/Popup/LikeDisLike';
import { Loader } from '../../utils/loaderButton'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next'
import locat from '../../assests/svg/Icon material-location-on.svg'
import PdfPreview from '../../components/RenatalPanelComp/RentalApp/PdfPreview'
import { ConfirmReporting } from '../../lib/Api/RentalAppApi'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import Confirermation from '../Property/Confirermation'
import r1 from '../../assests/images/r1.png'
import r2 from '../../assests/images/r2.png'
import r3 from '../../assests/images/r3.png'
import r4 from '../../assests/images/r4.png'
import r5 from '../../assests/images/r11.png'
import r6 from '../../assests/images/r22.png'
import r7 from '../../assests/images/r33.png'
import r8 from '../../assests/images/r44.png'
const ReazyRentalsDetail = ({ detail, swipeLeft, close }) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [properties, setProperties] = useState({})
    const [pdf, setPdf] = useState({})
    const [type, setType] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [popupType, setPopuptype] = useState("")
    const [swiperHeight, setSwiperHeight] = useState('auto');


    const handleImageLoad = (e, i) => {
        const imageHeight = e.target.clientHeight;
        setSwiperHeight(imageHeight);
    };
    const { t } = useTranslation()


 
    const handleConfirm = async () => {
        setConfirmLoading(true)
        let payload = {
            "property_id": properties?.id
        }
        let { res } = await ConfirmReporting(payload)
        if (res) {
            setConfirmLoading(false)
            swipeLeft(properties?.id)
            setType(false)
            close()
        }
        else {
            setConfirmLoading(false)

        }
        // toast.success(t('property_report'))
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                setTimeout(() => {
                    setLoading(false)
                    setProperties(detail)
                }, 2000);
            } catch (error) {
            }
        })();
    }, [detail])

    // console.log("detail", detail)

    // const url = `https://www.google.com/maps?q=${detail?.complete_address}`

    return (
        <>
            {show && <LikeDislikePopup permition={show} Toggle={setShow} type={type} />}
            {type && <DynamicPopup close={() => setType(false)} modalOpen={type} data={popupType === "confirm" ? <Confirermation handleConfirm={handleConfirm} close={() => setType(false)} confirmLoading={confirmLoading} /> : <PdfPreview pdf={pdf} t={t} />} />}
            {
                loading ?
                    <div className="flex justify-center items-center text-[120px]">
                        <Loader type="loadData" />
                    </div>
                    :
                    <div className="container mx-auto pt-4">
                        <div className="flex justify-center flex-col items-center">

                            {/* center section  */}

                            <div className="xl:w-[700px]  lg:w-[700px] md:w-full w-[500px] max-w-full col-span-3  xl:mx-auto lg:mx-auto md:mx-auto  xl:px-10 lg:px-10  ">
                                <div className='relative  shadow'>
                                    <Swiper
                                        pagination={{
                                            type: 'progressbar',
                                        }}
                                        navigation={true}
                                        modules={[Pagination, Navigation]}
                                        className="h-[400px] w-full"
                                    >
                                        {
                                            detail?.renter_reazy_settings?.images?.length > 0 ?
                                                detail?.renter_reazy_settings?.images?.map((item, i) => (
                                                    <SwiperSlide key={i} className='bg-[#efefef]'>
                                                        <img
                                                            src={item || blogImg}
                                                            alt="blogImg"
                                                            className='h-full object-contain'
                                                            onLoad={(e) => handleImageLoad(e, i)}
                                                        />
                                                    </SwiperSlide>
                                                ))
                                                :
                                                <SwiperSlide className='bg-[#efefef]'>
                                                    <img src={detail?.user_image || blogImg} alt="blogImg" className='h-full' />
                                                </SwiperSlide>

                                        }

                                    </Swiper>
                                </div>
                                <div className='pt-2'>
                                    <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap justify-between items-center   pb-2'>
                                        <div>
                                            <ul>
                                                <li>
                                                    <div className='flex items-center'>
                                                        <img src={detail?.user_image} alt="detail" className='object-cover w-[35px] h-[35px] rounded-full' />
                                                        <h2 className='px-2 text-black font-bold xl:text-[25px] lg:text-[25px] md:text-[25px] text-[16px] '>{detail?.first_name || "N/A"}</h2>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={`flex item-center pt-1 `}>
                                                        <div className='pr-1'>
                                                            <img src={locat} alt="detail" className='object-cover' />
                                                        </div>

                                                        {detail?.renter_reazy_settings?.state && detail?.renter_reazy_settings?.city
                                                            ? `${detail?.renter_reazy_settings?.state} ${detail?.renter_reazy_settings?.city}`
                                                            : detail?.renter_reazy_settings?.state
                                                                ? detail?.renter_reazy_settings?.state
                                                                : detail?.renter_reazy_settings?.city
                                                                    ? detail?.renter_reazy_settings?.city
                                                                    : null}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p className=''>
                                                {t('bi_incom')}
                                                {detail?.renter_reazy_settings?.bi_weekly_income_range?.map((rang, index) => (
                                                    <span className="pl-2" key={index}>{rang}{index === 0 ? ' - ' : ''}</span>
                                                ))}
                                            </p>


                                            <p className=''>
                                                {t('rr_incom')}
                                                {detail?.renter_reazy_settings?.rent_affording_range?.map((rang, index) => (
                                                    <span className="pl-2" key={index}>{rang}{index === 0 ? ' - ' : ''}</span>
                                                ))}
                                            </p>

                                            <p className=''>
                                                {t('cr_incom')}
                                                {detail?.renter_reazy_settings?.credit_score_range?.map((rang, index) => (
                                                    <span className="pl-2" key={index}>{rang}{index === 0 ? ' - ' : ''}</span>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="pt-4">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] '>{t('desc')}</h2>
                                    </div>
                                    <p className='pt-1'> {detail?.renter_reazy_settings?.description}</p>
                                </div>

                                <div className="pt-4">
                                    <div className='flex items-center'>
                                        <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] '>{t('detail')}</h2>
                                    </div>
                                    <div>
                                        <ul className='flex items-center justify-center pt-4'>
                                            <li >
                                                <div className='flex flex-col items-center'>
                                                    <img src={detail?.renter_reazy_settings?.have_pets === 1 ? r5 : r1} className='object-cover w-[50%] border rounded-xl bg-white p-1' />
                                                    <p className="font-semibold text-[16px] text-center ">{t('no_pets')}</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className='flex flex-col items-center'>
                                                    <img src={detail?.renter_reazy_settings?.have_children === 1 ? r6 : r2} className='object-cover w-[50%] border rounded-xl bg-white p-1' />
                                                    <p className="font-semibold text-[16px] text-center ">{t('no_child')}</p>
                                                </div>
                                            </li>
                                            {
                                                <li>
                                                    <div className='flex flex-col items-center pt-5'>
                                                        <img src={detail?.renter_reazy_settings?.qualify_for_federal_aid === 1 ? r7 : r3} className='object-cover w-[50%] border rounded-xl bg-white p-1' />
                                                        <p className="font-semibold text-[16px] text-center ">{t('no_fed')}</p>
                                                    </div>
                                                </li>
                                            }
                                            {
                                                <li>
                                                    <div className='flex flex-col items-center pt-5'>
                                                        <img src={detail?.renter_reazy_settings?.looking_for_smoke_free === 1 ? r8 : r4} className='object-cover w-[50%] border rounded-xl bg-white p-1' />
                                                        <p className="font-semibold text-[16px] text-center ">{t('no_smoke')}</p>
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className='flex items-center pt-4'>
                                    <h2 className='font-semibold xl:text-[20px] lg:text-[20px] md:text-[20px] text-[16px] '>{t('leased_p')}</h2>
                                </div>
                                <div className=''>
                                    <div>
                                        <h2 className='text-[18px] text-gray-400'>{detail?.renter_reazy_settings?.lease_period}</h2>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
            }

        </>
    )
}

export default ReazyRentalsDetail