
import AWS from 'aws-sdk'

export const S3_BUCKET = 'leased';
const REGION = 'us-east-1';


AWS.config.update({
    accessKeyId: "AKIA5CYBVB45TAWNFROA",
    secretAccessKey: "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",
});



export const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})
