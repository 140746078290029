import React from 'react'
import OffnCampus from './OffCampus'
import OnCampus from './OnCampus'
import Questions from './Questions'
// import CheckCollege from './CheckCollege'
// import CheckCampus from './CheckCampus'
import PersonInfo from './PersonInfo'
import { useSelector } from 'react-redux'
import Interests from './Interests'
import ExActivities from './ExActivities'
import CurrentList from './CurrentList'
import UniversityDetail from './UniversityDetails'
import PersonalInfo from '../../RenatalPanelComp/FilterRommate/PersonalInfo'

const CreateRoommate = () => {

  const {romateIndex} = useSelector((state) => state.rommate)
  return (
    <div>
      {/* {romateIndex === 0 && <CheckCollege />}
      {romateIndex === 1 && <CheckCampus />} */}
      {romateIndex === 2 && <PersonInfo />}
      {romateIndex === 3 && <OnCampus />}
      {romateIndex === 4 && <OffnCampus />}
      {romateIndex === 5 && <Questions />}
      {romateIndex === 6 && <Interests />}
      {romateIndex === 7 && <ExActivities />}
      {romateIndex === 8 && <CurrentList />}
      {romateIndex === 9 && <UniversityDetail />}
      {romateIndex === 10 && <PersonalInfo />}
    </div>
  )
}

export default CreateRoommate