import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// import { GetSessionCheckout } from '../../lib/Api/RentalAppApi';
import { Loader } from '../../utils/loaderButton';
import { stripePromise } from '../../config/Stripe'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { EditUserProfile } from '../../lib/Api/UserApi';
import moment from 'moment';
import { updateUser } from '../../Redux/UserAuthSlice/UserAuthSlice';
const IdVerified = ({ type, setLoading, close }) => {
    const { t } = useTranslation()
    const [loadingSession, setLoadingSession] = useState(false)
    // const websiteLink = window.location.origin;
    const token = useSelector((state) => state.userAuth.loginInfo.token)
    // const user = useSelector((state) => state?.userAuth?.userInfo)

    const dispatch = useDispatch()

    let updateUserInfo = async () => {
        try {
            const formData = new FormData();
            formData.append('id_verified_at', moment().format('YYYY-MM-DD'))
            let { res } = await EditUserProfile(formData)
            if (res) {
                setLoading(false)
                toast.success(t('update_user'))
                let UserData = Object.assign({}, ...res)
                dispatch(updateUser(UserData))
                close()
            }
           
        }
        catch (err) { }
    }



    const handleClick = async () => {
        const stripe = await stripePromise;

        setLoadingSession(true)
        axios.get('https://api.leased.homes/index.php/api/v1/user/id-verification-session', {
            headers: {
                'token': token
            }
        })
            .then(async (res) => {
                const { error } = await stripe?.verifyIdentity(res?.data?.client_secret)
                if (error) {
                    setLoadingSession(false)
                    toast.error(error)

                }
                else {
                    setLoadingSession(false)
                    if (type) {
                        close()
                        setLoading(true)
                    } 
                    updateUserInfo()
                    // close()
                }
            })


    };


    return (
        <>
            <h2 className='text-[18px] font-medium max-w-[500px] mx-auto'>{t('verify_identity')}</h2>
            <div className='flex justify-end items-end gap-5 pt-5'>
                <button onClick={() => close()} className='w-[35%]  py-1 bg-blue-500 text-white text-[18px] rounded-md'>{t('ok')}</button>
                <button onClick={() => handleClick()} className='w-[35%]  py-1 bg-[#F79F15] text-white text-[18px] rounded-md'>{loadingSession ? <Loader type="saved" /> : t('verify_now')}</button>
            </div>
        </>
    )
}

export default IdVerified