import React, { useEffect, useState } from 'react'
import { GetPropertyAmenities } from '../../../lib/Api/RantelServiceApi'
import { Loader } from '../../../utils/loaderButton'
import { ConvertBase64 } from '../../../utils/ConvBase64'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import PasswordVerify from './PasswordVerify'
import { FaRegImages } from 'react-icons/fa'
import { BsFillCheckCircleFill } from 'react-icons/bs'
const Aminities = ({ handleNext, handleBack, State, t }) => {
  const [amenities, setAmenities] = useState([])
  const [lists, setLists] = useState([])
  const [desc, setDesc] = useState('')
  const [leaseFile, setLeaseFile] = useState('')
  const [extention, setExtention] = useState('')
  const [filename, setFilename] = useState('')
  const [Error, setError] = useState({})
  const [loading, setLoading] = useState(false)
  const [showpass, setShowPass] = useState(false)

  const language = useSelector((state) => state?.userAuth)

  const HandleChecks = (id) => {
    if (lists?.includes(id)) {
      let l = lists.filter((f) => f !== id)
      setLists(l)
    }
    else {
      setLists((prev) => ([...prev, id]))
    }
  }

  const handleChangeImage = async (event) => {
    let files = event.target.files[0];
    if (
      files.type !== 'application/pdf' && 
      files.type !== 'application/msword' && 
      files.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      toast.error(t('pdf_vali'))
    }
    else {
      const fileName = files.name;
      setFilename(fileName)
      const fileExtension = fileName.split('.').pop().toLowerCase();
      setExtention(fileExtension)
      const base64 = await ConvertBase64(files)
      setLeaseFile(base64)

    }

  }

  const validateForm = () => {
    const newErrors = {};
    if (lists.length === 0) {
      newErrors.aminity = t('invalid_aminity')
    }
    if (desc === "") {
      newErrors.Description = t('invalid_desc')
    }
    return newErrors
  }

  const Submit = () => {
    let data = {
      desc,
      lists,
      extention,
      leaseFile
    }
    const validationErrors = validateForm(data);
    if (Object.keys(validationErrors).length === 0) {
      let payload = {
        "amenities": lists,
        "description": desc,
        "extention": extention,
        "leaseFile": leaseFile
      }
      handleNext(payload)
    }
    else {
      setError(validationErrors);
    }
  }

  const handleClose = () => {
    setShowPass(false)
    document.getElementById('fileInput').click();
  }

  useEffect(() => {
    let getType = async () => {
      setLoading(true)
      let { res } = await GetPropertyAmenities()
      const ConvertObj = Object.assign({}, ...res)
      if (res) {
        setLoading(false)
        setAmenities(ConvertObj.amenities)
      }
      else {
        setLoading(false)
      }
    }
    setTimeout(() => {
      getType()
    }, 1000);
  }, [language])


  useEffect(() => {
    if (State?.amenities?.length > 0) {
      setLists(State?.amenities)
      setExtention(State?.extention)
    }
    if (State?.description) {
      setDesc(State?.description)
    }
  }, [])


  return (
    <>
      {showpass && <DynamicPopup modalOpen={showpass} data={<PasswordVerify close={() => handleClose()} />} close={() => setShowPass(false)} />}
      <div>
        <h2 className='text-[20px] font-semibold my-4'>{t('amnity_lab')}</h2>
        {
          loading ?
            <div className='flex justify-start items-start'>
              <Loader type="loadData" />
            </div>
            :
            <div className='flex flex-wrap items-center xl:max-h-[200px] xl:overflow-y-auto lg:max-h-[200px] lg:overflow-y-auto md:max-h-[200px] md:overflow-y-auto'>
              {
                amenities?.map((c, i) => {
                  let d = lists.find((f) => f === c.id)
                  return (
                    <div className='flex items-center mx-1' key={i}>
                      <input id={c?.id} type="checkbox" onChange={() => HandleChecks(c.id)} defaultChecked={d} className='w-[20px] h-[20px]' />
                      <label htmlFor={c?.id} className='mx-1 text-[16px]'>{c.name}</label>
                    </div>
                  )
                })
              }
            </div>
        }

        {Error.aminity && (
          <p className="text-red-500 text-sm ">{Error.aminity}</p>
        )}

        <div className='pt-8'>
          <h2 className='text-[20px] font-semibold my-4'>{t('upload_lease_p')}</h2>
          <div className='border p-3 my-3'>
            {
              leaseFile ?
                <div className='flex items-center'>
                  <BsFillCheckCircleFill className='text-green-500' />
                  <p className='px-4'>{filename}</p>
                </div>
                :
                <div className='flex items-center text-gray-400 cursor-pointer' onClick={() => setShowPass(true)}>
                  <FaRegImages />
                  <h2 className='px-2'>{t('uplod_lbl')}</h2>
                </div>
            }
            <input type="file" id="fileInput" className='hidden' accept=".pdf,.doc,.docx" onChange={handleChangeImage} />
          </div>
        </div>
        <div className='flex flex-col'>
          <h2 className='text-[20px] font-semibold my-4'>{t('desc')}</h2>
          <textarea rows={8} value={desc} onChange={(e) => setDesc(e.target.value)} className='border p-2 rounded-md focus:outline-none' placeholder={t('desc_txt')} />
          {Error.Description && (
            <p className="text-red-500 text-sm ">{Error.Description}</p>
          )}
        </div>
        <div className='flex justify-between py-3'>
          <button type='button' onClick={() => handleBack()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
          <button type='submit' onClick={() => Submit()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Next')}</button>
        </div>

      </div >

    </>
  )
}

export default Aminities