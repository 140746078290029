import React, { useEffect, useState } from 'react'
import { IoLocation } from 'react-icons/io5'
import { BiCurrentLocation } from 'react-icons/bi'
import Layout from '../../components/Layout/Layout'
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import LikeDislikePopup from '../../utils/Popup/LikeDisLike';
// import PropertyFilter from '../../utils/Popup/FilterProperty';
import Deck from '../../components/Property/Tinder'
// import gp1 from '../../assests/images/gp1.png'
// import gp2 from '../../assests/images/gp2.png'
// import gp3 from '../../assests/images/gp3.png'
// import gp4 from '../../assests/images/gp4.png'
const Property = () => {
    const [active, setActive] = useState(0)
    const [show, setShow] = useState(false)
    // const [filter, setFilter] = useState(true)
    const [type, setType] = useState("")
    const [maxPrice, setMaxPrice] = useState(0)
    const [activePoperty, setActiveProperty] = useState(null)
    const [activeLeased, setActiveLeased] = useState(null)
    const Property = [
        "Any",
        "House",
        "Townhouse",
        "Apartment",
        "Condo",
        "Co-op"]

    const Leased = [" Fixed Term", "Month-on-month", "Year-on-year"]

    const Aminty = ["Street Parking", "WIFI",
        "Utilities Included",
        "Pool ",
        "Central Air ",
        "Gas ",
        "Electric",
        "Dishwasher ",
        "Washer Onsite ",
        "Garage",
        "Dryer Onsite ",
        "Furnished ",
        "Patio",
        "Pet Friendly ",
        "Smoking  ",
        "Public Park",
        "Basketball Court ",
        "Tennis Court ",
        "Golf",
        "College ",
        "Highschool ",
        "Elementary",
        "fetchPropetyClubs ",
        "Bar ",
        "Movie Theatre ",
        "Mall Grocery Store ",
        "Restaurants ",
        "Microwave",
    ]



    const handlePrice = (e) => {
        let { value } = e.target
        setMaxPrice(value)

    }







    return (
        <Layout>

            {/* {filter ? <PropertyFilter permition={filter} Toggle={setFilter} />
                : */}
            <>

                {show && <LikeDislikePopup permition={show} Toggle={setShow} type={type} />}


                {
                    // location === "/rent_property" &&
                    <Link to="/dashboard">
                        <div className='flex p-4 items-center ml-5 cursor-pointer' >
                            <IoIosArrowBack className="text-[20px]" />
                            <h2 className='text-[16px] font-semibold'>Dashboard</h2>
                        </div>
                    </Link>
                }

                <div className="container mx-auto relative">
                    <div className="grid lg:grid-cols-4 gap-3">
                        <div className='border rounded shadow-md  h-full'>
                            <div className='border-b border-[#F79F15] flex '>
                                <div onClick={() => setActive(0)} className={`flex justify-center mt-3 pb-1  cursor-pointer items-center w-full ${active === 0 && 'border-b-2 border-[#F79F15]'}`}>
                                    <h2 className={`${active === 0 && 'text-[#F79F15]'} text-center text-[18px] font-semibold`}>Rentals</h2>
                                </div>
                                <div onClick={() => setActive(1)} className={`flex justify-center mt-3 pb-1 cursor-pointer items-center w-full ${active === 1 && 'border-b-2 border-[#F79F15]'}`}>
                                    <h2 className={`${active === 1 && 'text-[#F79F15]'} text-center text-[18px] font-semibold`}>Roommates</h2>
                                </div>
                            </div>

                            <div className='m-4'>
                                <h2 className='font-semibold text-[18px]'>Leased Period</h2>
                                <div className='flex flex-wrap  items-center'>

                                    {
                                        Leased.map((p, i) => (
                                            <button onClick={() => setActiveLeased(i)} className={`border my-2 bg-white text-black rounded-md  first:mx-0 last:mx-0 px-4 py-1.5 ${activeLeased === i ? 'bg-[#F79F15] text-white' : 'bg-white text-[#F79F15]'}`}>
                                                {p}</button>

                                        ))
                                    }


                                </div>
                                {/* {
                                        allLeased && allLeased.lengh > 0 ? allLeased.map((leas, i) => (
                                            <div>
                                                <div className=' border-b-2 flex justify-between ' key={i}>
                                                    <div className='flex '>
                                                        <input type="radio" className='w-[20px] h-[20px]' />
                                                        <h2 className='mx-2 font-semibold'>{leas?.title}</h2>
                                                    </div>
                                                    <div className=''>
                                                        <h2 className='font-semibold flex items-center text-[14px]'>Read more
                                                            <RiArrowDropDownLine className='text-[25px]' />
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className='border bg-white shadow-md rounded p-2'>
                                                    <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, mollitia rem saepe architecto id quaerat voluptatum quo alias? </h2>
                                                </div>
                                            </div>
                                        ))
                                            : null
                                    } */}

                                <h2 className='text-[20px] font-semibold mt-4'>Location</h2>
                                <div className='flex items-center justify-between my-4'>
                                    <div className=' bg-[#F5F7F9] rounded-md w-full p-1 mr-2 flex  items-center'>
                                        <IoLocation className='text-[#A5A5A5]' />
                                        <input type="text" className='w-full bg-[#F5F7F9]
                                    border-transparent  focus:outline-none placeholder:text-[#A5A5A5]' placeholder='Search Location' />
                                    </div>
                                    <div className=' p-2 rounded-md bg-[#F79F15]'>
                                        <BiCurrentLocation className='text-white' />
                                    </div>

                                </div>
                                <div>
                                    <h2 className='text-[20px] font-semibold my-4'>Property Type</h2>
                                    <div className='flex flex-wrap  items-center '>

                                        {
                                            Property.map((p, i) => (
                                                <button onClick={() => setActiveProperty(i)} className={`border my-2 bg-white text-black rounded-md px-4 mx-2 py-1.5 ${activePoperty === i ? 'bg-[#F79F15] text-white' : 'bg-white text-[#F79F15]'}`}>
                                                    {p}</button>

                                            ))
                                        }

                                    </div>

                                </div>
                                <div>
                                    <h2 className='text-[20px] font-semibold my-4'>Price Range</h2>
                                    {/* <div>
                                            <Bar data={data} options={options} />
                                        </div> */}
                                    <div className='flex  items-center '>
                                        <span>0</span>
                                        {/* <input
                                                type="range"
                                                min={0}
                                                max={1000}
                                                className='w-[80%]'
                                                onChange={(e) => handlePrice(e)}
                                            /> */}
                                        <input id="myinput" min={0}
                                            max={20000} onChange={(e) => handlePrice(e)} value={maxPrice} className='progress' style={{ accentColor: "#FF8C00", width: '80%', color: 'red' }} type="range" />
                                        <input type="text" className='w-[30%] border rounded focus:outline-none' value={maxPrice} onChange={(e) => handlePrice(e)} />
                                    </div>


                                </div>
                                <div>
                                    <h2 className='text-[20px] font-semibold my-4'>Amenities</h2>
                                    <div className='flex flex-wrap items-center max-h-[120px] overflow-auto'>
                                        {
                                            Aminty?.map((c, i) => (

                                                <div className='flex items-center mx-1' key={i}>
                                                    <input type="checkbox" className='w-[20px] h-[20px]' />
                                                    <h2 className='mx-1 text-[16px]'>{c}</h2>
                                                </div>
                                            ))
                                        }

                                    </div>

                                </div>
                                <div className='flex mt-5 justify-center items-center'>
                                    <button className='border w-full py-2 rounded-md text-white   bg-[#F79F15]'>Search</button>
                                </div>
                            </div>
                        </div>
                        {/* center section  */}

                        <div className=" w-full  col-span-3 ">
                            <div className=' '>
                                <Deck toggle={setShow} />
                            </div>
                            {/* <div className="pt-8 w-full absolute   ">
                                <div className='flex justify-between max-w-[400px] m-auto border items-center px-3 py-[2px] '>
                                    <div>
                                        <img src={gp1} alt="icons" className='cursor-pointer' onClick={() => { return setShow(true), setType("dislike") }} />
                                    </div>
                                    <Link to="/flash">
                                        <img src={gp2} alt="icons" className='cursor-pointer' />
                                    </Link>
                                    <Link to="/reazy">
                                        <img src={gp3} alt="icons" className='cursor-pointer' />
                                    </Link>
                                    <div>
                                        <img src={gp4} alt="icons" className='cursor-pointer' onClick={() => { return setShow(true), setType("like") }} />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
            {/* } */}
        </Layout>
    )
}

export default Property