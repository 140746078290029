import React from 'react'
// import rent from '../../../assests/images/rent.png'
// import user from '../../../assests/images/ap.png'
import { Loader } from '../../../utils/loaderButton'
import { GetArchivedlist, RemoveArchieved, RestoreArchieved } from '../../../lib/Api/RantelServiceApi'
import { useEffect } from 'react'
import { useState } from 'react'
import moment from 'moment'
import { Truncate } from '../../../utils/TrucateString'
import Home from '../../../assests/svg/home.svg'
import { useTranslation } from 'react-i18next'
import gp4 from '../../../assests/svg/Group 4188.svg'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import RentalsDetail from '../../../pages/Property/PropertyDetail'
import { useSelector } from 'react-redux'
import { MylistDetail } from '../../GenericComp/MylistDetail'
import PropertyOffCampus from '../../RenatalPanelComp/FilterRommate/PropertyDetaiOffCamp'
import PropertyOnCampus from '../../RenatalPanelComp/FilterRommate/PropertyDetailOnCamp'
import DeletePopup from '../../../utils/Popup/DeletePopup'
import flash from '../../../assests/images/f5.png'
import reazy from '../../../assests/images/f6.png'

const Archived = () => {

  const [archiveList, setArchiveList] = useState([])
  const [loading, setLoading] = useState(false)
  const [updateLoader, setUpdateLoader] = useState(false)
  const [property, setProperty] = useState({})
  const [detail, setDetail] = useState(false)
  const [type, setType] = useState("")
  const [del, setDel] = useState(false)




  const location = useSelector((state) => state?.userAuth?.location)
  const lng = useSelector((state) => state?.userAuth?.language)
  let check = location && Object.keys(location)?.length > 0


  let fetchData = async () => {
    let payload = {
      latitude: 0,
      longitude: 0,
    }
    let { resArchive } = await GetArchivedlist(check ? location : payload)
    if (resArchive) {
      setArchiveList(resArchive)
      return true
    }
  }


  const handleDetail = (list) => {
    setProperty(list)
    setDetail(true)
  }


  const handleOpen = (data, type) => {
    setProperty(data)
    setType(type)
    setDel(true)
  }

  const handleRemoveArc = async (item) => {

    // setProperty(item)
    // setType("remove")
    let payload = {
      "list_id": Number(property?.id),
    }

    setUpdateLoader(true)
    let { res } = await RemoveArchieved(payload)
    if (res) {
      await fetchData()
      setDel(false)
      setUpdateLoader(false)

    }
  }
  const handleRestoreArc = async (item) => {
    setProperty(item)
    setType("restore")
    let payload = {
      "list": "saved",
      "list_id": Number(property?.id),
    }
    setUpdateLoader(true)
    let { res } = await RestoreArchieved(payload)
    if (res) {
      await fetchData()
      setDel(false)
      setUpdateLoader(false)


    }
  }

  useEffect(() => {
    let fetch = async () => {
      setLoading(true)
      // if (check) {
      let res = await fetchData()
      if (res) {
        setLoading(false)
      }
      // } else {
      //   setArchiveList([])
      // }
    }

    fetch()
  }, [lng, location])

  const { t } = useTranslation()
  return (
    <>
      {/* <Link to="/property"> */}
      {detail && <DynamicPopup modalOpen={detail} data={property?.property === null ? property?.roommate?.property_details !== null ? <PropertyOffCampus detail={property?.roommate} close={() => setDetail(false)} /> : <PropertyOnCampus detail={property?.roommate} close={() => setDetail(false)} /> : <RentalsDetail detail={property?.property} close={() => setDetail(false)} />} close={() => setDetail(false)} />}

      {del && <DeletePopup permition={del} loading={updateLoader} Toggle={(val) => setDel(val)} callback={type === "remove" ? handleRemoveArc : handleRestoreArc} title={type === "remove" ? t('remove_this') : t('restore_this')} />}

      <div className=' blog_list  mt-4'>
        {
          archiveList?.length > 0 ?
            <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4'>
              {

                archiveList?.map((list, i) => (
                  <div className='rounded-md bg-white shadow-md pb-4  mb-8 w-full relative' key={i}>
                    <div className='relative'>
                      <img src={list?.property !== null ? list?.property?.image : list?.roommate?.images?.length > 0 ? list?.roommate?.images[0]?.image : list?.roommate?.user?.user_image} className="w-full h-[200px] object-cover" alt="faverit_img" />
                      <div className='absolute top-0 w-full bg-black h-full bg-opacity-[0.5]' />

                      <div className='absolute p-[2px] top-[7%] text-white  left-[5%]'>
                        <div className='text-[20px] cursor-pointer bg-gray-200 rounded-full' onClick={() => handleDetail(list)}>
                          <img src={gp4} alt="detail" className='w-6 h-6' />
                        </div>
                        <div className='flex flex-col gap-1 mt-1'>
                          {list?.property?.flashlane && list?.property?.flashlane.seconds > 0 && (
                            <div className=''>
                              <img src={flash} alt="detail" className='w-6 h-6' />
                            </div>
                          )}
                          {list?.property?.reazy_lane && list?.property?.reazy_lane.seconds > 0 && (
                            <div className=' bg-white rounded-md mx-w-max'>
                              <img src={reazy} alt="detail" className='w-6 h-6' />
                            </div>
                          )}
                        </div>
                      </div>
                      <MylistDetail list={list} />
                    </div>
                    {check &&
                      <div className='px-3 pt-2'>
                        <p className='text-[12px]'>{list?.property?.distance}</p>
                      </div>
                    }
                    <div className='flex justify-between items-center p-3 h-[50px]'>
                      <div className='flex items-center w-[80%] '>
                        <img src={list.user?.user_image} className="w-[40px] h-[35px] border object-cover rounded-full" alt="user" />
                        <h2 className='font-bold mx-2 text-[14px]  max-w-[120px]'>{Truncate(list?.user?.first_name, 10)}</h2>
                      </div>
                      <div className='w-[50%]'>
                        <h2 className='text-[11px] font-semibold text-right'>{moment(list?.booking_details?.date_of_appointment).format('ll')}</h2>
                        <h2 className='text-[11px] font-semibold text-right'>{list?.booking_details?.time_of_appointment} </h2>
                      </div>

                    </div>

                    <div className='w-full'>
                      <div className='flex justify-center flex-wrap items-center mx-2 gap-2'>
                        <button onClick={() => handleOpen(list, "store")} className={`px-3 py-[3px] w-full  rounded-md bg-[#F79F15] text-white ${property?.id === list?.id && type === "restore" && 'bottom-0 px-6 py-1 mr-1'} `}>
                          {property?.id === list?.id && updateLoader && type === "restore" ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('restore')}
                        </button>
                        <button onClick={() => handleOpen(list, "remove")} className={`px-5 py-[3px] w-full  rounded-md bg-[#FF0000] text-white ${property?.id === list?.id && type === "remove" && 'bottom-0 px-6 py-1 mr-1'} `}>
                          {property?.id === list?.id && updateLoader && type === "remove" ? <div className='flex items-center justify-center ' ><Loader type="single" /> </div> : t('remove')}
                        </button>
                        {/* <button onClick={() => handleRemove(list)} className='px-5 py-[3px] w-full  rounded-md bg-[#FF0000] text-white'>Remove</button> */}
                      </div>
                    </div>
                  </div>

                ))

              }

            </div>
            :
            loading ? <div className='flex justify-start items-start'><Loader type="loadData" /> </div> : <div className='text-center'>
              <div className='flex justify-center items-center w-full '>
                <div className='flex justify-center items-center'>
                  <img src={Home} alt="home" className='w-[70%]' />
                </div>
              </div>
              <div className='pt-5'>
                <h2 className='text-xl font-bold '>{t('record_not_found')}</h2>
              </div>
            </div>
        }
      </div>
      {/* </Link> */}
    </>
  )
}

export default Archived