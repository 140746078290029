import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
import { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
const CheckRole = ({ close, setMedia, setPermission, title, buttonTitle , t  }) => {
    const [value, setValue] = useState(null);
    const [error, setError] = useState("")

    const onLogin = () => {
        if (value === null) {
            setError(t('invalid_role'))
        }
        else {
            setPermission(value === 0 ? "Servicer" : "renter")
            setMedia(false)
        }
    }

    return (
        <>
            {/* <Modal open={modalOpen} classNames="!w-[90%]" size={"lg"} center> */}
            <div className='flex justify-end m-4'>
                <div className='border cursor-pointer  w-[35px] rounded-md shadow-md p-2' onClick={() => close()}>
                    <AiOutlineClose />
                </div>

            </div>

            <div className=' m-10 '>
                <h2 className='py-2 text-[18px] text-center font-semibold'>{title} </h2>
                <div className='flex justify-between items-center'>
                    <div className={`${value === 0 ? 'bg-[#F79F15] text-white' : 'bg-transparent'} cursor-pointer border flex items-center rounded-md px-2 py-2 `} onClick={() => setValue(0)}>
                        <input type='radio' checked={value === 0 ? true : false} />
                        <p className='text-[16px] px-2'>{t('servicer')}</p>
                    </div>
                    <div className={`${value === 1 ? 'bg-[#F79F15] text-white' : 'bg-transparent'} cursor-pointer mx-2 border flex items-center rounded-md px-2 py-2 `} onClick={() => setValue(1)}>
                        <input type='radio' checked={value === 1 ? true : false} />
                        <p className='text-[16px] px-2'>{t('rentals')}</p>
                    </div>

                </div>
                {error ? <span className="text-red-500 text-md pt-2">{error}</span> : ""}
                <div className='flex justify-end my-3'>
                    <button onClick={() => onLogin()} className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white'>{buttonTitle}</button>
                </div>
            </div>
            {/* </Modal> */}
        </>
    )
}

export default CheckRole