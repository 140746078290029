import React, { useState, useEffect } from 'react'
// import PropertyDetail from '../PropertyDetail'
import { GetLeasedPeriod, UpdateRental } from '../../../../lib/Api/RantelServiceApi'
import { Loader } from '../../../../utils/loaderButton'
import keys from '../../../../assests/images/keys.png'
import { useTranslation } from 'react-i18next'

const EditPropertyType = ({ close, data, setEditType }) => {
    const [proertytype, setPropertyType] = useState(Number(data?.leased_period_id))
    const [loading, setLoading] = useState(false)
    const [loadingLeased, setLoadingLeased] = useState(false)
    const [Leased, setLeased] = useState([])
    // const Leased = ["Fixed Term", "Month-on-month", "Year-on-year"]

    const onSubmit = async (e) => {
        e.preventDefault()
        const obj = data;
        obj.leased_period_id = proertytype;
        let payload = {
            ...obj,
        }
        setLoading(true)
        data.images = null
        let { res, error } = await UpdateRental(payload)
        if (res) {
            setLoading(false)
            close(false)
            setEditType('')
        }
        else if (error) {
            setLoading(false)

        }
    }


    useEffect(() => {
        let fetchLeased = async () => {
            setLoadingLeased(true)
            let { res } = await GetLeasedPeriod();
            const ConvertObj = Object.assign({}, ...res)
            if (res) {
                setLoadingLeased(false)
                setLeased(ConvertObj?.lease_periods)
            }
            else {
                setLoadingLeased(false)
            }
        }
        fetchLeased()
    }, [])


    const {t} =  useTranslation()

    return (
        <>
            <div className="max-w-[600px]">
                <form onSubmit={onSubmit}>
                    <div className='flex  items-center flex-wrap'>
                        {
                            loadingLeased ? <Loader type="loadData" />
                                :
                                <div className="grid grid-cols-3 gap-5 mt-4">
                                    {
                                        Leased?.map((item, i) => (
                                            <div className={`${proertytype === item.id ? 'border-2 border-[#F79F15]' : 'bg-white '} rounded-md border shadow-md p-1`} key={i} onClick={() => setPropertyType(item?.id)}>
                                                <div className='flex justify-center flex-col items-center cursor-pointer p-2'>
                                                    <img src={item?.icon_image || keys} className='w-[50%] object-cover' alt="keys" />
                                                    <div className='flex flex-col text-center'>
                                                        <h2 className='font-semibold text-[18px] pt-4'>{item?.title}</h2>
                                                        {/* <p>{item?.description}</p> */}
                                                    </div>
                                                    {/* <MdArrowForwardIos className='text-[40px]' /> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                    </div>

                    <div className='flex items-center py-5 justify-end '>
                        <button type="button" onClick={() => close(false)} className='w-[138px] py-2 rounded-md bg-transparent border-[#ACACAC] border text-[#ACACAC]'>{t('cancel')}</button>
                        {
                            loading ? <div className='ml-5'><Loader type="saved" /></div>
                                :
                                <button className='w-[138px] py-2 ml-5 rounded-md bg-[#FF8C00] text-white'>{t('save')}</button>
                        }
                    </div>
                </form>
            </div>
        </>
    )
}

export default EditPropertyType