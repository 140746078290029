// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// const availableLanguages = ['sp', 'en']
// const option = {
//   order:['navigator', 'htmlTag', 'path', 'subdomail'],
//   checkWhitelist:true
// }
// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: 'en',
//     debug: true,
//     whitelist:availableLanguages,
//     detection:option,
//     interpolation: {
//       escapeValue: false, 
//     }
//   });


// export default i18n;



import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from 'i18next-browser-languagedetector';
import spTranslation from '../Partials/locales/sp/translation.json'
import enTranslation from '../Partials/locales/en/translation.json'
// import LanguageDetector from 'i18next-redux-languagedetector';
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: enTranslation
  },
  sp: {
    translation: spTranslation

  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;
