import React, { useState } from 'react'
import { Loader } from '../utils/loaderButton'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../lib/Api/UserApi'
import { signout } from '../Redux/UserAuthSlice/UserAuthSlice'
import { AddIndex, AddRentalId, ClearAll, GoStart, SendRent, addRentals } from '../Redux/UserAuthSlice/RentalSlice'
import { AddCampus, AddCollege, AddRommateId, AddRommates, RentQuestion, RommateNew, clearRommate, setUniversity } from '../Redux/UserAuthSlice/RommateSlice'
const LogoutLoader = ({ setShow }) => {
    const [isPermssion, setIsPermission] = useState(false)
    const { t } = useTranslation()


    const dispatch = useDispatch()
    const logout = async () => {
        setIsPermission(true)
        let res = await logoutUser()
        if (res) {
            setShow(false)
            setIsPermission(false)
            dispatch(AddIndex(2))
            dispatch(GoStart([{tab:1 , submited: false}]))
            dispatch(addRentals({}))
            sessionStorage.removeItem('hasWatchRentalApp');
            sessionStorage.removeItem('hasVerify');
            sessionStorage.removeItem('hasDeniedPermission');
            dispatch(AddRentalId(null))
            dispatch(SendRent({}))
            dispatch(clearRommate({}))
            dispatch(AddRommates({}))
            dispatch(AddCampus(null))
            dispatch(AddCollege(null))
            dispatch(AddRommateId(null))
            dispatch(RommateNew(false))
            dispatch(RentQuestion({}))
            dispatch(setUniversity({}))
            dispatch(ClearAll())
            dispatch(signout())

        }
    }
    return (
        <div className='p-5 bg-white'>
            {
                isPermssion ?
                    <>
                        <Loader type="loadData" />
                    </>
                    :
                    <>
                        <h2 className='text-[18px] font-medium max-w-[500px] mx-auto'>{t('logoff')}</h2>
                        <div className='flex justify-end items-end gap-5 pt-5'>
                            <button onClick={() => setShow(false)} className='w-full  py-1 bg-blue-500 text-white text-[18px] rounded-md'>{t('No')}</button>
                            <button onClick={() => logout()} className='w-full  py-1 bg-[#F79F15] text-white text-[18px] rounded-md'>{t('yes')}</button>
                        </div>
                    </>
            }

        </div>
    )
}

export default LogoutLoader