import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DeleteRommate, GetRommates } from '../../../lib/Api/RantelServiceApi'
import { useTranslation } from 'react-i18next'
import Home from '../../../assests/svg/home.svg'
import Arrow from '../../../assests/svg/arrow.svg'
import { Loader } from '../../../utils/loaderButton'
import DynamicPopup from '../../../utils/Popup/DynamicPopup'
import avatar from '../../../assests/images/f10.png'
import Decision from './Decision'
import DeletePopup from '../../../utils/Popup/DeletePopup'
import { AddCampus, AddCollege, AddRommate, AddRommateId, AddRommateIndex, AddRommates, IspopUp, RommateNew } from '../../../Redux/UserAuthSlice/RommateSlice'
import { useDispatch, useSelector } from 'react-redux'
import { IoIosArrowForward } from "react-icons/io";
import { MdEdit } from 'react-icons/md'
const RoommateList = () => {
  const [roomate, setRommate] = useState({})
  const [status, setStatus] = useState({})
  const [loading, setLoading] = useState(false)
  const [statusBase, setStatusBase] = useState(null)
  const [show, setShow] = useState(false)
  const [delPopup, setDelPopup] = useState(false)
  const [delId, setDelId] = useState('')
  let item = roomate

  let interest = item?.interests?.length
  let extraActivity = item?.university_details?.extra_curricular_activities?.length

  const { rommateList, isPopupShow } = useSelector((state) => state?.rommate)

  const { t } = useTranslation()

  const onCampusList = [
    {
      heading: t('rommate_list_1'),
      subHeading: "",
      link: ""
    },
    {
      heading: t('rommate_uni_detail'),
      subHeading: `${status?.universityDetails ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "3"
    },
    {
      heading: t('rommate_ex_acti'),
      subHeading: `${status?.extra_curricular_activities ? `${extraActivity || 0} ${t('rommate_list_6')}` : t('rommate_list_5')}`,
      link: "7"
    },
    {
      heading: t('interested'),
      subHeading: `${status?.interests ? `${interest} ${t('rommate_list_6')}` : t('rommate_list_5')}`,
      link: "6"
    },
    {
      heading: t('rommate_list_2'),
      subHeading: `${status?.priorities ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "5"
    }


  ]
  const offCampusList = [
    {
      heading: t('rommate_list_1'),
      subHeading: "",
      link: ""
    },
    {
      heading: t('property_Detail'),
      subHeading: `${status?.propertyDetails ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "4"
    },
    {
      heading: t('current_info'),
      subHeading: `${status?.current_information ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "8"
    },
    {
      heading: t('interested'),
      subHeading: `${status?.interests ? `${interest} ${t('rommate_list_6')}` : t('rommate_list_5')}`,
      link: "6"
    },
    {
      heading: t('rommate_list_2'),
      subHeading: `${status?.priorities ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "5"
    }

  ]

  const offCampusListOff = [
    {
      heading: t('rommate_list_1'),
      subHeading: "",
      link: ""
    },
    {
      heading: t('rommate_uni_detail'),
      subHeading: `${status?.universityDetails ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "9"
    },
    {
      heading: t('property_Detail'),
      subHeading: `${status?.propertyDetails ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "4"
    },
    {
      heading: t('current_info'),
      subHeading: `${status?.current_information ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "8"
    },
    {
      heading: t('interested'),
      subHeading: `${status?.interests ? `${interest} ${t('rommate_list_6')}` : t('rommate_list_5')}`,
      link: "6"
    },
    {
      heading: t('rommate_ex_acti'),
      subHeading: `${status?.extra_curricular_activities ? `${extraActivity || 0} ${t('rommate_list_6')}` : t('rommate_list_5')}`,
      link: "7"
    },
    {
      heading: t('rommate_list_2'),
      subHeading: `${status?.priorities ? t('rommate_list_4') : t('rommate_list_5')}`,
      link: "5"
    }
  ]

  const statusList = [
    {
      type: "onCampus",
      lists: ["universityDetails", "personal_info", "extra_curricular_activities", "priorities"]
    },
    {
      type: "offCampus",
      lists: ["universityDetails", "personal_info", "propertyDetails", "extra_curricular_activities", "current_information", "interests", "priorities"]
    },
    {
      type: "local",
      lists: ["propertyDetails", "personal_info", "current_information", "priorities", "interests",]
    }
  ]


  function updateStatus(res, status) {
    let selectedType;
    if (res?.college_student === 1 && res?.on_campus === 1) {
      selectedType = "onCampus";
    } else if (res?.college_student === 1 && res?.on_campus === 0) {
      selectedType = "offCampus";
    } else if (res?.college_student === 0 && res?.on_campus === 0) {
      selectedType = "local";
    }
    if (selectedType) {
      const selectedTypes = statusList?.find(item => item?.type === selectedType).lists;
      let check = selectedTypes?.some(key => status[key] === false);
      setStatusBase(check)
    }
    // else {
    //   setStatusBase(false)
    // }
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => state?.userAuth?.userInfo)

  const deleteInspire = async () => {
    try {
      let { res } = await DeleteRommate(delId)
      if (res) {
        setDelId('')
      }
      // setProperties(res?.my_rentals)
    } catch (error) {

    }
  }
  const handleNavigate = (link) => {
    if (link === "") {
      dispatch(IspopUp(true))
    }
    else {
      dispatch(AddRommateIndex(Number(link)))
      navigate('/createRoommate')
    }
  }

  useEffect(() => {
    let fetchData = async () => {
      setLoading(true)
      let { res } = await GetRommates()
      if (res) {
        setLoading(false)
        if (Object.keys(res?.roommates ?? {}).length > 0) {
          setRommate(res?.roommates || {})
          setStatus(res?.status || {})
          const info = {
            college_student: res?.roommates?.college_student,
            on_campus: res?.roommates?.on_campus
          };
          updateStatus(info, res?.status)
          dispatch(AddRommates(res?.roommates))
          dispatch(RommateNew(false))
          dispatch(AddRommateId(res?.roommates?.id))
          dispatch(AddCollege(res?.roommates?.college_student))
          dispatch(AddCampus(res?.roommates?.on_campus))
        }
        else {
          dispatch(RommateNew(true))
        }
      }
      else {
        setLoading(false)
      }
    }
    if (delId === '') {
      fetchData()
    }
  }, [delId])

  // useEffect(() => {
  //   const info = {
  //     college_student: roomate?.college_student,
  //     on_campus: roomate?.on_campus
  //   };
  //   updateStatus(info, status)

  // }, [roomate])

  let checkImg = user?.userimg !== "https://leased.s3.amazonaws.com/" ? user?.userimg : avatar
  const showBanner = new Date(item.published_at) > Date.now()

  return (
    <>
      {isPopupShow && <DynamicPopup data={<Decision updateStatus={updateStatus} setRommate={setRommate} setStatus={(val) => setStatus(val)} setShow={setShow} />} modalOpen={isPopupShow} close={() => dispatch(IspopUp(false))} />}
      {delPopup && <DeletePopup permition={delPopup} callback={deleteInspire} Toggle={() => setDelPopup(false)} />}

      {statusBase === true &&
        <div className='px-14 mt-10'>
          <div className='mb-3 bg-red-500 border-red-700 border rounded-md text-black text-[18px] p-2'>
            <span className='text-red-900 font-bold'>{t('rental_app_warning')}:</span> {t('rommate_error')}
          </div>
        </div>
      }
      {
        showBanner &&
        <div className='px-14 mt-10'>
          <div className='mb-3 bg-red-500 border-red-700 border rounded-md text-black text-[18px] p-2'>
            <span className='text-red-900 font-bold'>{t('rental_app_warning')}:</span> {t('rommate_banner_text')}
          </div>
        </div>
      }


      <div className='lg:p-8 p-2 relative'>
        {loading ? <div className='flex justify-start items-start'><Loader type="loadData" /></div>
          :
          Object.keys(roomate)?.length > 0 ?
            <div className="max-w-[700px]  mx-auto border p-3 xl:pb-auto lg:pb-auto md:pb-auto pb-12 rounded-md">
              <div className='bg-white cursor-pointer shadow-md rounded-md border border-gray-200 px-3 pb-2'>
                <div className='flex justify-end items-end mt-2 space-x-2'>
                  <div onClick={() => handleNavigate('2')} className='border xl:w-8 lg:w-8 md:w-8 w-6  xl:h-8 lg:h-8 md:h-8 h-6 flex justify-center items-center bg-white bg-opacity-[0.7] p-1 rounded-full'>
                    <MdEdit className={`text-[15px]  ${status?.personal_info ? 'text-green-500' : 'text-red-500'}`} />
                  </div>
                  <p className={`text-[14px] font-normal ${status?.personal_info ? 'text-green-500' : 'text-red-500'} `}>
                    {status?.personal_info ? t('rommate_list_4') : t('rommate_list_5')}
                  </p>
                </div>
                <div className='flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center gap-4'>
                  <div className='xl:w-[120px] lg:w-[120px] md:w-[120px] w-[60px]  xl:h-[120px] lg:h-[120px] md:h-[120px] h-[60px] relative flex justify-center items-center border rounded-full'>
                    <img src={checkImg} alt="profile" className="xl:w-[120px] lg:w-[120px] md:w-[120px] w-[60px]  xl:h-[120px] lg:h-[120px] md:h-[120px] h-[60px] rounded-full" />
                  </div>
                  <div className=''>
                    <div className=''>
                      <h4 className='text-[16px] font-semibold '>{user?.fname}</h4>
                      <p>{user?.email}</p>
                    </div>
                    <div className='pt-2'>
                      <p className='text-[14px]'>{roomate?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
              {

                rommateList?.college_student === 1 && rommateList?.on_campus === 0 ?
                  offCampusListOff?.map((camp) => {
                    return (
                      <div onClick={() => handleNavigate(camp?.link)} className='bg-white rounded-md shadow-md p-3 mt-3 border border-gray-200 cursor-pointer'>
                        <div className='flex justify-between items-center'>
                          <h2 className='text-[16px] font-semibold'>{camp?.heading}</h2>
                          <div className='flex items-center gap-2'>
                            {camp?.subHeading === "" &&
                              <p className={`text-[14px] font-normal text-black`}>{rommateList?.roommate_type}</p>
                            }
                            <p className={`text-[14px] font-normal ${camp?.subHeading === t('rommate_list_5') ? 'text-red-500' : 'text-green-500'} `}>{camp?.subHeading}</p>
                            <IoIosArrowForward />
                          </div>
                        </div>
                      </div>
                    )
                  })
                  :
                  rommateList?.college_student === 0 && rommateList?.on_campus === 0
                    ?
                    offCampusList?.map((camp) => {
                      return (
                        <div onClick={() => handleNavigate(camp?.link)} className='bg-white rounded-md shadow-md p-3 mt-3 border border-gray-200 cursor-pointer'>
                          <div className='flex justify-between items-center'>
                            <h2 className='text-[16px] font-semibold'>{camp?.heading}</h2>
                            <div className='flex items-center gap-2'>
                              {camp?.subHeading === "" &&
                                <p className={`text-[14px] font-normal text-black`}>{rommateList?.roommate_type}</p>
                              }
                              <p className={`text-[14px] font-normal ${camp?.subHeading === t('rommate_list_5') ? 'text-red-500' : 'text-green-500'} `}>{camp?.subHeading}</p>
                              <IoIosArrowForward />
                            </div>
                          </div>
                        </div>
                      )
                    })
                    :
                    onCampusList?.map((camp) => (
                      <div onClick={() => handleNavigate(camp?.link)} className='bg-white rounded-md shadow-md p-3 mt-3 border border-gray-200 cursor-pointer'>
                        <div className='flex justify-between items-center'>
                          <h2 className='text-[16px] font-semibold'>{camp?.heading}</h2>
                          <div className='flex items-center gap-2'>
                            {camp?.subHeading === "" &&
                              <p className={`text-[14px] font-normal text-black`}>{rommateList?.roommate_type}</p>
                            }
                            <p className={`text-[14px] font-normal ${camp?.subHeading === t('rommate_list_5') ? 'text-red-500' : 'text-green-500'} `}>{camp?.subHeading}</p>
                            <IoIosArrowForward />
                          </div>
                        </div>
                      </div>
                    ))
              }

            </div>
            :
            <div className='text-center'>
              <div className='flex justify-center items-center w-full '>
                <div>
                  <img src={Home} alt="home" />
                </div>
              </div>
              <div className='pt-5'>
                <h2 className='text-xl font-bold  '>{t('rommates')}</h2>
                <p className='text-lg'>{t('rommate_appears')}</p>
              </div>
              <div className='flex justify-center items-center'>
                <img src={Arrow} alt="home" />
              </div>
            </div>
        }
        {
          !Object.keys(roomate)?.length &&
          <div className={'flex justify-center items-center'}>
            <button onClick={() => dispatch(IspopUp(true))} className={` bg-[#F79F15] text-white text-[16px] fixed bottom-10  rounded-md px-4 py-1 ${Object.keys(roomate)?.length > 0 ? 'left-[24%]' : 'right-[24%]'}`}>
              + {t('add_new_property')}  </ button>
          </div>
        }
      </div >
    </>
  )
}

export default RoommateList