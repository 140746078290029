import React from 'react'
import user1 from '../../assests/clientImgs/Roommate-Renter .jpeg'
import user2 from '../../assests/clientImgs/Leased-Servicer-John.jpeg'
import logo from '../../assests/images/logobg.png'
import servicer from '../../assests/clientImgs/Leased Servicer App.png'
import customer from '../../assests/clientImgs/Leased Logo.png'
import { useTranslation } from 'react-i18next'
import { SocialButtons } from '../GenericComp/SocialButtons'
const DownloadApp = () => {
    const { t } = useTranslation()
    return (
        <>
            <div className='container mx-auto'>
                <h2 className='text-center lg:text-[2rem] text-[1.5rem] font-bold'>{t('home_footer_1')}</h2>
                <div className='flex lg:flex-row flex-col items-center justify-center mt-[2rem]'>
                    <div className='lg:w-[22%] md:w-[50%] w-[100%] relative'>
                        <img src={user1} alt="user_imaege" className='object-cover h-auto rounded-tl-lg rounded-bl-lg' />
                        <div className='absolute bottom-[5%]  w-full flex justify-center flex-col items-center'>
                            <div className='bg-gray-100 bg-opacity-[0.5] mb-3 bg-cover rounded-md w-[50%] px-7 py-1 bg-no-repeat '>
                                <img src={logo} className="" alt="logo" />
                            </div>
                            <div className=" flex items-center gap-2 ">
                                <img src={customer} className="object-cover w-8 h-8 rounded-md" alt="logo" />
                                <h2 className='lg:text-[16px] text-[16px]  text-white uppercase font-bold'>{t('web_list_rommate_11')}</h2>
                            </div>
                            <div className='flex items-center  justify-center ml-2'>
                                <SocialButtons />
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-[22%] md:w-[50%] w-[100%]  relative'>
                        <img src={user2} alt="user_imaege" className='rounded-tr-lg rounded-br-lg h-auto object-cover' />
                        <div className='absolute bottom-[5%] w-full flex justify-center flex-col items-center'>
                            <div className='bg-gray-100 bg-opacity-[0.5] mb-3 bg-cover rounded-md w-[50%] px-7 py-1 bg-no-repeat '>
                                <img src={logo} className="" alt="logo" />
                            </div>
                            <div className="inset-0 bg-orange-400 bg-opacity-50 p-1 flex items-center ">
                                <img src={servicer} className="object-cover w-10 h-10" alt="logo" />
                                <h2 className='lg:text-[16px] text-[16px]  text-white uppercase font-bold'>{t('web_list_rommate_12')}</h2>
                            </div>
                            <div className='flex items-center justify-center ml-2'>
                                <SocialButtons />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DownloadApp