import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SendFeedback } from '../../lib/Api/RantelServiceApi';
import { Loader } from '../../utils/loaderButton';

const Feedback = ({ setShow }) => {
    const [selected, setSelected] = useState(null);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const feedbackOptions = [
        { label: 'Bad', emoji: '😡', color: 'text-red-700' },
        { label: 'Poor', emoji: '😟', color: 'text-pink-500' },
        { label: 'Average', emoji: '😐', color: 'text-yellow-500' },
        { label: 'Good', emoji: '😊', color: 'text-green-500' },
        { label: 'Excellent', emoji: '😍', color: 'text-teal-500' },
    ];

    const handleClick = (index) => {
        setSelected(index);
    };

    const handleSubmit = async () => {
        try {
            let valueInc = selected + 1
            let payload = {
                "rating": valueInc,
                "comment": comment
            }
            setLoading(true)
            let res = await SendFeedback(payload)
            if (res) {
                setLoading(false)
                setShow(false)
            } else {
                setLoading(false)
            }
        }
        catch (err) { }
    }

    const { t } = useTranslation()

    return (
        <div className="text-center font-sans p-4">
            <h2 className="text-xl font-bold">{t('notifi_title')}</h2>
            <p className="text-sm text-gray-600  py-5">
                {t('notif_suub_title')}
            </p>
            <div className="flex justify-center mb-4">
                {feedbackOptions.map((option, index) => (
                    <div
                        key={index}
                        className={`flex flex-col items-center mx-2 cursor-pointer transform transition-transform duration-200 ${selected === index ? 'scale-110' : ''
                            }`}
                        onClick={() => handleClick(index)}
                    >
                        <span className={`text-4xl ${option.color}`}>{option.emoji}</span>
                        <span className="mt-2 text-base">{option.label}</span>
                    </div>
                ))}
            </div>
            {selected !== null &&
                <div className='pt-5'>
                    <textarea rows={5} value={comment} onChange={(e) => setComment(e.target.value)} placeholder='Type Here....' className='w-full px-2 border focus:outline-none rounded-md text-[14px] py-2'></textarea>
                    <div className="pt-5">
                        <button onClick={() => handleSubmit()} className='bg-[#F79F15] text-white text-center h-[40px] rounded-md w-full'>{
                            loading ? <Loader type="saved" /> : t('contact_submit')}</button>
                    </div>
                </div>
            }

        </div>
    );
};

export default Feedback;
