import React, { useEffect } from 'react'
import bed from '../../../assests/images/bed.png'
import Home from '../../../assests/svg/home.svg'
import Arrow from '../../../assests/svg/arrow.svg'
import bath from '../../../assests/images/bath.png'
import { GetRental } from '../../../lib/Api/RantelServiceApi'
import Action from '../../../utils/Dropdowns/ServicerAction'
import { useState } from 'react'
import gp4 from '../../../assests/svg/Group 4188.svg'
import flash from '../../../assests/images/f5.png'
import reazy from '../../../assests/images/f6.png'
// import { callPrivateApi, callPublicApi } from '../../../lib/Apiendpoint'
import DeletePopup from '../../../utils/Popup/DeletePopup'
import { DeleteRental } from '../../../lib/Api/RantelServiceApi';
import { Link } from 'react-router-dom'
import { Loader } from '../../../utils/loaderButton'
// import { FaHome } from 'react-icons/fa'
import { Truncate } from '../../../utils/TrucateString'
import { MdLocationPin, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
const Saved = ({ type, setlists }) => {
  const [properties, setProperties] = useState([])
  const [delPopup, setDelPopup] = useState(false)
  const [delId, setDelId] = useState('')
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState([])


  const deletePopToggle = (id) => {
    setDelId(id)
    setDelPopup(true)
  }

  const deleteInspire = async () => {

    try {
      let { res } = await DeleteRental(delId)
      if (res) {
        setDelId('')
      }
      // setProperties(res?.my_rentals)
    } catch (error) {

    }
  }

  const handlePageClick = ({ selected }) => {
    fetchServicer(selected + 1)
    setCurrentPage(selected + 1)
  }

  let fetchServicer = async (currentPage) => {
    setLoading(true)
    let { res } = await GetRental(currentPage)
    let obj = Object.assign({}, ...res)
    if (res) {
      setLoading(false)
      setProperties(obj.my_rentals)
      setTotalPage(obj?.pagination?.length)
      setlists(obj.my_rentals)
    }
  }

  useEffect(() => {
    if (delId === '') {
      fetchServicer(currentPage)
    }
  }, [delId])


  const showBanner = properties.some(
    (property) => new Date(property.published_at) > Date.now()
  );

  const { t } = useTranslation()


  return (
    <>
      {delPopup && <DeletePopup permition={delPopup} callback={deleteInspire} Toggle={() => setDelPopup(false)} />}

      {showBanner && (
        <div className=' mt-10'>
          <div className='mb-3 bg-red-500 border-red-700 border rounded-md text-white text-[18px] p-2'>
            <span className='text-red-900 font-bold'>{t('remember_text')}:</span> {t('rentals_banner_text')}
          </div>
        </div>
      )
      }
      <div className='max-h-[500px]  blog_list  mt-4'>
        {
          loading ? <div className='flex justify-start items-start'><Loader type="loadData" /></div>
            :
            properties && properties.length > 0 ?
              <div className='grid lg:grid-cols-4 md:grid-cols-2  gap-4 w-full'>
                {
                  properties?.map((item, i) => (
                    <div className='rounded-md bg-white  shadow-md pb-4 mb-4 w-full ' key={i}>
                      <div className='relative '>
                        <img src={item.image} className="w-full h-[200px] object-cover" alt="faverit_img" />
                        <div className='absolute top-2 w-full right-2'>
                          <div className="flex justify-between items-center">
                            <Action id={item?.id} deletePopToggle={deletePopToggle} type={type} />
                            <Link to={`/renterDetails/${item?.id}`}>
                              <img src={gp4} alt="detail" className='object-cover w-[30px] border rounded-full' />
                            </Link>
                          </div>
                        </div>
                        <div className='absolute top-12 w-full right-2'>
                          <div className="flex justify-between items-center">
                            {item?.flashlane && item.flashlane?.seconds > 0 ?
                              <div className='pl-3'>
                                <img src={flash} alt="flash" className='w-8 h-8' />
                              </div>
                              : <div></div>
                            }
                            {item?.reazy_lane && item?.reazy_lane?.seconds > 0  ?
                              <div className='bg-white rounded-lg'>
                                <img src={reazy} alt="flash" className='w-8 h-8' />
                              </div>
                              : <div></div>
                            }
                          </div>
                        </div>
                        {new Date(item?.published_at) > Date.now() &&
                          <div className='absolute bottom-2 w-full left-2'>
                            <div className="bg-white max-w-max rounded-full px-3 py-1">
                              <p className='text-[12px] text-[#F79F15]'>Pending</p>
                            </div>
                          </div>
                        }

                      </div>
                      <div className='px-2 '>
                        <h2 className='font-bold py-1'>{Truncate(item?.description, 30)}</h2>
                        <p className='flex '>
                          <MdLocationPin className='text-red-500 mr-2 text-[30px]' />
                          {item?.complete_address}</p>
                        <p className='pb-2'>{item?.property_type?.name}</p>
                        <div className='flex items-center'>
                          <div className='flex items-center'>
                            <img src={bed} alt="beds" />
                            <h2 className='px-2'>{item?.number_of_beds}</h2>
                          </div>
                          <div className='flex items-center mx-3'>
                            <img src={bath} alt="beds" />
                            <h2 className='px-2'>{item?.number_of_baths}</h2>
                          </div>
                        </div>
                        <div className='pt-2'>
                          <h2 className='font-medium text-[16px] text-[#F79F15]'>{item?.applications} {t('application')}</h2>
                          <h2 className='font-medium text-[16px] text-[#F79F15]'>{item?.appointment} {t('appointment')}</h2>
                        </div>
                        <h2 className='font-bold text-[20px]'>${Number(item?.rent).toLocaleString()}/{t('Month')}</h2>
                        <h2 className='font-bold text-[18px]'>{t('Sec_dp')} ${Number(item?.security_deposit).toLocaleString()}</h2>

                      </div>


                    </div>
                  ))
                }

              </div>
              :
              <div className='text-center'>
                <div className='flex justify-center items-center w-full '>
                  <div>
                    <img src={Home} alt="home" />
                  </div>
                </div>
                <div className='pt-5'>
                  <h2 className='text-xl font-bold  '>{t('servicer_rentals')}</h2>
                  <p className='text-lg'>{t('rental_appears')}</p>
                </div>
                <div className='flex justify-center items-center'>
                  <img src={Arrow} alt="home" />
                </div>
              </div>
        }
        {properties && properties.length > 0 &&
          <div className="py-5">
            <ReactPaginate
              breakLabel="..."
              breakClassName="bg-[#1B5FC1]"
              nextLabel={<MdOutlineKeyboardArrowRight />}
              onPageChange={handlePageClick}
              activeClassName="!bg-[#F79F15] text-white rounded"
              nextClassName="px-3 bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
              previousClassName="px-3 bg-[#F3F3F3] text-[20px] pt-[5px] rounded xxtra-small:mb-4"
              pageRangeDisplayed={5}
              pageCount={totalPage}
              previousLabel={<MdOutlineKeyboardArrowLeft />}
              className="flex justify-end  xxtra-small:w-full xxtra-small:justify-start xtra-small:justify-start xxtra-small:flex-wrap xtra-small:flex-wrap"
              pageClassName=" w-[30px] h-[30px]  text-center mx-2 pt-[3px] rounded bg-[#F3F3F3] xxtra-small:mb-4 xtra-small:mb-4"
            />
          </div>
        }
      </div>
    </>
  )
}

export default Saved