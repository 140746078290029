// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore  } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDFqy3LKRtfIneQJeipNQH5980T5k7I4SE",
  authDomain: "leasedmobile.firebaseapp.com",
  databaseURL: "https://leasedmobile-default-rtdb.firebaseio.com",
  projectId: "leasedmobile",
  storageBucket: "leasedmobile.appspot.com",
  messagingSenderId: "620506373376",
  appId: "1:620506373376:web:bd7bb8d585dfec167aa7e5",
  measurementId: "G-QTHLPB8N8R"
};


// Initialize Firebase
const Firebaseapp = initializeApp(firebaseConfig);
const auth = getAuth(Firebaseapp);
const db = getFirestore();
const database = getDatabase(Firebaseapp);
export { Firebaseapp, database ,  db,  auth }