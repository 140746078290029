import React, { useState, useEffect } from 'react'
import { BsFillTagFill } from 'react-icons/bs'
import flash from '../../assests/images/rease.png'
import { GetPricesREsy, GetReasySubscription } from '../../lib/Api/RentalAppApi'
import { Loader } from '../../utils/loaderButton'
import { GetPricesServicerREsy } from '../../lib/Api/RantelServiceApi'
import { useDispatch, useSelector } from 'react-redux'
import Purchase from '../../components/ServicerPanelComp/Flash/Purchase'
import { useTranslation } from 'react-i18next'
import BlockTime from '../../components/GenericComp/BlockTime'
import FlashSidebar from '../RentalPanelPages/FlashSidebar'
import ServicerFlashpass from './FlashPass'
import { useLocation } from 'react-router-dom'
import PropertiesList from './PropertiesList'
import ReazySettings from '../../components/RenatalPanelComp/ReazySetting/ReazySettings'
import { setIsNewStatus } from '../../Redux/UserAuthSlice/RentalSlice'

const Reasy = () => {
    const [packages, setPackage] = useState("")
    const [loading, setLoading] = useState(false)
    const [priecs, setPrices] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [purchaseInfo, setPurchaseInfo] = useState()
    const [ReazyHistory, setReazyHistory] = useState("")
    const [purchasedHistory, setPurchaseHistory] = useState()
    const [isTimeSelect, setIsTimeSelect] = useState(false)
    const [selected, setSelected] = useState({})
    const [booked, setBooked] = useState(null)
    const [property, setProperty] = useState("")
    const [autoBook, setAutoBook] = useState(false)
    const [activeTab, setActiveTab] = useState(0)

    const location = useLocation().pathname
    const dispatch = useDispatch()

    let { userRole } = useSelector((state) => state?.userAuth?.userInfo)
    let { isNew } = useSelector((state) => state?.rental)

    let fetchRenterSubsc = async () => {
        setLoadingStatus(true)
        let { res } = await GetReasySubscription()
        if (res) {
            if (res.purchased === false) {
                dispatch(setIsNewStatus(true))
            }
            setLoadingStatus(false)
            setPurchaseInfo(res)
            setPurchaseHistory(res)
        }
    }

    let fetchRenterFlashLane = async () => {
        setLoading(true)
        let { res } = await GetPricesREsy()
        if (res) {
            setLoading(false)
            setPrices(res)
        }
    }
    let fetchServicerFlashLane = async () => {
        setLoading(true)
        let { res } = await GetPricesServicerREsy()
        if (res) {
            setLoading(false)
            setPrices(res)
        }
    }

    const handleCheck = (item) => {
        setSelected(item)
        setIsTimeSelect(true)
    }

    const handleToggle = (toggle) => {
        setActiveTab(toggle)
        if (toggle === 1 && purchasedHistory?.roommate?.purchased === true) {
            setPurchaseInfo(purchasedHistory)
        } else {
            setProperty("")
            setIsTimeSelect(false)
            setPackage(false)
        }
    }

    useEffect(() => {
        if (userRole === "renter") {
            fetchRenterSubsc()
            fetchRenterFlashLane()
        }
        else {
            fetchRenterSubsc()
            fetchServicerFlashLane()
        }
    }, [])

    useEffect(() => {
        if (ReazyHistory !== "" && activeTab === 0) {
            setPurchaseInfo(purchasedHistory)
            setReazyHistory("")
        }
    }, [ReazyHistory, activeTab])

    const { t } = useTranslation()

    return (
        <>

            {

                loadingStatus ? <div className="pt-5"><Loader type="loadData" /> </div>
                    :
                    activeTab === 0 && purchaseInfo?.purchased === true ?
                        <>
                            {location === "/flash" || location === "/reazy" ?
                                isNew ?
                                    <ReazySettings />
                                    :
                                    <FlashSidebar info={purchaseInfo} />
                                :
                                <div className=''>
                                    <ServicerFlashpass
                                        setPurchaseInfo={setPurchaseInfo}
                                        handleToggle={handleToggle}
                                        activesTab={0}
                                        setActivesTab={setActiveTab}
                                        setReazyHistory={setReazyHistory}
                                        info={purchaseInfo} />
                                </div>
                            }
                        </>
                        :
                        activeTab === 1 && purchasedHistory.roommate?.purchased === true ?
                            <div className=''>
                                <ServicerFlashpass
                                    setPurchaseInfo={setPurchaseInfo}
                                    handleToggle={handleToggle}
                                    activesTab={1}
                                    setActivesTab={setActiveTab}
                                    setReazyHistory={setReazyHistory}
                                    info={purchaseInfo} />
                            </div>
                            :
                            <>
                                {location === "/reazy" || location === "/flash" ? "" :
                                    <div className='pt-5 max-w-[300px]'>
                                        <div className={` flex items-center rounded-md border `}>
                                            <div onClick={() => handleToggle(0)} className={`${activeTab === 0 ? `${location === "/servicer_reazy" ? "bg-[#B913F0]" : "bg-[#FFDD00]"} rounded-bl-md rounded-tl-md text-white` : 'text-black'} cursor-pointer w-full border-r px-6 h-full py-2 `}>
                                                <p>{t('servicer_rentals')}</p>
                                            </div>
                                            <div onClick={() => handleToggle(1)} className={`${activeTab === 1 ? `${location === "/servicer_reazy" ? "bg-[#B913F0]" : "bg-[#FFDD00]"} rounded-tr-md rounded-br-md text-white` : 'text-black'}  cursor-pointer w-full px-6 h-full py-2`}>
                                                <p>{t('rommates')}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    packages ?
                                        <Purchase activeTab={activeTab} data={selected} autoBook={autoBook} property={property} booked={booked} setPackage={() => setPackage(false)} />
                                        :

                                        isTimeSelect ? <BlockTime activeTab={activeTab} setAutoBook={setAutoBook} setBooked={setBooked} selected={selected} setPackage={setPackage} setIsTimeSelect={setIsTimeSelect} />
                                            :
                                            property === "" && location === "/servicer_reazy" ?
                                                <PropertiesList activeTab={activeTab} setProperty={setProperty} />
                                                :
                                                <div className=' lg:max-w-[550px] m-auto bg-cover   h-full w-full  '>
                                                    <div className='lg:p-[3rem] p-[20px]'>
                                                        <h2 className='text-[30px] font-semibold text-center'>{t('welcom_resy')}</h2>
                                                        <h2 className='text-[15px]'>{t('reazy_desc')}</h2>
                                                        <h2 className='text-[23px] font-semibold'>{t('how_work')}</h2>
                                                        <div className='pt-4'>
                                                            <h2 className='text-[15px] '>{t('reazy_desc_2')}</h2>
                                                            <h2 className='text-[15px]  '>{t('reazy_desc_3')}</h2>
                                                        </div>
                                                        <ul className='my-3'>
                                                            <li className='flex items-center '>
                                                                <img src={flash} alt="flash" />
                                                                <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('reazy_text_1')}</h2>
                                                            </li>
                                                            <li className='flex items-center my-2'>
                                                                <img src={flash} alt="flash" />
                                                                <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('reazy_text_2')}</h2>
                                                            </li>
                                                            <li className='flex items-center my-2 '>
                                                                <img src={flash} alt="flash" />
                                                                <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('reazy_text_3')}</h2>
                                                            </li>
                                                            {/* <li className='flex items-center '>
                                                                <img src={flash} alt="flash" />
                                                                <h2 className='mx-2 font-medium lg:text-[16px] text-[14px]'>{t('have_all')}</h2>
                                                            </li> */}
                                                        </ul>
                                                        <h2 className='text-[23px] font-semibold'>{t('pricing')}</h2>

                                                        <ul className='my-2'>
                                                            {!loading && priecs?.map((list, i) => {
                                                                let addDot = list?.unit_amount_decimal / 100
                                                                return (
                                                                    <li onClick={() => handleCheck(list)} key={i} className=' cursor-pointer border p-1 px-2  flex justify-between items-center bg-gradient-to-r from-[#B913F0] to-[#E63ECD] h-full rounded-full '>
                                                                        <div className='flex mx-3 items-center'>
                                                                            <img src={list?.metadata?.image} className='object-cover w-6 h-6' alt="icon_img" />
                                                                            <div className='flex flex-col items-start '>
                                                                                <h2 className='text-white mx-1 text-[18px]'>{
                                                                                    list?.metadata?.name
                                                                                }</h2>
                                                                                <p className='text-[12px] text-white px-2'>{list?.metadata?.desc}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <p className='text-white px-2'>${addDot}</p>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                            }
                                                            {loading ? <Loader type="loadData" /> : ""}
                                                        </ul>
                                                    </div>

                                                </div>
                                }

                            </>
            }
        </>
        // <>
        //     <BlockTime />
        // </>

    )
}

export default Reasy