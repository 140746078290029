import React from 'react'
import exp1 from '../../assests/images/exp1.png'
import exp2 from '../../assests/images/hartford.jpg'
import exp3 from '../../assests/images/exp3.png'
import exp4 from '../../assests/images/exp4.png'
import exp5 from '../../assests/images/exp5.png'
import exp6 from '../../assests/images/exp6.png'
import exp7 from '../../assests/images/exp7.png'
import exp8 from '../../assests/images/malden.jpg'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addExplore } from '../../Redux/UserAuthSlice/RentalSlice'
import { useNavigate } from 'react-router-dom'
const Explore = () => {
    const { t } = useTranslation()
    let obj = {
        "min": "0",
        "max": "10000",
        "address": {
            "latitude": 37.7749295,
            "longitude": -122.4194155,
            "address": "San Francisco, CA, USA",
            "city": "San Francisco",
            "state": "CA",
            "zipcode": "",
            "street": " "
        },
        "bath": 1,
        "bed": 3,
        "property": {
            "id": 3,
            "name": "Apartment",
            "name_es": "Apartamento",
            "created_at": "2023-01-09T07:03:35.000000Z",
            "updated_at": "2023-03-29T16:24:29.000000Z",
            "deleted_at": null
        }
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const handleSend = () => {
        dispatch(addExplore({ propertyName: "min", value: obj?.min }))
        dispatch(addExplore({ propertyName: "max", value: obj?.max }))
        dispatch(addExplore({ propertyName: "address", value: obj?.address }))
        dispatch(addExplore({ propertyName: "bath", value: obj?.bath }))
        dispatch(addExplore({ propertyName: "bed", value: obj?.bed }))
        dispatch(addExplore({ propertyName: "property", value: obj?.property }))
        navigate('/search')

    }
    return (
        <>
            <div className="container mx-auto lg:my-0 mt-[2rem] lg:px-0 px-4">
                <h2 className='text-center font-bold underline text-[2rem] lg:text-[2.5rem] py-7'>{t('explore_home')}</h2>
                <div className='grid lg:grid-cols-5 grid-cols-2 gap-3 '>
                    <div className='relative overflow-hidden cursor-pointer rounded-md '>
                        <img src={exp1} className="object-cover transition-all duration-500 ease-in-out transform hover:scale-150   " alt="explore" />
                        <div className='flex justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <div className='absolute  bottom-4 '>
                                    <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_1')}</h2>
                                    <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className='relative overflow-hidden cursor-pointer rounded-md'>
                            <img src={exp2} className="object-cover h-[175px] w-full transition-all duration-500 ease-in-out transform hover:scale-150 " alt="explore" />
                            <div className='flex justify-center items-center'>
                                <div className='absolute  bottom-2 '>
                                    <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_2')}</h2>
                                    <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                                </div>
                            </div>
                        </div>
                        <div className='relative my-3 overflow-hidden rounded-md'>
                            <img src={exp3} className="object-cover transition-all duration-500 ease-in-out transform hover:scale-150 " alt="explore" />
                            <div className='flex justify-center items-center'>
                                <div className='absolute  bottom-2 '>
                                    <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_3')}</h2>
                                    <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='relative overflow-hidden cursor-pointer rounded-md'>
                            <img src={exp4} className="object-cover transition-all duration-500 ease-in-out transform hover:scale-150 " alt="explore" />
                            <div className='flex justify-center items-center'>
                                <div className='absolute  bottom-2 '>
                                    <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_4')}</h2>
                                    <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                                </div>
                            </div>
                        </div>
                        <div className='relative my-3 overflow-hidden rounded-md'>
                            <img src={exp5} className="object-cover transition-all duration-500 ease-in-out transform hover:scale-150 " alt="explore" />
                            <div className='flex justify-center items-center'>
                                <div className='absolute  bottom-2 '>
                                    <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_5')}</h2>
                                    <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <div className='relative overflow-hidden cursor-pointer rounded-md'>
                            <img src={exp6} className="object-cover transition-all duration-500 ease-in-out transform hover:scale-150 " alt="explore" />
                            <div className='flex justify-center items-center'>
                                <div className='absolute  bottom-2 '>
                                    <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_6')}</h2>
                                    <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className='relative  overflow-hidden cursor-pointer rounded-md'>
                            <img src={exp7} className="object-cover transition-all duration-500 ease-in-out transform hover:scale-150 " alt="explore" />
                            <div className='flex justify-center items-center'>
                                <div className='absolute  bottom-2 '>
                                    <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_7')}</h2>
                                    <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                                </div>
                            </div>
                        </div>
                        <div className='relative my-3 lg:block hidden  overflow-hidden rounded-md'>
                            <img src={exp8} className="object-cover transition-all duration-500 ease-in-out transform hover:scale-150 w-full h-[175px] " alt="explore" />
                            <div className='flex justify-center items-center'>
                                <div className='absolute  bottom-2 '>
                                    <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_8')}</h2>
                                    <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='relative lg:hidden  overflow-hidden rounded-md'>
                            <img src={exp2} className="object-cover transition-all duration-500 ease-in-out transform hover:scale-150 " alt="explore" />
                            <div className='absolute  bottom-2 lg:left-[25%] left-[18%]'>
                                <h2 className='text-white font-semibold my-2 text-[18px]'>{t('exp_8')}</h2>
                                <button onClick={() => handleSend()} className='bg-white  text-black rounded-md w-full  py-1'>{t('view')}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Explore