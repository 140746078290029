import React from 'react'
import { useTranslation } from 'react-i18next'
const SettingContactUs = () => {

    const { t } = useTranslation()
    return (
        <>
            <div className="container my-24 px-6 mx-auto">

                <section className="mb-32 text-gray-800">
                    <div className="">
                        {/* <div className="grow-0 shrink-0 basis-auto mb-6 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
              <h2 className="lg:text-5xl text-4xl font-bold mb-6">{t('contact_us')}</h2>
              <p className="text-gray-500 mb-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laudantium, modi accusantium ipsum corporis quia asperiores
                dolorem nisi corrupti eveniet dolores ad maiores repellendus enim
                autem omnis fugiat perspiciatis? Ad, veritatis.
              </p>
              <p className="text-gray-500 mb-2">New York, 94126, United States</p>
              <p className="text-gray-500 mb-2">+ 01 234 567 89</p>
              <p className="text-gray-500 mb-2">info@gmail.com</p>
            </div> */}

                        <div className="grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full px-3 lg:px-6">
                            <h2 className="lg:text-2xl text-center text-4xl font-bold mb-6">{t('contact_us')}</h2>
                            <form>
                                <div className='grid grid-cols-2 gap-5'>
                                    <div className="form-group mb-6">
                                        <input type="text" className="form-control block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                                            placeholder={`${t('contact_first_name')}`} />
                                    </div>
                                    <div className="form-group mb-6">
                                        <input type="text" className="form-control block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
                                            placeholder={`${t('contact_last_name')}`} />
                                    </div>
                                </div>
                                <div className='grid grid-cols-2 gap-5'>
                                   
                                    <div className="form-group mb-6">
                                        <input type="email" className="form-control block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput8"
                                            placeholder={`${t('contact_email')}`} />
                                    </div>
                                    <div className="form-group mb-6">
                                        <input type="tel" className="form-control block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput7"
                                            placeholder={`${t('contact_phone')}`} />
                                    </div>
                                </div>


                                <div className="form-group mb-6">
                                    <textarea className="
              form-control
              block
              w-full
              px-3
              py-1.5
              text-base
              font-normal
              text-gray-700
              bg-white bg-clip-padding
              border border-solid border-gray-300
              rounded
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
            " id="exampleFormControlTextarea13" rows="3" placeholder={`${t('contact_message')}`}></textarea>
                                </div>

                                <button type="submit" className="
            w-full
            px-6
            py-3
            bg-[#F79F15]
            text-white
            font-medium
            text-xs
            leading-tight
            uppercase
            rounded
            shadow-md
            hover:bg-[#F79F15] hover:shadow-lg
            focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
            active:bg-blue-800 active:shadow-lg
            transition
            duration-150
            ease-in-out">{t('contact_submit')}</button>
                            </form>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default SettingContactUs