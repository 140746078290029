import React from 'react'
import blogImge from '../../assests/images/blogImg.png'
import { FaUserAlt } from 'react-icons/fa'
import { BsCalendar2Week } from 'react-icons/bs'
import './style.css'
import { Link } from 'react-router-dom'
const BlogLists = () => {
    return (
        <>
            <div className="container mx-auto">
                <div className='h-[650px] blog_list overflow-y-auto pt-[4rem] p-4'>
                    <div className='grid lg:grid-cols-3  grid-cols-1 gap-5'>
                        {
                            Array(15).fill(15).map((_, i) => (
                                <div key={i}>
                                    <Link to="/blogDetail">
                                        <img src={blogImge} alt="blog_image" />
                                    </Link>
                                    <div className='flex items-center py-3'>
                                        <span className='bg-blue-500 px-8 py-1 text-white font-semibold'>News</span>
                                        <h2 className=' mx-2 text-[18px]'>Construction</h2>
                                    </div>
                                    <div className='grid grid-cols-3 gap-2'>
                                        <div className='flex items-center border-r-2'>
                                            <FaUserAlt />
                                            <p className='mx-1 text-[15px]'>BY Hong hang</p>
                                        </div>
                                        <div className='flex items-center '>
                                            <BsCalendar2Week />
                                            <p className='mx-1'>13, Jan 22</p>
                                        </div>
                                        <div className='flex items-center'>
                                            <h2 className='text-[1.2rem]'>2</h2>
                                            <p className='mx-1'>comments</p>
                                        </div>
                                    </div>
                                    <div className='py-4'>
                                        <p className='text-[#A5A5A5]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis risus leo, elementum in malesuada an darius ut augue. Cras sit amet lectus et justo feugiat euismod...</p>
                                    </div>
                                    <Link to="/blogDetail" className='px-6 py-2.5 rounded-md bg-[#000000] text-white'>Read more </Link>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogLists