import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
// import { useLocation } from 'react-router-dom'
import SignUp from '../../pages/AuthPages/SignUp'
import SignIn from '../../pages/AuthPages/SignIn'
import { useDispatch, useSelector } from 'react-redux'
import { addPopup } from '../../Redux/UserAuthSlice/RentalSlice'
import DynamicPopup from '../../utils/Popup/DynamicPopup'
import BookAppointment from '../RenatalPanelComp/Dashboard/BookAppointment'
import NotificationChecker from '../../Partials/NotificationChecker'
import LogoutLoader from '../../Partials/LogoutLoader'
import Feedback from '../GenericComp/Feedback'

const Layout = ({ children }) => {
    const [signIn, setSignIn] = useState(false)
    const [signUp, setSignUp] = useState(false)
    const [show, setShow] = useState(false)
    const [type, setType] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [property, setproperty] = useState({});

    const popup = useSelector((state)=> state?.rental?.isLoginPop)
    const dispatch = useDispatch()

    const handleClose = () =>{
        dispatch(addPopup(false))
        setSignIn(false)
    }


    return (
        <div className='relative overflow-hidden'>
            {signUp && <SignUp modalOpen={signUp} setSignIn={setSignIn} onCloseModal={() => setSignUp(false)} />}
            {signIn === true || popup === true  ?  <SignIn modalOpen={signIn || popup } setSignUp={setSignUp}  onCloseModal={() => handleClose() } /> : null}
            {show && <DynamicPopup modalOpen={show} data={ type === "logout" ? <LogoutLoader setShow={setShow} /> : type === "appointment" ? <BookAppointment property={property} /> : type === "feedback" ? <Feedback setShow={setShow}/> : <NotificationChecker data={property} setShow={setShow} setType={setType} type={type} />} close={() => setShow(false)} />}
            <Header setSignUp={setSignUp} setSignIn={setSignIn}
            setShow={setShow} sidebarOpen={sidebarOpen} setType={setType} setproperty={setproperty} setSidebarOpen={setSidebarOpen}
            />

            {
                children
            }

            <Footer />
        </div>
    )
}

export default Layout