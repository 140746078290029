import React, { useEffect, useState } from 'react'
import LeftSide from '../../components/RenatalPanelComp/Payments/LeftSide'
import RightSide from '../../components/RenatalPanelComp/Payments/RightSide'
import LoginInfo from '../../components/RenatalPanelComp/Payments/LoginInfo'
// import Information from '../../components/AboutUsComp/Infromation'
import SettingFaq from '../../components/GenericComp/Settings/SettingFaq'
import SettingContactUs from '../../components/GenericComp/Settings/SettingContactUs'
import SettingInformation from '../../components/GenericComp/Settings/SettingInfromation'
import TermsCondition from '../../components/GenericComp/Safety/TermsCondition'
import Privacy from '../../components/GenericComp/Safety/Privacy'
import VerifyPhoneOtpWithoutStrip from '../../components/GenericComp/WithoutStripOtpVeri'
import Payment from './Payment'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { redirect } from 'react-router-dom'
// import LeasedBase from '../../components/GenericComp/Safety/LeasedBase'
// import { useSelector } from 'react-redux'
const Profile = () => {
  const { t } = useTranslation()
  const [pic, setPic] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [activeTabText, setActiveTabText] = useState(t('profile'))
  const [result, setResult] = useState("");
  const [profileData, setProfileData] = useState({});

  const lng = useSelector((state) => state?.userAuth?.language)

  const openNewTab = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };


  useEffect(() => {
    if (activeTabText === t('right_responsive')) {
      const url = lng === 'sp' ? 'https://ctlawhelp.org/es/derechos-de-los-inquilinos-informacion-general' : 'https://www.jud.ct.gov/Publications/hm031.pdf';
      openNewTab(url)
    }
    else if (activeTabText === t('eviction_guid')) {
      const url = lng === 'sp' ? 'https://www.jud.ct.gov/Publications/Spanish/HM014S.pdf' : 'https://www.jud.ct.gov/Publications/hm014.pdf';
      openNewTab(url)
    }
    else if (activeTabText === t('leased_base')) {
      const url = lng === 'sp' ? 'https://www.epa.gov/lead/declaracion-de-informacion-sobre-pintura-base-de-plomo-yo-peligros-de-la-pintura-base-de-plomo' : 'https://www.epa.gov/lead/lessors-disclosure-information-lead-based-paint-andor-lead-based-paint-hazards';
      openNewTab(url)
    }
  }, [activeTabText])

  return (
    <>
      <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 gap-2">
        <div className='border-r h-full'>
          <LeftSide setPic={setPic} pic={pic} setActiveTab={setActiveTab} setActiveTabText={setActiveTabText} />
        </div>
        <div className='xl:col-span-2 lg:col-span-2 md:col-span-2 border'>
          {activeTabText === t('profile') && activeTab === 0 && <RightSide pic={pic} setActiveTabText={setActiveTabText} setResult={setResult} setProfileData={setProfileData} setActiveTab={setActiveTab} />}
          {activeTab === 1 && activeTabText === "otp_verify" && <VerifyPhoneOtpWithoutStrip setActiveTab={setActiveTab} result={result} setActiveTabText={setActiveTabText} profileData={profileData} />}
          {activeTabText === t('payment_info') && <Payment pic={pic} />}
          {activeTabText === t('login_info') && <LoginInfo />}
          {/* {activeTabText === t('leased_base') && <LeasedBase />} */}
          {activeTabText === t('about_leased') && activeTab === 0 && <SettingInformation />}
          {activeTabText === t('contact_us') && <SettingContactUs />}
          {/* {activeTabText === t('contact_us') && <LeasedBase />} */}
          {activeTabText === t('faq') && <SettingFaq />}
          {activeTabText === t('terms_service') && activeTab === 0 && <TermsCondition />}
          {activeTabText === t('privacy_policy') && <Privacy lang={lng} />}
        </div>
      </div>

    </>
  )
}

export default Profile