import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AddRommateIndex, AddRommates, IspopUp, AddCollege, AddCampus } from '../../../Redux/UserAuthSlice/RommateSlice'
import {setUserRommate} from '../../../Redux/UserAuthSlice/UserAuthSlice'
import { CreateRommateProfile } from '../../../lib/Api/RantelServiceApi'
import { useNavigate } from 'react-router-dom'
import { Loader } from '../../../utils/loaderButton'

const CheckCollege = ({ setActives, setStatus, updateStatus, setRommate }) => {
  const [active, setActive] = useState(null)
  const [type, setType] = useState("")
  const [loading, setLoading] = useState(false)
  const { isNewRommate } = useSelector((state) => state?.rommate)
  const user = useSelector((state) => state?.userAuth?.userInfo)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  let formData = new FormData()
  formData.append('first_name', user?.fname)
  formData.append('last_name', user?.lname)
  formData.append('email', user?.email)
  formData.append('phone', user?.phone)

  // let checkList = Object.keys(rommateList ?? {}).length > 0

  const handleCheckNext = async (value) => {
    setActive(value)
    if (value === 0) {
      setActives(1)
      dispatch(AddCollege(1))
    }
    else {
      if (!isNewRommate) {
        dispatch(AddCollege(0))
        dispatch(AddCampus(0))
        formData.append('college_student', 0)
        formData.append('on_campus', 0)
        setType("no")
        setLoading(true)
        let res = await CreateRommateProfile(formData)
        if (res) {
          setLoading(false)
          let obj = Object.assign({}, ...res)
          setRommate(obj?.roommate)
          const info = {
            college_student: obj?.roommate?.college_student,
            on_campus: obj?.roommate?.on_campus
          };
          updateStatus(info, obj?.status)
          dispatch(AddRommates(obj?.roommate))
          dispatch(setUserRommate(info))
          setStatus(obj?.status)
          setType("")
          // setShow(false)
          dispatch(IspopUp(false))
          navigate('/roommatesList')
        }
      }
      else {
        dispatch(AddCollege(0))
        dispatch(AddCampus(0))
        dispatch(AddRommateIndex(2))
        // setShow(false)
        dispatch(IspopUp(false))
        navigate('/CreateRoommate')
      }
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <h2 className='text-center mt-5 text-[20px] font-bold'>{t('college_stu')}</h2>
      <div className="grid lg:grid-cols-2 gap-5  pt-[10px] ">
        <div className='group'>
          <div onClick={() => handleCheckNext(0)} className={`${active === 0 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  border max-w-[300px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <h2 className='text-[1rem] font-bold'>{loading && type === "yes" ? <Loader type="socialLoder" /> : t('yes')} </h2>

          </div>
        </div>
        <div className='group'>
          <div onClick={() => handleCheckNext(1)} className={`${active === 1 && 'border-2 rounded-md border-[#FFC000]'} bg-white cursor-pointer   shadow-md h-full  border max-w-[300px] mx-auto rounded-md flex justify-center flex-col items-center p-6`}>
            <h2 className='text-[1rem] font-bold '>{loading && type === "no" ? <Loader type="socialLoder" /> : t('no')}</h2>
          </div>
        </div>
      </div>


    </>
  )
}

export default CheckCollege