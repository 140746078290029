import React, { useState } from 'react';
import { Loader } from '../../../utils/loaderButton';
import { useEffect } from 'react';
import { CreateRommateQuestion, GetCampusQuestions, GetOffCampusQuestions } from '../../../lib/Api/RantelServiceApi';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AddRommate, AddRommateIndex, RommateNew } from '../../../Redux/UserAuthSlice/RommateSlice';
import { toast } from 'react-toastify'
import { IoArrowBackSharp } from "react-icons/io5";

const Questions = () => {
    const [showChild, setShowChild] = useState(false)
    const [loadQus, setLoadQus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentQuestionObj, setCurrentQuestionObj] = useState(null);
    const [currentChileQuestionObj, setCurrentChileQuestionObj] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [d1, setD1] = useState([]);
    const [d2, setD2] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(null));


    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { rommate, rommateId, college, campus, rommateList, isNewRommate } = useSelector((state) => state?.rommate)

    let checkQues = college === 1 && campus === 1 || rommateList?.college_student === 1 && rommateList?.on_campus === 1
    let checkQues2 = college === 1 && campus === 0 || rommateList?.college_student === 1 && rommateList?.on_campus === 0
    let GetQuestions = async () => {
        setLoadQus(true)
        let check = checkQues ? GetCampusQuestions() : checkQues2 ? GetOffCampusQuestions("off_campus") : GetOffCampusQuestions("local_resident")
        let { res } = await check
        const ConvertIntr = Object.assign({}, ...res)
        if (res) {
            setLoadQus(false)
            let allQuestions = ConvertIntr.questions;
            allQuestions.map((question, index) => {
                let thisQuestionsAnswer = rommateList?.priorities?.filter((f) =>
                    question.id === f?.roommates_questions_id
                );
                if (thisQuestionsAnswer.length > 0) {
                    if (thisQuestionsAnswer[0].roommates_questions_options_id !== 0) {
                        thisQuestionsAnswer = thisQuestionsAnswer[0].roommates_questions_options_id;
                        question.selectedOptions = thisQuestionsAnswer;
                    }
                }
                else {
                    question.selectedOptions = [];
                }
                question.optionList = [];
                return question
            })
            if (rommateList?.priorities?.length > 0) {
                const newSelectedOptions = selectedOptions;
                allQuestions?.map((ques) => {
                    let thisQuestionsAnswer = rommateList?.priorities?.filter((f) =>
                        ques.id === f?.roommates_questions_id
                    );
                    if (ques?.type === "checkbox") {
                        let optionList = [];
                        optionList = ques?.selectedOptions;
                        newSelectedOptions[ques?.id] = { optionList };
                    }
                    else if (thisQuestionsAnswer[0]?.answer !== null) {
                        let obj = Object.assign({}, ...thisQuestionsAnswer)
                        newSelectedOptions[ques.id] = obj
                    }
                    else {
                        newSelectedOptions[ques?.id] = thisQuestionsAnswer
                    }
                });
                let checkAns = rommateList?.priorities?.filter((p) => {
                    if (p?.roommates_questions_options_id?.includes(0)) {
                        return p
                    }
                })

                const childQuestions = allQuestions.reduce((acc, question) => {
                    const childQuestionObjects = question.options
                        .filter(option => option.child_question !== null)
                        .map(option => {
                            return {
                                ...option,
                                question_id: question.id // Use question.id instead of question?.id
                            };
                        });
                    return [...acc, ...childQuestionObjects];
                }, []);
                let findData = checkAns.filter((f) => childQuestions.some((child) => child?.child_question?.id === f.roommates_questions_id));

                findData?.map((ad) => (
                    newSelectedOptions[ad.roommates_questions_id] = ad
                ))

            }
            setQuestions(allQuestions)
            setD1(allQuestions)
            // setD2((prev) => [...prev, allQuestions[0]])
            setCurrentQuestionObj(allQuestions[0])


        }
        else {
            setLoadQus(false)
        }
    }

    const handleInputFieldChange = (value, isChild = false) => {
        const newSelectedOptions = [...selectedOptions];
        const inputFieldObject = {
            id: 0 || currentQuestionObj?.id,
            roommates_questions_id: currentQuestionObj.id,
            option: "",
            option_id: 0,
            option_es: "",
            roommates_questions_options_id: 0,
            answer: value
        };
        if (currentQuestionObj?.selectedOptions?.length && !currentQuestionObj?.selectedOptions?.includes(0)) {
            let checkObj = currentQuestionObj?.options?.find((f) => f.id === currentQuestionObj.selectedOptions[0])
            newSelectedOptions[checkObj?.child_question?.id] = inputFieldObject;
            setCurrentChileQuestionObj(inputFieldObject)
        } else {
            newSelectedOptions[currentQuestionObj.id] = inputFieldObject;
        }

        setSelectedOptions(newSelectedOptions);
    };


    const handleOptionChange = (option) => {
        const newSelectedOptions = selectedOptions;
        let updatedQuestions = [...questions]
        let updatedOptions = currentQuestionObj?.selectedOptions;
        if (newSelectedOptions[currentQuestionObj.id]) {
            if (newSelectedOptions?.[currentQuestionObj.id] && currentQuestionObj?.options?.length <= 4) {
                if (currentQuestionObj?.selectedOptions?.length) {
                    updatedOptions = [];
                    updatedOptions.push(option.id)
                    updatedQuestions.map((f) => {
                        if (f.id === currentQuestionObj.id) {
                            f.selectedOptions = [option.id]
                        }
                    })
                    newSelectedOptions[currentQuestionObj.id] = [option]
                }
                else {
                    updatedOptions = [];
                    updatedOptions.push(option.id);
                    updatedQuestions.map((f) => {
                        if (f.id === currentQuestionObj.id) {
                            f.selectedOptions = [option.id]
                        }
                    })
                    newSelectedOptions[currentQuestionObj.id] = [option]
                }
            }
            else if (!newSelectedOptions?.[currentQuestionObj.id] && currentQuestionObj?.options?.length <= 4) {
                newSelectedOptions[currentQuestionObj.id].push(option)
            }
            else {
                if (!newSelectedOptions[currentQuestionObj.id]?.optionList) {
                    newSelectedOptions[currentQuestionObj.id] = { optionList: [] };
                }
                if (rommateList?.priorities?.length > 0) {
                    let checkIndex = newSelectedOptions[currentQuestionObj.id].optionList.indexOf(option.id);
                    if (checkIndex !== -1) {
                        newSelectedOptions[currentQuestionObj.id].optionList = newSelectedOptions[currentQuestionObj.id].optionList.filter((f) => f !== option.id);

                        setCurrentQuestionObj((prev) => {
                            const updatedOptions = prev.selectedOptions.filter((f) => f !== option.id);
                            return {
                                ...prev,
                                selectedOptions: updatedOptions
                            };
                        });
                        updatedQuestions.forEach((f) => {
                            if (f.id === currentQuestionObj.id) {
                                f.selectedOptions = newSelectedOptions[currentQuestionObj.id].optionList;
                            }
                        });
                        setSelectedOptions(newSelectedOptions)
                        setQuestions(updatedQuestions);
                    }
                    else {

                        const optionList = newSelectedOptions[currentQuestionObj.id].optionList;
                        if (!Object.isExtensible(optionList)) {
                            newSelectedOptions[currentQuestionObj.id].optionList = [...optionList];
                        }

                        const index = newSelectedOptions[currentQuestionObj.id].optionList.length;
                        newSelectedOptions[currentQuestionObj.id].optionList[index] = option.id;
                        setSelectedOptions(newSelectedOptions)
                        setCurrentQuestionObj((prev) => {
                            const updatedOptions = prev.selectedOptions.concat(option.id);
                            return {
                                ...prev,
                                selectedOptions: updatedOptions
                            };
                        });
                        updatedQuestions.forEach((f) => {
                            if (f.id === currentQuestionObj.id) {
                                f.selectedOptions = newSelectedOptions[currentQuestionObj.id].optionList;
                            }
                        });
                        setQuestions(updatedQuestions);
                    }
                }
                else {
                    let checkIndex = newSelectedOptions[currentQuestionObj.id].optionList.indexOf(option.id);
                    if (checkIndex !== -1) {
                        newSelectedOptions[currentQuestionObj.id].optionList.splice(checkIndex, 1);
                        // setSelectedOptions(newSelectedOptions)
                        setCurrentQuestionObj((prev) => {
                            const updatedOptions = [...prev.selectedOptions.slice(0, checkIndex), ...prev.selectedOptions.slice(checkIndex + 1)];
                            return {
                                ...prev,
                                selectedOptions: updatedOptions
                            };
                        });
                        updatedQuestions.forEach((f) => {
                            if (f.id === currentQuestionObj.id) {
                                f.selectedOptions = newSelectedOptions[currentQuestionObj.id].optionList;
                            }
                        });
                        setQuestions(updatedQuestions);
                    }
                    else {
                        updatedOptions.push(option.id);
                        // updatedQuestions.map((f) => {
                        //     if (f.id === currentQuestionObj.id) {
                        //         f.selectedOptions = [option.id]
                        //     }
                        // })
                        newSelectedOptions[currentQuestionObj.id].optionList.push(option.id);
                        setSelectedOptions(newSelectedOptions);
                        updatedQuestions.forEach((f) => {
                            if (f.id === currentQuestionObj.id) {
                                f.selectedOptions = newSelectedOptions[currentQuestionObj.id].optionList;
                            }
                        });
                        setQuestions(updatedQuestions)
                        setCurrentQuestionObj((prev) => ({
                            ...prev,
                            selectedOptions: updatedOptions
                        }))
                    }
                }
            }
        } else {
            if (currentQuestionObj?.options?.length <= 4) {
                selectedOptions[currentQuestionObj.id] = [option];
                updatedOptions.push(option.id);
                updatedQuestions.map((f) => {
                    if (f.id === currentQuestionObj.id) {
                        f.selectedOptions = [option.id]
                    }
                })

            }
            else {
                if (!newSelectedOptions[currentQuestionObj.id]?.optionList) {
                    newSelectedOptions[currentQuestionObj.id] = { optionList: [] };
                }
                updatedOptions.push(option.id);
                newSelectedOptions[currentQuestionObj.id].optionList.push(option.id);
                updatedQuestions.map((f) => {
                    if (f.id === currentQuestionObj.id) {
                        f.selectedOptions = [option.id]
                    }
                })
                setSelectedOptions(newSelectedOptions);
                setQuestions(updatedQuestions)
                setCurrentQuestionObj((prev) => ({
                    ...prev,
                    selectedOptions: updatedOptions
                }))
            }

        }
        if (currentQuestionObj?.options?.length < 4) {
            setSelectedOptions(newSelectedOptions);
            setQuestions(updatedQuestions)
            setCurrentQuestionObj((prev) => ({
                ...prev,
                selectedOptions: updatedOptions
            }))
        }

        let child = newSelectedOptions[currentQuestionObj.id][0]?.child_question;
        if (child) {
            setShowChild(true)
            setCurrentChileQuestionObj(child)
        }
        else {
            setShowChild(false)
        }
    };

    const handleNext = () => {
        const option = selectedOptions[currentQuestionObj.id];
        if (!option && rommateList?.priorities?.length === 0 || option?.optionList?.length === 0) {
            toast.error(t('error_option_select'));
            return;
        }
        selectedOptions.filter(item => item !== null);
        let inc = currentQuestion + 1
        setCurrentQuestion(inc);
        let val = questions[inc]
        setCurrentQuestionObj(val)
        let newOp = d1[inc]
        setD2((prev) => [...prev, newOp])
        let findIndx = selectedOptions[val?.id]
        let findQues = questions.find((f) => f.id === val?.id)
        let findSelected = findQues?.options?.filter((f) => findIndx?.some((s) => s.roommates_questions_options_id.includes(f.id)))
        if (findIndx !== undefined && findIndx[0]?.answer !== null) {
            let obj = Object.assign({}, ...findIndx)
            let findChild = selectedOptions[obj?.child_question?.id]
            if (findChild) {
                let obj2 = {
                    id: obj?.child_question?.id
                }
                setShowChild(true)
                setCurrentChileQuestionObj(obj2)
            }
            else {
                setShowChild(false)
                setCurrentChileQuestionObj({})

            }
        }
        else if (findSelected !== undefined) {
            let obj = Object.assign({}, ...findSelected)
            let findChild = selectedOptions[obj?.child_question?.id]
            if (findChild) {
                let obj2 = {
                    id: obj?.child_question?.id
                }
                setShowChild(true)
                setCurrentChileQuestionObj(obj2)
            }
            else {
                setShowChild(false)
                setCurrentChileQuestionObj({})

            }
        }
        else {
            setShowChild(false)
            setCurrentChileQuestionObj({})
        }

    };

    const handlePrevious = () => {

        let des = currentQuestion - 1
        let val = questions[des]
        let check = d2?.filter((f) => f?.id !== val?.id)
        let findIndx = selectedOptions[val?.id]
        let findQues = questions?.find((f) => f.id === val?.id)

        if (findIndx !== undefined && findIndx[0]?.answer !== null && !findIndx?.optionList) {
            let obj = Object.assign({}, ...findIndx)
            let findChild = selectedOptions[obj?.child_question?.id]
            if (findChild) {
                let obj2 = {
                    id: obj?.child_question?.id
                }
                setShowChild(true)
                setCurrentChileQuestionObj(obj2)
            }
            else {
                setShowChild(false)
                setCurrentChileQuestionObj({})

            }
        }
        else if (!findIndx?.optionList) {
            let findSelected = findQues?.options?.filter((f) => findIndx?.some((s) => s?.roommates_questions_options_id?.includes(f.id)))
            if (findSelected !== undefined) {
                let obj = Object.assign({}, ...findSelected)
                let findChild = selectedOptions[obj?.child_question?.id]
                if (findChild) {
                    let obj2 = {
                        id: obj?.child_question?.id
                    }
                    setShowChild(true)
                    setCurrentChileQuestionObj(obj2)
                }
                else {
                    setShowChild(false)
                    setCurrentChileQuestionObj({})

                }
            }
            else {
                setShowChild(false)
                setCurrentChileQuestionObj({})
            }
            if (des === 0) {
                setD2([])
            } else {
                setD2(check)
            }
        }
        setCurrentQuestion(des);
        setCurrentQuestionObj(val)
    };

    //console.log("selected", selectedOptions)

    const handleSubmit = async () => {
        debugger
        const option = selectedOptions[currentQuestionObj.id];
        if (option && Object.keys(option)?.length === 0 || option === undefined || option === null) {
            toast.error(t('error_option_select'));
            return;
        }

        const newArray = [];
        selectedOptions.forEach((item, index) => {
            if (item !== undefined) {
                newArray[index] = item;
            }
        });
        let filter = newArray?.map((f, questionId) => {
            let op2 = f?.optionList?.map((op) => {
                return op
            })
            if (Array.isArray(f)) {
                return {
                    "question_id": questionId,
                    "options": f.map((option) => {
                        if (option.roommates_questions_options_id?.length > 0) {
                            return option.roommates_questions_options_id[0]
                        } else {
                            return option.id
                        }
                    })
                }
            }
            else if (f?.optionList) {
                return {
                    "question_id": questionId,
                    "options": op2
                }
            }
            else if (f?.answer !== undefined) {
                return {
                    "question_id": questionId,
                    "answer": f?.answer
                }
            }

        })


        let payload = {
            roommate_id: rommateId,
            priorities: Object.values(filter)
        };
        setLoading(true)
        let res = await CreateRommateQuestion(payload)
        if (res?.success === true) {
            setLoading(false)
            // dispatch(AddRommate({}))
            navigate('/roommatesList')
            dispatch(AddRommateIndex(0))
            dispatch(RommateNew(false))
        }
        else {
            setLoading(false)
        }
    }

    useEffect(() => {
        GetQuestions()
    }, [])


    return (
        <>

            <div className='xl:hidden lg:hidden md:hidden block'>
                <Link to="/roommatesList">
                    <IoArrowBackSharp size={20} />
                </Link>
            </div>
            <h1 className='text-center text-[25px] mt-5 font-semibold'>{t('rommate_question')}</h1>

            <div className='mt-5 border shadow-md p-5 rounded-sm'>
                {
                    loadQus ?
                        <div className='flex justify-start items-start'>
                            <Loader type="loadData" />
                        </div>
                        :
                        <div className='flex flex-col'>
                            <h2 className='text-[20px] font-semibold'>{`${currentQuestion + 1} ${t('of')} ${questions?.length}`} {`:`} {currentQuestionObj?.question}</h2>

                            {currentQuestionObj?.type === 'input_field' ? (
                                <div className=''>
                                    <input
                                        type="text"
                                        id={`input-field-${currentQuestionObj?.id}`}
                                        name={`question-${currentQuestionObj?.id}`}
                                        className='border focus:outline-none px-2 py-1 rounded-md w-full h-[50px]'
                                        value={selectedOptions[currentQuestionObj?.id]?.answer}
                                        required
                                        placeholder={currentQuestionObj?.input_plaecholder}
                                        onChange={(e) => handleInputFieldChange(e.target.value)}
                                    />
                                </div>

                            ) : (
                                <>
                                    <div>
                                        {currentQuestionObj?.options?.map((option, index) => {
                                            return (
                                                <div className='flex items-center mx-1 py-2' key={index}>
                                                    <input
                                                        type={
                                                            currentQuestionObj.type === 'checkbox'
                                                                ? 'checkbox'
                                                                : 'radio'
                                                        }
                                                        required
                                                        id={`option-${option.id}`}
                                                        name={`question-${currentQuestion}`}
                                                        value={option.id} // Assuming option.id is unique
                                                        checked={
                                                            currentQuestionObj?.selectedOptions?.includes(option.id)

                                                        }
                                                        onChange={() => handleOptionChange(option)}
                                                    />

                                                    <label htmlFor={`option-${option.id}`} className='mx-1 cursor-pointer text-[16px]'>
                                                        {option?.option}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {
                                        // Object.keys(selectedOptions).length > 0 && selectedOptions[currentQuestionObj.id][0]?.child_question != null &&
                                        showChild &&
                                        // 
                                        <div className='bg-[#efefef] p-[10px]'>
                                            {/* <label htmlFor="" className='font-bold pb-2'>{currentChileQuestionObj?.input_plaecholder}</label> */}
                                            <input
                                                type="text"
                                                id={`input-field-${currentChileQuestionObj?.id}`}
                                                name={`question-${currentChileQuestionObj?.id}`}
                                                value={selectedOptions[currentChileQuestionObj?.id]?.answer}
                                                placeholder={currentChileQuestionObj?.input_plaecholder}
                                                className='border focus:outline-none px-2 py-1 rounded-md w-full h-[125px]'
                                                onChange={(e) => handleInputFieldChange(e.target.value, true)}
                                            />
                                        </div>
                                    }

                                </>
                            )}


                        </div>

                }
                <div className="flex justify-between items-center">
                    {currentQuestion > 0 && (
                        <div className={` py-3`}>
                            <button onClick={handlePrevious} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                {t('Back')}
                            </button>
                        </div>
                    )}
                    {
                        !loadQus && currentQuestion === questions?.length - 1 && rommateList?.priorities?.length > 0 ?
                            <div className={`flex justify-end py-3 gap-2`}>
                                <Link to="/roommatesList">
                                    <button type="button" className='font-semibold border-[#F79F15] border text-[#F79F15]  px-6 py-1.5  rounded-md'>{t('cancel')}</button>
                                </Link>
                                <button onClick={() => handleSubmit()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                    {loading ? <Loader type="saveLoder" /> : t('save')}
                                </button>
                            </div>
                            :
                            !loadQus && currentQuestion === questions?.length - 1 && rommateList?.priorities?.length === 0 ?
                                <div>
                                    <button onClick={() => handleSubmit()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                        {loading ? <Loader type="saveLoder" /> : t('save')}
                                    </button>
                                </div>
                                :
                                !loadQus && <div className={` py-3`}>
                                    <button onClick={() => handleNext()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>
                                        {t('Next')}
                                    </button>
                                </div>
                    }
                </div>
            </div >
        </>
    )
}

export default Questions