import React, { useState, useEffect } from 'react'
import { S3_BUCKET, myBucket } from '../../../config/Aws'
import { toast } from 'react-toastify'
import { ConvertBase64 } from '../../../utils/ConvBase64'
import { Loader } from '../../../utils/loaderButton'
import { FaRegImages } from 'react-icons/fa'
import { IoMdCloseCircle } from 'react-icons/io'
import { BsFillCheckCircleFill } from 'react-icons/bs'
const UploadFiles = ({ handleNext, loading, handleBack, setUploadData, uplaodData, t }) => {
  // const [Url, setUrl] = useState([])
  // const [codeFile, setCodeFile] = useState('')
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    Url: [],
    codeFile: '',
    threeDFloor: []
  });
  const [Loading, setLoading] = useState(false)



  const handleImageChange = (event, type) => {
    let fileLen = event.target.files
    if (type === "3dFloor" && fileLen?.length > 5) {
      toast.error(t('upload_img_info'))
    }
    else if (type !== "3dFloor" && fileLen?.length > 15) {
      toast.error(t('upload_3d_info'))
    }
    else {
      const files = Array.from(event.target.files);
      const acceptedFileTypes = ['image/jpeg', 'image/png']; // Add more accepted file types as needed
      const imageFiles = files.filter(file => acceptedFileTypes.includes(file.type));
      Promise.all(
        imageFiles.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
          });
        })
      )
        .then(base64Images => {
          if (type === "3dFloor") {
            setFormData((prevObject) => ({
              ...prevObject,
              threeDFloor: [...formData?.threeDFloor, ...base64Images],
            }));
            setUploadData((prevObject) => ({
              ...prevObject,
              threeDFloor: [...formData?.threeDFloor, ...base64Images],
            }));
          }
          else {
            setFormData((prevObject) => ({
              ...prevObject,
              Url: [...formData?.Url, ...base64Images],
            }));
            setUploadData((prevObject) => ({
              ...prevObject,
              Url: [...formData?.Url, ...base64Images],
            }));
          }

        })
        .catch(error => {
          //console.log('Error occurred while converting images to base64:', error);
        });
    }
  };

  const RemoveImage = (type, id) => {
    if (type === "3dfloor") { 
      let ImagesList = formData?.threeDFloor?.filter((_, i) => i !== id)
      setFormData((prev) => ({
        ...prev,
        threeDFloor: ImagesList
      }))
      setUploadData((prevObject) => ({
        ...prevObject,
        threeDFloor: ImagesList
      }));
    }
    else {
      let ImagesList = formData?.Url?.filter((_, i) => i !== id)
      setFormData((prev) => ({
        ...prev,
        Url: ImagesList
      }))
      setUploadData((prevObject) => ({
        ...prevObject,
        Url: ImagesList
      }));
    }
  }



  const handleCodeFile = async (evnt) => {
    const file = evnt.target.files[0]
    if (!file.name.match(/.(mp4|mov|wmv|avi|avchd|flv|mkv|mpeg-2)$/i)) {
      toast.error("Only Video File is Required")
    } else {
      setLoading(true)
      const params = {
        ACL: 'private',
        Body: file,
        Bucket: S3_BUCKET,
        Key: file?.name
      };

      myBucket.putObject(params, (err, data) => {

        if (err) {
          setLoading(false)
          toast.error(err)
        } else {
          setFormData((prev) => ({ ...prev, codeFile: file?.name }))
          setUploadData((prevObject) => ({
            ...prevObject,
            codeFile: file?.name
          }));
          setErrors((prev) => ({ ...prev, video: '' }))

          setLoading(false)
        }
      });
    }
  }


  const validateForm = () => {
    const newErrors = {};
    if (!formData.Url.length > 0) {
      newErrors.Image = t('invalid_img_req')
    }
    return newErrors
  }





  const Submit = () => {
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      let payload = {
        "images": formData.Url,
        "video": formData.codeFile,
        "threeDFloor": formData?.threeDFloor
      }
      // setImagesData(payload)
      handleNext(payload)
    } else {
      setErrors(validationErrors);
    }
  }



  useEffect(() => {
    if (uplaodData.Url && uplaodData.codeFile !== "") {
      setFormData({ Url: uplaodData.Url, codeFile: uplaodData.codeFile })
    }
  }, [])


  return (
    <>
      <h2 className='font-semibold text-[20px]'>{t('img_lab')}</h2>
      <div className='border py-3 pl-3 flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center '>
        <label htmlFor='upload' className={`${formData?.Url?.length === 15 ? 'cursor-not-allowed' : 'cursor-pointer'} bg-gray-100 cursor-pointer border rounded-md px-6 h-[60px] flex justify-center items-center`}>
          <div className='flex items-center text-gray-400  ' >
            <FaRegImages />
            <h2 className='px-2'>{t('uplod_lbl')}</h2>
          </div>
        </label>
        <input type="file" id="upload" className='hidden' disabled={formData?.Url?.length === 15 ? true : false} name="image_upload" multiple onChange={(e) => handleImageChange(e, "images")} />
        <div className='xl:ml-3 lg:ml-3 md:ml-3 xl:mt-0 lg:mt-0 md:mt-0 mt-2 grid xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 grid-cols-2 gap-2 '>
          {
            formData?.Url?.map((u, i) => (
              <div className='relative  xl:first:mx-2 lg:first:mx-2 md:first:mx-2 first:mx-0 border rounded-md' key={i}>
                <img src={u} alt="user_image" className='object-cover w-[120px] h-[60px] rounded-md ' />
                <div className='absolute right-1 top-1'>
                  <IoMdCloseCircle className='text-red-500 cursor-pointer' onClick={() => RemoveImage("", i)} />
                </div>
              </div>
            ))
          }

        </div>
      </div>
      <span className='text-sm text-red-500'> {errors.Image ? errors.Image : t('invaild_img')} </span>

      <h2 className='font-semibold text-[20px]'>{t('3d_lab')} {(t('optional'))}</h2>
      <div className='border py-3 pl-3 flex xl:flex-nowrap lg:flex-nowrap md:flex-nowrap flex-wrap items-center '>
        <label htmlFor='uploads' className={`${formData?.threeDFloor?.length === 5 ? 'cursor-not-allowed' : 'cursor-pointer'} bg-gray-100 cursor-pointer border rounded-md px-6 h-[60px] flex justify-center items-center`}>
          <div className='flex items-center text-gray-400 '>
            <FaRegImages />
            <h2 className='px-2'>{t('uplod_lbl')}</h2>
          </div>
        </label>
        <input type="file" id="uploads" className='hidden' disabled={formData?.threeDFloor?.length === 5 ? true : false} name="image_upload" multiple onChange={(e) => handleImageChange(e, "3dFloor")} />

        <div className=' xl:ml-3 lg:ml-3 md:ml-3 xl:mt-0 lg:mt-0 md:mt-0 mt-2  grid xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 grid-cols-2 gap-2 '>
          {
            formData?.threeDFloor?.map((u, i) => (
              <div className='relative xl:first:mx-2 lg:first:mx-2 md:first:mx-2 first:mx-0 x border rounded-md' key={i}>
                <img src={u} alt="user_image" className='object-cover w-[120px] h-[60px] rounded-md ' />
                <div className='absolute right-1 top-1'>
                  <IoMdCloseCircle className='text-red-500 cursor-pointer' onClick={() => RemoveImage("3dfloor" , i)} />
                </div>
              </div>
            ))
          }

        </div>

      </div>
      <span className='text-sm text-red-500'> {errors.Image ? errors.Image : t('invaild_imgs')} </span>
      {/* <div className='border p-3 my-3'>
        <input type="file" onChange={handleChangeImage} />
      </div> */}
      <h2 className='font-semibold text-[20px]'>{t('vide_lab')} {(t('optionalVideo'))}</h2>
      <div className='border p-3 mt-3 '>
        {
          Loading ?
            <Loader type="loadData" />
            :
            <>
              {formData.codeFile === "" ?
                <input type="file" onChange={handleCodeFile} />
                :
                <div className='flex items-center'>
                  <BsFillCheckCircleFill className='text-green-500' />
                  <p className='px-4'>{formData.codeFile}</p>
                </div>
              }
            </>
        }
      </div>
      {/* <span className='text-sm text-red-500'> {errors.video} </span> */}
      <div className='flex justify-between py-3'>
        {
          loading ?
            <Loader type="saved" />
            :
            <>
              <button type='button' onClick={() => handleBack()} className='font-semibold bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Back')}</button>
              <button onClick={() => Submit()} disabled={formData?.Url.length > 0 ? false : true} className='font-semibold disabled:cursor-not-allowed bg-[#F79F15] px-6 py-1.5 text-white rounded-md'>{t('Finish')}</button>
            </>
        }


      </div >


    </>
  )
}

export default UploadFiles