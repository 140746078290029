import React, { useEffect, useState } from 'react';
import { useSprings, animated, to as interpolate } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import './tinder.css'
import gp4 from '../../../assests/svg/Group 4188.svg'
import person from '../../../assests/svg/Icon ionic-ios-person.svg'
import locat from '../../../assests/svg/Icon material-location-on.svg'
import { useLocation } from 'react-router-dom'
import { GetPaginationRentProp } from '../../../lib/Api/RentalAppApi';
import { useTranslation } from 'react-i18next';
import DynamicPopup from '../../../utils/Popup/DynamicPopup'

const to = i => ({
    x: 0,
    y: i * -4,
    scale: 1,
    rot: -10 + Math.random() * 20,
    delay: i * 100,
});
const from = i => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });

const trans = (r, s) => `perspective(1500px)  rotateY(${r / 10}deg)  scale(${s})`

function Deck({ toggle, setProperty, revert, listing, setListing, setDetail, setOriginalLists, rommateType, originalLists, setSavedPositions, savedPositions, setPoints }) {
    const location = useLocation().pathname;
    const [gone, setGone] = useState(() => new Set());
    const [show, setShow] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    const [props, api] = useSprings(listing?.length, i => ({
        ...to(i),
        from: from(i),
    }))


    const determineDirCard = (x, index) => {
        const numberOfElementsToKeep = 1;
        const filteredData = listing.filter((item, i) => i >= listing.length - numberOfElementsToKeep);
        let obj = Object.assign({}, ...filteredData);
        setListing(prev => prev.filter(item => item.id !== obj.id));
        if (x > 0) {
            setProperty(listing[index]);
            toggle(true);
        }
    }


    const PaginateFilter = async () => {
        try {
            let { res } = await GetPaginationRentProp();
            let ObjProperty = Object.assign({}, ...res);
            if (ObjProperty.listings) {
                setListing(ObjProperty?.listings);
            }
        }
        catch (err) {
            // Handle error
        }
    }

    const bind = useDrag(({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
        const trigger = velocity > 0.2;
        const dir = xDir < 0 ? -1 : 1;
        let find = listing[index];
        let findIndex = savedPositions?.find((f, i) => i === index);

        if (findIndex) {
            setPoints(prev => [...prev, findIndex]);
        }
        setSavedPositions(savedPositions.filter((_, i) => i !== index));
        let check = originalLists.find(f => f.id === find.id);

        if (!check) {
            setOriginalLists(prev => [...prev, find]);
        }
        if (!down && trigger) gone.add(index);
        let result = "";
        api.start(i => {
            if (index !== i) return;
            const isGone = gone.has(index);
            const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0;
            const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0);
            const scale = down ? 1.1 : 1;

            if (!down && trigger) determineDirCard(x, index);

            return {
                x,
                rot,
                scale,
                delay: undefined,
                config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
            };
        });

        if (!down && gone.size === listing.length) {
            result = PaginateFilter();
            if (result) {
                setTimeout(() => {
                    gone.clear();
                    api.start(i => to(i));
                }, 600);
            }
        }
    });

    useEffect(() => {
        const handleResize = () => {
          setInnerWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    const { t } = useTranslation();

    const HandleMatch = () => {
        return (
            <div className='max-w-[300px] text-center'>
                <h2 className='text-center font-semibold text-[20px] text-black '>{t('match_info')}</h2>
            </div>
        );
    }

    // //console.log("inner", innerWidth)
    return (
        <div className={`${innerWidth <= 1366 ? 'max-w-[500px]' : innerWidth === 1517 ? 'max-w-[525px]' : innerWidth === 1707 ? 'max-w-[570px]' : innerWidth === 1821 ? 'max-w-[600px]' : 'max-w-[625px]'} mx-auto overflow-hidden `}>
            {/* <button>drag</button> */}
            {show && <DynamicPopup modalOpen={show} data={<HandleMatch />} close={() => setShow(false)} />}

            {props?.map(({ x, y, rot, scale }, i) => {
                const currentListing = listing[i];
                const imageUrl = currentListing?.images?.length > 0 ? currentListing?.images[0]?.image : currentListing?.user?.user_image;
                return (
                    <animated.div
                        className={`${location === "/property" ? '!w-[70%]' : 'w-auto'} dd`}
                        key={i}
                        style={{
                            transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`)
                        }}
                    >
                        <animated.div
                            className="bg-contain bg-center"
                            {...bind(i)}
                            style={{
                                transform: interpolate([rot, scale], trans),
                                backgroundImage: `url(${imageUrl})`
                            }}
                        >
                            <div className='w-full'>
                                <div className='cursor-pointer'>
                                    <div className='flex justify-between w-full items-center pt-5 px-5 absolute top-0'>
                                        <div className='bg-gray-100 rounded-full px-2'>
                                            {currentListing?.human_readable_time}
                                        </div>
                                        <div onClick={() => setDetail(currentListing)}>
                                            <img src={gp4} alt="detail" className='object-cover' />
                                        </div>
                                    </div>
                                    <div className='absolute bottom-40 z-40 right-4'>
                                        <div onClick={() => setShow(true)} className='bg-red-500 text-white py-3 rounded-full px-2'>
                                            {currentListing?.match} {t('matched')}
                                        </div>
                                    </div>
                                    <div className='absolute w-full bottom-0'>
                                        <div className='px-3 pb-2 bg-black h-full py-3 rounded-b-xl'>
                                            <div className='flex justify-between items-center'>
                                                <div>
                                                    <ul>
                                                        <li>
                                                            <div className='flex items-center max-w-[250px]'>
                                                                <img src={person} alt="detail" className='object-cover' />
                                                                <h2 className='px-2 text-white text-[18px]'>
                                                                    {`${currentListing?.user?.first_name} ${currentListing?.user?.last_name}`}
                                                                </h2>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className='flex items-center'>
                                                                <img src={locat} alt="detail" className='object-cover' />
                                                                <h2 className='px-2 text-white text-[14px]'>
                                                                    {rommateType === "offcampus"
                                                                        ? `${currentListing?.property_details?.city} ${currentListing?.property_details?.state}`
                                                                        : currentListing?.university_details?.university_name || "N/A"}
                                                                </h2>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    {rommateType === "oncampus" ? (
                                                        <>
                                                            <p className='text-white bg-gray-50 bg-opacity-[0.5] px-2'>{t('rommate_uni_major')}</p>
                                                            <h2 className='text-white text-[16px]'>{currentListing?.university_details?.major || "N/A"}</h2>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p className='text-white bg-gray-50 bg-opacity-[0.5] px-2'>{t('rent_amount')}</p>
                                                            <h2 className='text-white text-[25px]'>${currentListing?.property_details?.rent}</h2>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="pt-2">
                                                <div className="flex items-center gap-x-2 justify-center">
                                                    <div className='flex items-center bg-[#F79F15] text-white rounded-md p-1'>
                                                        <h2 className='text-[14px] px-2 font-medium'>{currentListing?.applications} {t('saved')}</h2>
                                                    </div>
                                                    <div className='flex items-center bg-[#F79F15] text-white rounded-md p-1'>
                                                        <h2 className='text-[14px] px-2 font-medium'>{currentListing?.appointments} {t('appointment')}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <animated.svg
                                className="tinder-icon reject"
                                style={{
                                    opacity: interpolate([x, y], (x, y) => `${x / -80}`),
                                    transform: interpolate([x, scale], (x, scale) => `translate(${x / 10}px, -50%) scale(${x > -140 ? x / -80 : 1.6})`)
                                }}
                                viewBox="0 0 96 96"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="57.264" height="57.264" viewBox="0 0 57.264 57.264">
                                    <g id="broken-heart" transform="translate(-157.652 -318.769) rotate(-1)">
                                        <circle id="Ellipse_869" cx="28.145" cy="28.145" r="28.145" transform="translate(152.048 322.455)" fill="#f90000" />
                                        <path id="icon-broken-heart" d="M31.825,5.058l-.161-.168A8.779,8.779,0,0,0,20.269,3.9l2.3,6.947-6.45,4.3,3.225,8.6L9.674,14.068l6.45-4.3L14.2,3.957A8.771,8.771,0,0,0,2.734,4.9l-.161.161A9.233,9.233,0,0,0,2.083,17.3l14.25,14.686a1.2,1.2,0,0,0,1.727,0L32.315,17.292a9.225,9.225,0,0,0-.49-12.234Z" transform="translate(163.271 333.489)" fill="#fff" />
                                    </g>
                                </svg>
                            </animated.svg>
                            <animated.svg
                                className="tinder-icon interest"
                                style={{
                                    opacity: interpolate([x, y], (x, y) => `${x / 80}`),
                                    transform: interpolate([x, scale], (x, scale) => `translate(${x / 10}px, -50%) scale(${x < 140 ? x / 80 : 1.6})`)
                                }}
                                viewBox="0 0 96 96"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="60.075" height="60.075" viewBox="0 0 60.075 60.075">
                                    <g id="heart" transform="matrix(0.998, 0.07, -0.07, 0.998, -125.158, -331.804)">
                                        <circle id="Ellipse_24" cx="28.143" cy="28.143" r="28.143" transform="translate(151.915 321.991)" fill="#11d157" />
                                        <path id="icon-heart" d="M31.058,2.058a9.187,9.187,0,0,0-12.536.914L17.2,4.335,15.876,2.972A9.186,9.186,0,0,0,3.34,2.058a9.647,9.647,0,0,0-.665,13.967l13,13.423a2.106,2.106,0,0,0,3.043,0l13-13.423a9.641,9.641,0,0,0-.658-13.967Z" transform="matrix(0.995, -0.105, 0.105, 0.995, 161.409, 339.356)" fill="#fff" />
                                    </g>
                                </svg>
                            </animated.svg>
                        </animated.div>
                    </animated.div>
                );
            })}

        </div>
    )
}

export default Deck;
