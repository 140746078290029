
import sl1 from '../../assests/images/slider4.png'
import logo from '../../assests/images/logo.png'
import { AiOutlineClose } from 'react-icons/ai'
import DynamicDropdonw from '../../utils/DaynamicDropdown';
import { MdArrowLeft } from 'react-icons/md';
import OTPInput from "otp-input-react";
import { useState } from 'react';
import { SendOtp } from '../../lib/Api/UserApi';
import { Loader } from '../../utils/loaderButton';
const VerifyOtp = ({ close, setActive, email, t }) => {
  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    let data = {
      email: email,
      otp: otp
    }
    if (otp !== '') {
      setLoading(true)
      let { res, error } = await SendOtp(data)
      if (res) {
        setActive(3)
      }
      else if (error) {
        setLoading(false)
      }
    }
    else {
      setOtpError(t('verify_code'))
    }
  }


  return (
    <>
      <div className='lg:flex'>
        <div className='lg:w-[60%] w-full relative hidden lg:block'>
          <img src={sl1} className=" h-full w-full object-cover" alt="slider" />
          <div className='absolute bottom-[10%] left-[20%]'>
            <h2 className='text-[20px] text-white font-semibold'>{t('welcome')}</h2>
          </div>

        </div>
        <div className='w-full px-5 pb-5 '>
          <div className='flex justify-between items-center '>
            <img src={logo} className="h-[95px] object-contain" alt="logo" />
            {/* <div>
              <DynamicDropdonw />
            </div> */}
          </div>
          <div className=' flex justify-between items-end cursor-pointer' onClick={() => setActive(2)} >
            <div className='border w-[35px] rounded-md shadow-md p-2'>
              <MdArrowLeft />
            </div>
            <div className='border w-[35px] rounded-md shadow-md p-2' onClick={() => close()}>
              <AiOutlineClose />
            </div>


          </div>
          <div className='pt-6'>
            <h2 className='lg:text-[2rem] text-[1.5rem] font-bold'>{t('verification_code_text')}</h2>
            <p className='text-[14px]'>
              {t('verification_code')}
            </p>
          </div>
          <div className='mt-5'>
            <div className=' w-full flex justify-center'>
              <OTPInput
                value={otp}
                onChange={setOtp}
                autoFocus
                inputClassName="border-b-2 fous:border-transparent focus:ring-0"
                OTPLength={4}
                otpType="number"
                disabled={false}
                secure={false} />
            </div>

            {otpError && <p className='text-red-500 text-[14px] pt-2 text-center'>{otpError}</p>}

            <div className='pt-6'>
              {
                loading ?
                  <Loader />
                  :
                  <button className='text-center rounded-md bg-[#F79F15] w-full py-2 text-white' onClick={(e) => handleSubmit(e)}>{t('continue')}</button>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyOtp