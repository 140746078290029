import { toast } from "react-toastify"
import { callPrivateApi, callPublicApi } from "../Apiendpoint"
import axios from "axios"


// get Metadata
// Get property Type
export const GetPropertyType = async () => {

    try {
        let response = await callPrivateApi('/servicer/properties/property-types', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get property Type Public
export const GetPublicPropertyType = async () => {

    try {
        let response = await callPrivateApi('/servicer/properties/property-types', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get property Amanities
export const GetPropertyAmenities = async () => {

    try {
        let response = await callPrivateApi('/servicer/properties/get-property-amenities', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get property Amanities Public
export const GetPublicPropertyAmenities = async () => {
    try {
        let response = await callPublicApi('/servicer/properties/get-property-amenities', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Leased peroid
export const GetLeasedPeriod = async () => {
    try {
        let response = await callPrivateApi('/user/get-lease-periods', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}
// Get Leased peroid
export const GetPublicLeasedPeriod = async () => {
    try {
        let response = await callPrivateApi('/website/properties/get-lease-periods', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}

// Get Leased peroid Public
export const GetLeasedPeriodPublic = async () => {
    try {
        let response = await callPublicApi('/user/get-lease-periods', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}

// Get Property
export const GetRental = async (page) => {
    try {
        let response = await callPrivateApi(`/servicer/properties?page=${page}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Get Property
export const GetCountProperty = async (payload) => {
    try {
        let response = await callPrivateApi('/servicer/properties/seat-count', 'post', payload)
        if (response.success === true) {
            return response.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



// Get rental by id 
export const GetRentById = async ({ id }) => {
    try {
        let response = await callPrivateApi(`/servicer/properties/show/${id}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get rental by id 
export const GetPublicRentById = async (id) => {
    try {
        let response = await callPublicApi(`/website/properties/${id}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Create Rental
export const CreateRantel = async (data) => {
    let { lister_type, listed_with_realtor, owner_name, owner_email, realtor_name, realtor_email, company_name, realtor_license, sponsoring_broker, professional_license, latitude, longitude, street, city, state, zipcode, rent, security_deposit, property_type_id, leased_period_id, number_of_beds, number_of_baths, amenities, description, images, threeDFloor, video, extention, leaseFile, apt, broker_fee, broker_fee_type } = data


    try {
        let payload = {
            "lister_type": lister_type,
            "listed_with_realtor": listed_with_realtor,
            "owner_name": owner_name || "",
            "owner_email": owner_email || "",
            "realtor_name": realtor_name || "",
            "realtor_email": realtor_email || "",
            "realtor_license": realtor_license || "",
            "professional_license": professional_license || "",
            "sponsoring_broker": sponsoring_broker || "",
            "company_name": company_name || "",
            "latitude": latitude,
            "longitude": longitude,
            "street": street,
            "city": city,
            "state": state,
            "apt": apt,
            "zipcode": zipcode,
            "rent": rent,
            "security_deposit": security_deposit,
            "property_type_id": property_type_id,
            "broker_fee": broker_fee,
            "broker_fee_type": broker_fee_type,
            "leased_period_id": leased_period_id,
            "number_of_beds": number_of_beds,
            "number_of_baths": number_of_baths,
            "amenities": amenities,
            "description": description || "",
            "three_d_floor": threeDFloor,
            "extension": extention || "",
            "lease_pdf": leaseFile,
            "images": images,
            "video": video
        }

        let response = await callPrivateApi('/servicer/properties/store', 'post', payload)
        if (response.success === true) {
            //
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Rental Update 
export const UpdateRental = async (data, threedfloor) => {
    let { latitude, longitude, street, city, state, zipcode, rent, security_deposit, property_type_id, leased_period_id, number_of_beds, number_of_baths, three_d_floor, threeDFloor, amenities, description, images, video, id, user_id } = data
    try {
        let aminty = []
        if (amenities.length > 0) {
            for (let index = 0; index < amenities?.length; index++) {
                const element = amenities[index];
                if (element?.id) {
                    aminty.push(element?.id)
                }
                else {
                    aminty.push(element)

                }

            }
        }
        let payload = {
            "property_id": id,
            "user_id": user_id,
            "latitude": latitude,
            "longitude": longitude,
            "street": street,
            "zipcode": zipcode,
            "city": city,
            "rent": rent,
            "state": state,
            "security_deposit": security_deposit,
            "property_type_id": property_type_id,
            "leased_period_id": leased_period_id,
            "number_of_beds": number_of_beds,
            "number_of_baths": number_of_baths,
            "amenities": aminty,
            "description": description,
            "three_d_floor": threeDFloor || threedfloor,
            "images": images,
            "video": video
        }

        let response = await callPrivateApi(`/servicer/properties/update`, 'post', payload)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const DeleteRental = async (id) => {
    try {

        let response = await callPrivateApi(`/servicer/properties/delete/${id}`, 'get')
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const DelRentalImage = async (id) => {
    try {

        let response = await callPrivateApi(`/servicer/properties/delete/image/${id}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const DelRentalVideo = async (data) => {
    try {

        let response = await callPrivateApi(`/servicer/properties/video/delete`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const UploadRentalImage = async (data) => {
    try {

        let response = await callPrivateApi(`/servicer/properties/image/store`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const AddRentalVideo = async (data) => {
    try {

        let response = await callPrivateApi(`/servicer/properties/video/store`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get payments
export const GetTransaction = async () => {

    try {
        let response = await callPrivateApi('/user/payments/transactions', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}


// ------- Get My lists --------

// Get saved
export const GetSavedList = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};
    try {
        let response = await callPrivateApi(`/servicer/lists/get/saved?lat=${latitude}&lng=${longitude}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resSaved: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Saved Accept
export const SavedListAcept = async (data) => {
    try {
        let response = await callPrivateApi('/servicer/appointments/respond-to-appointment-request', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Saved Decline
export const SavedListDecline = async (data) => {
    try {
        let response = await callPrivateApi('/servicer/post-appointment/not-interested', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get Books
export const GetBookedList = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};
    try {
        let response = await callPrivateApi(`/servicer/lists/get/booked?lat=${latitude}&lng=${longitude}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resBook: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Cancel book list 
export const BookActions = async (data) => {
    try {

        let response = await callPrivateApi('/servicer/appointments/respond-to-appointment-request', 'post', data)
        if (response.success === true) {

            // let obj = Object.assign({}, ...response.data)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}
// Get penddings
export const GetPenddingList = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};
    try {
        let response = await callPrivateApi(`/servicer/lists/get/pending?lat=${latitude}&lng=${longitude}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resPendding: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get penddings
export const PenddingSingnlease = async (data) => {

    try {
        let response = await callPrivateApi('/servicer/post-appointment/send-lease', 'post', data)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get penddings
export const PenddingCancel = async (data) => {

    try {
        let response = await callPrivateApi('/servicer/post-appointment/cancel-lease', 'post', data)
        if (response.success === true) {


            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get penddings
export const PenddingSendLease = async (data) => {

    try {
        let response = await callPrivateApi('/servicer/post-appointment/send-lease', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get archieved
export const GetArchivedlist = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};

    try {
        let response = await callPrivateApi(`/servicer/lists/get/archived?lat=${latitude}&lng=${longitude}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resArchive: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Restore archieved list 
export const RestoreArchieved = async (data) => {
    try {

        let response = await callPrivateApi('/servicer/lists/restore', 'post', data)
        if (response.success === true) {

            // let obj = Object.assign({}, ...response.data)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}
// Remove archieved list 
export const RemoveArchieved = async (data) => {
    try {

        let response = await callPrivateApi('/servicer/lists/remove-servicer', 'post', data)
        if (response.success === true) {

            // let obj = Object.assign({}, ...response.data)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }

}
// Get confirmed
export const GetConfirmedList = async (location) => {
    const { latitude = 0, longitude = 0 } = location || {};

    try {
        let response = await callPrivateApi(`/servicer/lists/get/confirmed?lat=${latitude}&lng=${longitude}`, 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                resConfirm: obj?.my_list
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Confirm Decline
export const ConfirmListDecline = async (data) => {
    try {
        let response = await callPrivateApi('/servicer/post-appointment/not-interested', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Confirm share owner
export const ConfirmListShareOwner = async (data) => {
    try {
        let response = await callPrivateApi('/servicer/post-appointment/send-owner-contact', 'post', data)
        if (response.success === true) {
            return { res: response.data }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


//  confirmed end lease
export const ConfirmedEndLease = async (data) => {

    try {
        let response = await callPrivateApi('/servicer/post-appointment/end-lease', 'post', data)
        if (response.success === true) {


            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
//  confirmed end lease
export const RequestRentalApp = async (data) => {

    try {
        let response = await callPrivateApi('/servicer/post-appointment/request-rental-application', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get flash prices
export const GetBillingDetailServicer = async (payload) => {

    try {
        let response = await callPrivateApi('/servicer/subscriptions/billing-details', 'post', payload)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get flash prices
export const GetServicerPaymentIntent = async (data) => {
    try {

        let response = await callPrivateApi('/servicer/subscriptions/checkout-session', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get time slots
export const GetTimeSlotsReasy = async (data) => {
    try {

        let response = await callPrivateApi('/servicer/subscriptions/r-eazy-lane/get-blocked-times', 'post', data)
        if (response.success === true) {
            let obj = Object.assign({}, ...response?.data)
            return obj?.blocked_times
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}


// Update time slots
export const UpdateTimeSlotsReasy = async (data) => {
    try {

        let response = await callPrivateApi('/servicer/subscriptions/r-eazy-lane/block-times', 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Register Subscription

export const ServicerPaymentIntent = async (data) => {
    try {
        let response = await callPrivateApi('/servicer/subscriptions/register-subscription', 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) { }
}





// Loard Pdf 
export const GetViewRentalApp = async (data) => {
    try {
        let response = await callPrivateApi(`/servicer/rental-application/view-reports`, 'post', data)
        if (response.success === true) {
            return response.data
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// -------endList Api ----- //


//------- Start Flash Api ------ //

// Get flash prices
export const GetPricesServicer = async () => {

    try {
        let response = await callPrivateApi('/servicer/subscriptions/flash-lane/prices', 'post')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.prices
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get flash prices
export const GetPaymentIntentServicer = async (data) => {
    try {

        let response = await callPrivateApi('/servicer/subscriptions/checkout-session', 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Get flash prices
export const GetCitiesCountry = async () => {

    try {
        let response = await callPrivateApi('/user/get-cities-updated', 'get')
        if (response.states?.length) {
            // let res = Object.assign({}, ...response.data)
            return response
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

export const GetServicerProperty = async (payload) => {
    try {
        let response = await callPrivateApi('/servicer/properties/flash-lane', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const GetServicerFlashProperty = async () => {
    try {
        let response = await callPrivateApi('/servicer/properties/my-rentals/flash-lane', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const GetServicerReazyRomate = async () => {
    try {
        let response = await callPrivateApi('/servicer/roommates/r-eazy-lane', 'post')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}
export const GetServicerFlashRomate = async () => {
    try {
        let response = await callPrivateApi('/servicer/roommates/flash-lane', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const GetServicerMainFlashProperty = async () => {
    try {
        let response = await callPrivateApi('/servicer/properties/my-rentals/no-flash-lane', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

//------- End Flash Api ------ //


//------- Start ResyLane Api ------ //

// Get R-EasyLane prices
export const GetPricesServicerREsy = async () => {

    try {
        let response = await callPrivateApi('/servicer/subscriptions/r-eazy-lane/prices', 'post')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj?.prices
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
export const GetREazyProperty = async (payload) => {
    try {
        let response = await callPrivateApi('/servicer/properties/r-eazy-lane', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const GetTimeSlotes = async (payload) => {
    try {
        let response = await callPrivateApi('/servicer/subscriptions/r-eazy-lane/generate-timeslots', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const GetTimeSlotesRenter = async (payload) => {
    try {
        let response = await callPrivateApi('/renters/subscriptions/r-eazy-lane/generate-timeslots', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const GetREazyPropertys = async () => {
    try {
        let response = await callPrivateApi('/servicer/properties/my-rentals/r-eazy-lane', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

export const GetFilterPrefferance = async () => {
    try {
        let response = await callPrivateApi('/servicer/subscriptions/r-eazy-lane/settings', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const GetRenterFilterPrefferance = async () => {
    try {
        let response = await callPrivateApi('/renter/subscriptions/r-eazy-lane/settings', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const CreateFilterPrefferance = async (payload) => {
    try {
        let response = await callPrivateApi('/servicer/subscriptions/r-eazy-lane/settings', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const CreateFilterRentPrefferance = async (payload) => {
    try {
        let response = await callPrivateApi('/renter/subscriptions/r-eazy-lane/settings', 'post', payload)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}



export const GetServicerMainReasyProperty = async () => {
    try {
        let response = await callPrivateApi('/servicer/properties/my-rentals/no-r-eazy-lane', 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

//------- End R-EasyLane Api ------ //



// -------------- Rommate api -------------

// Get rommates
export const GetRommates = async () => {

    try {
        let response = await callPrivateApi('/servicer/roommates', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response?.data)
            return {
                res: obj
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get Campus Interest
export const GetCampusInterest = async () => {

    try {
        let response = await callPrivateApi('/servicer/roommates/metadata/get-interests', 'get')
        if (response.success === true) {
            return {
                resInterest: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get Campus Activity
export const GetCampusActivities = async () => {

    try {
        let response = await callPrivateApi('/servicer/roommates/metadata/get-extra-curricular-activities', 'get')
        if (response.success === true) {
            return {
                resActivity: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}
// Get Campus Question
export const GetCampusQuestions = async () => {

    try {
        let response = await callPrivateApi('/user/roommates/questions/on_campus', 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}


export const CreateRommateProfile = async (data) => {
    try {
        let res = await callPrivateApi(`/servicer/roommates/store`, 'post', data)
        if (res.success === true) {
            return res.data
        }
        else {
            toast.error(res.message)
            return {
                error: res.data
            }
        }
    }
    catch (err) { }
}

// Create Rommate on campus
export const CreateRommateOnCampus = async (data) => {
    try {

        let response = await callPrivateApi(`/servicer/roommates/store/university-details`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Create Rommate off campus
export const CreateRommateOffCampus = async (data) => {
    try {
        let response = await callPrivateApi(`/servicer/roommates/store/property-details`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            // toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Get off Campus Question
export const GetOffCampusQuestions = async (type) => {

    try {
        let response = await callPrivateApi(`/user/roommates/questions/${type}`, 'get')
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}


// Create Current List
export const CreateCurrentList = async (data) => {
    try {
        let response = await callPrivateApi(`/servicer/roommates/store/current-information`, 'post', data)
        if (response.success === true) {
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}
// Create Rommate off campus
export const CreateRommateQuestion = async (data) => {
    try {
        let response = await callPrivateApi(`/servicer/roommates/store/priority-details`, 'post', data)
        if (response.success === true) {
            return response
        }
        else {
            toast.error(response.message)
            return response
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const GetCountries = async () => {
    try {
        let res = await callPrivateApi('/user/universities', 'get')
        if (res) {
            let obj = Object.assign({}, ...res?.data)
            return obj?.universities
        }
    }
    catch (err) { }
}


export const DeleteRommateImage = async (id) => {
    try {

        let response = await callPrivateApi(`/servicer/roommates/delete/image/${id}`, 'get')
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const DeleteRommate = async (id) => {
    try {

        let response = await callPrivateApi(`/servicer/roommates/roommate-delete/${id}`, 'get')
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Update Rommate 
export const UpdateRommate = async (data) => {
    let { university_name, domm_name, room, age, major, grade_level, length_of_stay, on_campus, amenities, leased_period_id, images, property_type_id, priorities, id } = data
    try {
        let aminty = []
        for (let index = 0; index < amenities.length; index++) {
            const element = amenities[index];
            if (element?.id) {
                aminty.push(element?.id)
            }
            else {
                aminty.push(element)
            }
        }

        let payload = {
            "security_deposit": 500,
            "rent": 100.80,
            "latitude": 70.454654,
            "longitude": 54.456445,
            "zipcode": "45460",
            "street": "Ancd",
            "city": "Houston",
            "state": "TX",
            "description": "Lorem ipsum",
            "number_of_beds": 8,
            "number_of_baths": 7,
            "college_student": 0,
            "on_campus": 0,
            "roommate_amenities": [430, 431],
            "lease_period_id": 1,
            "property_type_id": 2,
            "priorities": [
                {
                    "question_id": 4,
                    "option_id": 45
                }
            ],
            "interests": [1, 2, 3],
            "images": [""]
        }

        let response = await callPrivateApi(`/servicer/roommates/roommate-update/${id}`, 'post', payload)
        if (response.success === true) {

            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Inactive Property Notification

// notification Open and remove property still available 
export const ChangeNotificationStil = async (data) => {
    try {
        let response = await callPrivateApi('/servicer/properties/update', 'post', data)
        if (response.success === true) {
            // 
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
            return {
                error: response.data
            }
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Get Single Property
export const GetSingleProperty = async (id) => {

    try {
        let response = await callPrivateApi(`/servicer/properties/show/${id}`, 'get')
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}



// Not Interested Property 
export const NotInterestedProperty = async (data) => {

    try {
        let response = await callPrivateApi(`/servicer/post-appointment/not-interested`, 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// share owner confirmed
export const ShareContact = async (data) => {
    try {
        let response = await callPrivateApi(`/servicer/post-appointment/send-owner-contact`, 'post', data)
        if (response.success === true) {
            return response?.data
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }


}

// Feedback api

export const SendFeedback = async (payload) => {
    try {
        let res = await callPrivateApi('/user/feedback/submit', 'post', payload)
        if (res?.success === true) {
            return res.data
        }
        else {
            toast.error(res.message)
        }
    }
    catch (err) {
        toast.error(err)
    }
}


// validate address 

export const ValidateAddress = async (data, t) => {
    let { street, city, state, apt } = data
    let payload = {
        "street": street,
        "city": city,
        "state": state,
        "apt": apt,

    }
    try {
        let response = await callPrivateApi('/servicer/properties/validate', 'post', payload)
        if (response.success === true) {
            return response?.success
        }
        else {
            //console.log("data", response)
            let obj = Object.assign({}, ...response?.data)
            toast.error(
                <span>
                    {t('support_link')} &nbsp;
                    <a href={obj?.url?.link} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                        {t('sup_link')}
                    </a>
                </span>
            )
        }
    }
    catch (err) {
        toast.error(err)

    }
}
