import { toast } from "react-toastify"
import { callPrivateApi } from "../Apiendpoint"

// Get Propert
export const GetLeasedsProperty = async () => {
    try {
        let response = await callPrivateApi('/servicer/lease-builder/get-properties-for-lease', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.properties

            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Get Propert
export const GetLeaseds = async () => {
    try {
        let response = await callPrivateApi('/servicer/lease-builder/get-all', 'get')
        if (response.success === true) {
            let obj = Object.assign({}, ...response.data)
            return {
                res: obj.leases
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


// Create Rental
export const CreateLeased = async (data) => {
    // let { property_id, leased_type, rent_collection_date ,   animal_allowed , parking_allowed , legal_disclosure , additional_details } = data

    try {

        let response = await callPrivateApi('/servicer/lease-builder/store', 'post', data)
        if (response.success === true) {
            toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}

// Rental Update 
export const UpdateLeased = async (data) => {
    // let { type, realtor, name, email, license, broker, latitude, longitude, street, city, state, zipcode, rent, security_deposit, propertyType, leased_period, number_of_beds, number_of_baths, amenities, description, images, video } = data
    try {
        
        let response = await callPrivateApi('/servicer/lease-builder/store', 'post', data)
        if (response.success === true) {
            // toast.success(response.message)
            return {
                data: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}


export const DeleteLeased = async (id) => {
    try {

        let response = await callPrivateApi(`/servicer/lease-builder/destroy/${id}`, 'get')
        if (response.success === true) {
            // toast.success(response.message)
            return {
                res: response.data
            }
        }
        else {
            toast.error(response.message)
        }
    }
    catch (err) {
        toast.error(err)

    }
}