import { Doughnut } from 'react-chartjs-2';
import { useEffect } from 'react';
import ChartJS from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';

ChartJS.register(ChartDataLabels);

const GaugeChart = ({  credit , setCredit }) => {
    const dataList = [
        { color: "red", name: 'Poor (300-579)' },
        { color: "orange", name: 'Fair (580-669)' },
        { color: "yellow", name: 'Good (670-739)' },
        { color: "#3BA32D", name: 'Very Good (740-799)' },
        { color: "#3B5C0A", name: 'Excellent (800-850)' }
    ];

    useEffect(() => {
        ChartJS.register({
            id: 'gaugeNeedle',
            afterDatasetDraw: (chart, args, options) => {
                const ctx = chart.ctx;
                const meta = chart.getDatasetMeta(0);
                const element = meta.data[0];

                const centerX = element.x;
                const centerY = element.y;
                const baseRotation = (3 * Math.PI) / 2; // Start at 270 degrees

                // Define each range and corresponding angles
                const dataRanges = [
                    { min: 300, max: 579, startAngle: 0, endAngle: 36 },   // Poor
                    { min: 580, max: 669, startAngle: 36, endAngle: 72 },  // Fair
                    { min: 670, max: 739, startAngle: 72, endAngle: 108 }, // Good
                    { min: 740, max: 799, startAngle: 108, endAngle: 144 }, // Very Good
                    { min: 800, max: 850, startAngle: 144, endAngle: 180 }, // Excellent
                ];

                // Find the segment that `credit` falls into
                const segment = dataRanges.find(range => credit >= range.min && credit <= range.max);

                if (segment) {
                    // Calculate the exact angle within the segment based on credit
                    const segmentSpan = segment.endAngle - segment.startAngle;
                    const creditPosition = (credit - segment.min) / (segment.max - segment.min);
                    const needleAngle = segment.startAngle + creditPosition * segmentSpan;

                    // Convert angle to radians for rotation
                    const needleRotation = baseRotation + (needleAngle * Math.PI / 180);

                    // Draw the needle
                    ctx.save();
                    ctx.translate(centerX, centerY);
                    ctx.rotate(needleRotation);
                    ctx.beginPath();
                    ctx.moveTo(0, -100); // Tip of the needle
                    ctx.lineTo(-8, 0);   // Left side of the base
                    ctx.lineTo(0, 15);   // Bottom center of the base (for the teardrop shape)
                    ctx.lineTo(8, 0);    // Right side of the base
                    ctx.closePath();
                    ctx.fillStyle = '#007bff';  // Needle color
                    ctx.fill();

                    // Draw the central circle
                    ctx.beginPath();
                    ctx.arc(0, 0, 12, 0, 2 * Math.PI);
                    ctx.fillStyle = '#333'; // Dark center circle color
                    ctx.fill();
                    ctx.strokeStyle = '#aaa';
                    ctx.lineWidth = 2;
                    ctx.stroke();
                    ctx.restore();
                }
            },
        });
    }, [credit]); // Ensures the needle updates when credit changes

    const data = {
        labels: ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent'],
        datasets: [
            {
                data: [1, 1, 1, 1, 1], // Equal segments for each rating
                backgroundColor: [
                    'red',
                    'orange',
                    'yellow',
                    '#3BA32D',
                    '#3B5C0A',
                ],
                borderColor: [
                    'red',
                    'orange',
                    'yellow',
                    '#3BA32D',
                    '#3B5C0A',
                ],
                borderWidth: 1,
                circumference: 180,
                rotation: 270,
                circumference: 180, // Half-doughnut
            },
        ],
    };

    const options = {
        responsive: true,
        rotation: -90,
        aspectRatio: 1,
        layout: {
            padding: {
                left: 40,
                right: 50,
            },
        },
        circumference: 180,
        rotation: 270,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const ranges = ['300-579', '580-669', '670-739', '740-799', '800-850'];
                        return `${ranges[tooltipItem.dataIndex]} `;
                    },
                },
            },
            datalabels: {
                display: true,
                color: 'black',
                align: 'center',
                anchor: 'center',
                offset: 50,
                formatter: (value, context) => {
                    const ranges = ['Poor', 'Fair', 'Good', 'Very Good', 'Excellent'];
                    return ranges[context.dataIndex];
                },
                rotation: (context) => {
                    const index = context.dataIndex;
                    const totalSegments = context.chart.data.labels.length;
                    const anglePerSegment = 180 / totalSegments;
                    const baseRotation = -72;
                    const additionalRotation = anglePerSegment * index;
                    return baseRotation + additionalRotation;
                },
                font: {
                    size: 15,
                    weight: 'bold',
                },
            },
        },
    };
    

    const {t} = useTranslation()

    return (
        <div className="flex justify-center flex-col w-[500px]">
            {credit !== "" && (
                <div className='cursor-pointer font-bold pb-2' onClick={() => setCredit('')}>
                    Back
                </div>
            )}
           <h2 h2 className='text-center font-bold text-[25px]' > {t('credit_check')}</h2>
            <div className='ml-5 flex justify-center items-center'>
                <Doughnut
                    data={data}
                    options={options}
                />
            </div>
            <div className='mt-5'>
                {dataList.map((list, index) => (
                    <div key={index} className='flex items-center pb-3'>
                        <div style={{ backgroundColor: list.color }} className="rounded-md w-[20px] h-[20px]"></div>
                        <p className='px-2'>{list.name}</p>
                    </div>
                ))}
            </div>
        </div >
    );
};

export default GaugeChart;

