import React, { useEffect, useState } from 'react'
import { ImHome2, ImKey } from 'react-icons/im'
import { BsSearch } from 'react-icons/bs'
import DynamicDropdonw from '../../utils/DaynamicDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GetPublicLeasedPeriod, GetPublicPropertyAmenities, GetPublicPropertyType } from '../../lib/Api/RentalAppApi'
import Autocomplete from "react-google-autocomplete";
import { addExplore, resetSearch } from '../../Redux/UserAuthSlice/RentalSlice'
import { useNavigate } from 'react-router-dom'
import { MultiSelect } from "react-multi-select-component";
import { toast } from 'react-toastify'
// import SignUp from '../../Partials/Popups/SignUp'
const ExploreBanner = ({ setSignUp }) => {
    const [active, setActive] = useState(0)
    const [propertyType, setPropertyType] = useState([])
    const [aminities, setAminities] = useState([])
    const [leasedTye, setLeasedType] = useState([])
    const [type, setType] = useState(0)
    const [defaultAddress, setDefaultAdress] = useState("")
    const [formData, setFormData] = useState({})
    const [radiuss, setRadius] = useState(0);
    const [selected, setSelected] = useState([]);

    const bed = [{ type: "bed", id: 1, period: 1 }, { type: "bed", id: 2, period: 2 }, { type: "bed", id: 3, period: 3 }, { type: "bed", id: 4, period: 4 }, { type: "bed", id: 5, period: 5 }, { type: "bed", id: 6, period: `6+` }]
    const bath = [{ type: "bath", id: 1, period: 1 }, { type: "bath", id: 2, period: 2 }, { type: "bath", id: 3, period: 3 }, { type: "bath", id: 4, period: 4 }, { type: "bath", id: 5, period: 5 }, { type: "bath", id: 6, period: `6+` }]
    const token = useSelector((state) => state.userAuth.loginInfo.token);
    const lng = useSelector((state) => state?.userAuth?.language)

    const { t } = useTranslation()
    let PropertyData = `${t('property_type')}`
    let PriceData = `${t('price')}`
    let RomeData = `${t('baths')}`
    let BedData = `${t('beds')}`
    let aminity = `${t('amnity_lab')}`
    let leasedPeriod = `${t('leased_p')}`

    // handle check address in area of US
    var autocompleteOptions = {
        types: ['(regions)'],
        componentRestrictions: { country: "us" }
    };

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchData = useSelector((state) => state?.rental?.searchProperty)


    const handleMove = () => {
        dispatch(addExplore({ propertyName: "radius", value: radiuss }))
        dispatch(addExplore({ propertyName: "aminity", value: selected }))
        let {
            min,
            max,
            address,
            bath,
            bed,
            property,
            leasedPeriod,
            radius,
        } = searchData
        if (min === 0 || max === 0 || address === "" || bath === 0 || bed === 0 || property === "" || leasedPeriod === "" || radius !== 0) {
            toast.error(t('field_required'))
        }
        else {
            navigate('/search')
        }
    }


    useEffect(() => {
        let fetch = async () => {
            let property = await GetPublicPropertyType(lng === "sp" ? "es" : "en")
            let ObjProperty = Object.assign({}, ...property?.res)
            let updated = ObjProperty?.property_type.map(obj => ({
                ...obj,
                type: 'propertyType' // You can set whatever value you want for the type property
            }));
            setPropertyType(updated)

            let aminty = await GetPublicPropertyAmenities(lng === "sp" ? "es" : "en")
            let Objaminty = Object.assign({}, ...aminty?.res)
            let updatedAm = Objaminty?.amenities.map(obj => {
                return {
                    label: obj.name, value: obj?.id
                }
            })
            setAminities(updatedAm)
            let peroid = await GetPublicLeasedPeriod(lng === "sp" ? "es" : "en")
            let Objperoid = Object.assign({}, ...peroid?.res)
            let updatedPer = Objperoid?.lease_periods.map(obj => ({
                ...obj,
                type: 'leasedPeriod'
            }));

            setLeasedType(updatedPer)

        }
        fetch()
    }, [lng])

    useEffect(() => {
        dispatch(resetSearch())
    }, [])


    // const customLabel = selectAll ? 'Deselect All' : '';

    return (
        <>
            {/* {active === 0 && <SignUp id="job-modal" modalOpen={true}/>} */}

            <div className='relative w-full'>
                {
                    <div className="bg-explore bg-cover w-full bg-no-repeat lg:h-[700px] h-[950px] ">
                        <div className='bg-black h-full opacity-[0.3]'></div>
                        <div className='container mx-auto'>
                            <div className=' absolute lg:top-[30%] top-[10%] '>
                                <div className='grid lg:grid-cols-2'>
                                    <div className=' w-full lg:px-[4rem]  px-[2rem]  py-6'>
                                        <h2 className='text-white  font-bold lg:text-[2.8rem] text-[2rem] '>{t('explore_ultimate')}</h2>
                                        <p className='text-white '>{t('match_people')}</p>
                                    </div>
                                    <div className='w-full'>
                                        <div className='border rounded-md lg:max-w-[600px] md:max-w-[700px] md:m-auto bg-white '>

                                            <div className="grid grid-cols-2">
                                                <div onClick={() => setActive(0)} className={`${active === 0 && 'border-b-4 border-[#F79F15]'} flex-col  items-center cursor-pointer rounded-sm  flex justify-center p-4`}>
                                                    <ImHome2 className={`text-[2.5rem] ${active === 0 ? 'text-[#F79F15]' : 'text-black'}`} />
                                                    <h2 className='font-semibold text-[20px] pt-2'>{t('list')} </h2>
                                                </div>
                                                <div onClick={() => setActive(1)} className={`${active === 1 && 'border-b-4 border-[#F79F15]'} flex-col  items-center cursor-pointer rounded-sm  flex justify-center p-4`}>
                                                    <ImKey className={`text-[2.5rem] ${active === 1 ? 'text-[#F79F15]' : 'text-black'}`} />
                                                    <h2 className='font-semibold text-[20px] pt-2'>{t('rent_lbl')} </h2>
                                                </div>
                                            </div>

                                            <div className='p-5'>
                                                {
                                                    active === 0 ?
                                                        <div>
                                                            <h2 className='text-[2rem] font-bold'>{t('list_with_leased')}</h2>
                                                            <p className='text-[14px]'>{t('discover_new')}</p>
                                                            {!token &&
                                                                <div className='flex justify-between items-center pt-2'>
                                                                    <h2 className='font-bold'>{t('sign_up_post')}</h2>
                                                                    <button className='px-6 py-2 rounded-md text-white bg-[#F79F15] border' onClick={() => setSignUp(true)}>{t('sign_up_home')}</button>

                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div className=''>
                                                            <div className=''>
                                                                <button onClick={() => setType(0)} className={`${type === 0 ? 'bg-[#F79F15] text-white' : "bg-white text-[#F79F15]"}  border border-[#F79F15]  font-semibold px-6 py-2 rounded-md mx-3`}>{t('rent_lbl')}</button>
                                                                <button onClick={() => setType(1)} className={`${type === 1 ? 'bg-[#F79F15] text-white' : "bg-white text-[#F79F15]"}  border border-[#F79F15]  font-semibold px-6 py-2 rounded-md`}>{t('rommates')}</button>
                                                            </div>
                                                            <div className='my-4 flex flex-col lg:flex-row items-start justify-start '>
                                                                <div className='w-[40%] '>
                                                                    <DynamicDropdonw text={leasedPeriod} list={leasedTye} setFormData={setFormData} />
                                                                </div>
                                                                <div className='border w-full h-[35px] lg:mx-2 lg:my-auto my-3 flex items-center rounded-md '>
                                                                    <Autocomplete
                                                                        apiKey={"AIzaSyCpBFsGdMzJ_qo1PPGUM6i0g__WTZuzTQk"}
                                                                        options={autocompleteOptions}
                                                                        defaultValue={defaultAddress}
                                                                        onPlaceSelected={async (place) => {
                                                                            let addres = place?.formatted_address
                                                                            setDefaultAdress(addres)
                                                                            const latitude = place.geometry?.location?.lat();
                                                                            const longitude = place.geometry?.location?.lng();
                                                                            let city = "";
                                                                            let country = "";
                                                                            let zipcode = "";
                                                                            let street = "";
                                                                            let propertyId = "";

                                                                            // Iterate through the address components
                                                                            place?.address_components.forEach(component => {
                                                                                const types = component.types;
                                                                                // Check for the types relevant to city, country, and zipcode
                                                                                if (types.includes("locality")) {
                                                                                    city = component.long_name;
                                                                                } else if (types.includes("administrative_area_level_1")) {
                                                                                    country = component.short_name;
                                                                                } else if (types.includes("postal_code")) {
                                                                                    zipcode = component.long_name;
                                                                                }
                                                                                else if (types.includes("route")) {
                                                                                    street = component.long_name;
                                                                                }
                                                                                else if (types.includes("street_number")) {
                                                                                    propertyId = component.long_name;
                                                                                }
                                                                            })
                                                                            let payload = {
                                                                                latitude,
                                                                                longitude,
                                                                                address: addres,
                                                                                city,
                                                                                state: country,
                                                                                zipcode,
                                                                                street: `${propertyId} ${street}`
                                                                            }
                                                                            dispatch(addExplore({ propertyName: "address", value: payload }))
                                                                            setFormData((prev) => ({ ...prev, payload }))

                                                                        }}

                                                                        className='w-full px-2 
                                            border-transparent focus:outline-none placeholder:text-[#A5A5A5]'
                                                                        placeholder={t('select_location')}
                                                                    />
                                                                    <button className='border-l  rounded-r-md bg-[#F79F15]   w-[40px] h-[40px] flex justify-center items-center'>
                                                                        <BsSearch className='text-white mx-3 ' />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className='grid lg:grid-cols-4 lg:gap-2 gap-4 grid-cols-2 md:grid-cols-3'>
                                                                <div>
                                                                    <DynamicDropdonw text={PropertyData} list={propertyType} setFormData={setFormData} />
                                                                </div>
                                                                <div>
                                                                    <DynamicDropdonw text={PriceData} list={"input"} setFormData={setFormData} />
                                                                </div>
                                                                <div>
                                                                    <DynamicDropdonw setFormData={setFormData} text={BedData} list={bed} />
                                                                </div>
                                                                <div>
                                                                    <DynamicDropdonw setFormData={setFormData} text={RomeData} list={bath} />
                                                                </div>
                                                            </div>
                                                            <div className='grid mt-3 lg:grid-cols-2 lg:gap-2 gap-4 grid-cols-2 md:grid-cols-2'>

                                                                <div>
                                                                    <h2 className='text-[12px] font-normal '>{t('mile_radius')}</h2>
                                                                    <div className='flex  items-center '>
                                                                        <span>0</span>
                                                                        <input
                                                                            type="range"
                                                                            min="0"
                                                                            className='progress' style={{ accentColor: "#FF8C00", width: '80%', color: 'red' }}
                                                                            max="100"
                                                                            step="5"
                                                                            value={radiuss}
                                                                            onChange={(e) => setRadius(e.target.value)}
                                                                        />

                                                                        <p> {radiuss}</p>
                                                                    </div>

                                                                </div>
                                                                <div>
                                                                    <MultiSelect
                                                                        // options={aminities}
                                                                        options={aminities}
                                                                        value={selected}
                                                                        onChange={setSelected}
                                                                        overrideStrings={{
                                                                            selectSomeItems: t('amnity_lab'),
                                                                            allItemsAreSelected: t('all_option'),
                                                                            selectAll: t('select_all'),
                                                                            search:t('search_lab')
                                                                        }}
                                                                    // className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <"> */}
                                                            <div onClick={() => handleMove()} className='border w-full text-center py-2 mt-3 rounded-md text-white cursor-pointer   bg-[#F79F15]'>{t('search_lab')}</div>
                                                            {/* </Link> */}
                                                        </div>


                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </>
    )
}

export default ExploreBanner