import React, { useState, useRef, useEffect } from 'react';
import Transition from '../Transition';
import message from '../../assests/images/message.png'
import servicerImg from '../../assests/images/s_app.png'
import renterImage from '../../assests/images/r_app.png'
import { BsBellFill } from 'react-icons/bs';
import { RiMessage3Fill } from 'react-icons/ri'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GetNotifications, GetNotificationsCount } from '../../lib/Api/RentalAppApi';
import { ref, onValue, off, get, onChildAdded, orderByChild, equalTo, } from 'firebase/database';

// import moment from 'moment/moment';
// import { IoMdArrowDropdown } from 'react-icons/io'

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loader } from '../loaderButton';
import { Truncate } from '../TrucateString';
import { setLocateNotification, setUsers } from '../../Redux/UserAuthSlice/UserAuthSlice';
import 'moment/locale/es';
import { database } from '../../config/Firebase';
import { GetSingleUser, GetUsers } from '../../components/GenericComp/GetcChatUser';
import { query } from 'firebase/firestore';

function MessageDrop({ type, setproperty, setShow, setType }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notifLoader, setNotifLoader] = useState(false);
    const [notificationLoader, setNotificationLoader] = useState(false);
    const [messageLoder, setMessageLoader] = useState(false);
    const [showNotiMes, setShowNotifMes] = useState(false);
    const [notif, setNotif] = useState([]);
    const [count, setCount] = useState(0);
    const [UnReadCount, setUnReadCount] = useState(0);
    const [nextPage, setnextpage] = useState();
    const [page, setPage] = useState(1)
    const [users, setUsers] = useState([]);


    const trigger = useRef(null);
    const dropdown = useRef(null);
    const dispatch = useDispatch()
    // const reciverid = useParams().id
    const user = useSelector((state) => state?.userAuth?.userInfo)
    const allUsers = useSelector((state) => state?.userAuth?.chatUsers)
    const notifId = useSelector((state) => state?.userAuth?.removeId)
    // const token = useSelector((state) => state.userAuth.loginInfo.token);
    const lng = useSelector((state) => state?.userAuth?.language)

    const location = useLocation().pathname

    // console.log("allUsers", allUsers)


    const navigate = useNavigate()

    const { t } = useTranslation()

    let fetchNotifi = async () => {
        setNotificationLoader(true)
        let { res } = await GetNotifications(page)
        if (res) {
            let obj = Object.assign({}, ...res)
            setNotificationLoader(false)
            setNotif(obj?.notifications?.data)
            setnextpage(obj?.notifications?.next_page_url)
        }
        // setNotif((prevData) => [...prevData, ...obj?.notifications?.data]);
    }

    let fetchNotifiCount = async () => {
        setMessageLoader(true)
        let res = await GetNotificationsCount()
        if (res) {
            let obj = Object.assign({}, ...res)
            setMessageLoader(false)
            setCount(obj?.notifications_count)
        }
    }



    const handleScroll = async (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

        // if (scrollHeight - (scrollTop + clientHeight) < 10) {
        if (scrollTop + clientHeight >= scrollHeight - 20 && !notifLoader) {
            setNotifLoader(true)
            let inc = page + 1
            setPage(inc)
            let { res } = await GetNotifications(inc)
            let obj = Object.assign({}, ...res)
            setNotifLoader(false)
            setNotif((prevData) => [...prevData, ...obj?.notifications?.data]);
        }
    };



    const handleNotifi = (list) => {
        if (list.screen === "booked") {
            dispatch(setLocateNotification("Booked"))
            if (user?.userRole === "servicer") {
                navigate(`mylist`)
            }
            else {
                navigate(`/dashboard`)
            }
        }
        else if (list.screen === "saved") {
            dispatch(setLocateNotification("Saved"))
            if (user?.userRole === "servicer") {
                navigate(`mylist`)
            }
            else {
                navigate(`/dashboard`)
            }
        }
        else if (list.screen === "confirmed") {
            dispatch(setLocateNotification("Confirmed"))

            if (user?.userRole === "servicer") {
                navigate(`mylist`)
            }
            else {
                navigate(`/dashboard`)
            }
        }
        else if (list.screen === "archived") {
            dispatch(setLocateNotification("Archived"))
            if (user?.userRole === "servicer") {
                navigate(`mylist`)
            }
            else {
                navigate(`/dashboard`)
            }
        }
        else if (list.screen === "post_appointment") {
            setproperty(list)
            setShow(true)
        }
        else if (list.screen === "post_appointment_roommate") {
            setproperty(list)
            setShow(true)
        }
        else if (list.screen === "post_appointment_roommate_alert") {
            setproperty(list)
            setShow(true)
        }
        else if (list.screen === "post_appointment_alert") {
            // dispatch(setLocateNotification("post_appointment_alert"))
            setproperty(list)
            setShow(true)
        }
        else if (list?.screen === "feedback_notification") {
            setType("feedback")
            setproperty(list)
            setShow(true)
        }
    }


    const MoveMessage = (check) => {
        setDropdownOpen(false)
        if (type !== 'notification')
            navigate(`/message/${check}`)
    }



    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        fetchNotifiCount()
        setPage(1)
        if (dropdownOpen === true && type === "notification") {
            fetchNotifi()
        }
    }, [dropdownOpen])

    const filteredNotifications = notif?.filter(
        (notification) => notification.property_id !== notifId
    );

    moment.locale(lng === 'sp' ? 'es' : 'en');
    const hasUnreadMessages = allUsers?.some(user => user?.unReadCount > 0);

    return (
        <div className="relative">
            <button
                ref={trigger}
                className={`  px-2 py-1 w-full  flex  flex-col justify-between  bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600`}

                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
                {type === "notification" ?
                    <div className='relative'>
                        <BsBellFill className='text-[20px]' />
                        {count > 0 ?
                            <div className='w-2 h-2 bg-red-500 rounded-full text-white absolute -top-2 -right-1 flex justify-center items-center text-[12px]'></div>
                            : ""
                        }
                    </div>
                    :
                    <div className='relative'>
                        <RiMessage3Fill className='text-[25px]' />
                        {hasUnreadMessages ?
                            <div className='w-2 h-2 bg-red-500 rounded-full text-white absolute -top-[1px] -right-[1px] flex justify-center items-center text-[12px]'></div>
                            : ""
                        }
                    </div>
                }
            </button>
            <Transition
                show={dropdownOpen}
                tag="div"
                className={`first-letter:z-10 absolute top-full xl:right-0 lg:right-0 md:right-0 -right-20  h-[300px] xl:w-[50vh] lg:w-[50vh] md:w-[50vh] w-[40vh]   bg-white border border-slate-200 rounded shadow-lg overflow-hidden ${type === "notification" ? 'mt-4' : 'mt-4'}
                `}
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-slate-600 "
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    {/* {
                        list?.map(option => {
                            return ( */}
                    <div className="w-full absolute z-10 border h-full overflow-x-hidden transform translate-x-0 transition ease-in-out duration-700" id="notification" onScroll={type === "notification" && nextPage !== null ? handleScroll : ""}
                    >
                        <div className=" bg-white  p-5  w-full   absolute right-0">
                            <div className="flex items-center justify-between">
                                <p className="xl:text-2xl lg:text-2xl md:text-2xl text-[14px] font-semibold leading-6 text-gray-800">{type === "notification" ? t('notification') : t('messages')} </p>

                            </div>
                            {type !== 'notification' ?
                                <div className=' border-b pb-1 w-full '>
                                    {
                                        allUsers?.map((userlist) => {
                                            let check = user?.userid === userlist?.receiverID ? userlist?.senderID : userlist?.receiverID
                                            const timeAgo = moment.utc(userlist?.timestamp)
                                                .fromNow()
                                            const translatedTimeAgo = t(timeAgo);
                                            return (
                                                <div onClick={() => MoveMessage(check)}>
                                                    <div className="w-full  mt-8   ">
                                                        <div className='flex justify-between'>
                                                            <div className='flex'>
                                                                <div
                                                                    className="flex items-center justify-center border h-8 w-8 bg-indigo-200 rounded-full"
                                                                >
                                                                    <img src={userlist?.image || message} alt="user" className='rounded-full w-8 h-8' />
                                                                </div>
                                                                <div className='flex flex-col items-start'>
                                                                    <h2 className="text-[12px] px-2 leading-[18px] font-semibold text-[#444444] truncate  lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full">
                                                                        {userlist.first_name !== "" ? Truncate(userlist?.first_name, 15) : "N/A"}
                                                                    </h2>
                                                                    <div className='px-2'>
                                                                        <p className="text-[12px] text-[#898989] leading-[18px] truncate lg:sidebar-expanded:w-[50px] xl:sidebar-expanded:w-full ">
                                                                            {Truncate(userlist?.lastMessage, 15)}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <h2 className="text-[#98A2B3] text-[10px] flex items-center">
                                                                    {translatedTimeAgo}
                                                                </h2>
                                                                {userlist?.unReadCount > 0 &&
                                                                    <div className='flex justify-end items-end'>
                                                                        <div className='bg-red-500 text-center text-[12px] rounded-full w-5 h-5 pt-[1px] text-white'>
                                                                            {userlist?.unReadCount}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })

                                    }

                                </div>
                                :
                                notif?.length > 0 ? (
                                    <div

                                    >
                                        {
                                            filteredNotifications?.map((list) => (
                                                <div className=' border-b pb-1 w-full ' onClick={() => handleNotifi(list)}>
                                                    <div className="w-full  mt-8   ">
                                                        <div className=" flex justify-between ">
                                                            <div className="flex justify-between rounded-full border-gray-200 ">
                                                                <img src={list?.user?.user_image ? list?.user?.user_image : user?.userRole === "servicer" ? servicerImg : renterImage} className='w-10 h-10 object-cover rounded-full' alt="message" />
                                                                <p className="text-sm leading-none px-2">
                                                                    <h1 className='xl:text-[1.2rem] lg:text-[1.2rem] md:text-[1.2rem] text-[1rem] font-semibold'>{list?.user?.first_name}</h1>
                                                                    <h2 className='py-1 text-[14px]'>{list?.title}</h2>
                                                                    <p className="text-xs leading-3  text-gray-500 py-2">{list?.human_readable_time}</p>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            ))
                                        }
                                        {notifLoader && <div className='p-5'><Loader type="loadData" /></div>}
                                    </div>
                                )
                                    :
                                    notificationLoader ? <div className='p-5'><Loader type="loadData" /></div> :
                                        (
                                            <p>No notifications available.</p>
                                        )
                            }

                        </div>
                    </div>
                    {/* )
                        })


                    } */}
                </div>
            </Transition >
        </div >
    );
}

export default MessageDrop;

