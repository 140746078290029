import React from 'react'
import serviceImg from '../../assests/images/service.png'
import { BsCheckCircle } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
const Services = () => {

    const {t}  = useTranslation()
    return (
        <>
            <div className='container mx-auto '>
                <h2 className='text-center font-bold underline lg:text-[2.3rem] text-[2rem] py-7'>{t('how_leased')}</h2>

                <div className='grid lg:grid-cols-2'>
                    <div className='flex justify-center'>
                        <img src={serviceImg} className="w-[75%] object-contain" alt="service" />
                    </div>
                    <div className='lg:px-0 px-5'>
                        <h2 className='text-left font-bold underline lg:text-[2.3rem] text-[2rem] py-7'>{t('for_service')}</h2>
                        <p className='lg:text-[20px] text-[18px] mb-2 font-medium'>{t('lists')}</p>
                        {/* <span className=' text-justify'>{t('lists')}</span> */}

                        <div className='my-2'>
                            <ul>
                                <li className='my-2'>
                                    <div className='flex items-center'>
                                        <div className='border rounded-full bg-green-500 p-1'>
                                            <BsCheckCircle className='text-white text-[13px]' />
                                        </div>
                                        <h2 className='text-[13px] mx-1 font-semibold'>{t('collect_rent')}</h2>
                                    </div>
                                </li>
                                <li className='my-2'>
                                    <div className='flex items-center'>
                                        <div className='border rounded-full bg-green-500 p-1'>
                                            <BsCheckCircle className='text-white text-[13px]' />
                                        </div>
                                        <h2 className='text-[13px] mx-1 font-semibold'>{t('confirm_book')}</h2>
                                    </div>
                                </li>
                                <li className='my-2'>
                                    <div className='flex items-center'>
                                        <div className='border rounded-full bg-green-500 p-1'>
                                            <BsCheckCircle className='text-white text-[13px]' />
                                        </div>
                                        <h2 className='text-[13px] mx-1 font-semibold'>{t('upload_lease')}</h2>
                                    </div>
                                </li>
                                <li className='my-2'>
                                    <div className='flex items-center'>
                                        <div className='border rounded-full bg-green-500 p-1'>
                                            <BsCheckCircle className='text-white text-[13px]' />
                                        </div>
                                        <h2 className='text-[13px] mx-1 font-semibold'>{t('learn_rent')}</h2>
                                    </div>
                                </li>
                                <li className='my-2'>
                                    <div className='flex items-center'>
                                        <div className='border rounded-full bg-green-500 p-1'>
                                            <BsCheckCircle className='text-white text-[13px]' />
                                        </div>
                                        <h2 className='text-[13px] mx-1 font-semibold'>{t('learn_cash')}</h2>
                                    </div>
                                </li>

                            </ul>
                            <div className='flex flex-col items-start '>
                                <Link to="/list" className='rounded-full font-semibold px-6 my-4 py-2 bg-[#F79F15] text-white'>{t('learn_more_list')}</Link>
                                <Link to="/list/rommate" className='rounded-full font-semibold px-6 py-2 bg-[#F79F15] text-white'>{t('learn_more_list_rom')}</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Services