import React, { useEffect } from 'react'
import Dashboard from '../../components/RenatalPanelComp/Dashboard/Dashboard'
import { ChangeLanguage } from '../../lib/Api/UserApi'
import { useSelector } from 'react-redux'
const RentalDashboard = () => {


  const lng = useSelector((state) => state?.userAuth?.language)
  const handleChangeLanguage = async (check) => {
    await ChangeLanguage(lng)
  }

  useEffect(() => {
    handleChangeLanguage()
  }, [])
  return (
    <>
      <Dashboard />
    </>
  )
}

export default RentalDashboard