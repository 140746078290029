
import {
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../config/Firebase";
export const generateCap = (number, setActiveTab, setVerify) => {
    let recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'normal',
        'callback': (response) => {
            if (setVerify) {
                setVerify(true)
            }
            else {
                setActiveTab(1)
            }
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
        },
        'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
        }
    }, auth);

    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier)
}
