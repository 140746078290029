import React from 'react'
import Layout from '../../components/Layout/Layout'
import OurBlog from '../../components/BlogsComp/OurBlog'
import BlogLists from '../../components/BlogsComp/BlogLists'
const Blog = () => {
  return (
    <Layout>
      <OurBlog/>
      <BlogLists/>
    </Layout>
  )
}

export default Blog