import React, { useEffect, useState } from 'react'
import { useSelector , useDispatch} from 'react-redux'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
// import { EditUserProfile } from '../../../lib/Api/UserApi';
import { Loader } from '../../../utils/loaderButton';
// import { updateUser } from '../../../Redux/UserAuthSlice/UserAuthSlice';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
// import { generateCap } from '../../../utils/PhoneCaptcGene';/
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth, database } from "../../../config/Firebase";
import { EditUserProfile } from '../../../lib/Api/UserApi';
import { updateUser } from '../../../Redux/UserAuthSlice/UserAuthSlice';
import { get, ref, update } from 'firebase/database';
const schema = yup.object({
  fname: yup.string().required("First Name is required"),
  lname: yup.string().required("Last Name is required"),
  email: yup.string().email("Invalid email format").required("Email is required"),
  // phone: yup.string(),
  city: yup.string().required("City is required"),
  countryState: yup.string().required("State is required"),
  zipcode: yup.string().required("ZipCode is required"),
  address: yup.string().required("Address is required"),
});

const RightSide = ({ setActiveTab, setProfileData, setResult, setActiveTabText }) => {
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState("")
  const [fiedError, setFieldError] = useState("")
  const dispatch = useDispatch()
  const user = useSelector((state) => state?.userAuth?.userInfo)
  const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const generateCap = (number, setActiveTab) => {
    let recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'normal',
      'callback': (response) => {
        setActiveTab(1)
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
      'expired-callback': () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      }
    }, auth);

    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier)
  }

  const handleUpdate = async (UserData) => {
    const usersRef = ref(database, `user/${user?.userid}`);
    try {
        const snapshot = await get(usersRef); 
        const newUserData = {
            ...snapshot.val(), 
            "first_name": UserData?.user?.first_name,
            "last_name": UserData?.user?.last_name,
        };
        await update(usersRef, newUserData);
    } catch (error) {
        console.error("Error updating user data:", error);
    }
};

  const onSubmit = async (data) => {

    if (phone === "") {
      setFieldError("Phone is required")
    }
    else {
      const formData = new FormData();
      formData.append('first_name', data.fname)
      formData.append('last_name', data?.lname)
      formData.append('phone', phone)
      formData.append('email', data?.email)
      formData.append('city', data?.city)
      formData.append('address', data?.address)
      formData.append('state', data?.countryState)
      formData.append('zip', data?.zipcode)
      // formData.append('user_image', pic)s
      // let payload = {
      //   id: user?.userid,
      //   first_name: data?.fname,
      //   last_name: data?.lname,
      //   phone_number: phone,
      //   email: data?.email,
      //   address: data?.address,
      //   city: data?.city,
      //   state: data?.state,
      //   zip: data?.zipcode,
      //   user_image:  

      // }
      if (phone !== user?.phone) {
        const response = await generateCap(`+${phone}`, setActiveTab);
        if (response) {
          setResult(response);
          setProfileData(formData)
          setActiveTabText("otp_verify")
        }
      }
      else {
        setLoading(true)
        let { res, error } = await EditUserProfile(formData)
        if (res) {
          let UserData = Object.assign({}, ...res)
          handleUpdate(UserData)
          dispatch(updateUser(UserData))
          setLoading(false)
        }
        else if (error) {
          setLoading(false)

        }
      }
    }


  }


  let { t } = useTranslation()

  useEffect(() => {
    reset(user)
    setPhone(user?.phone)
  }, [])



  return (
    <>
      <div className='xl:pt-[4rem] lg:pt-[4rem] md:pt-[4rem] pt-2 px-4 xl:pb-0 lg:pb-0 md:pb-0 pb-10'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2  xl:gap-20 lg:gap-20 md:gap-20 '>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('contact_first_name')} </label>
              <input type="text" {...register('fname')} placeholder='John' className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.fname ? 'border border-red-500' : ''}`} />

              {errors.fname && (
                <p className="text-red-500 text-sm ">{errors.fname.message}</p>
              )} </div>
            <div className='flex flex-col mb-4'>
              <label className='mb-2 text-[16px] font-semibold'>{t('contact_last_name')} </label>
              <input type="text"  {...register('lname')} placeholder='doe' className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.lname ? 'border border-red-500' : ''}`} />
              {errors.lname && (
                <p className="text-red-500 text-sm ">{errors.lname.message}</p>
              )}
            </div>
          </div>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('email')} </label>
            <input type="email"  {...register('email')} placeholder='jhon.doe@gmail.com' className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.email ? 'border border-red-500' : ''}`} />
            {errors.email && (
              <p className="text-red-500 text-sm ">{errors.email.message}</p>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <label className='mb-2 text-[16px] font-semibold'>{t('phone')}</label>
            <div className={`${fiedError ? 'border-b border-red-500 ' : ''} w-full`}>
              <PhoneInput
                country={'us'}
                value={phone}
                onChange={d => setPhone(d)}
              />
            </div>
            {fiedError && (
              <p className="text-red-500 text-sm ">{fiedError} </p>
            )}
          </div>
          <div className='flex flex-col'>
            <label className='mb-2 text-[16px] font-semibold'>{t('address')}   </label>
            <input type="text" placeholder={t('profile_Addres')}  {...register('address')} className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.address ? 'border border-red-500' : ''}`} />
          </div>
          <div className='flex flex-col mt-2'>
            <label className='mb-2 text-[16px] font-semibold'>{t('address')} #2</label>
            <input type="text" placeholder={t('curent_Ad')}  {...register('address')} className={`p-2 rounded-md bg-[#F5F6FC] focus:outline-none ${errors.address ? 'border border-red-500' : ''}`} />
          </div>
          <div className='grid xl:grid-cols xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 xl:gap-5 lg:gap-5 md:gap-5  xl:mt-4 lg:mt-4 md:mt-4'>
            <div>
              <label className='mb-2 text-[16px] font-semibold hidden mt-3'>dffsd </label>
              <input type="text" placeholder={`${t('city')}`}  {...register('city')} className={`p-2 mt-[1.5rem] w-[100%] rounded-md bg-[#F5F6FC]  focus:outline-none ${errors.city ? 'border border-red-500' : ''}`} />
              {/* {errors.city && (
                <p className="text-red-500 text-sm ">{errors.city.message}</p>
              )} */}
            </div>
            <div>
              <label className='mb-2 text-[16px] font-semibold hidden mt-3'>dffsd </label>
              <input type="text" placeholder={t('state')}  {...register('countryState')} className={`mt-[1.5rem] p-2 w-[100%] focus:outline-none rounded-md bg-[#F5F6FC]  ${errors.countryState ? 'border border-red-500' : ''}`} />
              {/* {errors.countryState && (
                <p className="text-red-500 text-sm ">{errors.countryState.message}</p>
              )} */}
            </div>
            <div>
              <label className='mb-2 text-[16px] font-semibold hidden'>sdfsd </label>
              <input type="text" placeholder={`${t('zipcode')}`}  {...register('zipcode')} className={`mt-[1.5rem] p-2 w-[100%] focus:outline-none rounded-md bg-[#F5F6FC]  ${errors.zipcode ? 'border border-red-500' : ''}`} />
              {/* {errors.zipcode && (
                <p className="text-red-500 text-sm ">{errors.zipcode.message}</p>
              )} */}
            </div>
          </div>


          <div id="recaptcha-container"
            data-sitekey="6LcsaxsdAAAAAEBn0sPDCEncnU9564MisyRuDzD_"
            data-callback="sendForm"
            data-size="normal">
          </div>



          <div className='flex justify-end my-5'>
            {
              loading ?
                <Loader type="saved" />
                :
                <button className='px-8 py-1.5 text-white  rounded-md bg-[#F79F15]'>{t('save')}</button>
            }
          </div>
        </form>
      </div>
    </>
  )
}

export default RightSide